import imageError from '../../assets/images/error.png';
import * as React from 'react';

const ErrorNotFound: React.FC = () => {
  return (
    <div className="transition-opacity duration-300">
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col w-full text-center p-4">
          <h1 className="text-xl font-bold text-black">Loja não encontrada</h1>
          <img
            src={imageError}
            alt="404"
            className="self-center w-[60%] md:w-64"
            loading="lazy"
          />
          <h1 className="text-lg font-bold text-black">
            Esta loja não foi encontrada, <br />
            por gentileza verifique o link acessado.
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ErrorNotFound;
