import React, { useEffect, useRef, useState } from 'react';
import NoPhoto from '../../assets/images/no-photos.png';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { Medium } from '@onbeefapp/constants';
import { useMerchantStore } from '../../stores/merchant';
import Skeleton from '../Skeleton/Skeleton';
import { RotativeImagesDetail } from './RotativeImagesDetail';

interface RotativeImageProps {
  productName: string;
  images: Medium[];
}

const RotativeImage: React.FC<RotativeImageProps> = ({
  images,
  productName,
}) => {
  const [open, setOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const startXRef = useRef(0);
  const isDragging = useRef(false);
  const sensitivity = 120;

  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);
  const { merchant } = useMerchantStore((state) => ({
    merchant: state.merchant,
  }));

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (e.touches.length === 1) {
      startXRef.current = e.touches[0]!.clientX;
      isDragging.current = true;
    }
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (e.touches.length === 1 && isDragging.current) {
      const currentX = e.touches[0]!.clientX;
      const diffX = startXRef.current - currentX;

      if (Math.abs(diffX) > sensitivity) {
        if (diffX > sensitivity) {
          setCurrentImageIndex((prevIndex) =>
            Math.min(prevIndex + 1, images.length - 1),
          );
        } else if (diffX < -sensitivity) {
          setCurrentImageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        }
        startXRef.current = currentX;
      }
    }
  };

  const handleTouchEnd = () => {
    isDragging.current = false;
  };

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      container.style.transform = `translateX(${-currentImageIndex * 100}%)`;
      container.style.transition = 'transform 0.3s ease-in-out';
    }
  }, [currentImageIndex]);

  if (!images) return null;

  return (
    <React.Fragment>
      <Skeleton
        className="w-full h-[40vh]"
        isLoading={isLoading}
        borderRadius="10px"
      >
        <div className="relative w-full overflow-hidden">
          <div
            ref={containerRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            className="flex mx:auto w-full lg:gap-2"
            style={{
              flexDirection: 'row',
              transition: 'transform 0.3s ease-in-out',
            }}
          >
            {images.map((image, index) => (
              <div
                key={`image-${index}`}
                className="flex-shrink-0 w-full lg:w-auto"
              >
                <center>
                  <img
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = merchant?.logo || NoPhoto;
                    }}
                    onClick={() => setOpen(true)}
                    className="object-cover lg:h-[300px] lg:rounded-md h-[40vh] w-full lg:w-auto"
                    src={image.file_url}
                    loading="lazy"
                    alt={`Imagem ${index + 1}`}
                  />
                </center>
              </div>
            ))}
          </div>
        </div>
      </Skeleton>
      <RotativeImagesDetail
        open={open}
        onClose={() => setOpen(false)}
        index={currentImageIndex}
        images={images}
        productName={productName}
      />
    </React.Fragment>
  );
};

export default RotativeImage;
