import React, { useEffect, useState } from 'react';
import { useProductsStore } from '../stores/products';
import { diff } from '@onbeefapp/constants';

interface Props {
  productID: string;
  targetDate: Date;
  setIsExpired: React.Dispatch<React.SetStateAction<boolean>>;
}

const Countdown: React.FC<Props> = ({
  productID,
  targetDate,
  setIsExpired,
}) => {
  const { refreshProduct } = useProductsStore();

  const [timeRemaining, setTimeRemaining] = useState(
    diff(targetDate, new Date()),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeRemaining = diff(targetDate, new Date());

      if (newTimeRemaining.seconds < 0) {
        setIsExpired(true);

        refreshProduct(productID);

        clearInterval(interval);
      }

      setTimeRemaining(newTimeRemaining);
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const days = timeRemaining.days.toString().padStart(2, '0');
  const hours = timeRemaining.hours.toString().padStart(2, '0');
  const minutes = timeRemaining.minutes.toString().padStart(2, '0');
  const seconds = timeRemaining.seconds.toString().padStart(2, '0');

  //If days equal 0, don't show days
  if (days === '00') {
    return <>{`${hours} : ${minutes} : ${seconds}`}</>;
  }

  return <>{`${days}D ${hours} : ${minutes} : ${seconds}`}</>;
};

export default Countdown;
