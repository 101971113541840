import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput } from '@onbeef/components/input';
import { Toast } from '@onbeefapp/constants';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useForgotPassword } from '../../queries/login';
import { Link } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Informe um email valido'),
});

export default function ForgotPassword() {
  const forgotPassword = useForgotPassword();
  const methods = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (formData: { email: string }) => {
    forgotPassword
      .mutateAsync(formData.email)
      .then((response) => {
        if ('message' in response.data) {
          Toast.success(response.data.message);
        } else if (response.data.error) {
          Toast.error(response.data.error.join(' '));
        }
      })
      .catch((ex) => {
        Toast.error(ex);
      });
  };

  return (
    <div className="w-full h-screen flex items-center justify-center vertical-center p-6">
      <div className="w-full max-w-md">
        <h2 className="text-3xl font-bold mb-6">Esqueceu a senha?</h2>
        <p className="mb-2">
          Sem problemas! Insira seu e-mail para criar uma nova senha.
        </p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-6">
            <div className="flex flex-col">
              <TextInput
                name="email"
                placeholder="E-mail obrigatório"
                type="email"
                className="mt-1 p-3 border border-gray-500 rounded mb-1"
              />
              {(forgotPassword.isError || forgotPassword.isSuccess) && (
                <span className="text-sm text-gray-600">
                  Se o email existir na nossa base, você receberá um email com
                  as instruções para redefinir sua senha.
                </span>
              )}
            </div>
            <div className="flex justify-between items-center">
              <Link
                className="px-6 py-2 rounded border border-gray-300 font-medium text-gray-500"
                to="/login"
              >
                Voltar
              </Link>
              <button
                className="bg-red-600 text-white px-6 py-2 rounded"
                type="submit"
              >
                {forgotPassword.isLoading ? 'Carregando...' : 'Resetar Senha!'}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
