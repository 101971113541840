import { AxiosError } from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { useValidateCoupon } from '../../queries/coupon';
import { Coupon } from '../../queries/coupon/types';
import { useCartStore } from '../../stores/cart';
import { useOrderDetails } from '../../stores/order-details';
import LoadingSpin from '../LoadingSpin';
import { Toast } from '@onbeefapp/constants';

interface CardCouponProps {
  coupon: Coupon;
}

const CardCoupon: React.FC<CardCouponProps> = ({ coupon }) => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const {
    merchantId,
    coupon: selectedCoupon,
    setCoupon: setSelectedCoupon,
  } = useCartStore();
  const { deliveryType } = useOrderDetails();
  const { subtotal, userClubDiscount } = React.useContext(OrderDetailsContext);

  const {
    mutate: validateCoupon,
    data,
    error,
    isLoading,
  } = useValidateCoupon();

  const handleSelectCoupon = () => {
    if (!merchantId) return;
    if (selectedCoupon && selectedCoupon.code === coupon.code) {
      setSelectedCoupon(undefined);
    } else {
      validateCoupon({
        code: coupon.code,
        merchant_id: merchantId,
        order_amount: subtotal - userClubDiscount,
        type_delivery: deliveryType,
      });
    }
  };

  React.useEffect(() => {
    if (data) {
      setSelectedCoupon(data);
      navigate(-1);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
        ?.error;
      msgs?.forEach((msg) => Toast.error(msg));
      setSelectedCoupon(undefined);
    }
  }, [error]);

  return (
    <div
      className={
        'flex flex-col w-full items-center justify-center text-sm bg-white'
      }
    >
      <div
        className={`w-full p-2 border shadow-md rounded-md ${
          selectedCoupon?.code === coupon.code
            ? 'border-current'
            : 'border-gray-300'
        }`}
      >
        <div className="flex flex-row p-2">
          <div className="w-11/12">
            <div className="w-full">
              <b>{coupon.code}</b>
            </div>
            <div className="w-full">{coupon.description}</div>
          </div>
          <div className="w-1/12">
            {isLoading ? (
              <LoadingSpin color="primary" />
            ) : (
              <input
                type="radio"
                checked={selectedCoupon?.code === coupon.code}
                onChange={handleSelectCoupon}
                disabled={!coupon.status}
              />
            )}
          </div>
        </div>
        <div
          className={`p-1 pb-${
            open ? '2' : '3'
          } text-primary hover:cursor-pointer`}
          onClick={() => setOpen(!open)}
        >
          <b>{open ? 'Ocultar regras' : 'Ver regras'}</b>
        </div>
        {open && (
          <div className="p-2 pb-5">
            <ul className="pl-6 text-gray-700">
              {coupon.rules.map((rule, index) => (
                <li key={index}>{rule}</li>
              ))}
            </ul>
          </div>
        )}
        <div
          className={`flex items-center justify-center rounded-md ${
            coupon.status
              ? 'bg-green-500 text-white'
              : 'bg-gray-200 text-gray-700'
          } p-2 text-sm`}
        >
          <b>
            {coupon.status ? (
              <button
                onClick={handleSelectCoupon}
                className="flex items-center cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 inline mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                  />
                </svg>
                Adicionar cupom
              </button>
            ) : (
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 inline mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                  />
                </svg>
                Cupom bloqueado
              </div>
            )}
          </b>
        </div>
      </div>
    </div>
  );
};

export default CardCoupon;
