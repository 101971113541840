import axios from 'axios'

export const notifyError = async (
  app: string,
  data: { [key: string]: string }
) => {
  const webhook =
    'https://discord.com/api/webhooks/1196152235919429642/GML22mf3JxU0SgrhA7FDF-ZPlRxm_Vc2Q1peGqLO7HncZAbqMa4hBU9EOg6vVchmt_kJ'
  const maxLength = 400

  axios.post(webhook, {
    content: null,
    embeds: [
      {
        title: '🚨 NOVO ERRO INESPERADO RECEBIDO 🚨',
        description: `${app} <:boa_sorte:1196118115981983885>`,
        fields: Object.keys(data).map((key) => {
          return {
            name: key,
            value: `\`${
              data[key]!.length > maxLength
                ? data[key]!.substring(0, maxLength)
                : data[key]
            }\``,
          }
        }),
      },
    ],
    attachments: [],
  })
}
