import { CacheResponse } from '../queries/home/types';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type Banner = {
  // images filtered and in order
  images: CacheResponse['content']['advertisements'];
  setImages(images: CacheResponse['content']['advertisements']): void;
};

export const useBannerStore = create(
  persist<Banner>(
    (set) => ({
      images: [],
      setImages: (images: CacheResponse['content']['advertisements']) =>
        set(() => ({
          images: images
            .map((image) => {
              return {
                ...image,
              };
            })
            .sort((a, b) => a.order - b.order),
        })),
    }),
    {
      name: 'banner',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
