import { Skeleton } from '@onbeef/components/skeleton';
import { currencyFormat, Product, ProductType } from '@onbeefapp/constants';
import React from 'react';
import { useDrawerStore } from '../../stores/drawer';
import { ProductCartType } from '../../stores/cart';
import { getSelectedCutDetail } from '../../utils/CutsUtils';

interface CutsProps {
  product: Product;
}

export const Cuts: React.FC<CutsProps> = ({ product }) => {
  const { toggleDrawer, setProductDrawer, setCutID } = useDrawerStore(
    (state) => ({
      toggleDrawer: state.toggleDrawer,
      setProductDrawer: state.setProductDrawer,
      setCutID: state.setCutID,
    }),
  );

  const [cols, setCols] = React.useState(4);

  const updateCols = () => {
    const windowWidth = window.innerWidth - 650;
    const calculatedCols = Math.floor(windowWidth / 84) - 1;
    setCols(calculatedCols > 3 ? calculatedCols : 3);
  };

  const shortSpiceName = (name: string) => {
    return name.substring(0, 9) + '...';
  };

  React.useEffect(() => {
    updateCols();
    window.addEventListener('resize', updateCols);
    return () => {
      window.removeEventListener('resize', updateCols);
    };
  }, []);

  const handleAddCut = (cutID: string) => {
    setProductDrawer(product as ProductCartType);
    toggleDrawer('drawerGrams');
    setCutID(cutID);
  };

  return (
    product.product_type === ProductType.KG &&
    product?.cuts &&
    product?.cuts.length > 0 && (
      <div className="flex flex-col gap-2 px-4 py-2">
        <div className="flex flex-col gap-1">
          <span className="font-semibold">Selecione um dos cortes abaixo</span>
        </div>
        <div
          style={{ gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))` }}
          className="transition-all lg:ml-2 grid duration-300 transform gap-2 p-2 justify-items-center"
        >
          {product.cuts.map((cut) => {
            const cutInfo = getSelectedCutDetail(product.id, cut.id);

            return (
              <div
                className="relative flex flex-col w-full cursor-pointer hover:bg-gray-200 justify-between border-2 rounded-md border-gray-300 p-2 pr-4 mb-2 mt-2 items-center relative"
                onClick={() => {
                  handleAddCut(cut.id);
                }}
              >
                {cut.price && (
                  <div className="absolute top-[-12%] text-sm rounded-lg px-2 border border-gray-500 bg-gray-200">
                    + {currencyFormat(cut.price - product.price_per_kilo!)}
                  </div>
                )}
                <Skeleton
                  className="w-32 h-4 mb-1"
                  isLoading={false}
                  borderRadius="10px"
                >
                  <article className="leading-normal text-sm font-medium w-full items-center justify-center flex flex-row text-center">
                    {cut.name}
                  </article>
                  {cut.spice && (
                    <span className="text-xs">
                      {shortSpiceName(cut.spice.name)}
                    </span>
                  )}
                </Skeleton>
                <div className="w-full overflow-hidden relative">
                  <img
                    src={cut.picture}
                    className="w-full h-auto object-cover transform translate-y-[-10%]"
                    loading="lazy"
                  />
                </div>
                {cutInfo?.quantity && (
                  <>
                    <div className="min-w-[50px] text-center self-center absolute flex flex-row w-full items-center justify-center left-0 bottom-0">
                      <Skeleton
                        className="self-center w-16 h-6"
                        isLoading={false}
                        borderRadius="10px"
                      >
                        <article className="leading-normal text-sm font-semibold ">
                          {cutInfo?.quantity}g
                        </article>
                      </Skeleton>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};
