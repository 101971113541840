import { useCartStore } from '../../stores/cart';
import { useDrawerStore } from '../../stores/drawer';
import { useProductsStore } from '../../stores/products';
import React from 'react';

const FilterCut: React.FC = () => {
  const { toggleDrawer } = useDrawerStore((state) => ({
    toggleDrawer: state.toggleDrawer,
  }));

  const filters = useProductsStore((state) => state.filters);

  const { productsCountIndividual } = useCartStore((state) => ({
    productsCountIndividual: state.productsCountIndividual,
  }));
  const showCart = productsCountIndividual > 0;

  if (showCart) return null;

  return (
    <div className="fixed w-full lg:w-3/5 xl:w-3/4 bottom-12">
      <div className="flex z-40">
        <button
          onClick={() => toggleDrawer('drawerFilters')}
          className="hover:drop-shadow-xl text-contrastText px-8 md:px-20 mx-auto text-sm font-semibold rounded-full py-2 bg-secondary shadow-2xl drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]"
        >
          {filters.length > 0
            ? `Apenas: ${filters[0]?.name}${filters.length > 1 ? ' ...' : ''}`
            : 'Assistente de compras'}
        </button>
      </div>
    </div>
  );
};

export default FilterCut;
