import FilterCategory from '../../components/Filters/FilterCategory';
import HeaderHome from '../../components/Headers/HeaderHome';
import React from 'react';

interface SideBarProps {
  nameSelected?: string;
  isProduct?: boolean;
}

const Sidebar: React.FC<SideBarProps> = ({ nameSelected, isProduct }) => {
  return (
    <div className="hidden h-screen lg:flex flex-col fixed top-0 left-0 z-40 w-[380px] p-6 transition-all  -translate-x-full sm:translate-x-0 border-r-[2px] border-gray-300">
      <HeaderHome />
      <FilterCategory nameSelected={nameSelected} isProduct={isProduct} />
    </div>
  );
};

export default Sidebar;
