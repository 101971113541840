import * as React from 'react';

type RightSideProps = {
  children: React.ReactNode;
  className?: string;
};

export default function RightSide({ children, className }: RightSideProps) {
  return (
    <div
      className={`w-full lg:pl-[400px] lg:pr-[20px] lg:pt-[20px] lg:mb-64 ${
        className || ''
      }`}
    >
      {children}
    </div>
  );
}
