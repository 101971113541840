import { OrderPayload } from '../queries/order/types';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type Payload = {
  payload: OrderPayload | undefined;
  setPayload(payload: OrderPayload): void;
};

export const usePayloadStore = create(
  persist<Payload>(
    (set) => ({
      payload: undefined,
      setPayload: (payload: OrderPayload) =>
        set(() => ({
          payload: payload,
        })),
    }),
    {
      name: 'payload',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
