import {
  ComplementItem,
  DefaultCategoryType,
  Product as IProduct,
  KitType,
  ProductType,
  Toast,
  currencyFormat,
  formatOrderTime,
  promotionExpired,
  promotionStarted,
} from '@onbeefapp/constants';
import { TagOptions } from '@onbeefapp/drawers';
import cx from 'classnames';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Slide } from 'react-toastify';
import DrawerComments from '../../components/Drawers/DrawerComments';
import DrawerGrams from '../../components/Drawers/DrawerGrams';
import RightSide from '../../components/RightSide/RightSide';
import RotativeImage from '../../components/Rotative/RotativeImage';
import Skeleton from '../../components/Skeleton/Skeleton';
import { TrackingContext } from '../../contexts/TrackingContext';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { ProductCartType, useCartStore } from '../../stores/cart';
import { useComplementsStore } from '../../stores/complements';
import { useDrawerStore } from '../../stores/drawer';
import { useKitsStore } from '../../stores/kits';
import { useMerchantStore } from '../../stores/merchant';
import { useProductsStore } from '../../stores/products';
import Countdown from '../../utils/Countdown';
import { calculateKitItemsTotalGrams } from '../../utils/KitsUtils';
import {
  calculateDisplayClubPrice,
  getQuantity,
} from '../../utils/ProductUtils';
import Complement from '../Complement/Complement';
import { Cuts } from '../Cuts';
import { KitItems } from '../Kit/KitItems';
import { RelatedProducts } from '../RelatedProducts';
import Sidebar from '../Sidebar/Sidebar';
import CollapsibleTabProduct from '../../components/Tab/TabProduct';

enum ProductTabs {
  DETAILS = 1,
  PREPARATION = 2,
}

const Product: React.FC = () => {
  const params = useParams();
  const paramsSlug = useSlug();
  const { searchProductBySlug } = useProductsStore((state) => ({
    searchProductBySlug: state.searchProductBySlug,
  }));
  const { isLoading: merchantLoading } = useMerchantCache(paramsSlug.slug);

  const { addCart, products, increaseQuantity, removeProduct, setCart } =
    useCartStore((state) => ({
      addCart: state.addCart,
      products: state.products,
      increaseQuantity: state.increaseQuantity,
      removeProduct: state.removeProduct,
      setCart: state.setCart,
    }));

  const {
    complements,
    setComplements,
    deleteComplements,
    checkRequiredComplements,
  } = useComplementsStore((state) => ({
    complements: state.complements,
    setComplements: state.setComplements,
    deleteComplements: state.deleteComplements,
    checkRequiredComplements: state.checkRequiredComplements,
  }));

  const { checkRequiredKitItems } = useKitsStore((state) => ({
    checkRequiredKitItems: state.checkRequiredKitItems,
  }));

  const { isMerchantOpen, schedules, merchant } = useMerchantStore((state) => ({
    isMerchantOpen: state.isMerchantOpen,
    schedules: state.schedules,
    merchant: state.merchant,
  }));

  const [tempProducts, setTempProducts] =
    React.useState<Record<string, ProductCartType>>();
  const [tempComplements, setTempComplements] =
    React.useState<
      Record<string, Record<string, Record<string, ComplementItem[]>>>
    >();

  const { toggleDrawer, setProductDrawer } = useDrawerStore((state) => ({
    toggleDrawer: state.toggleDrawer,
    setProductDrawer: state.setProductDrawer,
  }));
  const location = useLocation();
  const navigate = useNavigate();
  const navigateBack = () => {
    if (location.key === 'default') {
      return navigate(`/`);
    }

    return navigate(-1);
  };

  const { track } = React.useContext(TrackingContext);

  const [product, setProduct] = React.useState<IProduct>();

  React.useEffect(() => {
    const run = async () => {
      try {
        const p = await searchProductBySlug(
          params.name! + (params.brand ? `/${params.brand}` : ''),
        );
        setProduct(p);
      } catch (error) {
        Toast.error('Não foi possível encontrar esse produto');
        navigate(-1);
      }
    };
    run();
  }, [params, merchant]);

  const {
    formatted: formattedQuantity,
    number: quantityNumber,
    quantity: quantity,
    totalAmountReturn: totalAmountReturn,
  } = getQuantity(products, product!);

  const complementRefs = React.useRef<{ [key: string]: HTMLDivElement | null }>(
    {},
  );
  const kitCategoriesRefs = React.useRef<{
    [key: string]: HTMLDivElement | null;
  }>({});

  const [isPromotion, setIsPromotion] = React.useState<boolean>(false);

  const [openTab, setOpenTab] = React.useState<ProductTabs>();

  const handleSetOpenTab = (tab: ProductTabs) => {
    if (openTab === tab) {
      setOpenTab(undefined);
    } else {
      setOpenTab(tab);
    }
  };

  React.useEffect(() => {
    //clone products to tempProducts
    setTempProducts(products);
    setTempComplements(complements);
  }, []);
  React.useEffect(() => {
    //clone products to tempProducts
    setTempProducts(products);
    setTempComplements(complements);
  }, [products, complements]);
  React.useEffect(() => {
    setIsPromotion(
      product?.promotion !== null &&
        promotionStarted(product?.promotion) &&
        !promotionExpired(product?.promotion),
    );

    const isSoldOut =
      product?.promotion?.available != null &&
      product?.promotion?.available === 0;
    if (isSoldOut) {
      Toast.error('Produto esgotado!!');
      navigate('/', { replace: true });
    }
  }, [product]);

  // Função para adicionar a quantidade do produto
  const addProduct = (success = false) => {
    if (product) {
      const canAdd = checkProductAddStatus(product);
      if (!canAdd) return;
    }

    if (product?.product_type === ProductType.KG) {
      setProductDrawer(product as ProductCartType);
      toggleDrawer('drawerGrams');
    } else {
      if (
        isPromotion &&
        product?.promotion?.max_buyer &&
        product?.promotion?.max_buyer != 0 &&
        quantityNumber >= product?.promotion?.max_buyer
      ) {
        return Toast.error(
          `Este produto possui o limite de ${product?.promotion?.max_buyer} por pessoa`,
        );
      }
      if (quantityNumber < 1) {
        addCart(product!, 1, '');
        success && addSuccess();
      } else {
        increaseQuantity(product!.id);
      }
    }
  };

  // Função para diminuir a quantidade do produto
  const removeProductView = () => {
    if (product?.product_type === ProductType.KG) {
      setProductDrawer(product as ProductCartType);
      toggleDrawer('drawerGrams');
    } else {
      if (quantityNumber > 0) {
        track('OnCustomerCartRemoveProduct', {
          ...product,
        });
        removeProduct(product!.id);
        deleteComplements(product!.id);
        if (quantity <= 1) {
          navigateBack();
        }
      }
    }
  };

  const calculateGramsPerPerson = (product: IProduct) => {
    let totalWeight = 0;
    let weightPerServing = merchant?.config?.weight_per_serving;

    if (!weightPerServing) return 0;

    if (product.product_type === ProductType.KG) {
      totalWeight += totalAmountReturn ?? 0;
      weightPerServing = merchant?.config?.weight_per_serving;
    }

    const servings = Math.floor(totalWeight / weightPerServing!);
    return servings;
  };

  const handleOpenClub = () => {
    navigate('/club', { state: { clubName: product?.clubName } });
  };

  const handleAddCart = () => {
    if (!isMerchantOpen && schedules?.receive_orders_when_closed === false) {
      return Toast.error(
        'Loja fechada, não é possivel adicionar produtos na sacola',
      );
    }

    if (product) {
      const canAdd = checkProductAddStatus(product);
      if (!canAdd) return;
    }

    if (quantityNumber < 1) {
      if (
        product &&
        product.product_type === ProductType.KG &&
        product.cuts &&
        product.cuts.length > 0
      ) {
        Toast.error('Selecione pelo menos um corte');
      } else {
        addProduct(true);
      }
      return;
    }

    track('OnCustomerCartAddProduct', {
      ...product,
    });
    addSuccess();
  };

  const checkProductAddStatus = (product: IProduct) => {
    // check required kit items
    if (product.type === ProductType.KIT && product.kit_type != null) {
      const categoryID = checkRequiredKitItems(product);
      if (categoryID) {
        focusKitCategory(categoryID);
        return false;
      }
    }

    // check required complements
    const complementID = checkRequiredComplements(product);
    if (complementID) {
      const ref = complementRefs.current[complementID];
      shakeDiv(ref);
      return false;
    }

    return true;
  };

  const addSuccess = () => {
    Toast.success(
      <div
        onClick={() => {
          track(`OnCustomerViewCart`, {});
          navigate(`/cart`);
        }}
      >
        Produto adicionado ao carrinho!
      </div>,
      {
        toastId: 'cart-button-toast',
        className: 'toastify-sucess-cart',
        position: 'top-center',
        autoClose: 1500,
        closeButton: false,
        icon: true,
        hideProgressBar: true,
        closeOnClick: true,
        transition: Slide,
        theme: 'colored',
      },
    );
    navigateBack();
  };

  const focusKitCategory = (categoryID: string, shake?: boolean) => {
    const ref = kitCategoriesRefs.current[categoryID];
    shakeDiv(ref, shake);
  };

  const shakeDiv = (ref?: HTMLDivElement | null, shake = true) => {
    ref?.focus();
    ref?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    if (shake) {
      ref?.classList.add('animate-shake');
      setTimeout(() => {
        ref?.classList.remove('animate-shake');
      }, 1000);
    }
  };

  const staticGramsPerPerson =
    product?.estimated_weight && merchant?.config?.weight_per_serving
      ? Math.floor(
          product?.estimated_weight / merchant?.config?.weight_per_serving,
        )
      : 0;

  const productPrice = product?.price_per_kilo
    ? product?.price_per_kilo
    : product?.price_per_unit
      ? product.price_per_unit
      : 0;
  const calculatedClubPrice = calculateDisplayClubPrice(product);
  const estimatedWeightPrice =
    product?.price_per_kilo! * (product?.estimated_weight! / 1000);

  const withoutCuts =
    product?.product_type !== ProductType.KG
      ? true
      : product?.hasCuts === false &&
        !(product?.cuts && product?.cuts?.length > 0);
  const isKit = product?.type === ProductType.KIT;
  const isKitDaily =
    product?.default_category_tag === DefaultCategoryType.EVERYDAY_KIT;
  const isKitOpen = isKit && product.kit_type === KitType.OPEN;
  const isKitClosed = isKit && product.kit_type === KitType.CLOSED;
  const isKitCustom = isKit && product.kit_type != null;
  const kitPricePer =
    product?.displayPrice && product.number_of_people
      ? product.displayPrice / product.number_of_people
      : 0;
  const kitTotalGrams = React.useMemo(
    () => calculateKitItemsTotalGrams(product?.items),
    [product?.items],
  );

  const withDetails = product?.description || product?.breedDetails;
  const withTags = product?.tags && product.tags.length > 0;

  const withRelated =
    product?.related_product_groups &&
    product?.related_product_groups?.length > 0;

  const isLoading = merchantLoading || !product;

  return (
    <div className="w-full">
      <Helmet>
        <meta
          name="title"
          content={`${merchant?.name} produto ${product?.name}`}
        />
        <meta
          name="description"
          content={`${product?.description || ''}${product?.brand?.name || ''}`}
        />
        <meta
          name="keywords"
          content={[
            product?.name,
            product?.brand?.name,
            product?.clubName,
            product?.breed_name,
          ]
            .filter((v) => v != undefined)
            .join(', ')}
        />
      </Helmet>
      {/* Sidebar for desktop view */}
      <Sidebar isProduct={true} />
      <RightSide>
        {/* Botão de voltar quando for layout pc */}
        <button
          className="lg:flex hidden mb-4 ml-2 top-2 left-2 z-[9999]"
          onClick={() => {
            track(`OnCustomerLeaveProductPage`, {});
            navigateBack();
            setCart(tempProducts!);
            setComplements(tempComplements!);
          }}
        >
          <div className="flex flex-row rounded-full pr-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              strokeWidth={1.5}
              stroke="currentColor"
              className="py-2 w-7 h-8 lg:w-9 lg:h-9 text-primary"
            >
              <path
                fillRule="evenodd"
                d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
                clipRule="evenodd"
              />
            </svg>
            <div className="text-primary text-sm lg:text-sm font-bold self-center hover:underline">
              Voltar
            </div>
          </div>
        </button>

        {/* Imagens do produto que o cliente pode mudar arrastando o dedo para direita */}
        {product && product.media && (
          <RotativeImage images={product.media} productName={product.name} />
        )}
        <Skeleton
          className="w-full h-2"
          isLoading={isLoading}
          borderRadius="10px"
        >
          <button
            className="absolute lg:hidden top-2 left-2 z-30"
            onClick={() => {
              track(`OnCustomerLeaveProductPage`, {});
              navigateBack();
              setCart(tempProducts!);
              setComplements(tempComplements!);
            }}
          >
            <div className="flex flex-row rounded-full pr-2 bg-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                strokeWidth={1.5}
                stroke="currentColor"
                className="py-2 w-7 h-8 text-contrastText"
              >
                <path
                  fillRule="evenodd"
                  d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="text-contrastText text-sm font-normal self-center underline">
                Voltar
              </div>
            </div>
          </button>
        </Skeleton>

        <div className="absolute lg:hidden top-2 right-2 z-30">
          <TagOptions
            tags={product?.tags.filter((t) => t.type === 'PRODUCT_STORAGE')}
            selectedTags={{
              PRODUCT_STORAGE: product?.tags.filter(
                (t) => t.type === 'PRODUCT_STORAGE',
              ),
            }}
            black={true}
          />
        </div>

        <div className="bg-[#f5f5f5] flex flex-col absolute lg:static lg:rounded-none rounded-t-2xl w-full lg:top-[350px] pb-20 space-y-1">
          <div className="px-4 py-2 w-full border-b-[1px] border-gray-200">
            {/* Data de expiração quando houver promoção */}
            {product?.promotion &&
              product?.promotion?.expired_at &&
              isPromotion && (
                <Skeleton
                  className="w-full h-12 mb-1 rounded-md"
                  isLoading={isLoading}
                >
                  <div className="flex items-center w-full mb-2 rounded-md border-2 h-[3.25rem] border-current text-center">
                    <div className="flex justify-center items-center w-[30%] h-full bg-primary text-center text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-11 h-11"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    <div className="flex flex-col items-center justify-center w-full text-center text-primary p-2">
                      {/* Data de expiração */}
                      <article className="leading-normal text-xs font-medium uppercase">
                        Termina em:
                      </article>
                      <article className="leading-normal text-base font-bold">
                        <Countdown
                          productID={product.id}
                          targetDate={new Date(product.promotion.expired_at)}
                          setIsExpired={setIsPromotion}
                        />
                      </article>
                    </div>
                  </div>
                </Skeleton>
              )}

            {/* Quantidade de unidades no estoque quando houver promoção */}
            {isPromotion && (
              <>
                <Skeleton
                  className="w-full h-12 mb-1 rounded-md"
                  isLoading={isLoading}
                >
                  <div className="flex flex-col w-full mb-2 bg-orange-300 rounded-md h-min-7">
                    {product?.promotion?.available != 0 &&
                      product?.promotion?.available && (
                        <div className="flex flex-row place-items-center">
                          <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="m-2 w-7 h-7"
                          >
                            <path
                              d="M15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11M20 7L18 3H6L4 7M20 7H4M20 7V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V7"
                              stroke="#000000"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>

                          <article className="leading-normal text-sm font-normal text-center place-content-center place-items-center">
                            Restam: <b>{product?.promotion?.available}</b>{' '}
                            unidades
                          </article>
                        </div>
                      )}

                    {/* Limite por cliente */}
                    {product?.promotion?.max_buyer != 0 &&
                      product?.promotion?.max_buyer && (
                        <div className="flex flex-row place-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="m-2 w-7 h-7"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                              clipRule="evenodd"
                            />
                          </svg>
                          {ProductType.UNIT === product.product_type && (
                            <article className="leading-normal text-sm font-normal text-center place-content-center place-items-center">
                              <b>{product?.promotion?.max_buyer}</b>{' '}
                              {product?.promotion.max_buyer === 1
                                ? 'unidade'
                                : 'unidades'}{' '}
                              por cliente
                            </article>
                          )}

                          {ProductType.KG === product.product_type &&
                            product.estimated_weight && (
                              <article className="leading-normal text-sm font-normal text-center place-content-center place-items-center">
                                <b>
                                  {product?.promotion?.max_buyer *
                                    product?.estimated_weight}
                                  g
                                </b>{' '}
                                por cliente
                              </article>
                            )}
                        </div>
                      )}

                    {/* Data de expiração */}
                    {product?.promotion?.expired_at && isPromotion && (
                      <div className="flex flex-row place-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="m-2 w-7 h-7"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>

                        <article className="leading-normal text-sm font-normal text-center place-content-center place-items-center">
                          Valido até{' '}
                          <b>
                            {formatOrderTime(product?.promotion?.expired_at)}
                          </b>
                        </article>
                      </div>
                    )}
                  </div>
                </Skeleton>
              </>
            )}

            {/* Detalhes do produto */}
            <div className="flex flex-row items-center justify-between w-full space-x-1">
              <div className="flex flex-col">
                {/* Nome do produto */}

                <Skeleton
                  className="w-24 h-5"
                  isLoading={isLoading}
                  borderRadius="10px"
                >
                  <article
                    className={cx(
                      'leading-normal text-lg font-bold',
                      product?.brand ? '' : 'mb-1',
                    )}
                  >
                    {product?.name}
                  </article>
                </Skeleton>

                {product?.brand && (
                  <Skeleton
                    className="w-24 h-5"
                    isLoading={isLoading}
                    borderRadius="10px"
                  >
                    <article className="leading-normal font-medium mb-1">
                      Marca: {product?.brand?.name}
                    </article>
                  </Skeleton>
                )}

                {/* Se produto_type = KG, mostrar o valor referente ao estimated_weigth, caso não, mostra o valor unitário */}
                {!isKit && (
                  <Skeleton
                    className="w-24 h-5 mt-2"
                    isLoading={isLoading}
                    borderRadius="10px"
                  >
                    <article className="leading-normal text-sm font-normal">
                      {product?.product_type === ProductType.KG ? (
                        <div className="flex flex-col">
                          <span>
                            {isPromotion &&
                            product?.promotion?.fixed_amount != null &&
                            product?.promotion?.fixed_amount !==
                              product.original_value ? (
                              <>
                                {currencyFormat(
                                  product?.promotion?.fixed_amount,
                                )}
                                <s className="ml-1">
                                  {currencyFormat(product?.price_per_kilo)}
                                </s>
                              </>
                            ) : (
                              currencyFormat(product?.price_per_kilo)
                            )}{' '}
                            /Kg
                          </span>
                          <span>
                            {isPromotion && product?.promotion?.fixed_amount
                              ? currencyFormat(
                                  product?.promotion?.fixed_amount *
                                    (product.estimated_weight! / 1000),
                                )
                              : currencyFormat(estimatedWeightPrice)}{' '}
                            /{product?.estimated_weight}g
                          </span>
                        </div>
                      ) : (
                        currencyFormat(product?.displayPrice)
                      )}
                      {product?.product_type === ProductType.KG &&
                        product.estimated_weight &&
                        staticGramsPerPerson > 0 && (
                          <div className="flex flex-col items-start xs:flex-row xs:items-center mt-1 space-x-2 font-semibold">
                            <span>*Serve +/-</span>
                            <div className="flex flex-row items-center space-x-1">
                              <b className="text-lg">{staticGramsPerPerson}</b>
                            </div>
                          </div>
                        )}
                    </article>
                  </Skeleton>
                )}
              </div>

              {/* Preço do clube */}
              {product?.clubPrice && (
                <Skeleton
                  className="w-32 h-10 mt-1"
                  isLoading={isLoading}
                  borderRadius="10px"
                >
                  <div
                    onClick={handleOpenClub}
                    className="cursor-pointer w-[55%] lg:w-2/4 flex flex-col items-center bg-black rounded-lg text-white text-sm font-semibold px-6 py-2 space-y-1"
                  >
                    <span className="whitespace-nowrap">
                      Sócio {product?.clubName}
                    </span>
                    <div className="w-full flex flex-row justify-around whitespace-nowrap">
                      <s>{currencyFormat(productPrice)}</s>
                      <div className="bg-white text-black px-2 rounded-md">
                        {(
                          ((calculatedClubPrice - (productPrice ?? 0)) /
                            (productPrice ?? 0)) *
                          100
                        ).toFixed(1)}
                        % OFF
                      </div>
                    </div>
                    <span>
                      R${' '}
                      <b className="text-xl">
                        {calculatedClubPrice.toFixed(2).replace('.', ',')}
                      </b>{' '}
                      {product.price_per_kilo && '/kg'}
                    </span>
                  </div>
                </Skeleton>
              )}
            </div>

            {/* Informações do Kit */}
            {isKit && !isKitOpen && (
              <div className="w-full flex flex-row divide-x divide-black gap-2 p-2 mt-2">
                <div className="w-full flex flex-col gap-2 items-center">
                  <span className="text-sm">
                    {isKitClosed ? 'A partir de' : 'Valor do Kit'}
                  </span>
                  <span className="font-bold">
                    {currencyFormat(product?.displayPrice)}
                  </span>
                </div>
                {!isKitCustom && kitTotalGrams ? (
                  <div className="w-full flex flex-col gap-2 items-center">
                    <span className="text-sm">Peso em g</span>
                    <span className="font-bold">{kitTotalGrams}g</span>
                  </div>
                ) : null}
                {product.number_of_people ? (
                  <div className="w-full flex flex-col gap-2 items-center">
                    <span className="text-sm">Serve</span>
                    <span className="font-bold">
                      {product.number_of_people}
                    </span>
                  </div>
                ) : null}
                {product.show_price_per_person && kitPricePer > 0 && (
                  <div className="w-full flex flex-col gap-2 items-center">
                    <span className="text-sm">
                      Por {isKitDaily ? 'refeição' : 'pessoa'}
                    </span>
                    <span className="font-bold">
                      {currencyFormat(kitPricePer)}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 px-4 pt-2">
            {withDetails || withTags ? (
              <span className="w-full font-semibold">
                Informações do produto
              </span>
            ) : null}

            <div className="w-full flex gap-2">
              {/* Descrição do produto */}
              {withDetails && (
                <CollapsibleTabProduct
                  title="Detalhes"
                  active={openTab === ProductTabs.DETAILS}
                  onClick={() => handleSetOpenTab(ProductTabs.DETAILS)}
                />
              )}

              {withTags && (
                <CollapsibleTabProduct
                  title="Preparo"
                  active={openTab === ProductTabs.PREPARATION}
                  onClick={() => handleSetOpenTab(ProductTabs.PREPARATION)}
                />
              )}
            </div>
          </div>

          {openTab && (
            <div className="w-full px-2 border-b-[1px]">
              {openTab === ProductTabs.DETAILS && (
                <div className={cx('flex flex-col px-4 py-2')}>
                  <Skeleton
                    className="w-full h-3 mt-4"
                    isLoading={isLoading}
                    repeatQuantity={3}
                    borderRadius="10px"
                  >
                    {product &&
                    product.product_type === ProductType.KG &&
                    product.breedDetails ? (
                      <div>
                        <p className="leading-normal text-sm font-normal whitespace-pre-wrap">
                          {product.description}
                        </p>
                        {product.breedDetails.media && (
                          <div className="mt-4 flex justify-center">
                            <div
                              style={{
                                backgroundImage: `url(${product.breedDetails.media})`,
                              }}
                              className="bg-center bg-cover h-64 w-96"
                            />
                          </div>
                        )}
                        {product.breedDetails.name && (
                          <div className="flex justify-end">
                            <span className="leading-normal text-sm font-normal">
                              {`Animal da raça: ${product.breedDetails.name}`}
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <p className="leading-normal text-sm font-normal whitespace-pre-wrap">
                        {product?.description}
                      </p>
                    )}
                  </Skeleton>
                </div>
              )}
              {openTab === ProductTabs.PREPARATION && (
                <div className="w-full flex flex-col px-4 py-2 space-y-2">
                  <div className="w-full flex flex-row justify-between">
                    <TagOptions
                      text="Tipo de ocasião"
                      tags={product?.tags.filter(
                        (t) => t.type === 'PRODUCT_EVENT_TYPE',
                      )}
                      black={true}
                    />
                  </div>

                  <div className="hidden lg:flex">
                    <TagOptions
                      text="Armazenamento"
                      tags={product?.tags.filter(
                        (t) => t.type === 'PRODUCT_STORAGE',
                      )}
                      black={true}
                    />
                  </div>

                  <TagOptions
                    text="Forma de preparo"
                    tags={product?.tags.filter(
                      (t) => t.type === 'PRODUCT_PREPARATION_MODE',
                    )}
                    black={true}
                  />
                </div>
              )}
            </div>
          )}

          {/* Itens do Kit */}
          {product && (
            <KitItems
              product={product}
              setRef={(el, id) => (kitCategoriesRefs.current[id] = el)}
              focusCategory={(id) => focusKitCategory(id, false)}
            />
          )}

          {/* Cortes do produto */}
          {product && <Cuts product={product} />}

          {/* Complementos do produto */}
          <div className={`w-full ${withRelated ? '' : 'pb-16'}`}>
            {product?.complements.map((complement) => (
              <Complement
                key={complement.id}
                setRef={(el) => (complementRefs.current[complement.id] = el)}
                complement={complement}
                product={product}
              />
            ))}
          </div>

          {/* Produtos relacionados */}
          <RelatedProducts product={product} />

          {/* Div fixa que irá ter a opção do cliente adicionar a quantidade de produtos desejada e dps clicar em "Adicionar" para salvar no carrinho */}
          <div className="fixed bottom-0 flex flex-row justify-between lg:w-8/12 w-screen pt-2 pb-2 pl-4 pr-4 lg:bg-[#f5f5f5] bg-white z-40">
            <div className="flex flex-col w-1/4">
              <div className="flex flex-row items-center justify-center">
                {/* Botão para remover unidades/gramas no produto */}
                {withoutCuts && (
                  <Skeleton
                    className="w-6 h-6 mb-1 mr-1"
                    isLoading={isLoading}
                    borderRadius="5px"
                  >
                    <button onClick={removeProductView}>
                      {quantity > 1 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className=" lg:w-8 lg:h-8 w-6 h-6 text-primary"
                        >
                          <path
                            fillRule="evenodd"
                            d="M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="lg:w-8 lg:h-8 w-6 h-6 text-primary"
                          viewBox="0 0 24 24"
                        >
                          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                        </svg>
                      )}
                    </button>
                  </Skeleton>
                )}

                {/* Quantidade de unidades/gramas selecionadas do produto */}
                <Skeleton
                  className="w-20 h-5 mb-1"
                  isLoading={isLoading}
                  borderRadius="10px"
                >
                  <div className="text-sm lg:text-[20px] w-20 text-center font-semibold p-1 self-center">
                    {formattedQuantity}
                  </div>
                </Skeleton>

                {/* Botão para adicionar unidades/gramas no produto */}
                {withoutCuts && (
                  <Skeleton
                    className="w-6 h-6 mb-1 ml-1"
                    isLoading={isLoading}
                    borderRadius="5px"
                  >
                    <button onClick={() => addProduct()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        stroke="currentColor"
                        className="lg:w-8 lg:h-8 w-6 h-6 text-primary"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </Skeleton>
                )}
              </div>

              {/* Caso produto seja type = KG, mostrar targa de peso variável */}
              {product?.product_type === ProductType.KG && (
                <Skeleton
                  className="w-full h-5"
                  isLoading={isLoading}
                  borderRadius="10px"
                >
                  <div className="bg-[#952306] text-xs p-1 text-center text-white font-semibold rounded-lg mt-1 ">
                    Peso variável
                  </div>
                </Skeleton>
              )}
            </div>

            {product?.product_type === ProductType.KG &&
              product.estimated_weight &&
              calculateGramsPerPerson(product) > 0 && (
                <div className="w-1/4 h-full flex flex-col border border-black rounded-lg justify-center items-center text-xs self-center ml-2 py-1 font-medium">
                  <span className="font-medium text-base">Serve</span>
                  <b className="text-xl font-bold">
                    {calculateGramsPerPerson(product)}
                  </b>
                </div>
              )}

            {/* Adicionar no carrinho */}
            <Skeleton
              className="w-[180px] h-8 rounded-3xl self-center"
              isLoading={isLoading}
            >
              <div className="self-center w-full text-end">
                {/* Analisar necessidade do botão de adicionar na página do produto */}

                <button
                  onClick={handleAddCart}
                  className={cx(
                    'text-contrastText p-2 font-semibold rounded-3xl text-sm right-0 self-center w-[180px',
                    product && checkRequiredComplements(product)
                      ? 'bg-gray-400'
                      : 'bg-primary',
                  )}
                >
                  Adicionar ao carrinho
                </button>
              </div>
            </Skeleton>
          </div>
        </div>

        {/* Botão para adicionar observação no produto  */}
        {quantityNumber >= 1 && (
          <div className="fixed w-full lg:w-3/5 xl:w-3/4 bottom-20 text-primary pr-6">
            <div className="flex">
              <button
                onClick={() => toggleDrawer('drawerComments')}
                className="gap-2 flex flex-row mx-auto justify-center bg-white items-center text-primary border-2 border-current border-primary p-2 font-semibold rounded-3xl text-sm right-0 self-center w-[220px] disabled:bg-gray-400 disabled:cursor-not-allowed"
                disabled={quantityNumber < 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
                Deixar instrução extra
              </button>
            </div>
          </div>
        )}

        {/* Drawer com as especifições da gramatura do produto */}
        <DrawerComments product={product} />
        <DrawerGrams />
      </RightSide>
    </div>
  );
};

export default Product;
