import { Avatar, Button, Typography } from '@material-tailwind/react';
import { pagarmeToHumanColor, typeToHumanColor } from '@onbeefapp/constants';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Order } from '../../queries/my-orders/types';
import { formatOrderTime } from '@onbeefapp/constants';

interface CardOrderProps {
  order: Order;
}

const CardOrder: React.FC<CardOrderProps> = ({ order }) => {
  const navigate = useNavigate();

  const openDetail = () => {
    navigate(`/orders/${order.id}`, { replace: true });
  };

  return (
    <div
      className={
        'flex flex-col space-y-2 divide-y divide-gray-300 border border-gray-300 p-2 rounded-lg'
      }
      onClick={openDetail}
    >
      <div className="flex flex-row justify-between mt-2">
        <div className="flex space-x-6">
          <Avatar
            placeholder={'store logo'}
            src={order.logo}
            className="w-6 h-6"
          />
          <span className="font-medium">{order.merchant_name}</span>
        </div>
        <div className="flex mr-5">
          <span className="font-semibold">#{order.code}</span>
        </div>
      </div>
      <div className="flex flex-col pt-2">
        <article className="font-light">
          <span className="font-medium">Pedido feito: </span>
          {formatOrderTime(order.created_at)}
        </article>
        <article className="font-light">
          <span className="font-medium">Status do pedido: </span>
          <span
            className="dot"
            style={{
              height: '10px',
              width: '10px',
              marginLeft: '5px',
              marginRight: '5px',
              backgroundColor: typeToHumanColor[order.status]?.color,
              borderRadius: '50%',
              display: 'inline-block',
            }}
          ></span>
          {typeToHumanColor[order.status]?.label}
        </article>
        {order.status_payment != null && (
          <article className="font-light">
            <span className="font-medium">Status do pagamento: </span>
            <span
              className="dot"
              style={{
                height: '10px',
                width: '10px',
                marginLeft: '5px',
                marginRight: '5px',
                backgroundColor:
                  pagarmeToHumanColor[order.status_payment?.toLowerCase() ?? '']
                    ?.color,
                borderRadius: '50%',
                display: 'inline-block',
              }}
            ></span>
            {
              pagarmeToHumanColor[order.status_payment?.toLowerCase() ?? '']
                ?.label
            }
          </article>
        )}
      </div>
      <div className="flex">
        <Button
          onClick={openDetail}
          fullWidth
          className="normal-case"
          variant="text"
          color="gray"
          placeholder={'Ver detalhes'}
        >
          Ver detalhes
        </Button>
      </div>
    </div>
  );
};

export default CardOrder;
