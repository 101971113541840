import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useMerchantCache } from '../../queries/home';
import { Skeleton } from '@onbeef/components/skeleton';
import { useSlug } from '../../hooks/useSlug';
import cx from 'classnames';

interface ProfileItemProps {
  icon: JSX.Element;
  title: string;
  description?: string;
  disabled?: boolean;
  path: string;
  state?: any;
}

export const ProfileItem: React.FC<ProfileItemProps> = ({
  icon,
  title,
  description,
  path,
  state,
  disabled = false,
}) => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);

  return (
    <Skeleton isLoading={isLoading} className="w-full h-10">
      <Link
        className={cx(
          'w-full flex gap-4 items-center border-b-2 p-2 px-4',
          disabled ? 'bg-gray-200 opacity-80' : '',
        )}
        to={disabled ? '' : path}
        state={state}
      >
        {icon}
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-col">
            <span>{title}</span>
            {(description || disabled) && (
              <span className="text-sm text-gray-400">
                {disabled ? 'Desativado' : description}
              </span>
            )}
          </div>
          <ChevronRightIcon className="w-4" />
        </div>
      </Link>
    </Skeleton>
  );
};
