import { useNavigate } from 'react-router-dom';
import { usePWAInstall } from 'react-use-pwa-install';
import { useMerchantStore } from '../../stores/merchant';

export default function PwaAndroid() {
  const navigate = useNavigate();
  const merchant = useMerchantStore((state) => state.merchant);
  const install = usePWAInstall();

  const goBack = () => {
    navigate(`/`);
  };

  if (!merchant) return <></>;

  return (
    <div className="w-100 mt-4">
      <center>
        <div>
          <div style={{ padding: '15px' }}>
            <button
              className="bg-primary text-contrastText rounded-lg px-4 py-2 font-semibold"
              onClick={() => {
                install();
              }}
            >
              Adicionar à tela inicial
            </button>
          </div>
          <div className="mb-4">
            <center>
              <p onClick={goBack} className="font-bold text-blue-700 underline">
                Não, obrigado
              </p>
            </center>
          </div>
        </div>
      </center>
    </div>
  );
}
