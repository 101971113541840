import { LoadingIcon, maskPhone, Toast } from '@onbeefapp/constants';
import { AxiosError } from 'axios';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CodeInput } from '../../components/CodeInput';
import { useCRMSendPhoneCode } from '../../queries/crm';
import { useLoyaltyVerifyPhone } from '../../queries/loyalty';
import { useMerchantStore } from '../../stores/merchant';
import { useUserStore } from '../../stores/user';

export const CRMPhoneValidation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const token = location?.state?.token;

  const merchant = useMerchantStore((state) => state.merchant);
  const phone = useUserStore((state) => state.phone);

  const { mutateAsync: sendPhoneCode, isLoading: sendLoading } =
    useCRMSendPhoneCode();
  const { mutateAsync: verifyPhone, isLoading } = useLoyaltyVerifyPhone();

  const [code, setCode] = React.useState('');

  const handleCompleteCode = (code: string) => {
    setCode(code);
  };

  const handleSendPhoneCode = async () => {
    try {
      if (sendLoading || !token) return;
      await sendPhoneCode(token);
    } catch (error) {
      const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
        ?.error;
      msgs?.forEach((msg) => Toast.error(msg));
    }
  };

  const continueAction = () => {
    const url = location.state?.go_back || '/';
    navigate(url, { replace: true });
  };

  const handleValidatePhone = async (code: string) => {
    try {
      if (!code) return Toast.error('Digite um código válido!');
      if (!token || !merchant) return;
      const res = await verifyPhone({
        code,
        phone,
        token,
        slug: merchant.slug,
      });
      const msg = res.data.message;
      msg && Toast.success(msg);
      continueAction();
    } catch (error) {
      const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
        ?.error;
      msgs?.forEach((msg) => Toast.error(msg));
    }
  };

  React.useEffect(() => {
    if (code.length === 4) {
      handleValidatePhone(code);
    }
  }, [code]);

  React.useEffect(() => {
    if (location.state?.sms_sent) return;
    handleSendPhoneCode();
  }, []);

  if (!token) {
    navigate(-1);
  }

  return (
    <div
      className={`flex items-center bg-white p-5 text-center w-full h-screen`}
    >
      <div className="flex flex-col gap-12 w-full h-full items-center justify-center">
        <div className="w-full flex flex-col gap-2 items-center justify-center">
          <img
            alt="Logo"
            className="h-20 w-20 mb-6 md:hidden flex rounded-full"
            height="32"
            src={merchant?.logo}
            style={{
              aspectRatio: '32/32',
              objectFit: 'cover',
            }}
            width="32"
          />
          <span className="text-2xl font-bold">Valide seu telefone</span>
          <span className="text-lg font-medium">
            Digite o código enviado por SMS para o número:
          </span>
          <span className="font-light text-gray-600">{maskPhone(phone)}</span>
        </div>
        <CodeInput onComplete={handleCompleteCode} />

        <div className="w-full flex flex-col gap-2 mt-12">
          <button
            type="button"
            onClick={() => handleValidatePhone(code)}
            className="rounded-lg px-4 py-3 bg-primary text-contrastText flex items-center justify-center"
          >
            {isLoading ? <LoadingIcon /> : 'Confirmar'}
          </button>
          <button
            type="button"
            onClick={() => continueAction()}
            className="rounded-lg border border-current text-primary px-4 py-2 flex items-center justify-center"
          >
            Não recebi o código
          </button>
        </div>
      </div>
    </div>
  );
};
