import { Typography } from '@material-tailwind/react';
import { TextArea } from '@onbeef/components/input';
import { Stars } from '@onbeef/components/stars';
import { Toast } from '@onbeefapp/constants';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import OrdersHeader from '../../../components/Headers/OrdersHeader';
import LoadingSpin from '../../../components/LoadingSpin';
import SkeletonRateOrder from '../../../components/Skeleton/SkeletonRateOrder';
import {
  useGetMotives,
  useRegisterAssessment,
} from '../../../queries/my-orders';
import { Motive } from '../../../queries/my-orders/types';
import { useMerchantStore } from '../../../stores/merchant';
import { useMyOrdersStore } from '../../../stores/my-orders';

interface RateOrderForm {
  readonly: boolean;
  comments: string;
  motives: string[];
  stars: number;
}

const RateOrder: React.FC = () => {
  const merchant = useMerchantStore((state) => state.merchant);

  const { state } = useLocation();

  const { data: motives, isLoading } = useGetMotives();
  const {
    data: registerRes,
    mutate: registerAssement,
    isLoading: isRegisterLoading,
  } = useRegisterAssessment();

  const selectedOrder = useMyOrdersStore((state) => state.selectedOrder);
  const setSelectedOrder = useMyOrdersStore((state) => state.setSelectedOrder);

  const selectedColor = '#e8c3ba';

  const methods = useForm<RateOrderForm>();

  const readonly = methods.watch('readonly');
  const stars = methods.watch('stars');
  const selectedMotives = methods.watch('motives');

  const handleSelectMotive = (motive: Motive) => {
    if (readonly) return;
    if (selectedMotives?.includes(motive.id)) {
      methods.setValue(
        'motives',
        selectedMotives.filter((m) => m !== motive.id),
      );
    } else {
      methods.setValue('motives', [...selectedMotives, motive.id]);
    }
  };

  const onSubmit = (data: RateOrderForm) => {
    if (!selectedOrder || readonly) return;

    registerAssement({
      merchant_id: selectedOrder.merchant_id,
      order_id: selectedOrder.id,
      rating: data.stars,
      motives: data.motives,
      comments: data.comments || undefined,
      type: 'ORDER',
    });
  };

  useEffect(() => {
    if (selectedOrder?.assessment) {
      methods.setValue('readonly', true);
      methods.setValue(
        'motives',
        selectedOrder.assessment.motives.map((m) => m.id),
      );
      methods.setValue(
        'comments',
        selectedOrder.assessment.comments || 'Sem comentário',
      );
      methods.setValue('stars', selectedOrder.assessment.rating);
    } else {
      methods.setValue('readonly', false);
      methods.setValue('motives', []);
      methods.setValue('comments', '');
      methods.setValue('stars', state?.stars || 0);
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (registerRes) {
      if (registerRes.message === 'Avaliação registrada com sucesso') {
        // TODO: usar o assessment que vem do back
        if (!selectedOrder) return;
        Toast.success(
          `Enviado com sucesso! A ${merchant?.name} agradece sua avaliação para melhor te atendermos`,
        );
        setSelectedOrder({
          ...selectedOrder,
          assessment: {
            merchant_id: selectedOrder!.merchant_id,
            order_id: selectedOrder!.id,
            rating: stars,
            motives:
              motives?.filter((m) => selectedMotives?.includes(m.id)) || [],
            comments: methods.getValues('comments'),
            type: 'ORDER',
          },
        });
      }
    }
  }, [registerRes]);

  if (!selectedOrder || !merchant) {
    return <></>;
  }

  return (
    <div className="flex flex-col p-2">
      <OrdersHeader title="Detalhes do pedido" />
      {isLoading ? (
        <SkeletonRateOrder readonly={readonly} />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex items-center justify-center"
          >
            <div className="flex flex-col p-4 mt-4 w-[100%] md:w-[60%] align-center space-y-6">
              <div>
                <Typography variant="h5" placeholder={''}>
                  {readonly ? 'Sua avaliação' : 'Avalie seu pedido'}
                </Typography>
              </div>
              <div className="flex flex-col">
                <Typography variant="h6" placeholder={''}>
                  O que você achou?
                </Typography>
                <Typography variant="small" placeholder={'avalie'}>
                  Avalie de 1 a 5 estrelas para classificar.
                </Typography>
                <Stars
                  value={stars}
                  handler={(v) => methods.setValue('stars', v)}
                  readonly={readonly}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <Typography variant="h6" placeholder={'label'}>
                  O que pode melhorar?
                </Typography>
                <div className="flex flex-wrap w-full gap-1">
                  {motives?.map((motive) => {
                    const selected = selectedMotives?.includes(motive.id);
                    return (
                      <button
                        type="button"
                        key={motive.id}
                        style={{
                          backgroundColor: selected ? selectedColor : '#ffffff',
                          borderColor: selected ? selectedColor : '#e0e0e0',
                        }}
                        className="px-4 py-2 font-medium transition duration-200 border rounded-full"
                        onClick={() => {
                          handleSelectMotive(motive);
                        }}
                      >
                        {motive.name}
                      </button>
                    );
                  })}
                </div>
              </div>
              <div>
                <Typography placeholder={'comentario label'} variant="h6">
                  Deixar comentário
                </Typography>
                <TextArea
                  name="comments"
                  className="mt-2 w-full h-[100px] text-base resize-none outline-none rounded-md px-2 py-1 border border-gray-300"
                  placeholder="Conte-nos o que aconteceu (opcional)"
                  disabled={readonly}
                />
              </div>
              {!readonly && (
                <div className="flex items-center justify-center w-full">
                  <button
                    type="submit"
                    disabled={isRegisterLoading}
                    className="px-6 py-2 font-medium rounded-lg bg-primary text-contrastText"
                  >
                    {isRegisterLoading ? <LoadingSpin /> : 'Avaliar'}
                  </button>
                </div>
              )}
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default RateOrder;
