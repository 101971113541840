import { ProductType } from '@onbeefapp/constants';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { TrackingContext } from '../../contexts/TrackingContext';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { ProductCartType, useCartStore } from '../../stores/cart';
import CardHorizontalProduct from '../Cards/CardHorizontalProduct';
import { CardRewardProduct } from '../Cards/CardRewardProduct';
import Skeleton from '../Skeleton/Skeleton';

const ListCartProduct: React.FC = () => {
  const params = useSlug();
  const { data, isLoading } = useMerchantCache(params.slug);
  const { products, isExtension } = useCartStore((state) => ({
    products: state.products,
    isExtension: state.isExtension,
  }));
  const { rewardProduct, giftProduct } = React.useContext(OrderDetailsContext);

  const dataTotal = data?.content.merchant.config;

  const navigate = useNavigate();

  const { track } = React.useContext(TrackingContext);

  if (Object.values(products).length <= 0)
    return (
      <div className="flex flex-col items-start justify-start w-full mt-4">
        <button
          className="text-sm font-bold self-center text-center text-primary"
          onClick={() => {
            track(`OnCustomerLeaveCart`, {});
            navigate(`/`);
          }}
        >
          Clique aqui para adicionar itens na sua sacola
        </button>
      </div>
    );

  // incomplete, neeed to calculate with backend values
  const calculateGramsPerPerson = (products: ProductCartType[]) => {
    let kitServings = 0;
    let totalWeight = 0;
    let weightPerServing = 300;

    products.forEach((product) => {
      if (product.product_type === ProductType.KG) {
        totalWeight += product?.totalAmount ?? 0;
        weightPerServing = dataTotal?.weight_per_serving ?? 300;
      } else if (
        product.product_type === ProductType.KIT &&
        product.number_of_people
      ) {
        kitServings += product.number_of_people * product.quantity;
      }
    });
    const servings = Math.floor(totalWeight / weightPerServing) + kitServings;
    return servings;
  };

  return (
    <>
      <div className="flex flex-row justify-between w-full">
        <Skeleton isLoading={isLoading} className="h-6 rounded-lg w-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>

          <div className="flex flex-row justify-between w-full">
            <div className="ml-2 mr-3 font-bold text-sm">Produtos</div>
            {/* Média da porção */}
            {dataTotal?.weight_per_serving !== 0 &&
            dataTotal?.weight_per_serving !== null
              ? calculateGramsPerPerson(Object.values(products)) > 0 && (
                  <div className="flex flex-row gap-2 px-4 items-center font-medium -mt-1 p-1 text-center text-sm border border-gray-900 rounded-full border-spacing-2">
                    <span>Carnes servem +/- </span>
                    <b>{calculateGramsPerPerson(Object.values(products))}</b>
                  </div>
                )
              : null}
          </div>
        </Skeleton>
      </div>
      <div className="flex flex-col items-start justify-start w-full mt-4 gap-2">
        {Object.values(products).map((product: ProductCartType) => (
          <CardHorizontalProduct
            key={`ProductCart${product.id}`}
            product={product}
          />
        ))}
        {!isExtension && rewardProduct && (
          <CardRewardProduct product={rewardProduct} />
        )}
        {!isExtension && giftProduct && (
          <CardRewardProduct product={giftProduct} />
        )}
      </div>
      {!isExtension && (
        <div
          className={`flex flex-col items-start justify-start w-full h-auto mt-4 mb-8`}
        >
          <Skeleton
            isLoading={isLoading}
            className="self-center h-4 rounded-lg w-80"
          >
            <button
              className="text-sm font-bold self-center text-center text-primary hover:underline cursor-pointer"
              onClick={() => {
                track(`OnCustomerLeaveCart`, {});
                navigate(`/`);
              }}
            >
              Clique aqui para adicionar itens na sua sacola
            </button>
          </Skeleton>
        </div>
      )}
    </>
  );
};

export default ListCartProduct;
