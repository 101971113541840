import { useProductsStore } from '../stores/products';
import Category from './Category';
import React from 'react';

const Content: React.FC = () => {
  const categories = useProductsStore((state) => state.categories);
  const categoriesAndProducts = useProductsStore(
    (state) => state.categoriesAndProducts,
  );

  return (
    <div className="mb-28">
      {/* Categorias e abaixo os produtos */}

      {categories.map((category, index) => {
        const products = categoriesAndProducts[category.id] || [];
        return (
          <Category
            category={category}
            key={`category${index}`}
            name={category.name}
            slug={category.slug}
            products={products}
          />
        );
      })}
    </div>
  );
};

export default Content;
