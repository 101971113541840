import React from 'react';
import HeaderBar from '../../../components/Headers/HeaderBar';
import ListAllSchedulings from '../../../components/Lists/ListAllSchedulings';

const AllSchedulings: React.FC = () => {
  return (
    <div className="flex flex-col p-2 pt-3 items-center justify-center">
      {/* Header */}
      <HeaderBar>
        <div className="text-sm font-normal text-center w-full">
          Agende seu pedido
        </div>
      </HeaderBar>

      {/* Content */}
      <div className="flex flex-col w-[100%] md:w-[60%] align-center">
        <ListAllSchedulings />
      </div>
    </div>
  );
};

export default AllSchedulings;
