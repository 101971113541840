import { FormEvent } from 'react'

export const currencyFormat = (value?: number) => {
  if (value === null) return undefined

  return value?.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })
}

export function mascaraMoeda(event: FormEvent<HTMLInputElement>) {
  const onlyDigits = event.currentTarget.value
    .split('')
    .filter((s: string) => /\d/.test(s))
    .join('')
    .padStart(3, '0')
  const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2)
  event.currentTarget.value = maskCurrency(parseFloat(digitsFloat))
}
export function mascaraMoedaFormat(event: FormEvent<HTMLInputElement>) {
  const onlyDigits = event.currentTarget.value
    .split('')
    .filter((s: string) => /\d/.test(s))
    .join('')
    .padStart(3, '0')
  const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2)
  event.currentTarget.value = maskCurrencyFormat(parseFloat(digitsFloat))
}

export function currencyToFloat(valor?: string | null) {
  if (!valor) return 0
  return parseFloat(valor.replace('R$', '').replace('.', '').replace(',', '.'))
}

export function currencyCentsToCurrency(valor: number) {
  return maskCurrencyFormat(valor / 100)
}

function maskCurrency(valor: number, locale = 'pt-BR', currency = 'BRL') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(valor)
}

function maskCurrencyFormat(valor: number, locale = 'pt-br', currency = 'BRL') {
  return valor?.toLocaleString(locale, {
    style: 'currency',
    currency,
  })
}

export const currencyMask = (setter: (value: string) => void) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/\D/g, '')

    let valueWithCurrency = ''

    if (!value) {
      valueWithCurrency = 'R$ 0,00'
    } else {
      value = (parseInt(value) / 100).toFixed(2).replace('.', ',')
      valueWithCurrency = `R$ ${addThousandSeparator(value)}`
    }

    setter(valueWithCurrency)
  }

  return handleChange
}

export const formatCurrency = (value: number): string => {
  const formattedValue = value?.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
  return formattedValue
}

export const parseCurrency = (value: string): number => {
  const numberString = value.replace(/[^\d,]/g, '')
  const numberWithDot = numberString.replace(',', '.')
  const parsedNumber = parseFloat(numberWithDot)
  return isNaN(parsedNumber) ? 0 : parsedNumber
}

const addThousandSeparator = (value: string) => {
  const [integerPart, decimalPart] = value.split(',')

  const formattedIntegerPart = integerPart!.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    '.'
  )

  return `${formattedIntegerPart},${decimalPart}`
}
