import { useMutation } from '@tanstack/react-query';
import api from '../../services/api';
import { GetRelatedProductsListParams, RelatedProductsList } from './types';

const getRelatedProductsList = async (params: GetRelatedProductsListParams) => {
  const { data } = await api.post<RelatedProductsList[]>(
    `/all/merchants/${params.merchant_id}/related/products/groups`,
    params.related_product_groups
      ? { related_product_groups: params.related_product_groups }
      : undefined,
  );
  return data;
};

export function useGetRelatedProductsList() {
  return useMutation(['getRelatedProductsList'], getRelatedProductsList);
}
