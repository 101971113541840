import { ClubSubscriptionStatus, isDateExpired } from '@onbeefapp/constants';
import { UserClub } from '../queries/login/types';
import { useAuthStore } from '../stores/login';

export const isUserClubActive = (userClub: UserClub) => {
  if (
    userClub.status === ClubSubscriptionStatus.CANCELED ||
    userClub.status === ClubSubscriptionStatus.INACTIVE
  ) {
    return userClub.expired_at ? !isDateExpired(userClub.expired_at) : false;
  }

  return userClub.status !== ClubSubscriptionStatus.PENDING;
};

export const getUserClub = (): UserClub | null => {
  const user = useAuthStore.getState().user;
  if (!user?.clubs || user?.clubs.length === 0) return null;
  const userClub = user.clubs[0];
  return userClub && isUserClubActive(userClub) ? userClub : null;
};
