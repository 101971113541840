import { Skeleton } from '@onbeef/components/skeleton';
import { useUserStore } from '../stores/user';
import { useMerchantCache } from '../queries/home';
import { useSlug } from '../hooks/useSlug';
import { useAuthStore } from '../stores/login';
import { Link } from 'react-router-dom';
import { UserIcon } from '@heroicons/react/24/solid';
import { useMerchantStore } from '../stores/merchant';
import React from 'react';
import { ArrowInlIcon, ArrowOutlIcon } from '@onbeefapp/constants';

export const ProfileHeader: React.FC = () => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);

  const merchant = useMerchantStore((state) => state.merchant);

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const name = useUserStore((state) => state.name);

  const isTest = merchant?.slug === `o-futuro-da-carne-chegou`;

  const [isFullscreen, setIsFullscreen] = React.useState(false);

  const handleFullscreenClick = () => {
    if (!document.fullscreenElement) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
        setIsFullscreen(true);
      } else if ((document.documentElement as any).webkitRequestFullscreen) {
        // Safari
        (document.documentElement as any).webkitRequestFullscreen();
        setIsFullscreen(true);
      } else if ((document.documentElement as any).msRequestFullscreen) {
        // IE11
        (document.documentElement as any).msRequestFullscreen();
        setIsFullscreen(true);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      } else if ((document as any).webkitExitFullscreen) {
        // Safari
        (document as any).webkitExitFullscreen();
        setIsFullscreen(false);
      } else if ((document as any).msExitFullscreen) {
        // IE11
        (document as any).msExitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  return (
    <Skeleton
      width="w-full"
      height="30px"
      borderRadius="10px"
      isLoading={isLoading}
    >
      <div className="flex w-full items-center justify-between">
        {isTest && !isFullscreen && (
          <button onClick={handleFullscreenClick} className={'absolute left-0'}>
            {!isFullscreen ? <ArrowOutlIcon /> : <ArrowInlIcon />}
          </button>
        )}
        <div className="w-full flex items-center p-2 gap-2 justify-end">
          {isAuthenticated ? (
            <Link
              to="/profile"
              className="rounded-full bg-primary w-8 h-8 text-sm text-center flex justify-center items-center text-contrastText"
            >
              {name.charAt(0).toUpperCase()}
            </Link>
          ) : (
            <Link to="/login" className="font-medium text-sm">
              <UserIcon className="h-5 w-5 text-gray-500" />
            </Link>
          )}
        </div>
      </div>
    </Skeleton>
  );
};
