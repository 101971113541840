import React from 'react'
import cx from 'classnames'

interface SkeletonProps {
  children?: React.ReactNode
  width?: string
  height?: string
  borderRadius?: string
  repeatQuantity?: number
  isLoading: boolean
  className?: string
  horizontal?: boolean
  containerClassName?: string
}

export const Skeleton: React.FC<SkeletonProps> = ({
  children,
  width,
  height,
  borderRadius,
  repeatQuantity,
  isLoading,
  className,
  horizontal = false,
  containerClassName = '',
}) => {
  const skeletonStyle: React.CSSProperties = {
    width,
    height,
    borderRadius,
  }

  if ((!isLoading && children) || !isLoading) return <>{children}</>

  if (repeatQuantity) {
    return (
      <div
        className={cx(
          'flex',
          horizontal ? 'flex-row space-x-1' : 'flex-col space-y-1',
          containerClassName
        )}
      >
        {Array.from({ length: repeatQuantity }, (_, i) => i).map((_, i) => (
          <div
            key={`skeleton${i}`}
            className={`bg-gray-200 animate-pulse ${className}`}
            style={skeletonStyle}
          ></div>
        ))}
      </div>
    )
  }

  return (
    <div
      className={`bg-gray-200 animate-pulse ${className}`}
      style={skeletonStyle}
    ></div>
  )
}
