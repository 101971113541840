import cx from 'classnames'
import React from 'react'
import {
  FieldValues,
  RegisterOptions,
  useFormContext,
  FieldError,
} from 'react-hook-form'
import styled from 'styled-components'

export interface TextAreaPayload
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  containerClassName?: string
  className?: string
  customLabel?: string
  customLabelWidth?: string
  placeholder?: string
  name: string
  validationSchema?: RegisterOptions<FieldValues, string> | undefined
  error?: boolean
  ref?: React.LegacyRef<HTMLTextAreaElement>
  errors?: FieldError
}

const Input = styled.textarea`
  background-color: #fff;
  :focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`

export const TextArea: React.FC<TextAreaPayload> = ({
  containerClassName,
  className,
  name,
  value,
  placeholder,
  validationSchema,
  customLabel,
  customLabelWidth,
  error,
  errors,
  ...rest
}) => {
  const { register } = useFormContext()

  return (
    <div
      className={cx(
        'w-full',
        containerClassName,
        customLabel && 'flex flex-col space-y-2'
      )}
    >
      <label
        htmlFor={name}
        className={cx(
          'text-left text-sm font-medium text-gray-500',
          customLabelWidth ? customLabelWidth : 'w-full',
          error || errors?.message ? 'text-red-400' : ''
        )}
      >
        {customLabel}
      </label>
      <div className="relative">
        <Input
          id={name}
          placeholder={placeholder}
          className={cx(
            ` rounded-md rounded-lg border border-gray-400 py-1 pl-1 w-full`,
            error || errors?.message ? ' border-red-400' : '',
            className
          )}
          {...register?.(name ?? '', validationSchema ? validationSchema : {})}
          {...rest}
        />
        {errors?.message && (
          <span className="text-red-400 text-sm">{errors.message}</span>
        )}
      </div>
    </div>
  )
}

TextArea.displayName = 'TextArea'
export type TextAreaProps = TextAreaPayload
export default TextArea
