import { Typography } from '@material-tailwind/react';
import React from 'react';
import { useNavigate, To } from 'react-router-dom';

interface OrdersHeaderProps {
  title: string;
  back_url?: To;
}

const OrdersHeader: React.FC<OrdersHeaderProps> = ({
  title,
  back_url = -1 as To,
}) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(back_url, { replace: true });
  };

  return (
    <div className="flex justify-center items-center">
      <div className="pl-2 pt-2 flex items-center w-[100%] md:w-[60%]">
        <div>
          <button onClick={goBack} className="text-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </button>
        </div>
        <div className="w-[90%] text-center">
          <Typography variant="h6" placeholder={''}>
            {title}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default OrdersHeader;
