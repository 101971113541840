import cx from 'classnames'
import React from 'react'
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

interface InputPayload extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string
  name: string
  validationSchema?: RegisterOptions<FieldValues, string> | undefined
  labelClass?: string
  containerClass?: string
  label: string
  value: string | number
  noMargin?: boolean
}

const Input = styled.input`
  background-color: #fff;
  :focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`

export const RadioButton: React.FC<InputPayload> = ({
  className,
  labelClass,
  containerClass,
  name,
  validationSchema,
  label,
  id,
  value,
  disabled,
  noMargin = false,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const error = errors?.[name] != null

  return (
    <div className={containerClass || ''}>
      <div
        className={cx(
          'flex flex-row cursor-pointer items-center',
          noMargin ? '' : 'mr-4'
        )}
      >
        <Input
          type="radio"
          id={id}
          value={value}
          className={cx(
            'rounded-md rounded-lg min-w-5 min-h-5 cursor-pointer border border-gray-400 py-1 pl-1 items-center flex',
            error ? 'outline outline-red-400' : '',
            className
          )}
          {...register?.(name, validationSchema ? validationSchema : {})}
          disabled={disabled}
        />
        <label
          htmlFor={id}
          className={cx(
            'ml-2 text-gray-800 dark:text-gray-300 cursor-pointer',
            labelClass
          )}
        >
          {label}
        </label>
      </div>
    </div>
  )
}

RadioButton.displayName = 'RadioButton'
export default RadioButton
