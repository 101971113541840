export default function PwaIphone() {
  return (
    <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2">
      <center>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="3.0"
          stroke="currentColor"
          className="w-[5rem] h-[5rem] text-[100px] text-primary"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
          />
        </svg>
      </center>
    </div>
  );
}
