import { Coupon } from '../../queries/coupon/types';
import CardCoupon from '../Cards/CardCoupon';
import SkeletonCoupons from '../Skeleton/SkeletonCupons';
import React from 'react';

interface ListCouponsProps {
  coupons?: Coupon[];
  isLoading: boolean;
  open: boolean;
}

const ListCoupons: React.FC<ListCouponsProps> = ({
  coupons,
  open,
  isLoading,
}) => {
  return (
    open && (
      <SkeletonCoupons isLoading={isLoading}>
        <div className={'flex flex-wrap items-center space-y-2'}>
          {coupons?.map((coupon) => {
            return <CardCoupon key={coupon.code} coupon={coupon} />;
          })}
          {coupons && coupons.length <= 0 ? (
            <span className="ml-2 text-center w-full mt-10">
              Nenhum cupom existente.
            </span>
          ) : null}
        </div>
      </SkeletonCoupons>
    )
  );
};

export default ListCoupons;
