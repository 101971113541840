import { notifyError } from '@onbeefapp/constants';
import * as Sentry from '@sentry/react';
import imageError from '../../assets/images/error.png';
import { JsErrorObject } from '../../queries/home/types';
import { getBrowserInfo } from '../../utils/function';

const eventId = Sentry.captureMessage(`Page JS Error Has Occurred`);

function ErrorPage({ error }: { error: Error }) {
  if (error) {
    // eslint-disable-next-line no-useless-escape
    const fileName = error?.stack
      ? error?.stack?.match(/\/([^\/]+):/)?.['1']
      : 'N/A';
    const obj: JsErrorObject = {
      name: error?.name,
      message: error?.message,
      cause: error?.stack as string,
      stack: error?.stack as string,
      currentUrl: window.location.href,
      fileName: fileName,
    };
    console.error('Aconteceu um erro inesperado!', obj);

    const browserInfo = getBrowserInfo();

    if (
      import.meta.env.PROD ||
      import.meta.env.VITE_REACT_APP_ENV === 'production'
    ) {
      notifyError('Catálogo', {
        Mensagem: obj.message || '',
        URl: obj.currentUrl || '',
        Stack: obj.stack || '',
        Navegador: JSON.stringify(browserInfo),
        'Sentry ID': eventId,
      });
    }
  }

  return (
    <div className="transition-opacity duration-300">
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col w-full text-center p-4">
          <h1 className="text-xl font-bold text-black">
            Aconteceu um erro inesperado!
          </h1>
          <img
            src={imageError}
            alt="504"
            className="self-center w-[60%] md:w-64"
            loading="lazy"
          />
          <h1 className="text-lg font-bold text-black">
            <small>Código do erro: #{eventId}</small> <br />
          </h1>
          <div className="flex justify-center items-center">
            <button
              className="w-fit rounded-lg bg-green-600 px-4 py-2 text-white mt-4"
              onClick={() => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = '/';
              }}
            >
              Voltar para página inicial
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ErrorPage;
