import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Avatar } from '@material-tailwind/react';
import { GroupRadioButton, RadioButton } from '@onbeef/components/input';
import { Skeleton } from '@onbeef/components/skeleton';
import { Tab } from '@onbeef/components/tab';
import { ClubSubscriptionStatus, formatCurrency } from '@onbeefapp/constants';
import cx from 'classnames';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateClubSubscription } from '../../../queries/club';
import { Club } from '../../../queries/home/types';
import { useAuthStore } from '../../../stores/login';
import { useMerchantStore } from '../../../stores/merchant';
import { CancelClubSubscription } from './CancelClubSubscription';
import { CreateClubSubscription } from './CreateClubSubscription';
import { PendingClubSubscription } from './PendingClubSubscription';
import Tooltip from '@onbeef/components/tooltip';

enum TabTypes {
  SUBED = 1,
  UNSUBED = 2,
}

export const FormClub: React.FC = () => {
  const clubs = useMerchantStore((state) => state.clubs);
  const userClubs = useAuthStore((state) => state.user?.clubs);

  const [unsubedClubs, setUnsubedClubs] = React.useState<Club[]>([]);
  const [subedClubs, setSubedClubs] = React.useState<Club[]>([]);
  const methods = useForm<{ club_id: string }>();
  const { errors } = methods.formState;
  const { merchant } = useMerchantStore((state) => state);
  const navigate = useNavigate();
  const location = useLocation();

  const [imageBase64, setImageBase64] = React.useState('');

  React.useEffect(() => {
    const fetchImage = async () => {
      try {
        if (!merchant) return;
        const response = await fetch(merchant.logo);
        const blob = await response.blob();

        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result as string;
          setImageBase64(base64String);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Erro ao buscar a imagem:', error);
      }
    };

    fetchImage();
  }, [merchant]);

  const [activeTab, setActiveTab] = React.useState<TabTypes>(TabTypes.UNSUBED);

  const setSelectedClub = (club_id: string) => {
    methods.setValue('club_id', club_id);
  };

  const handleTabClick = (tabIndex: TabTypes) => {
    setActiveTab(tabIndex);
  };

  React.useEffect(() => {
    const unsubed: Club[] = [];
    const subed: Club[] = [];
    clubs?.forEach((club) => {
      if (userClubs?.find((uc) => uc.club_id === club.id)) {
        subed.push(club);
      } else {
        unsubed.push(club);
      }
    });
    setUnsubedClubs(unsubed);
    setSubedClubs(subed);
  }, [clubs, userClubs]);

  React.useEffect(() => {
    if (location.state?.clubName) {
      const id = clubs?.find((c) => c.name === location.state.clubName)?.id;
      if (id) {
        methods.setValue('club_id', id);
        const userSub = userClubs?.find((uc) => uc.club_id === id);
        if (userSub?.club_id === id) {
          setActiveTab(TabTypes.SUBED);
        } else {
          setActiveTab(TabTypes.UNSUBED);
        }
      }
    }
  }, [userClubs, location.state?.clubName]);

  if (!clubs) {
    navigate(-1);
  }

  return (
    <div className="flex flex-col w-full space-y-4">
      <div className="w-full p-1 flex flex-col space-y-1">
        <span className="font-light text-md">
          Gerencie seus clubes de benefícios
        </span>
      </div>

      <div className="flex w-full mb-2">
        <Tab
          className="w-full"
          active={activeTab === TabTypes.SUBED}
          onClick={() => handleTabClick(TabTypes.SUBED)}
        >
          <span className="text-sm text-gray-600 font-light">Meus clubes</span>
        </Tab>
        <Tab
          className="w-full"
          active={activeTab === TabTypes.UNSUBED}
          onClick={() => handleTabClick(TabTypes.UNSUBED)}
        >
          <span className="text-sm text-gray-600 font-light">Disponíveis</span>
        </Tab>
      </div>
      <div className="w-full flex flex-col gap-1">
        <FormProvider {...methods}>
          {unsubedClubs.length > 0 && activeTab == 2 && (
            <GroupRadioButton
              alignment="vertical"
              className="w-full flex flex-col gap-1"
              errors={errors.club_id}
            >
              {unsubedClubs.map((club) => (
                <ClubCard
                  key={club.id}
                  club={club}
                  setSelectedClub={setSelectedClub}
                  logo={imageBase64}
                />
              ))}
            </GroupRadioButton>
          )}
          {subedClubs.length > 0 && activeTab == 1 && (
            <GroupRadioButton
              alignment="vertical"
              className="w-full flex flex-col gap-1"
              errors={errors.club_id}
            >
              {subedClubs.map((club) => (
                <ClubCard
                  key={club.id}
                  club={club}
                  setSelectedClub={setSelectedClub}
                  logo={imageBase64}
                />
              ))}
            </GroupRadioButton>
          )}
        </FormProvider>
      </div>
    </div>
  );
};

const ClubCard: React.FC<{
  club: Club;
  setSelectedClub: (club_id: string) => void;
  logo?: string;
}> = ({ club, setSelectedClub, logo }) => {
  const { isLoading } = useCreateClubSubscription();
  const userClubs = useAuthStore((state) => state.user?.clubs);

  const subscription = userClubs?.find((uc) => uc.club_id === club.id);
  const pendingPayment =
    subscription && subscription.status === ClubSubscriptionStatus.PENDING;
  return (
    <div key={club.id} className="w-full">
      <div
        className={cx(
          `w-full bg-white rounded-lg border border-current flex flex-row justify-between ${
            isLoading ? 'animate-pulse' : ''
          }`,
        )}
        onClick={() => {
          setSelectedClub(club.id);
        }}
      >
        <Skeleton isLoading={isLoading} className="w-full h-9 rounded-lg">
          <div className="flex flex-row p-1 w-full space-x-2">
            <Avatar
              placeholder={'store logo'}
              src={logo}
              className="w-24 h-24"
            />

            <div className="flex flex-col w-full justify-between">
              <div className=" flex flex-col space-y-1">
                <div className="flex flex-row">
                  <RadioButton
                    name="club_id"
                    id={club.slug}
                    value={club.id}
                    className="hidden ml-0"
                    label={club.name}
                    labelClass="font-medium ml-0 w-full text-md"
                  />
                  <Tooltip
                    text={
                      <div className="flex flex-col gap-1">
                        {club.messages?.map((msg) => <span>{msg}</span>)}
                      </div>
                    }
                  >
                    <InformationCircleIcon className="h-6 w-6 text-gray-500" />
                  </Tooltip>
                </div>
                <div className=" px-2 text-sm w-full font-medium rounded-md">
                  {club.price_cents === 0
                    ? 'Gratuito'
                    : `${formatCurrency(club.price_cents / 100)} ${
                        club.interval_type === 'MONTH' ? '/mês' : '/ano'
                      }`}
                </div>
              </div>

              <div className="px-2 flex flex-col space-y-1">
                {club ? (
                  subscription ? (
                    pendingPayment ? (
                      <PendingClubSubscription subscription={subscription} />
                    ) : (
                      <CancelClubSubscription subscription={subscription} />
                    )
                  ) : (
                    <CreateClubSubscription
                      clubID={club.id}
                      isFree={club.price_cents === 0}
                    />
                  )
                ) : null}
              </div>
            </div>
          </div>
        </Skeleton>
      </div>
    </div>
  );
};
