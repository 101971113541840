import { Address as IAddress, StepType } from '@onbeefapp/constants';
import FormAddress from '../../../components/Forms/FormAddress';
import HeaderBar from '../../../components/Headers/HeaderBar';
import { useUserStore } from '../../../stores/user';
import { useNavigate } from 'react-router-dom';
import { useOrderDetails } from '../../../stores/order-details';

export const Address: React.FC = () => {
  const navigate = useNavigate();

  const address = useUserStore((state) => state.address);
  const setAddress = useUserStore((state) => state.setAddress);
  const setCurrentStep = useOrderDetails((state) => state.setCurrentStep);

  const handleSelectedAddress = (address: IAddress) => {
    setAddress(address);
    setCurrentStep(StepType.SCHEDULING);
    navigate(-1);
  };

  const addressNotFound = address
    ? !address.latitude || !address.longitude
    : false;

  return (
    <div className="w-full p-2">
      <HeaderBar>
        <div className="w-full text-center">Endereço</div>
      </HeaderBar>

      <FormAddress
        onAddressSelected={handleSelectedAddress}
        predefinedAddress={addressNotFound ? address : undefined}
      />
    </div>
  );
};
