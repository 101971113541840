import CollapsibleItem from '../../../components/Collapse/CollapsibleItem';
import { useOrderDetails } from '../../../stores/order-details';
import { StepType } from '@onbeefapp/constants';
import * as React from 'react';

const ItemDeliveryInstructions: React.FC = () => {
  const {
    currentStep,
    setCurrentStep,
    setDeliveryInstructions,
    deliveryInstructions,
    setAction,
  } = useOrderDetails((state) => ({
    currentStep: state.currentStep,
    setCurrentStep: state.setCurrentStep,
    setDeliveryInstructions: state.setDeliveryInstructions,
    deliveryInstructions: state.deliveryInstructions,
    setAction: state.setAction,
  }));

  return (
    <CollapsibleItem
      active={currentStep === StepType.DELIVERY_INSTRUCTIONS}
      onClick={() => {
        const step =
          currentStep === StepType.DELIVERY_INSTRUCTIONS
            ? StepType.NONE
            : StepType.DELIVERY_INSTRUCTIONS;
        setCurrentStep(step);
        setAction(() => {
          setCurrentStep(StepType.FINISHED);
          window.scrollTo(0, 0);
        });
      }}
      title={
        <div className="flex flex-row items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
            />
          </svg>

          <div className="flex flex-col justify-start">
            <div className="ml-2 font-bold text-sm">Instruções de entrega</div>
            <div className="ml-2 text-sm font-normal  text-gray-500">
              Como quer receber sua entrega?
            </div>
          </div>
        </div>
      }
    >
      <textarea
        className="w-full h-24 p-2 mt-2 border-2 border-gray-200 rounded-md"
        placeholder="Ex: Toque o interfone 1234"
        value={deliveryInstructions}
        onChange={(e) => {
          setDeliveryInstructions(e.target.value);
        }}
      ></textarea>
    </CollapsibleItem>
  );
};

export default ItemDeliveryInstructions;
