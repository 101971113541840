import { Item } from '../../types/types'
import { Skeleton } from '@onbeef/components/skeleton'
import { AddIcon, MinusIcon, currencyFormat } from '@onbeefapp/constants'
import * as React from 'react'

interface ListComplementItemProps {
  item: Item
  isLoading: boolean
  quantityNumber: number
  statusButton: () => boolean
  handleRemoveComplement: (item: Item) => void
  handleAddComplement: (item: Item) => void
  formattedQuantity: string
  disabledButton?: boolean
}

const ListComplementItem: React.FC<ListComplementItemProps> = ({
  item,
  isLoading,
  quantityNumber,
  handleRemoveComplement,
  handleAddComplement,
  statusButton,
  formattedQuantity,
  disabledButton = false,
}) => {
  return (
    <React.Fragment>
      <div className="flex flex-row space-x-1 justify-between border-t-[1px] first:border-0 border-gray-200 p-2 pl-4 pr-4 mb-2 mt-2 items-center">
        <div className="flex flex-col space-y-1">
          <Skeleton
            className="w-32 h-4 mb-1"
            isLoading={isLoading}
            borderRadius="10px"
          >
            <article className="leading-normal text-sm font-normal">
              {item?.name}
            </article>
          </Skeleton>
          <Skeleton
            className="w-16 h-4"
            isLoading={isLoading}
            borderRadius="10px"
          >
            <article className="leading-normal text-sm font-normal text-[#a80000]">
              {currencyFormat(item?.price)}
            </article>
          </Skeleton>
        </div>

        <div className="flex flex-row h-full space-x-4">
          {quantityNumber > 0 && (
            <>
              <Skeleton
                className="w-7 h-7"
                isLoading={isLoading}
                borderRadius="10px"
              >
                <button
                  className="text-primary"
                  onClick={() => {
                    handleRemoveComplement(item)
                  }}
                  type="button"
                >
                  <MinusIcon />
                </button>
              </Skeleton>
              <div
                className="min-w-[50px] text-center self-center"
                onClick={() => {
                  handleAddComplement(item)
                }}
              >
                <Skeleton
                  className="self-center w-16 h-6"
                  isLoading={isLoading}
                  borderRadius="10px"
                >
                  <article className="leading-normal text-sm font-semibold">
                    {formattedQuantity}
                  </article>
                </Skeleton>
              </div>
            </>
          )}
          <Skeleton
            className="w-7 h-7"
            isLoading={isLoading}
            borderRadius="10px"
          >
            <button
              className={
                statusButton() && !disabledButton
                  ? `text-primary`
                  : `text-gray-400`
              }
              onClick={() => {
                handleAddComplement(item)
              }}
              type="button"
              disabled={!statusButton()}
            >
              <AddIcon />
            </button>
          </Skeleton>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ListComplementItem
