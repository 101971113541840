import { Radio } from '@material-tailwind/react';
import {
  calculateSchedulingDisplayValue,
  formatDate,
  weekDayToHuman,
} from '@onbeefapp/constants';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { useGetSchedulings } from '../../queries/order';
import { SchedulingTime } from '../../queries/order/types';
import { useCartStore } from '../../stores/cart';
import { useMerchantStore } from '../../stores/merchant';
import { useUserStore } from '../../stores/user';
import Skeleton from '../Skeleton/Skeleton';
import SkeletonAllSchedulings from '../Skeleton/SkeletonAllSchedulings';

interface Props {
  goBack?: () => void;
}

const ListAllSchedulings: React.FC<Props> = ({ goBack }) => {
  const merchant = useMerchantStore((state) => state.merchant);

  const navigate = useNavigate();

  const address = useUserStore((state) => state.address);

  const deliveryTime = useCartStore((state) => state.deliveryTime);
  const setDeliveryTime = useCartStore((state) => state.setDeliveryTime);

  const { data, isLoading } = useGetSchedulings(merchant?.id);
  const baseDiscount = data?.discounts?.[0];

  const {
    frete,
    isFreteGratis,
    isACombinar,
    freteValue,
    withFreteGratisDiscount,
  } = React.useContext(OrderDetailsContext);

  const [availableTimes, setAvailableTimes] =
    React.useState<SchedulingTime[]>();

  const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);

  const scrollToStart = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const navigateBack = () => {
    if (goBack) return goBack();
    return navigate(-1);
  };

  React.useEffect(() => {
    if (!data) return;
    if (deliveryTime) {
      setAvailableTimes(
        data?.daysAvailable?.find((day) => day.date === deliveryTime.date)
          ?.times,
      );
    } else {
      setAvailableTimes(data?.daysAvailable?.[0]?.times);
    }
  }, [deliveryTime, data]);

  return (
    <div className="flex flex-col w-full space-y-4 p-2">
      <p className="font-medium text-sm">
        Selecione o melhor horário para receber seu pedido
      </p>
      <div className="flex space-x-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
          />
        </svg>
        <p className="font-semibold text-sm">
          {address
            ? `${address.street_name}, ${address.street_number}`
            : 'Ainda sem endereço'}
        </p>
      </div>

      <div className="flex flex-col space-y-2 mt-4">
        <SkeletonAllSchedulings isLoading={isLoading}>
          <div
            className="flex flex-row overflow-x-auto w-full space-x-2"
            style={{
              scrollbarWidth: 'thin',
              scrollbarColor: '#cccccc transparent',
            }}
            ref={scrollableDivRef}
          >
            {data?.daysAvailable?.map((day) => {
              const selected = deliveryTime
                ? deliveryTime.date === day.date
                : day.times[0]?.default;
              return (
                <div
                  key={`day${day.date}`}
                  onClick={() => {
                    setDeliveryTime(day.date, day.times[0], freteValue);
                  }}
                  className={`flex flex-col flex-shrink-0 w-[28%] rounded-lg border-2 border-gray-300 p-2 items-center cursor-pointer ${
                    selected && '!border-[#0c8a47]'
                  }`}
                >
                  <span className="font-semibold text-sm">
                    {weekDayToHuman(day.day)?.substring(0, 3)}.
                  </span>
                  <span className="text-sm">{formatDate(day.date)}</span>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col border-t border-b divide-y h-[51vh] overflow-auto">
            {availableTimes?.map((time) => {
              const selected = deliveryTime
                ? deliveryTime?.time_interval.start === time.start
                : time.default;
              const discount = !time.default
                ? time.discount || baseDiscount
                : null;
              return (
                <div
                  key={`time${time.start}`}
                  className="flex flex-row justify-between items-center w-full p-2"
                  onClick={() => {
                    if (selected || time.default) {
                      setDeliveryTime(undefined);
                      scrollToStart();
                    } else {
                      const date =
                        deliveryTime?.date || data?.daysAvailable?.[0]?.date;
                      setDeliveryTime(date, { ...time, discount }, freteValue);
                    }
                  }}
                >
                  <div className="flex flex-row space-x-2 items-center">
                    <Radio
                      className="cursor-pointer"
                      checked={selected}
                      crossOrigin={''}
                    />
                    <span className="font-semibold text-sm">
                      {time.start} - {time.end}
                    </span>
                  </div>
                  <div className="flex flex-row items-center">
                    <p className="font-medium text-sm">
                      {calculateSchedulingDisplayValue({
                        discount,
                        frete,
                        freteValue,
                        isACombinar,
                        isFreteGratis,
                        withFreteGratisDiscount,
                      })}
                    </p>
                    {!isACombinar && discount && (
                      <p className="uppercase bg-[#fad2d2] text-[#700000] px-2 py-1 rounded-xl font-bold ml-2 text-sm">
                        -{discount.percent}%
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </SkeletonAllSchedulings>
      </div>
      <div className="flex flex-row items-center justify-center w-full mt-3">
        <Skeleton isLoading={isLoading} className="w-full rounded-md h-11">
          <button
            className=" p-2 text-contrastText bg-primary rounded-md w-full text-sm"
            onClick={navigateBack}
          >
            Selecionar horário
          </button>
        </Skeleton>
      </div>
    </div>
  );
};

export default ListAllSchedulings;
