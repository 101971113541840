import getAddressInfo from '@onbeefapp/constants/src/utils/GoogleAddressUtils';
import React from 'react';
import Geocode from 'react-geocode';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey('AIzaSyBt55rofDfwKXQhaz9zFRetA-Rrhugr4Fg');

// set response language. Defaults to english.
Geocode.setLanguage('pt-BR');

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion('BR');

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
// Geocode.setLocationType('ROOFTOP');

// Enable or disable logs. Its optional.
Geocode.enableDebug();

export function getUserPosition() {
  const promiseArray = [];

  if ((navigator as any).standalone) {
    promiseArray.push(
      new Promise((reject) => {
        const wait = setTimeout(() => {
          clearTimeout(wait);
          reject('Location has timed out');
        }, 4000);
      }),
    );
  }

  const getCurrentPositionPromise = new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        timeout: 5000,
        maximumAge: 2000,
        enableHighAccuracy: true,
      });
    } else {
      reject(new Error('Browser does not support geolocation!'));
    }
  });

  promiseArray.push(getCurrentPositionPromise);

  return Promise.race(promiseArray);
}

export function useGeocodeLocation() {
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const getLocationAddress = async () => {
    try {
      const position: any = await getUserPosition();
      const { latitude, longitude } = position.coords;
      const response = await Geocode.fromLatLng(latitude, longitude);
      setLoading(false);
      return {
        ...getAddressInfo(response.results[0].address_components),
        latitude,
        longitude,
      };
    } catch (error) {
      console.error(error);
      setError('GPS desativado');
      setLoading(false);
    }
  };

  return { error, loading, getLocationAddress };
}
