/**
 * TrackingContext
 * Version: 1.0.1
 * Author: Fellipe Orsini
 * License: onbeefapp.com.br
 *
 * (c) 2023 onbeefapp.com.br. All rights reserved.
 * This software is confidential and proprietary information of onbeefapp.com.br.
 * Unauthorized reproduction, distribution, or disclosure is prohibited.
 */
// @ts-nocheck
import { spUnixTimestamp } from '@onbeefapp/constants';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigationType } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useMerchantStore } from '../stores/merchant';
import posthog from 'posthog-js';

const DEBUG = false; // false in production

// Define the shape of traits for tracking
interface Traits {
  merchant?: string;
  merchantSlug?: string;
  merchantcurrentStep?: string;
  browserInfo?: object;
  timestamp?: number;
  path?: string;
  actionData?: any;
  eventId?: string;
  oldPath?: string;
  user?: string;
  slug?: string;
  cartSteps?: any;
  payment_method?: any;
  delivery_type?: any;
  tags?: any;
  cuts?: any;
  selectedTags?: any;
  id?: any;
  name?: any;
  final_value?: any;
  term?: any;
  subtotal?: any;
}

// Define function types for tracking
type TrackingFunctionA = (param: string, param2: object) => void;
type TrackingFunctionB = (param: object) => void;

// Define the type for the TrackingContext
type TrackingContextType = {
  track: TrackingFunctionA;
  trackPage?: TrackingFunctionA;
  trackForm?: TrackingFunctionB;
  trackLink?: TrackingFunctionB;
};

const pathList = [
  { path: '/:slug/category/:name', event: 'OnCustomerViewCategory' },
  { path: '/:slug/product/:name', event: 'OnCustomerViewProduct' },
  { path: '/:slug/details', event: 'OnCustomerViewMerchantDetails' },
  { path: '/:slug/cart', event: 'OnCustomerViewCart' },
  { path: '/:slug/coupon', event: 'OnCustomerAtCartViewCoupon' },
  { path: '/:slug/schedule', event: 'OnCustomerViewSchedule' },
  { path: '/:slug/checkout', event: 'OnCustomerViewCheckout' },
  { path: '/:slug/checkout/pix/:id', event: 'OnCustomerViewCheckoutPix' },
  { path: '/:slug/checkout/pix/:id', event: 'OnCustomerViewCheckoutPix' },
  {
    path: '/:slug/checkout/:orderid/validate-cents/:code',
    event: 'OnCustomerViewCheckoutOrderValidation',
  },
  { path: '/:slug/status/:id', event: 'OnCustomerViewStatus' },
  { path: '/:slug/loyalty', event: 'OnCustomerViewLoyalty' },
  { path: '/:slug/login', event: 'OnCustomerViewLogin' },
  { path: '/:slug/orders', event: 'OnCustomerViewOrders' },
  { path: '/:slug/orders', event: 'OnCustomerViewOrders' },
  { path: '/:slug/orders/:id', event: 'OnCustomerViewOrder' },
  { path: '/:slug/orders/:id/rate', event: 'OnCustomerViewOrderRate' },
];

const previousEvents: { [eventId: string]: boolean } = {};

export const TrackingContext = createContext<TrackingContextType>({
  track: () => {},
});

const TrackingProvider = ({ children }: React.PropsWithChildren<any>) => {
  const location = useLocation();
  const navigationType = useNavigationType();
  const [sessionId, setSessionId] = useState<string>(''); // State to store the session ID
  const [tabId] = useState<string>(uuidv4()); // Generate a unique tab ID using UUID v4
  const [merchantId, setMerchantId] = useState<string | undefined>(undefined); // State to store the current merchant id
  const [eventsList, setEventsList] = useState<
    Array<{ event: string; properties: Traits }>
  >([]); // State to store the list of events
  const [inSyncEventsList, setInSyncEventsList] = useState<
    Array<{ event: string; properties: Traits }>
  >([]); // State to store the list of events
  const [isSyncing, setSyncing] = useState<boolean>(false); // State to store the sync state
  //   const [browserInfo, setBrowserInfo] = useState<object>({}); // State to store the sync state
  const [currentURLPath, setCurrentURLPath] = useState<string>('');
  const { merchant } = useMerchantStore((state) => ({
    merchant: state.merchant,
  }));

  /**
   * syncEventsWithServer
   * @description Function responsible for syncing current "eventsList"
   * to the backend server, also handles "inSyncEventsList"
   * these events are trying to be synced while a sync is
   * already happening, so they are inserted in
   * "inSyncEventsList" array and when the current sync is
   * finished it'll put "inSyncEventsList" inside "eventsList"
   * and finally sync the events with the backend API
   * @returns boolean (true when events are synced, false for errors)
   */
  const syncEventsWithServer = async () => {
    if (DEBUG) {
      if (inSyncEventsList.length > 0) {
        setEventsList((prevList: any[]) => [...prevList, ...inSyncEventsList]);
        setInSyncEventsList([]);
      } else {
        // Clear eventsList after syncing
        setEventsList([]);
      }
      return;
    }
    if (eventsList.length === 0) return false; // Return early if there are no events to sync
    syncEventsWithPostHog();
    return true;
  };

  const syncEventsWithPostHog = async () => {
    const eventsListCopy = [...eventsList];
    eventsListCopy.forEach((event) => {
      const copyProperties = { ...event.properties };

      event.properties = {};
      event.properties.merchantId = merchantId;
      event.properties.sessionId = sessionId;
      event.properties.tabId = tabId;
      event.properties.eventData = JSON.stringify(copyProperties);

      //console.log('PostHog Event: ', event.event, event.properties);

      posthog?.catalogo_ph?.capture(event.event, event.properties, {
        timestamp: event.properties.eventData.timestamp,
      });
    });

    if (inSyncEventsList.length > 0) {
      setEventsList((prevList: any[]) => [...prevList, ...inSyncEventsList]);
      setInSyncEventsList([]);
    } else {
      // Clear eventsList after syncing
      setEventsList([]);
    }
  };

  /**
   * identity
   * @description This function will send an "identify"
   * event into eventsList
   * @param user_id
   * @param traits
   */
  //   const identify = (user_id: string | null, traits: Traits) => {
  //     // Identifies the current user session
  //     if (traits.merchant) {
  //       setMerchantId(traits.merchant);
  //     }
  //     traits.user = user_id === null ? 'anonymous' : user_id;

  //     track(`OnCustomerIdentify`, traits);
  //   };

  /**
   * track
   * @param event string
   * @param properties Traits
   * @returns boolean
   */
  const track = (event: string, properties: Traits) => {
    // Store current pathname
    properties.path = location.pathname;

    // Get the current timestamp in the 'America/Sao_Paulo' timezone and convert it to Unix timestamp
    properties.timestamp = spUnixTimestamp();

    // Combine the event name and timestamp to create a unique identifier for the event
    const eventId = `${event}_${properties.timestamp}`;

    // New event object
    const newEvent = { event, properties };

    // Check if the eventId already exists in the previousEvents object
    if (previousEvents[eventId]) {
      return false; // Return early if it's a duplicate event
    }

    // Store the new event in the previousEvents object
    previousEvents[eventId] = true;

    // Store eventId
    properties.eventId = eventId;

    // Store oldPath
    properties.oldPath =
      location.pathname != currentURLPath ? currentURLPath : '';

    // Check if current events are being synced
    if (isSyncing) {
      setInSyncEventsList((prevList: any[]) => [...prevList, newEvent]);
    } else {
      setEventsList((prevList: any[]) => [...prevList, newEvent]);
    }

    // Check if the app has just loaded
    switch (event) {
      case 'OnCustomerViewMerchantStore': {
        for (const i in pathList) {
          const pathData = pathList[i];
          const isMatch = matchPath(pathData.path, properties.path);
          if (isMatch) {
            track(pathData.event, {});
            break;
          }
        }
        break;
      }
      case 'OnCustomerViewMerchantDetails': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'Contact', {
            content_name: 'Ver Detalhes do Lojista',
          });
        break;
      }
      case 'OnCustomerClickWhatsAppPill': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'Contact', {
            content_name: 'Botão WhatsApp',
          });
        break;
      }
      case 'OnCustomerClickInstagramPill': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'ViewContent', {
            content_name: 'Ver Instagram',
          });
        break;
      }
      case 'OnCustomerClickGMapsPill': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'FindLocation');
        break;
      }
      case 'OnCustomerCartAddProduct': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'AddToCart', {
            content_ids: [properties.id],
            content_name: properties.name,
            price: properties.final_value,
            currency: 'BRL',
          });
        break;
      }
      case 'OnCustomerAtProductAddToCart': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'AddToCart', {
            content_ids: [properties.id],
            content_name: properties.name,
            price: properties.final_value,
            currency: 'BRL',
          });
        break;
      }
      case 'OnCustomerViewCart': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'ViewContent', {
            content_name: 'Ver Carrinho',
          });
        break;
      }
      case 'OnCustomerViewProduct': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'ViewContent', {
            content_name: 'Ver Produto',
            content_category: properties.name,
          });
        break;
      }
      case 'OnCustomerViewCategory': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'ViewContent', {
            content_name: 'Ver Categoria',
            content_category: properties.slug,
          });
        break;
      }
      case 'OnCustomerClickQuickCategoryPill': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'ViewContent', {
            content_ids: [properties.id],
            content_name: 'Ver Categoria Página Inicial',
            content_category: properties.name,
          });
        break;
      }
      case 'OnCustomerSearchInput': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'Search', {
            content_name: 'Busca',
            search_string: properties.term,
          });
        break;
      }
      case 'OnCustomerViewCheckout': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'InitiateCheckout');
        break;
      }
      case 'OnCustomerSubmitOrder': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'Purchase', {
            content_name: 'Realizou pedido',
            value: properties.subtotal ?? 0,
            currency: 'BRL',
            content_type: 'product',
          });
        break;
      }
      case 'OnCustomerAtCheckoutContinueWithPix': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'Purchase', {
            content_name: 'Realizou pedido via PIX',
            value: properties.subtotal ?? 0,
            currency: 'BRL',
            content_type: 'product',
          });
        break;
      }
      case 'OnCustomerAtCheckoutCreateCreditCard': {
        if (typeof window.fbq !== 'undefined' || null)
          window.fbq('track', 'AddPaymentInfo', {
            content_name: 'Adicionou Cartão de Crédito',
          });
        break;
      }
    }
    return true;
  };

  /**
   * trackLink
   * @description Calls "track" function with properties
   * with an event called "OnCustomerLinkClick"
   * @param properties Traits
   * @returns
   */
  const trackLink = (properties: Traits) => {
    track(`OnCustomerLinkClick`, properties); // old typo error: OnCustomerLinKClick
  };

  /**
   * trackForm
   * @description Calls "track" function with properties
   * with an event called "OnCustomerFormSubmit"
   * @param properties Traits
   * @returns
   */
  const trackForm = (properties: Traits) => {
    track(`OnCustomerFormSubmit`, properties);
  };

  /**
   * trackPage
   * @description Calls "track" function with properties
   * with an event name set in "page" param
   * @param page string
   * @param properties Traits
   * @returns
   */
  const trackPage = (page: string, properties: Traits) => {
    track(page, properties);
  };

  /**
   * Effects to generate user session id, also handles
   * page load, unload and url changes
   */
  useEffect(() => {
    // Function to retrieve or generate the session ID
    const getSessionId = () => {
      if (typeof localStorage !== 'undefined') {
        // Check if localStorage is supported
        let storedSessionId: string | null = localStorage.getItem('sessionId');
        if (!storedSessionId) {
          // If no session ID is stored, generate a new one
          storedSessionId = uuidv4();
          localStorage.setItem('sessionId', storedSessionId ?? ''); // Store the session ID in localStorage
        }
        setSessionId(storedSessionId ?? '');
      } else {
        // Use cookies as a fallback if localStorage is not supported
        const cookieName = 'sessionId';
        const cookieValue = document.cookie.replace(
          new RegExp(
            `(?:(?:^|.*;\\s*)${cookieName}\\s*\\=\\s*([^;]*).*$)|^.*$`,
          ),
          '$1',
        );
        if (!cookieValue) {
          // If no session ID is stored in cookies, generate a new one
          const newSessionId = uuidv4();
          document.cookie = `${cookieName}=${newSessionId}`; // Set the session ID as a cookie
          setSessionId(newSessionId);
        } else {
          setSessionId(cookieValue);
        }
      }
    };

    getSessionId(); // Call the getSessionId function to initialize the session ID

    const handleUnload = () => {
      trackPage(`OnCustomerLeaveMerchantStore`, {
        path: location.pathname,
      });
      syncEventsWithServer();
    };

    const handleLoad = () => {
      trackPage(`OnCustomerAppLoad`, {
        path: location.pathname,
      });
    };

    /*const timer = setInterval(() => {
            if (eventsList.length > 0) {
                syncEventsWithServer(); // Sync events with the server if there are new events
            }
            }, 10.3 * 1000); // Sync every 6.3 seconds*/

    window.addEventListener('beforeunload', handleUnload); // Sync events with the server before unloading the page
    window.addEventListener('load', handleLoad); // Sync events with the server before unloading the page

    // Create a function to update the URL state when the window location changes
    const handleUrlChange = () => {
      setCurrentURLPath(window.location.pathname);
    };

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      //clearInterval(timer); // Clean up the timer when the component unmounts
      //window.removeEventListener('beforeunload', handleUnload); // Remove the event listener
      //window.removeEventListener('load', handleLoad); // Sync events with the server before unloading the page
    };
  }, []);

  /**
   * Effects to detect location and navigation type changes
   */
  useEffect(() => {
    // Handle page change here
    if (merchantId) {
      setCurrentURLPath(location.pathname);
      trackPage('OnCustomerPageView', {
        path: location.pathname,
        actionData: navigationType,
      });
    }
  }, [location, navigationType]);

  /**
   * Effect to detect when "eventsList" should be synced
   * with the server
   */
  useEffect(() => {
    if (eventsList.length > 0 && !isSyncing) syncEventsWithServer();
  }, [eventsList]);

  /**
   * Effect to send "OnCustomerViewMerchantStore" event
   */
  useEffect(() => {
    if (merchant && !merchantId) {
      setMerchantId(merchant?.id);
      posthog?.group(`lojista`, merchant?.id, {
        merchant: merchant?.id,
        merchantSlug: merchant?.slug,
      });
      trackPage('OnCustomerViewMerchantStore', {
        merchant: merchant?.id,
        merchantcurrentStep: merchant?.slug,
      });
    }
  }, [merchant]);

  const contextValue: TrackingContextType = {
    track,
    trackPage,
    trackForm,
    trackLink,
  };

  return (
    <TrackingContext.Provider value={contextValue}>
      {children}
    </TrackingContext.Provider>
  );
};

export const UseTracking = () => React.useContext(TrackingContext);

export default TrackingProvider;
