import React from 'react'
import cx from 'classnames'
import { Tooltip as TooltipContainer } from '@material-tailwind/react'

interface Props {
  className?: string
  containerClassName?: string
  children: React.ReactNode
  text: string | JSX.Element
}

export const Tooltip: React.FC<Props> = ({
  containerClassName,
  className,
  text,
  children,
}) => {
  return (
    <div className={containerClassName}>
      <TooltipContainer
        content={text}
        placement="bottom-start"
        className={cx(
          'z-50 max-w-md border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10 text-black',
          className
        )}
      >
        <div className={containerClassName}>{children}</div>
      </TooltipContainer>
    </div>
  )
}

Tooltip.displayName = 'Tooltip'
export type TooltipProps = Props
export default Tooltip
