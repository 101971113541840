import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import api from '../../services/api';
import { GetCouponsResponse, ValidateCouponResponse } from './types';

export interface ValidateCouponParams {
  merchant_id: string;
  code: string;
  order_amount: number;
  type_delivery: string;
}

export async function validateCoupon(params: ValidateCouponParams) {
  const { data } = await api.post<ValidateCouponResponse>('/all/check/coupon', {
    ...params,
  });
  return data;
}

export interface GetCouponsParams {
  merchant_id: string;
  amount: number;
  type_delivery: string;
}

export async function getCoupons(ctx: QueryFunctionContext) {
  const [, params] = ctx.queryKey;
  if (!params) {
    return;
  }
  const { data } = await api.post<GetCouponsResponse>('/all/coupons/list', {
    ...params,
  });
  return data;
}

export function useValidateCoupon() {
  return useMutation(['validateCoupon'], validateCoupon);
}

export function useGetCoupons(params: GetCouponsParams) {
  return useQuery(['getCoupons', params], getCoupons);
}
