import { useNavigate } from 'react-router-dom';
import { getWhatsappURL } from '../utils/Whatsapp/WhatsappURL';
import { OrderDetails, StatusType } from '@onbeefapp/constants';
import { useGeneratedOrderStore } from '../stores/pix';
import { CreateOrderResponse } from '../queries/order/types';
import { useMerchantStore } from '../stores/merchant';
import { statusPage } from '../utils/function';
import React from 'react';
import { useGetOrderDetail } from '../queries/my-orders';
import { useRefreshLoyaltyData } from '../queries/loyalty';
import { useAuthStore } from '../stores/login';

export const useContinueActionAfterSales = (orderID: string) => {
  const navigate = useNavigate();

  const merchant = useMerchantStore((state) => state.merchant);
  const { generatedOrder, usedLoyalty } = useGeneratedOrderStore((state) => ({
    generatedOrder: state.generatedOrder,
    usedLoyalty: state.usedLoyalty,
  }));
  const updateLoyalty = useAuthStore((state) => state.updateLoyalty);

  const { data: dataOrder, isLoading: isLoadingOrder } =
    useGetOrderDetail(orderID);

  const {
    mutateAsync: refreshLoyaltyData,
    isLoading: isLoyaltyRefreshLoading,
  } = useRefreshLoyaltyData();

  const [orderData, setOrderData] = React.useState<OrderDetails | undefined>(
    undefined,
  );

  const handleRefreshLoyalty = async () => {
    const data = await refreshLoyaltyData();
    data && updateLoyalty(data);
  };

  const order = statusPage(
    (generatedOrder[orderID] ?? undefined) as CreateOrderResponse | undefined,
  );

  const continueAction = async (checkCRM = false) => {
    if (
      orderData &&
      generatedOrder[orderID] &&
      order.status === StatusType.SUCCESS
    ) {
      const id = generatedOrder[orderID]?.id ?? '';

      let wppURL = await getWhatsappURL(orderData.merchant.phone, orderData.id);

      if (merchant?.whatsapp_instance) {
        if (
          merchant.whatsapp_instance.is_connected &&
          merchant.whatsapp_instance.config_details.send_orders_to_customers &&
          merchant.whatsapp_instance.phone
        ) {
          const phone = merchant.whatsapp_instance.phone;
          wppURL =
            'https://api.whatsapp.com/send?phone=+' +
            encodeURIComponent(parseInt(phone.replace(/[^0-9]/g, ''))) +
            '&text=' +
            encodeURIComponent('Quero acompanhar meu pedido!');
        }
      }

      if (checkCRM && orderData.crm_bonus?.status === true) {
        return navigate(`/phone/${id}`);
      }

      if (usedLoyalty) {
        await handleRefreshLoyalty();
      }
      if (merchant?.orders_on_whatsapp) {
        window.location.href = wppURL;
      } else {
        navigate(`/orders/${id}`, { replace: true });
      }
    } else {
      navigate(`/`, { replace: true });
    }
  };

  React.useEffect(() => {
    if (dataOrder) {
      setOrderData(dataOrder);
    }
  }, [dataOrder]);

  return {
    continueAction,
    orderData,
    isLoadingOrder,
    isLoyaltyRefreshLoading,
  };
};
