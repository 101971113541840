import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import SkeletonTab from '../Skeleton/SkeletonTab';

interface TabProps {
  active: boolean;
  show?: boolean;
  onClick: () => void;
  children: React.ReactElement[] | React.ReactElement;
}

export const Tab: React.FC<TabProps> = ({
  show = true,
  active,
  onClick,
  children,
}) => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);

  if (!show) return null;

  return (
    <SkeletonTab isLoading={isLoading}>
      <div
        className={`flex items-center justify-center cursor-pointer p-2 relative w-full`}
        onClick={onClick}
      >
        {children}
        <div
          className={`absolute bottom-0 left-0 w-full h-1 bg-primary transition-all duration-300 ease-in-out transform ${
            active ? 'scale-x-100' : 'scale-x-0'
          }`}
        />
      </div>
    </SkeletonTab>
  );
};
