import { FormProvider, useForm } from 'react-hook-form';
import HeaderBar from '../../components/Headers/HeaderBar';
import {
  LoadingIcon,
  maskPhone,
  mergeSchema,
  Toast,
} from '@onbeefapp/constants';
import { useMerchantStore } from '../../stores/merchant';
import { useNavigate } from 'react-router-dom';
import {
  useLoyaltyCheckPhone,
  useLoyaltyVerifyPhone,
} from '../../queries/loyalty';
import { AxiosError } from 'axios';
import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PhoneCheck429Error } from '../../../../fidelidade/src/types/types';
import { Countdown as LoyaltyCountdown } from '../../../../fidelidade/src/components/Countdown';

interface LoyaltyPhoneForm {
  phone: string;
  code: string;
}

const validationSchema = mergeSchema(
  Yup.object().shape({
    phone: Yup.string().when(['$verifying'], {
      is: true,
      then: (s) => s.nullable(),
      otherwise: (s) =>
        s.required('Celular é obrigatório').min(14, 'Celular inválido'),
    }),
    code: Yup.string().when(['$verifying'], {
      is: true,
      then: (s) =>
        s
          .required('O código é obrigatório')
          .max(4, 'O código deve ter menos de 4 digitos'),
      otherwise: (s) => s.nullable(),
    }),
  }),
);

export const LoyaltyPhone: React.FC = () => {
  const navigate = useNavigate();

  const merchant = useMerchantStore((state) => state.merchant);

  const { mutateAsync: checkPhone, isLoading: checkPhoneLoading } =
    useLoyaltyCheckPhone();
  const { mutateAsync: verifyPhone, isLoading: verifyPhoneLoading } =
    useLoyaltyVerifyPhone();

  const isLoading = checkPhoneLoading || verifyPhoneLoading;

  const [phone, setPhone] = React.useState('');
  const [verifyingToken, setVerifyingToken] = React.useState('');

  const [disabled, setDisabled] = React.useState(false);
  const [releasedAt, setReleasedAt] = React.useState<Date | undefined>();

  const methods = useForm<LoyaltyPhoneForm>({
    resolver: yupResolver(validationSchema),
    context: { verifying: !!verifyingToken },
  });

  const onSubmit = async (data: LoyaltyPhoneForm) => {
    if (verifyingToken) {
      await runVerifyPhone(data);
    } else {
      await runCheckPhone(data);
    }
    methods.reset();
  };

  const runVerifyPhone = async (data: LoyaltyPhoneForm) => {
    if (!merchant || !phone) return;
    try {
      const res = await verifyPhone({
        slug: merchant.slug,
        phone,
        code: data.code,
        token: verifyingToken,
      });
      const token = res?.data?.data?.customer?.token;
      navigate(`/loyalty/register/${phone}/${token}`);
    } catch (error) {
      const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
        ?.error;
      msgs?.forEach((msg) => Toast.error(msg));
    }
  };

  const runCheckPhone = async (data: LoyaltyPhoneForm) => {
    if (!merchant) return;
    try {
      const verifySms = merchant?.config.send_sms_loyalty_programme_catalog;
      const formattedPhone = data.phone.replace(/\D/g, '');

      if (!verifySms) {
        return navigate(`/loyalty/register/${formattedPhone}`);
      }

      const res = await checkPhone({
        slug: merchant.slug,
        phone: formattedPhone,
      });
      const result = res.data.data;

      if (res.status === 201) {
        setPhone(formattedPhone);
        setVerifyingToken(result.token || '');
        return;
      }

      const complete = result.customer.has_complete_registration;
      if (complete) {
        Toast.success(
          'Seu cadastro já está completo, faça o login e aproveite seus benefícios!',
        );
        navigate('/login');
        return;
      }

      navigate(`/loyalty/register/${formattedPhone}/${result.customer.token}`);
    } catch (error) {
      const err = error as AxiosError<PhoneCheck429Error>;
      const msgs = err?.response?.data?.error;
      msgs?.forEach((msg) => Toast.error(msg));
      if (err?.response?.status === 429 && err?.response?.data?.released_at) {
        setReleasedAt(new Date(err.response?.data?.released_at));
        setDisabled(true);
      }
    }
  };

  return (
    <div className="w-full h-screen p-2">
      <HeaderBar>
        <span className="text-sm font-normal text-center w-full">
          Fidalidade
        </span>
      </HeaderBar>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="mt-20 p-4 gap-4 flex flex-col"
        >
          {verifyingToken ? (
            <div className="w-full flex flex-col gap-2">
              <label htmlFor="code" className="text-lg font-medium">
                Código SMS
              </label>
              <input
                {...methods.register('code')}
                onChange={(e) => {
                  const v = e.target.value;
                  console.log('v', v.length > 4);
                  if (v.length > 4) return;
                  methods.setValue('code', v);
                }}
                value={methods.watch('code')}
                className="bg-transparent outline-none border-b border-black p-2"
              />
            </div>
          ) : (
            <div className="w-full flex flex-col gap-1">
              <label htmlFor="phone" className="text-lg font-medium">
                Insira seu celular
              </label>
              <input
                {...methods.register('phone')}
                onChange={(e) => {
                  methods.setValue('phone', maskPhone(e.target.value));
                }}
                className="bg-transparent outline-none border-b border-black p-2"
              />
            </div>
          )}

          <button
            type="submit"
            disabled={disabled}
            className="rounded-lg px-6 py-2 border-2 border-current text-primary bg-contrastText uppercase font-medium flex items-center justify-center"
          >
            {isLoading ? <LoadingIcon className="text-primary" /> : 'Avançar'}
          </button>
          {disabled && (
            <span>
              Muitas tentativas, aguarde{' '}
              <LoyaltyCountdown
                targetDate={releasedAt}
                onStop={() => setDisabled(false)}
              />{' '}
              e tente novamente.
            </span>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
