import SmartPhonePix from '../assets/images/smartphone.png';
import { useGetStatusPix } from '../queries/order';
import { useOrderStore } from '../stores/orders';
import { useGeneratedOrderStore } from '../stores/pix';
import { TrackingContext } from '../contexts/TrackingContext';
import HeaderBar from './Headers/HeaderBar';
import copy from 'copy-to-clipboard';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from '@onbeefapp/constants';
import { timerDuration } from '@onbeefapp/constants';
import { useCartStore } from '../stores/cart';

const PixPayment: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const order_id = params.id ?? '';

  const isCheckoutLink = useCartStore((state) => state.isCheckoutLink);

  const isOrderPaid = useOrderStore((state) => state.isPaid);
  const addPaidOrder = useOrderStore((state) => state.addPaid);

  const generatedOrder = useGeneratedOrderStore(
    (state) => state.generatedOrder,
  );

  const next = () => {
    if (isCheckoutLink) {
      navigate('/checkout/success', {
        replace: true,
        state: { paid_at: statusPixData?.data?.updated_at },
      });
    } else {
      navigate(`/status/${order_id}`, { replace: true });
    }
  };

  React.useEffect(() => {
    if (isOrderPaid(order_id)) {
      next();
    }
  }, []);

  // countdown timer
  const [timer, setTimer] = React.useState('05:00');
  const [seconds, setSeconds] = React.useState<number>();
  const [_startSeconds, setStartSeconds] = React.useState(300);
  const Ref = React.useRef<any>();

  const getTimeRemaining = (e: any) => {
    const total = Date.parse(e) - Date.parse(new Date() as any);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: any) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total > 0) {
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds),
      );
    } else {
      setSeconds(total / 1000);
    }
  };

  const clearTimer = (e: any) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + seconds!);
    return deadline;
  };

  const { track } = React.useContext(TrackingContext);

  React.useEffect(() => {
    clearTimer(getDeadTime());
  }, [seconds]);

  React.useEffect(() => {
    setStartSeconds(300);
    setSeconds(300);
    var formatted = timerDuration(300);
    setTimer(formatted);
  }, []);

  const { data: statusPixData, refetch: checkStatusPix } = useGetStatusPix(
    order_id,
    generatedOrder[order_id]!.code,
  );

  React.useEffect(() => {
    if (statusPixData?.data.status_payment === 'PAID') {
      track('OnCustomerCompleteOrder', {
        payment_method: 'PIX',
      });
      addPaidOrder(order_id);
      next();
    }
  }, [statusPixData]);

  React.useEffect(() => {
    const run = async () => {
      let i = 0;
      const sleep = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));
      while (i < 30) {
        const res = await checkStatusPix();
        if (res.data?.data.status_payment === 'PAID') {
          break;
        }
        await sleep(10000);
        i++;
      }
    };
    run();
  }, []);

  let code = generatedOrder[order_id]?.payment_codes?.qr_code ?? '';

  function copyCode() {
    copy(code);

    Toast.success('Código copiado com sucesso');
  }

  const erroPix = generatedOrder[order_id]?.payment_codes?.error;

  if (erroPix) {
    return (
      <div className="w-full h-auto bg-white rounded-[10px] p-4 mt-4 flex flex-col">
        <div className="font-bold text-1xl">Erro ao gerar o Pix</div>
        <div className="flex flex-col justify-between rounded-lg border-[2px] border-gray-400 p-4 items-center mt-2">
          {generatedOrder[order_id]?.payment_codes?.error}
        </div>
      </div>
    );
  }

  return (
    <div className="p-2">
      <HeaderBar>
        <div className="text-sm font-normal text-center w-full">
          Pagamento pendente
        </div>
      </HeaderBar>

      <div className="w-full space-y-4 h-auto rounded-[10px] flex text-sm flex-col p-4">
        <img
          src={SmartPhonePix}
          alt="pix"
          className="w-[120px] h-[120px] rounded-lg self-center md:hidden flex"
          loading="lazy"
        />
        <div className="md:flex hidden self-center">
          Copie o código abaixo e utilize o Pix Copia e Cola ou escaneie o QR
          CODE no aplicativo que você vai fazer o pagamento:
        </div>
        <img
          src={generatedOrder[order_id]?.payment_codes.qr_code_url}
          alt="pix"
          className="w-[120px] h-[120px] rounded-lg self-center md:flex hidden"
          loading="lazy"
        />
        <div className="text-2xl font-bold text-center">
          Como fazer o pagamento?
        </div>
        <div>1 - Copie o código abaixo</div>
        <div>2 - Acesse seu banco ou app de pagamentos</div>
        <div>3 - Escolha pagar via pix copia e cola</div>
        <div>
          4 - Cole o código ou copie o QR Code para realizar o pagamento
        </div>
        <div className="bg-white text-black rounded-md p-2 mt-2">
          {generatedOrder[order_id]?.payment_codes.qr_code.slice(0, 45)}...
        </div>
        <button
          className="bg-primary text-contrastText rounded-md p-2 mt-2"
          onClick={copyCode}
        >
          Copiar código
        </button>
        <div>
          Você tem até 5 minutos para fazer o pagamento, seu tempo é de:
        </div>
        <div className="text-2xl font-bold text-center">{timer}</div>
      </div>
    </div>
  );
};

export default PixPayment;
