import React from 'react'
import { Skeleton } from '../skeleton'
import cx from 'classnames'
interface TabProps {
  active: boolean
  disabled?: boolean
  onClick: () => void
  isLoading?: boolean
  children: React.ReactElement[] | React.ReactElement
  className?: string
}

export const Tab: React.FC<TabProps> = ({
  disabled = false,
  active,
  onClick,
  isLoading,
  children,
  className,
}) => {
  return (
    <Skeleton isLoading={isLoading || false}>
      <button
        type="button"
        className={cx(
          `flex items-center justify-center cursor-pointer px-4 py-3 relative text-xl font-medium ${
            active ? 'text-primary' : 'text-gray-500'
          }`,
          className
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
        <div
          className={`absolute bottom-0 left-0 w-full h-0.5 bg-primary transition-all duration-300 ease-in-out transform ${
            active ? 'scale-x-100' : 'scale-x-0'
          }`}
        />
      </button>
    </Skeleton>
  )
}
