import { Item } from '../../types/types'
import { Skeleton } from '@onbeef/components/skeleton'
import { cortesImages } from '@onbeefapp/constants'
import * as React from 'react'

interface ListComplementImagesProps {
  item: Item
  isLoading: boolean
  quantityNumber: number
  handleClickGrams: (item: Item) => void
  formattedQuantity: string
}

const ListComplementImages: React.FC<ListComplementImagesProps> = ({
  item,
  isLoading,
  quantityNumber,
  handleClickGrams,
  formattedQuantity,
}) => {
  return (
    <React.Fragment>
      <div
        className="flex flex-row w-full cursor-pointer hover:bg-gray-200 h-[100px] lg:w-[100px] lg:h-[100px] justify-between border-2 rounded-md border-gray-300 p-2  pr-4 mb-2 mt-2 items-center relative"
        onClick={() => {
          handleClickGrams(item)
        }}
      >
        <img
          src={cortesImages[item.name]}
          loading="lazy"
          className="mx-auto pt-2"
        />
        <Skeleton
          className="absolute w-32 h-4 mb-1"
          isLoading={isLoading}
          borderRadius="10px"
        >
          <article className="leading-normal text-sm font-normal absolute left-0 top-1 w-full items-center justify-center flex flex-row">
            {item?.name}
          </article>
        </Skeleton>
        {quantityNumber > 0 && (
          <>
            <div className="min-w-[50px] text-center self-center absolute flex flex-row w-full items-center justify-center left-0 bottom-0">
              <Skeleton
                className="self-center w-16 h-6"
                isLoading={isLoading}
                borderRadius="10px"
              >
                <article className="leading-normal text-sm font-semibold ">
                  {formattedQuantity}
                </article>
              </Skeleton>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default ListComplementImages
