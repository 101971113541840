import cx from 'classnames'
import React from 'react'
import {
  FieldValues,
  RegisterOptions,
  useFormContext,
  FieldError,
} from 'react-hook-form'
import styled from 'styled-components'

interface InputPayload extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string
  className?: string
  customLabel?: string
  labelClassName?: string
  name: string
  validationSchema?: RegisterOptions<FieldValues, string> | undefined
  error?: boolean
  shrink?: boolean
  ref?: React.LegacyRef<HTMLInputElement>
  setRef?: (element: HTMLInputElement | null) => void
  errors?: FieldError
}

const Input = styled.input`
  background-color: #fff;
  :focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`

export const Checkbox: React.FC<InputPayload> = ({
  containerClassName = 'w-full',
  className,
  name,
  id,
  validationSchema,
  customLabel,
  labelClassName,
  error,
  errors,
  checked,
  setRef,
  ...rest
}) => {
  const { register } = useFormContext()

  const registerData = register?.(
    name,
    validationSchema ? validationSchema : {}
  )
  if (setRef) {
    registerData.ref = setRef
  }

  return (
    <div key={name} className={cx(containerClassName)}>
      <div className="relative flex flex-row space-x-3">
        <Input
          id={id || name}
          defaultChecked={checked}
          type="checkbox"
          className={cx(
            ` rounded-md rounded-lg min-w-5 min-h-5 cursor-pointer border w-5 border-gray-400 py-1 pl-1`,
            error || errors?.message ? ' border-red-400' : '',
            className
          )}
          {...registerData}
          {...rest}
        />
        {customLabel && (
          <label
            htmlFor={id || name}
            className={cx(
              'text-left text-sm cursor-pointer font-medium text-gray-500',
              error || errors?.message ? 'text-red-400' : '',
              labelClassName
            )}
          >
            {customLabel}
          </label>
        )}
        {errors?.message && (
          <span className="text-red-400 text-sm">{errors.message}</span>
        )}
      </div>
    </div>
  )
}

export default Checkbox
