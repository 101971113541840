import { OrderPayload } from '../queries/order/types';
import { ProductCartType } from './cart';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type OrderDraft = {
  id: string;
  name: string;
  phone: string;
  taxpayer_id_number: string;
  address?: {
    street_name: string;
    street_number: string;
    neighborhood: string;
    city: string;
    state: string;
    zip: string;
    street_complement: string;
    latitude: string;
    longitude: string;
    street_reference: string;
    country: string;
  };
};

type OrderStore = {
  // IDs de pedidos temporarios
  sent: string[];
  drafts: OrderDraft[];
  currentOrder: OrderDraft | null;
  tempOrder: OrderPayload | null;
  tempCart: Record<string, Record<string, ProductCartType>>;
  // pedidos ja enviados
  sentCart: Record<string, Record<string, ProductCartType>>;
  addSentCart: (cart: Record<string, Record<string, ProductCartType>>) => void;
  addSent: (id: string) => void;
  createOrderDraft: (draft: OrderDraft) => void;
  deleteOrderDraft: (draft: OrderDraft) => void;
  updateOrderDraft: (draft: OrderDraft) => void;
  setCurrentOrder: (draft: OrderDraft) => void;
  setTempOrder: (order: OrderPayload) => void;
  setTempCart: (cart: Record<string, Record<string, ProductCartType>>) => void;
  calculateTotal: (products: Record<string, ProductCartType>) => number;

  // pagamento online
  paidOrders: string[];
  isPaid: (order_id: string) => boolean;
  addPaid: (order_id: string | undefined) => void;
};

export const useOrderStore = create(
  persist<OrderStore>(
    (set, get) => ({
      sent: [],
      drafts: [],
      currentOrder: null,
      tempOrder: null,
      tempCart: {},
      sentCart: {},
      addSent: (id: string) => {
        set((state) => ({
          sent: [...state.sent, id],
        }));
      },
      addSentCart: (cart) => {
        set(() => ({
          tempCart: cart,
        }));
      },
      setTempCart: (cart) => {
        set(() => ({
          tempCart: cart,
        }));
      },
      setTempOrder: (order: OrderPayload) => {
        set(() => ({
          tempOrder: order,
        }));
      },
      setCurrentOrder: (draft: OrderDraft) => {
        set(() => ({
          currentOrder: draft,
        }));
      },
      createOrderDraft: (draft: OrderDraft) => {
        set((state) => ({
          drafts: [...state.drafts, draft],
        }));
      },
      deleteOrderDraft: (draft: OrderDraft) => {
        set((state) => ({
          drafts: state.drafts.filter((d) => d.id !== draft.id),
        }));
      },
      updateOrderDraft: (draft: OrderDraft) => {
        set((state) => ({
          drafts: state.drafts.map((d) => {
            if (d.id === draft.id) {
              return draft;
            }
            return d;
          }),
        }));
      },
      calculateTotal: (products: Record<string, ProductCartType>) => {
        const total = Object.values(products)
          .map((product) => product.calculatedValue ?? 0)
          .reduce((acc, value) => acc + value, 0);
        return total;
      },
      paidOrders: [],
      isPaid: (order_id: string) => {
        for (let id of get().paidOrders) {
          if (id === order_id) {
            return true;
          }
        }
        return false;
      },
      addPaid: (order_id: string | undefined) => {
        if (order_id === undefined) return;
        set((state) => ({ paidOrders: [...state.paidOrders, order_id] }));
      },
    }),
    {
      name: 'order',
      version: 1,
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
