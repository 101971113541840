import { MapPinIcon } from '@heroicons/react/24/outline';
import { Address, AddressUtils, StepType } from '@onbeefapp/constants';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import CollapsibleItem from '../../../components/Collapse/CollapsibleItem';
import FormAddress from '../../../components/Forms/FormAddress';
import { useCartStore } from '../../../stores/cart';
import { useOrderDetails } from '../../../stores/order-details';
import { useUserStore } from '../../../stores/user';

const ItemAddress: React.FC = () => {
  const navigate = useNavigate();

  const isExtension = useCartStore((state) => state.isExtension);

  const address = useUserStore((state) => state.address);
  const setAddress = useUserStore((state) => state.setAddress);
  const { currentStep, setCurrentStep } = useOrderDetails((state) => ({
    setCurrentStep: state.setCurrentStep,
    currentStep: state.currentStep,
  }));

  const handleChangeAddress = () => {
    navigate('/cart/address');
  };

  const handleExtensionSelectedAddress = (addrs: Address) => {
    setAddress(addrs);
    setCurrentStep(StepType.SCHEDULING);
  };

  const addressNotFound = address
    ? !address.latitude || !address.longitude
    : false;

  return (
    <CollapsibleItem
      active={currentStep === StepType.ADDRESS}
      onClick={() => {
        const step =
          currentStep === StepType.ADDRESS ? StepType.NONE : StepType.ADDRESS;
        setCurrentStep(step);
      }}
      title={
        <div className="flex flex-row items-center">
          <MapPinIcon className="w-6 h-6" />
          <div className="flex flex-col justify-start">
            <div className="ml-2 font-bold text-sm">Endereço</div>
            <div className="ml-2 text-xs font-normal text-left text-gray-500 ">
              {/* Rua 1, 123 - Bairro 1 */}
              {address
                ? AddressUtils.getFormattedAddress(address)
                : 'Digite seu endereço'}
            </div>
          </div>
        </div>
      }
    >
      {isExtension ? (
        <FormAddress
          onAddressSelected={handleExtensionSelectedAddress}
          predefinedAddress={addressNotFound ? address : undefined}
        />
      ) : (
        <div className="w-full p-2 flex flex-row justify-between items-center">
          <div className="flex flex-col">
            <span className="font-bold">
              {AddressUtils.getStreetAndNumber(address)}
            </span>
            <span className="font-light text-sm">
              {AddressUtils.getNeighborhoodAndComplement(address)}
            </span>
          </div>
          <button
            type="button"
            onClick={handleChangeAddress}
            className="text-primary font-medium"
          >
            Trocar
          </button>
        </div>
      )}
    </CollapsibleItem>
  );
};

export default ItemAddress;
