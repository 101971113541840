import api from '../../services/api';

export async function getWhatsappMessage(
  order_id: string,
  with_checkout_link = false,
) {
  const accessToken = localStorage.getItem('access_token');
  const { data } = await api.get<{ message: string }>(
    `/customers/orders/${order_id}/whatsapp/message?payment_link=${with_checkout_link}`,
    {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    },
  );
  return data.message;
}
