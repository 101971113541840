import * as React from 'react'
import './App.css'
import {
  cortesImages,
  Filter,
  FilterOption,
  Filters,
  FiltersType,
  SelectedFilter,
} from '@onbeefapp/constants'

type FilterProps = {
  filter: Filter
  selectedFilters: SelectedFilter[]
  setSelectedFilter: (
    filter: FilterOption,
    type: FiltersType,
    remove?: boolean
  ) => void
}

export const FilterOptions = ({
  filter,
  selectedFilters,
  setSelectedFilter,
}: FilterProps) => {
  return (
    <div className="flex flex-col space-y-2">
      <article className="text-sm font-normal">{filter.description}</article>
      <div className="flex flex-wrap gap-2">
        {filter.content.map((f, index) => {
          const filterSelected = selectedFilters.find((sf) => sf.id === f.id)
          return (
            <div
              key={`tagsProductEventType${index}`}
              onClick={() => {
                if (filterSelected) {
                  setSelectedFilter(f, filter.type, true)
                } else {
                  setSelectedFilter(f, filter.type)
                }
              }}
              className={`shrink-0 p-1 min-w-[95px]md:min-w-[10%] pl-2 pr-2 transition-all border border-current font-semibold ${
                filterSelected
                  ? `bg-primary text-contrastText`
                  : `bg-transparent text-primary`
              } rounded-2xl text-xs text-center font-medium  flex flex-row gap-2 justify-center items-center`}
            >
              <img
                className={'text-contrastText'}
                src={f.image}
                width={'20px'}
              />
              {f.name}
            </div>
          )
        })}
      </div>
    </div>
  )
}

type DrawerFiltersProps = {
  isOpen: boolean
  toggleDrawer: (key: string) => void
  id: string
  height?: string
  filters?: Filters
  filterProducts: () => void
  selectedFilters: SelectedFilter[]
  clearSelectedFilters: () => void
  setSelectedFilter: (
    filter: FilterOption,
    type: FiltersType,
    remove?: boolean
  ) => void
}

const DrawerFilters = (params: DrawerFiltersProps) => {
  const {
    isOpen,
    id,
    toggleDrawer,
    filters,
    filterProducts,
    selectedFilters,
    clearSelectedFilters,
    setSelectedFilter,
    height = '400px',
  } = params

  let open = isOpen && id === 'drawerFilters'

  const filter = () => {
    filterProducts()
    toggleDrawer('drawerFilters')
  }

  const handleClearFilters = () => {
    clearSelectedFilters()
    toggleDrawer('drawerFilters')
  }

  return (
    <div>
      <div
        className={`z-[99999] fixed inset-0 bottom-0 right-0 p-4 shadow-lg transition-all duration-300 transform
        ${
          open
            ? 'translate-y-0 bg-black-transparent-medium'
            : 'hidden bg-transparent'
        }`}
        onClick={() => filter()}
      />
      <div
        style={{
          height: height,
        }}
        className={`translate-y-0 fixed z-[99999] bottom-0 right-0 p-4 bg-white shadow-lg w-full rounded-t-2xl transition-all duration-300 transform
        ${open ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}
      >
        {filters ? (
          <div
            className={`h-full w-full z-[99999] overflow-y-scroll flex flex-col space-y-4`}
          >
            <article className="p-2 text-sm font-medium">
              Selecione as opções para podermos te ajudar
            </article>

            {Object.keys(filters).map((key) => {
              const filter = filters[key as keyof Filters]
              return (
                <FilterOptions
                  filter={filter}
                  selectedFilters={selectedFilters}
                  setSelectedFilter={setSelectedFilter}
                />
              )
            })}

            <div className="w-full text-center mt-2">
              <button
                onClick={filter}
                className="text-contrastText bg-primary py-2 px-4 rounded-lg text-sm font-semibold"
              >
                Aplicar
              </button>
            </div>
            {selectedFilters?.length > 0 && (
              <div
                className="w-full text-center text-[#d90016] text-sm font-semibold items-center py-1 mt-1"
                onClick={handleClearFilters}
              >
                Remover todos filtros selecionados
              </div>
            )}
          </div>
        ) : (
          <span>Nenhum filtro disponível no momento</span>
        )}
      </div>
    </div>
  )
}

export default DrawerFilters
