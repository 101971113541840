import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TrackingContext } from '../contexts/TrackingContext';
import { useSlug } from '../hooks/useSlug';
import { useMerchantCache } from '../queries/home';
import { Category as ICategory, Product } from '@onbeefapp/constants';
import ListScrollProduct from './Lists/ListScrollProduct';
import Skeleton from './Skeleton/Skeleton';
import { useProductsStore } from '../stores/products';

interface CategoryProps {
  category?: ICategory;
  name: string;
  slug?: string;
  products: Product[];
}

const Category: React.FC<CategoryProps> = ({
  category,
  name,
  slug,
  products,
}) => {
  const params = useSlug();
  const navigate = useNavigate();
  const { isLoading } = useMerchantCache(params.slug);
  const filtersLoading = useProductsStore((state) => state.filtersLoading);
  const search = useProductsStore((state) => state.search);
  const { track } = React.useContext(TrackingContext);
  const id = category?.id || slug || 'default-category';
  const productsCount = products.length;
  const productsLimit = 6;
  const productsMore = category
    ? category.hidden_product_count != null
      ? category.hidden_product_count
      : 0
    : products.length > 6
      ? products.length - 6
      : 0;

  // Se não houver produtos, não mostrar o componente
  if (productsCount <= 0 && !isLoading) {
    return null;
  }

  if (slug === 'destaques' && search) return null;

  return (
    <div id={id} className="px-2 pb-5">
      <div className="space-x-1 flex w-full justify-between font-semibold mb-2">
        <Skeleton
          width={'150px'}
          height={'20px'}
          borderRadius="10px"
          isLoading={isLoading || filtersLoading}
        >
          <div className="prose">{name}</div>
        </Skeleton>

        {/* Opção ver mais aparece quando a lista de produtos há mais de 15 produtos */}
        <Skeleton
          width={'50px'}
          height={'20px'}
          borderRadius="10px"
          isLoading={isLoading || filtersLoading}
        >
          {productsMore > 0 && (
            <div
              onClick={() => {
                track('OnCustomerViewCategory', {
                  slug: slug,
                });
                navigate(`category/${slug}`);
              }}
              className="items-end self-center text-xs text-primary hover:underline hover:cursor-pointer"
            >
              Ver mais
            </div>
          )}
        </Skeleton>
      </div>

      {/* Produtos */}
      <ListScrollProduct
        id={id}
        slug={slug}
        products={products}
        productsLimit={productsLimit}
        productsMore={productsMore}
      />
    </div>
  );
};

export default Category;
