import React from 'react';

interface ModalProps {
  title: string;
  children: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const ConfirmOrderWithoutClubModal: React.FC<ModalProps> = ({
  title,
  children,
  onConfirm,
  onCancel,
  isOpen,
  setIsOpen,
}) => {
  const handleOverlayClick = () => {
    setIsOpen(!isOpen);
  };

  const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[99999] flex items-center justify-center">
      <div
        className="fixed inset-0 bg-gray-900 opacity-50"
        onClick={handleOverlayClick}
      />
      <div
        className={`bg-white w-10/12 rounded-lg shadow-lg relative p-3`}
        onClick={handleModalClick}
      >
        <div className={`flex rounded-lg`}>
          <p className="text-xl font-semibold">{title}</p>
        </div>
        <div className={`flex my-2 text-sm`}>{children}</div>
        <div className={`flex my-2 text-sm`}>Deseja continuar o pedido?</div>

        <div className="flex flex-col w-full space-y-1 pt-2">
          <button
            className="px-4 py-2 w-full bg-primary text-contrastText text-sm font-semibold rounded-lg"
            onClick={() => {
              if (onConfirm) {
                onConfirm();
              } else {
                handleOverlayClick();
              }
            }}
          >
            Entrar para aproveitar
          </button>
          <button
            className="px-4 py-2 w-full text-sm font-semibold text-white bg-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-300"
            onClick={() => {
              onCancel && onCancel();
              handleOverlayClick();
            }}
          >
            Continuar sem benefícios
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOrderWithoutClubModal;
