import cx from 'classnames'
import React from 'react'

import { FieldError } from 'react-hook-form'

export interface Props {
  className?: string
  containerClass?: string
  alignment: 'vertical' | 'horizontal'
  children: React.ReactNode
  errors?: FieldError
}

export const GroupRadioButton: React.FC<Props> = ({
  className,
  containerClass,
  alignment,
  children,
  errors,
}) => {
  return (
    <div className={containerClass || ''}>
      <div
        className={cx(
          className,
          'flex-wrap',
          alignment === 'horizontal'
            ? 'flex flex-wrap'
            : 'flex flex-col space-y-1'
        )}
      >
        {children}
      </div>
      {errors?.message && (
        <span className="text-red-400 text-sm">{errors.message}</span>
      )}
    </div>
  )
}

GroupRadioButton.displayName = 'GroupRadioButton'
export type GroupRadioButtonProps = Props
export default GroupRadioButton
