import { Toast } from '@onbeefapp/constants';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAuthStore } from '../stores/login';
import { LoginResponse } from '../queries/login/types';

const api = axios.create({
  baseURL: import.meta.env.VITE_REACT_APP_API_URL,
});

let isRefreshing = false;
let subscribers: ((token: string) => void)[] = [];

function subscribeTokenRefresh(cb: (token: string) => void) {
  subscribers.push(cb);
}

function onRrefreshed(token: string) {
  subscribers.forEach((cb) => cb(token));
  subscribers = [];
}

api.interceptors.request.use(function (config) {
  const token = isRefreshing
    ? localStorage.getItem('refresh_token')
    : localStorage.getItem('access_token');
  if (config.headers && token) {
    (config.headers as any)['Authorization'] = 'Bearer ' + token;
  }
  return config; // Return the modified config object
});

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const { config, response } = error;
    const { status } = response;

    const originalRequest = config as AxiosRequestConfig & { _retry?: boolean };

    if (status === 401 && originalRequest.url === '/login') {
      const errorMessage = Array.isArray(response.data.error)
        ? response.data.error.join(', ')
        : response.data.error;
      Toast.error(errorMessage);
      return Promise.reject(error);
    }

    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const reauth = await refreshToken();

          if (reauth) {
            useAuthStore.getState().setAuthState(true, reauth);
            onRrefreshed(reauth.access_token);
            if (originalRequest.headers) {
              originalRequest.headers['Authorization'] =
                `Bearer ${reauth.access_token}`;
            }

            return api(originalRequest);
          }
        } catch (refreshError) {
          useAuthStore.getState().logout();
        } finally {
          isRefreshing = false;
        }
      }

      return subscribeTokenRefresh((token: string) => {
        if (originalRequest.headers) {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
        }

        return api(originalRequest);
      });
    }

    return Promise.reject(error);
  },
);

async function refreshToken(): Promise<LoginResponse | null> {
  const refreshToken = localStorage.getItem('refresh_token');
  if (!refreshToken) {
    return null;
  }

  try {
    const response = await api.post<LoginResponse>('/login');
    const { access_token, expires_in, refresh_token } = response.data;
    localStorage.setItem('access_token', access_token);
    if (refresh_token) {
      localStorage.setItem('refresh_token', refresh_token);
      localStorage.setItem(
        'login_ts',
        Math.floor(Date.now() / 1000).toString(),
      );
      localStorage.setItem('expires_in', expires_in.toString());
    }
    return response.data;
  } catch (error) {
    // Handle error appropriately
    console.error('Refresh token failed', error);
    throw error;
  }
}

export default api;
