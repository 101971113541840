import { WherePaymentType } from '@onbeefapp/constants';
import React from 'react';
import { useCartStore } from '../../stores/cart';

interface CardWherePaymentProps {
  setter: (value: WherePaymentType) => void;
  value: WherePaymentType | undefined;
  children: React.ReactNode;
  type: WherePaymentType;
  show?: boolean;
  className?: string;
}

const CardWherePayment: React.FC<CardWherePaymentProps> = ({
  setter,
  value,
  children,
  show = true,
  type,
  className,
}) => {
  const isCheckoutLink = useCartStore((state) => state.isCheckoutLink);

  if (!show || isCheckoutLink) return null;

  return (
    <div
      className={`${className} text-center text-sm border-[1px] p-2 border-current rounded-lg text-primary bg-transparent ${
        value === type && '!text-contrastText !bg-primary'
      } font-semibold`}
      onClick={() => {
        setter(type);
      }}
    >
      {children}
    </div>
  );
};

export default CardWherePayment;
