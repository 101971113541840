import { Skeleton } from '@onbeef/components/skeleton';
import HeaderBar from '../../../../components/Headers/HeaderBar';
import { useSlug } from '../../../../hooks/useSlug';
import { useMerchantCache } from '../../../../queries/home';
import { useUserStore } from '../../../../stores/user';
import { ProfileAddressForm } from './ProfileAddressForm';
import React from 'react';
import {
  Address,
  AddressUtils,
  EditIcon,
  LoadingIcon,
  Toast,
  formatAddress,
} from '@onbeefapp/constants';
import {
  useDeleteAddress,
  useUpdateDefaultAddress,
} from '../../../../queries/customer';
import { AxiosError } from 'axios';
import { TrashIcon } from '@heroicons/react/24/outline';
import { FormProvider, useForm } from 'react-hook-form';
import { RadioButton } from '@onbeef/components/input';
import cx from 'classnames';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import GoogleMaps from '../../../../../../../packages/assets/images/google-maps.png';
import { useCartStore } from '../../../../stores/cart';
interface ProfileAddressForm {
  address_id?: string;
}

export const ProfileAddress: React.FC = () => {
  const params = useSlug();
  const { isLoading: isMerchantLoading } = useMerchantCache(params.slug);

  const addresses = useUserStore((state) => state.addresses);
  const setAddresses = useUserStore((state) => state.setAddresses);
  const address = useUserStore((state) => state.address);
  const setAddress = useUserStore((state) => state.setAddress);

  const methods = useForm<ProfileAddressForm>();

  const { mutateAsync: deleteAddress, isLoading: deleteLoading } =
    useDeleteAddress();
  const { mutateAsync: updateDefaultAddress } = useUpdateDefaultAddress();

  const [deletingID, setDeletingID] = React.useState('');
  const [currentAddress, setCurrentAddress] = React.useState<
    Address | undefined
  >();
  const [addressID, setAddressID] = React.useState<string | undefined>();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [optionOpen, setOptionOpen] = React.useState(false);

  const onClose = () => {
    setModalOpen(false);
    setCurrentAddress(undefined);
  };

  const handleOpenAddressForm = (address?: Address) => {
    setModalOpen(true);
    setCurrentAddress(address);
    setOptionOpen(false);
  };

  const handleDeleteAddress = async (address?: Address) => {
    try {
      if (!address?.id) return;
      setDeletingID(address.id);
      const res = await deleteAddress(address.id);
      setAddresses(addresses?.filter((ad) => ad.id !== address.id));
      res.message && Toast.success(res.message);
    } catch (error) {
      (error as AxiosError<{ error: string[] }>).response?.data?.error?.forEach(
        (msg) => {
          Toast.error(msg);
        },
      );
    } finally {
      setOptionOpen(false);
    }
  };

  const handleSelectDefaultAddress = async (address_id: string) => {
    try {
      setAddressID(address_id);
      const res = await updateDefaultAddress(address_id);
      res.message && Toast.success(res.message);
      setAddress(res.data);
    } catch (error) {
      (error as AxiosError<{ error: string[] }>).response?.data?.error?.forEach(
        (msg) => {
          Toast.error(msg);
        },
      );
    } finally {
      setOptionOpen(false);
    }
  };

  const selectedAddrsID = methods.watch('address_id');

  React.useEffect(() => {
    if (selectedAddrsID && address?.id !== selectedAddrsID) {
      handleSelectDefaultAddress(selectedAddrsID);
    }
  }, [selectedAddrsID]);

  React.useEffect(() => {
    methods.setValue('address_id', address?.id);
    setAddressID(address?.id);
  }, [address]);

  return (
    <div className="w-full h-full p-4 space-y-2">
      <HeaderBar>
        <span className="text-sm font-normal text-center w-full">
          Seus endereços
        </span>
      </HeaderBar>

      <Skeleton
        isLoading={isMerchantLoading}
        repeatQuantity={5}
        className="w-full h-20 rounded-md"
      >
        <div className="flex flex-col gap-4 p-1">
          <span className="font-medium text-sm">
            Selecione o endereço da sua entrega
          </span>
          <FormProvider {...methods}>
            <form className="flex flex-col gap-2">
              {addresses?.map((addrs) => {
                return (
                  <div
                    key={addrs.id}
                    className={cx(
                      `flex items-center border-gray-300 border rounded-md justify-between p-2 cursor-pointer`,
                      {
                        '!border-current border-2': addressID === addrs.id,
                      },
                    )}
                  >
                    <div className="flex flex-col">
                      <div className="flex flex-row space-x-2 m-1">
                        <div
                          className="flex flex-row space-x-2 "
                          onClick={() => handleSelectDefaultAddress(addrs.id!)}
                        >
                          <img
                            src={GoogleMaps}
                            alt="address"
                            className="w-10 h-10 self-center rounded-full"
                          ></img>
                          <RadioButton
                            name="address_id"
                            className="hidden"
                            labelClass="text-xs font-medium"
                            label={AddressUtils.getFormattedAddress(addrs)}
                            value={addrs.id!}
                          />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <div
                            onClick={() => {
                              setOptionOpen(true);
                              setCurrentAddress(addrs);
                            }}
                          >
                            <EllipsisVerticalIcon className="h-5 w-5 font-bold text-gray-900" />
                          </div>
                          <div>
                            {addressID === addrs.id && (
                              <CheckCircleIcon className="h-5 w-5 font-bold text-primary" />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </form>
          </FormProvider>
          {(!addresses || addresses?.length === 0) && (
            <span>Nenhum endereço encontrado.</span>
          )}
          <button
            type="button"
            onClick={() => handleOpenAddressForm()}
            className="p-2 bg-primary text-contrastText rounded-lg mt-2"
          >
            Adicionar endereço
          </button>
        </div>
      </Skeleton>

      <ProfileAddressForm
        isOpen={modalOpen}
        onClose={onClose}
        address={currentAddress}
      />

      <div>
        <div
          className={`${
            optionOpen
              ? 'fixed translate-y-0 z-[9999] bg-black-transparent-medium'
              : 'fixed translate-y-full  z-[-10] bg-transparent'
          }  fixed inset-0 bottom-0 right-0 z-[99998] transition-all duration-0`}
          onClick={() => {
            setOptionOpen(false);
          }}
        />
        <div
          className={`${
            optionOpen
              ? 'fixed translate-y-0 z-[9999]'
              : 'fixed translate-y-full '
          } bottom-0 right-0 p-4 bg-white shadow-lg w-full rounded-t-2xl transition-all duration-300 transform`}
        >
          <div
            className={`translate-y-0 fixed z-[99999] bottom-0 right-0 p-4 bg-white shadow-lg w-full rounded-t-2xl transition-all duration-300 transform
        ${optionOpen ? 'translate-y-0' : 'translate-y-full'}`}
          >
            <div className="p-2 text-left text-black flex flex-col space-y-3 ">
              <div className="text-sm">Selecione uma das opções abaixo:</div>
              <div className="flex flex-row space-x-2 justify-between">
                <button
                  type="button"
                  onClick={() => handleOpenAddressForm(currentAddress)}
                  className="flex border-gray-300 place-content-center w-full rounded-md border p-2 items-center gap-2"
                >
                  <EditIcon className="w-5 h-5" />
                  <span>Editar</span>
                </button>
                <button
                  type="button"
                  onClick={() => handleDeleteAddress(currentAddress)}
                  className="flex items-center place-content-center w-full border-gray-300 rounded-md border p-2 gap-2"
                >
                  {deletingID === currentAddress?.id && deleteLoading ? (
                    <LoadingIcon className="w-5 h-5 text-red-400" />
                  ) : (
                    <TrashIcon className="w-5 h-5 text-red-400" />
                  )}
                  <span>Excluir</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
