import { useMerchantStore } from '../../stores/merchant';
import { getWhatsappMessage } from './Whatsapp';

export async function getWhatsappURL(merchant_phone: string, order_id: string) {
  const merchant = useMerchantStore.getState().merchant;
  if (!merchant) return '';

  const whatsappPayload = await getWhatsappMessage(order_id);

  const url =
    'https://api.whatsapp.com/send?phone=+55' +
    encodeURIComponent(parseInt(merchant_phone.replace(/[^0-9]/g, ''))) +
    '&text=' +
    whatsappPayload;

  return url;
}
