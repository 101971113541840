import { ComplementItem, Product, ProductType } from '@onbeefapp/constants';
import { useMerchantStore } from './merchant';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type ComplementsStore = {
  complements: Record<string, Record<string, Record<string, ComplementItem[]>>>;
  addComplement: (
    product_id: string,
    complement_id: string,
    items: ComplementItem,
    isTest?: boolean,
  ) => void;
  setComplements: (
    complements: Record<
      string,
      Record<string, Record<string, ComplementItem[]>>
    >,
  ) => void;
  getCountComplements: (product_id: string, isTest?: boolean) => number;
  getCountComplementsCuts: (product_id: string) => number;
  getSumQuantityComplementsCuts: (product_id: string) => number;
  getComplements: (product_id: string) => ComplementItem[];
  getSumTotalQuantityComplements: (product_id: string) => number;
  deleteComplementItem: (
    product_id: string,
    complement_id: string,
    item_id: string,
  ) => void;
  deleteComplements: (product_id: string) => void;
  // returns the missing required complement id if there is one
  checkRequiredComplements: (product: Product) => string | undefined;
  getSumQuantityComplements: (product_id: string) => number;
  getSumQuantityComplementsByComplementId: (
    product_id: string,
    complement_id: string,
  ) => number;
  getCountComplementsTotal: (
    product_id: string,
    complement_id: string,
  ) => number;
  getCountComplementsItem: (
    product_id: string,
    complement_id: string,
    item_id: string,
  ) => number;
  getComplementsItem: (
    product_id: string,
    complement_id: string,
    item_id: string,
  ) => ComplementItem | undefined;
  clearMerchantIdComplements: () => void;
  setMerchantIdProductComplements: (
    product_id: string,
    complements: any,
  ) => void;
};

export const useComplementsStore = create(
  persist<ComplementsStore>(
    (set, get) => ({
      complements: {},
      addComplement: (product_id, complement_id, items, isTest = false) => {
        set((state) => {
          let merchantId = useMerchantStore.getState().merchant?.id || '';
          if (merchantId == '' && !isTest) {
            return state;
          }

          if (isTest) {
            merchantId = '-65535';
          }

          const merchantIdComplements = state.complements[merchantId] || {};
          const currentProductComplements =
            merchantIdComplements[product_id] || {};
          const currentComplementItems =
            currentProductComplements[complement_id] || [];

          const complementItem = currentComplementItems.find(
            (item) => item.id === items.id,
          );
          if (complementItem) {
            complementItem.quantity = items.quantity;
            complementItem.totalQuantity = items.totalQuantity;
          } else {
            currentComplementItems.push(items);
          }

          if (items.quantity === 0 && currentComplementItems.length === 1) {
            delete currentProductComplements[complement_id];

            if (Object.keys(currentProductComplements).length === 0) {
              delete merchantIdComplements[product_id];
            }

            return {
              complements: {
                ...state.complements,
                [merchantId]: {
                  ...merchantIdComplements,
                },
              },
            };
          }

          if (complementItem?.quantity === 0) {
            return {
              complements: {
                ...state.complements,
                [merchantId]: {
                  ...merchantIdComplements,
                  [product_id]: {
                    ...currentProductComplements,
                    [complement_id]: currentComplementItems.filter(
                      (item) => item.id !== items.id,
                    ),
                  },
                },
              },
            };
          }

          return {
            complements: {
              ...state.complements,
              [merchantId]: {
                ...merchantIdComplements,
                [product_id]: {
                  ...currentProductComplements,
                  [complement_id]: currentComplementItems,
                },
              },
            },
          };
        });
      },
      getCountComplements: (product_id, isTest = false) => {
        let merchantId = useMerchantStore.getState().merchant?.id || '';
        if (merchantId == '' && !isTest) {
          return 0;
        }

        if (isTest) {
          merchantId = '-65535';
        }
        const merchantIdComplements = get().complements[merchantId] || {};
        const currentProductComplements =
          merchantIdComplements[product_id] || {};
        const currentComplementItems = Object.values(currentProductComplements);
        return currentComplementItems.flat().reduce((acc, item) => {
          return acc + item.quantity;
        }, 0);
      },
      getCountComplementsCuts: (product_id) => {
        const merchantId = useMerchantStore.getState().merchant?.id;
        if (!merchantId) {
          return 0;
        }
        const merchantIdComplements = get().complements[merchantId] || {};
        const currentProductComplements =
          merchantIdComplements[product_id] || {};
        const currentComplementItems = Object.values(currentProductComplements);
        return currentComplementItems.flat().reduce((acc, item) => {
          if (item.items_kg === 1) {
            return acc + item.quantity;
          }
          return acc;
        }, 0);
      },
      getSumTotalQuantityComplements: (product_id) => {
        const merchantId = useMerchantStore.getState().merchant?.id;
        if (!merchantId) {
          return 0;
        }
        const merchantIdComplements = get().complements[merchantId] || {};
        const currentProductComplements =
          merchantIdComplements[product_id] || {};

        if (Object.keys(currentProductComplements).length === 0) {
          return 0;
        }

        const currentComplementItems = Object.values(currentProductComplements);
        return currentComplementItems.flat().reduce((acc, item) => {
          const price = item.price ?? 0;

          return acc + price * item.totalQuantity;
        }, 0);
      },
      getSumQuantityComplements: (product_id) => {
        const merchantId = useMerchantStore.getState().merchant?.id;
        if (!merchantId) {
          return 0;
        }
        const merchantIdComplements = get().complements[merchantId] || {};
        const currentProductComplements =
          merchantIdComplements[product_id] || {};
        const currentComplementItems = Object.values(currentProductComplements);

        const quantity = currentComplementItems.flat().reduce((acc, item) => {
          if (item.type === ProductType.KG) {
            return acc + item.quantity * 100;
          } else {
            return acc + item.quantity;
          }
        }, 0);

        return quantity;
      },
      getSumQuantityComplementsByComplementId: (product_id, complement_id) => {
        const merchantId = useMerchantStore.getState().merchant?.id;
        if (!merchantId) {
          return 0;
        }
        const merchantIdComplements = get().complements[merchantId] || {};
        const currentProductComplements =
          merchantIdComplements[product_id] || {};
        const currentComplementItems =
          currentProductComplements[complement_id] || [];

        const quantity = currentComplementItems.reduce((acc, item) => {
          return acc + item.quantity;
        }, 0);

        return quantity;
      },
      getSumQuantityComplementsCuts: (product_id) => {
        const merchantId = useMerchantStore.getState().merchant?.id;
        if (!merchantId) {
          return 0;
        }
        const merchantIdComplements = get().complements[merchantId] || {};
        const currentProductComplements =
          merchantIdComplements[product_id] || {};
        const currentComplementItems = Object.values(currentProductComplements);

        const quantity = currentComplementItems.flat().reduce((acc, item) => {
          if (item.items_kg === 1) {
            if (item.type === ProductType.KG) {
              return acc + item.quantity * 100;
            } else {
              return acc + item.quantity;
            }
          }
          return acc;
        }, 0);

        return quantity;
      },
      getComplements: (product_id) => {
        const merchantId = useMerchantStore.getState().merchant?.id;
        if (!merchantId) {
          return [];
        }
        const merchantIdComplements = get().complements[merchantId] || {};
        const currentProductComplements =
          merchantIdComplements[product_id] || {};
        const currentComplementItems = Object.values(currentProductComplements);
        return currentComplementItems.flat();
      },
      getCountComplementsItem: (product_id, complement_id, item_id) => {
        const merchantId = useMerchantStore.getState().merchant?.id;
        if (!merchantId) {
          return 0;
        }
        const merchantIdComplements = get().complements[merchantId] || {};
        const currentProductComplements =
          merchantIdComplements[product_id] || {};
        const currentComplementItems =
          currentProductComplements[complement_id] || [];
        const complementItem = currentComplementItems.find(
          (item) => item.id === item_id,
        );
        return complementItem?.quantity || 0;
      },
      getCountComplementsTotal: (product_id, complement_id) => {
        const merchantId = useMerchantStore.getState().merchant?.id;
        if (!merchantId) {
          return 0;
        }
        const merchantIdComplements = get().complements[merchantId] || {};
        const currentProductComplements =
          merchantIdComplements[product_id] || {};
        const currentComplementItems =
          currentProductComplements[complement_id] || [];

        return currentComplementItems.flat().reduce((acc) => {
          return acc;
        }, 0);
      },
      clearMerchantIdComplements: () => {
        set((state) => {
          const merchantId = useMerchantStore.getState().merchant?.id;
          if (!merchantId) {
            return state;
          }
          // const merchantIdComplements = state.complements[merchantId] || {};
          return {
            complements: {
              ...state.complements,
              [merchantId]: {},
            },
          };
        });
      },
      checkRequiredComplements: (product) => {
        const merchantId = useMerchantStore.getState().merchant?.id;
        if (!merchantId || !product) return undefined;
        const merchantIdComplements = get().complements[merchantId] || {};
        const currentProductComplements =
          merchantIdComplements[product.id] || {};

        const found =
          product.complementsRequired?.find((item) => {
            if (!currentProductComplements[item.id]) return true;
            const currentComplementItems = currentProductComplements[item.id];
            const qtd = currentComplementItems?.flat().reduce((acc, item) => {
              return acc + item.quantity;
            }, 0);

            if ((qtd ?? 0) < item.quantity_min) {
              return true;
            }
          })?.id || undefined;
        return found;
      },
      setMerchantIdProductComplements: (product_id, complements) => {
        set((state) => {
          const merchantId = useMerchantStore.getState().merchant?.id;
          if (!merchantId) {
            return state;
          }
          const merchantIdComplements = state.complements[merchantId] || {};
          return {
            complements: {
              ...state.complements,
              [merchantId]: {
                ...merchantIdComplements,
                [product_id]: complements,
              },
            },
          };
        });
      },
      getComplementsItem: (product_id, complement_id, item_id) => {
        const merchantId = useMerchantStore.getState().merchant?.id;
        if (!merchantId) {
          return undefined;
        }
        const merchantIdComplements = get().complements[merchantId] || {};
        const currentProductComplements =
          merchantIdComplements[product_id] || {};
        const currentComplementItems =
          currentProductComplements[complement_id] || [];
        const complementItem = currentComplementItems.find(
          (item) => item.id === item_id,
        );
        return complementItem;
      },
      setComplements: (complements) => {
        set(() => {
          return {
            complements,
          };
        });
      },
      deleteComplementItem: (product_id, complement_id, item_id) => {
        set((state) => {
          const merchantId = useMerchantStore.getState().merchant?.id;
          if (!merchantId) {
            return state;
          }
          const merchantIdComplements = state.complements[merchantId] || {};
          const currentProductComplements =
            merchantIdComplements[product_id] || {};
          const currentComplementItems =
            currentProductComplements[complement_id] || [];
          const complementItem = currentComplementItems.find(
            (item) => item.id === item_id,
          );
          const newComplementItems = currentComplementItems.filter(
            (item) => item.id !== item_id,
          );
          if (complementItem?.quantity === 0) {
            return state;
          }
          return {
            complements: {
              ...state.complements,
              [merchantId]: {
                ...merchantIdComplements,
                [product_id]: {
                  ...currentProductComplements,
                  [complement_id]: newComplementItems,
                },
              },
            },
          };
        });
      },
      deleteComplements: (product_id) => {
        set((state) => {
          const merchantId = useMerchantStore.getState().merchant?.id;
          if (!merchantId) {
            return state;
          }
          const merchantIdComplements = state.complements[merchantId] || {};
          return {
            complements: {
              ...state.complements,
              [merchantId]: {
                ...merchantIdComplements,
                [product_id]: {},
              },
            },
          };
        });
      },
    }),
    {
      name: 'complements',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
