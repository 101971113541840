import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, TextInput } from '@onbeef/components/input';
import {
  Address,
  LoadingIcon,
  LocationIcon,
  maskCEP,
  Toast,
  ViaCepResponse,
} from '@onbeefapp/constants';
import getAddressInfo from '@onbeefapp/constants/src/utils/GoogleAddressUtils';
import Yup from '@onbeefapp/constants/src/utils/Yup';
import axios from 'axios';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useGeocodeLocation } from '../../hooks/useGeocodeLocation';
import { useCartStore } from '../../stores/cart';
import { MapContainer } from './MapContainer';
import { AddressSearch } from './AddressSearch';
import { useUserStore } from '../../stores/user';

type FormAddressProps = {
  predefinedAddress?: Address;
  onAddressSelected: (address: Address) => void;
  isLoading?: boolean;
};

interface IFormAddress extends Address {
  use_cep: boolean;
  use_pin: boolean;
  no_street_number: boolean;
}

const FormAddress: React.FC<FormAddressProps> = ({
  onAddressSelected,
  predefinedAddress,
  isLoading,
}) => {
  const setUserAddress = useUserStore((state) => state.setAddress);

  const { isExtension } = useCartStore((state) => ({
    isExtension: state.isExtension,
  }));

  const validationSchema = Yup.object().shape({
    street_name: Yup.string().required('Rua é obrigatório'),
    no_street_number: Yup.boolean().nullable(),
    street_number: Yup.string().when(['no_street_number'], {
      is: true,
      then: (s) => s.nullable(),
      otherwise: (s) => s.required('Número é obrigatório'),
    }),
    neighborhood: Yup.string().required('Bairro é obrigatório'),
    city: Yup.string().required('Cidade é obrigatório'),
    state: Yup.string().required('Estado é obrigatório'),
    zip: Yup.string().nullable(),
    street_complement: Yup.string(),
    latitude: Yup.string().nullable(),
    longitude: Yup.string().nullable(),
  });

  const submitBtn = React.useRef<HTMLButtonElement>(null);

  const [addressNotFound, setAddressNotFound] = React.useState(false);

  const methods = useForm<IFormAddress>({
    resolver: yupResolver(validationSchema) as any,
  });
  const errors = methods.formState.errors;

  type fields =
    | 'street_name'
    | 'street_number'
    | 'neighborhood'
    | 'city'
    | 'state'
    | 'zip'
    | 'street_complement'
    | 'latitude'
    | 'longitude'
    | 'use_cep'
    | 'use_pin';

  const handleInputChange = (e: any) => {
    const inputValue = e.target.value;
    const name: fields = e.target.name;
    if (name === 'zip') {
      methods.setValue(`${name}`, maskCEP(inputValue));
      if (!predefinedAddress) methods.setValue(`use_pin`, false);
    } else {
      methods.setValue(`${name}`, inputValue);
    }
  };

  const handleZipSearch = async (
    address: string | undefined,
    custom_lat_lng:
      | {
          lat: number;
          lng: number;
        }
      | undefined,
  ) => {
    if (!address && !zip) return;
    if (zip.length !== 9 && useCep) return;
    if (useCep && !zip) return;
    if (usePin && !address) return;

    const key = 'AIzaSyBt55rofDfwKXQhaz9zFRetA-Rrhugr4Fg';
    const res = await axios.get(
      `https://maps.google.com/maps/api/geocode/json?address=${
        useCep ? zip : `${address}`
      }&sensor=false&key=${key}`,
    );

    if (res.data?.results && res.data.results.length > 0) {
      const place = res.data.results[0];
      const { lat, lng } = place?.geometry?.location;
      const result = getAddressInfo(place.address_components);
      methods.setValue(
        'latitude',
        String(custom_lat_lng ? custom_lat_lng.lat : lat),
      );
      methods.setValue(
        'longitude',
        String(custom_lat_lng ? custom_lat_lng.lng : lng),
      );
      methods.setValue('neighborhood', result.neighborhood);
      methods.setValue('city', result.city);
      methods.setValue('state', result.state);
      methods.setValue('zip', result.zipCode);
      methods.setValue('country', 'Brasil');
      /*console.log ('result.streetName', result.streetName);
      console.log ('address', address);*/
      // if streetName is == "" then set the streetName to the address
      if (result.streetName == '' && address) {
        //console.log('SET AD ADDRESS')
        //methods.setValue('street_name', address); // Not really needed if we already have the street account name, we dont need to set it again
      } else {
        //console.log('SET NULL')
        methods.setValue('street_name', result.streetName);
        if (document.getElementById('google-autocomplete')) {
          (
            document.getElementById('google-autocomplete') as HTMLInputElement
          ).value = result.streetName;
        }
      }
      if (address) {
        methods.setValue('use_pin', false);
      } else {
        if (!predefinedAddress) methods.setValue('use_pin', !isExtension);
      }
    } else {
      if (useCep) {
        const res = await axios.get<ViaCepResponse>(
          `https://viacep.com.br/ws/${zip}/json/`,
        );
        setAddress({
          zip: zip,
          street_name: res.data.logradouro,
          neighborhood: res.data.bairro,
          city: res.data.localidade,
          state: res.data.uf,
        });
        setAddressNotFound(true);
      }
    }
  };

  const onSubmit = (data: Address) => {
    const address: Address = {
      street_name: data.street_name,
      street_number: data.street_number || null,
      neighborhood: data.neighborhood,
      city: data.city,
      state: data.state,
      zip: data.zip,
      street_complement: data.street_complement,
      latitude: data.latitude,
      longitude: data.longitude,
      country: 'Brasil',
    };
    methods.setValue('use_cep', false);
    methods.setValue('use_pin', false);
    onAddressSelected(address);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const { error: errorGPS, getLocationAddress } = useGeocodeLocation();

  const handleUseLocation = async () => {
    const address = await getLocationAddress();
    if (!address?.streetName) {
      Toast.warn('Endereço encontrado está incompleto, tente buscar por ele');
    }
    setAddress(address);
  };

  const handleSelectedAddress = (place: any) => {
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();
    const result = getAddressInfo(place.address_components);
    methods.setValue('latitude', latitude);
    methods.setValue('longitude', longitude);
    methods.setValue('neighborhood', result.neighborhood);
    methods.setValue('city', result.city);
    methods.setValue('state', result.state);
    methods.setValue('zip', result.zipCode);
    methods.setValue('country', 'Brasil');
    methods.setValue('street_name', result.streetName);
    methods.setValue('street_number', result.streetNumber);
    if (!predefinedAddress) methods.setValue(`use_pin`, !isExtension);
  };

  const handleAddressNotFound = () => {
    setAddressNotFound(true);
    setAddress({});
    setUserAddress(undefined);
  };

  const setAddress = (address?: Partial<Address>) => {
    methods.setValue('latitude', address?.latitude ?? '');
    methods.setValue('longitude', address?.longitude ?? '');
    methods.setValue('neighborhood', address?.neighborhood ?? '');
    methods.setValue('city', address?.city ?? '');
    methods.setValue('state', address?.state ?? '');
    methods.setValue('zip', address?.zip ?? '');
    methods.setValue('country', 'Brasil');
    methods.setValue('street_name', address?.street_name ?? '');
    methods.setValue('street_number', address?.street_number ?? '');
    methods.setValue('street_complement', address?.street_complement ?? '');
  };

  const handleResetAddress = () => {
    setAddressNotFound(false);
    setAddress({});
  };

  useEffect(() => {
    console.log('Form address got the predefinedAddress', predefinedAddress);
    setAddress(predefinedAddress);
    if (predefinedAddress) {
      if (!predefinedAddress.latitude || !predefinedAddress?.longitude)
        setAddressNotFound(true);
      if (!predefinedAddress.street_number)
        methods.setValue('no_street_number', true);
    }
  }, [predefinedAddress]);

  const zip = methods.watch('zip');
  const noStreetNumber = methods.watch('no_street_number');

  useEffect(() => {
    const timer = setTimeout(() => {
      useCep && handleZipSearch(undefined, undefined);
    }, 500);

    return () => clearTimeout(timer);
  }, [zip]);

  useEffect(() => {
    if (noStreetNumber) {
      methods.setValue('street_number', '');
    }
  }, [noStreetNumber]);

  const lat = methods.watch('latitude');
  const street_name = methods.watch('street_name');
  const useCep = methods.watch('use_cep');
  const usePin = methods.watch('use_pin');

  const addressSelected = street_name || lat || addressNotFound;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {!usePin && (
          <div className="flex flex-col w-full">
            {!addressSelected && (
              <>
                <div className="w-full">
                  <Checkbox name="use_cep" customLabel="Buscar por CEP" />
                </div>
                {useCep ? (
                  <div className="flex flex-col w-full mt-2">
                    <TextInput
                      placeholder="CEP"
                      name="zip"
                      type={'text'}
                      inputMode="numeric"
                      autoComplete="CEP"
                      onChange={handleInputChange}
                      className="w-full text-sm bg-white px-2 py-4 outline-current rounded-md border"
                    />
                  </div>
                ) : (
                  <div className="grid w-full grid-cols-6 gap-x-2 gap-y-1 mt-2">
                    <div className="w-full col-span-6 bg-white rounded-md">
                      <AddressSearch
                        defaultStreetName={methods.getValues('street_name')}
                        handleSelectedAddress={handleSelectedAddress}
                        handleAddressNotFound={handleAddressNotFound}
                      />
                    </div>
                    {!isExtension && !street_name && !usePin && (
                      <button
                        type="button"
                        className="p-2 rounded-lg bg-primary text-contrastText uppercase flex items-center justify-center gap-2 col-span-6 w-full font-medium"
                        onClick={handleUseLocation}
                      >
                        <LocationIcon />
                        Usar minha localização
                      </button>
                    )}
                    {/* error message GPS */}
                    {errorGPS && (
                      <div className="w-full col-span-6 text-red-500 text-xs">
                        {errorGPS}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}

        <div className="flex flex-col w-full gap-1 mt-1">
          {usePin && (
            <MapContainer
              addressData={methods.getValues()}
              onConfirmAddress={(address) => {
                methods.setValue(`use_pin`, true);
                handleZipSearch(address.display_name, {
                  lat: Number(address.lat),
                  lng: Number(address.lon),
                });
              }}
            />
          )}
          {addressSelected && !usePin && (
            <>
              {(useCep || addressNotFound) && (
                <TextInput
                  name="zip"
                  customLabel="CEP"
                  type={'text'}
                  autoComplete="CEP"
                  onChange={handleInputChange}
                />
              )}
              <TextInput
                name="street_name"
                customLabel="Nome da rua"
                type={'text'}
                autoComplete="rua"
                onChange={handleInputChange}
              />
              <TextInput
                name="neighborhood"
                validationSchema={{}}
                customLabel="Bairro"
                type={'text'}
                autoComplete="bairro"
                onChange={handleInputChange}
                error={errors?.neighborhood !== undefined}
              />
              <div className="text-red-400 text-xs">
                {errors?.neighborhood && errors?.neighborhood?.message}
              </div>
              {/* <div className="grid w-full grid-cols-6 gap-2 mt-2"> */}
              <div className="w-full col-span-3 rounded-md">
                <TextInput
                  name="city"
                  validationSchema={{}}
                  customLabel="Cidade"
                  type={'text'}
                  onChange={handleInputChange}
                  autoComplete="cidade"
                  error={errors?.city !== undefined}
                />
                <div className="text-red-400 text-xs">
                  {errors?.city && errors?.city?.message}
                </div>
              </div>
              <div className="w-full col-span-3 rounded-md">
                <TextInput
                  name="state"
                  validationSchema={{}}
                  customLabel="Estado"
                  type={'text'}
                  autoComplete="estado"
                  onChange={handleInputChange}
                  error={errors?.state !== undefined}
                />
                <div className="text-red-400 text-xs">
                  {errors?.state && errors?.state?.message}
                </div>
              </div>
              <div className="w-full flex-col rounded-md">
                <div className="w-full flex flex-row items-end justify-between">
                  <TextInput
                    name="street_number"
                    validationSchema={{}}
                    customLabel="Número"
                    containerClassName="w-[65%]"
                    type={'number'}
                    onWheel={(event: any) => event.currentTarget.blur()}
                    autoComplete="numero"
                    onChange={handleInputChange}
                    disabled={noStreetNumber}
                    error={errors?.street_number !== undefined}
                  />
                  <Checkbox
                    containerClassName="w-[35%] mb-2 flex justify-center"
                    name="no_street_number"
                    customLabel="Sem número"
                  />
                </div>
                <div className="text-red-400 text-xs">
                  {errors?.street_number && errors?.street_number?.message}
                </div>
              </div>

              <div className="w-full rounded-md">
                <TextInput
                  customLabel="Complemento"
                  name="street_complement"
                  validationSchema={{}}
                  type={'text'}
                  autoComplete="complemento"
                  onChange={handleInputChange}
                  error={errors?.street_complement !== undefined}
                />
                <div className="text-red-400 text-xs">
                  {errors?.street_complement &&
                    errors?.street_complement?.message}
                </div>
              </div>

              <div className="flex flex-col gap-2 items-center justify-center w-full mt-3">
                <button
                  ref={submitBtn}
                  className="w-full p-2 text-contrastText bg-primary rounded-md text-sm flex justify-center"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <LoadingIcon /> : 'Confirmar'}
                </button>
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={handleResetAddress}
                  className="w-full p-2 text-primary border border-current bg-white rounded-md text-sm flex justify-center"
                >
                  Mudar endereço
                </button>
              </div>
            </>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default FormAddress;
