import React, { useEffect, useState } from 'react';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useBannerStore } from '../../stores/banner';
import Skeleton from '../Skeleton/Skeleton';
import cx from 'classnames';
import { useProductsStore } from '../../stores/products';

const RotativeBanner = () => {
  const params = useSlug();

  const { isLoading } = useMerchantCache(params.slug);

  const search = useProductsStore((state) => state.search);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = useBannerStore((state) => state.images);

  const qtdImages = images.length;

  const handleOpenLink = (link: string | null) => {
    if (!link) return;
    window.open(link, '_blank');
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1,
      );
    }, 5000);
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    const targetElement = document.getElementById(`image-${currentImageIndex}`);
    const container = document.getElementById(`containerBanner`);

    if (!targetElement || !container) return;

    const elementoRetangulo = targetElement.getBoundingClientRect();
    const containerRetangulo = container.getBoundingClientRect();
    const deslocamentoScroll =
      elementoRetangulo.left +
      container.scrollLeft -
      containerRetangulo.left -
      10;

    container.scrollTo({
      left: deslocamentoScroll,
      behavior: 'smooth',
    });
  }, [currentImageIndex]);

  if (!images || images.length === 0) return null;

  if (
    images.map((image) => image.file_url).filter((image) => image).length === 0
  )
    return null;

  if (search) return null;

  return (
    <React.Fragment>
      <div className="p-2">
        <div
          className={`space-x-2 flex relative md:snap-x snap-mandatory w-full mx:auto overflow-x-scroll ${
            qtdImages <= 1 && 'overflow-hidden'
          }`}
          id={'containerBanner'}
        >
          <Skeleton
            width="100%"
            height="25vh"
            borderRadius="10px"
            isLoading={isLoading}
          >
            {images.map((image, index) => {
              if (!image.file_url) return null;

              return (
                <button
                  type="button"
                  onClick={() => handleOpenLink(image.link)}
                  key={`banner-${index}`}
                  className={cx(
                    qtdImages > 1 ? 'w-[96%]' : 'w-full',
                    image.link ? 'cursor-pointer' : 'cursor-default',
                    `snap-start shrink-0 relative rounded-lg lg:flex lg:justify-center transition-opacity duration-500 ease-in-out`,
                  )}
                >
                  <img
                    id={`image-${index}`}
                    className={`rounded-lg transition-opacity lg:flex lg:justify-center duration-500 ease-in-out w-full lg:w-8/12`}
                    src={image.file_url}
                    loading="lazy"
                    alt="Banner"
                  />
                </button>
              );
            })}
          </Skeleton>
        </div>

        {qtdImages > 1 && (
          <div className="space-x-2 flex flex-row relative mt-2 w-full mx:auto overflow-x-hidden place-content-center mb-2">
            <Skeleton
              width="80px"
              height="20px"
              borderRadius="10px"
              isLoading={isLoading}
            >
              {images.map((image, index) => {
                if (!image.file_url) return null;

                return (
                  <div
                    key={`banner-${index}`}
                    className={`w-2 h-2 rounded-full ${
                      index === currentImageIndex
                        ? 'bg-gray-700'
                        : 'bg-gray-400'
                    }`}
                    onClick={() => {
                      setCurrentImageIndex(index);
                    }}
                  ></div>
                );
              })}
            </Skeleton>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default RotativeBanner;
