import React from 'react';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useProductsStore } from '../../stores/products';

interface SkeletonProps {
  children: React.ReactNode;
  scroll?: boolean;
}

const SkeletonProduct: React.FC<SkeletonProps> = ({
  children,
  scroll = true,
}) => {
  const params = useSlug();

  const { isLoading } = useMerchantCache(params.slug);
  const filtersLoading = useProductsStore((state) => state.filtersLoading);

  // Atributo scroll = true serve para quando o componente for usado em um scroll horizontal. Caso seja false, ele diminui o componente para melhor visualização

  if (!isLoading && !filtersLoading) return <>{children}</>;

  return Array.from({ length: 15 }, (_, i) => i).map((item) => (
    <div
      className={`space-y-2 flex flex-col ${!scroll && 'mr-2 mb-2'}`}
      key={`skeletonProduct${item}`}
    >
      <div className={`space-y-2 flex flex-col ${!scroll && 'mr-2 mb-2'} mb-5`}>
        <div
          className={`bg-gray-200 animate-pulse ${
            scroll ? 'w-36 h-36' : 'w-[6.5rem] h-[6.5rem]'
          } rounded-[10px]`}
        />
        <div
          className={`space-x-1 flex flex-row h-4 ${
            scroll ? 'w-36' : 'w-[6.5rem]'
          }`}
        >
          <div
            className={`bg-gray-200 animate-pulse w-6/12 h-4 rounded-[10px]`}
          />
          <div
            className={`bg-gray-200 animate-pulse w-6/12 h-4 rounded-[10px]`}
          />
        </div>
        <div
          className={`bg-gray-200 animate-pulse ${
            scroll ? 'w-36' : 'w-[6.5rem]'
          } h-4 rounded-[10px]`}
        ></div>
      </div>
      <div
        className={`bg-gray-200 animate-pulse ${
          scroll ? 'w-36' : 'w-[6.5rem]'
        } h-8 rounded-full`}
      ></div>
    </div>
  ));
};

export default SkeletonProduct;
