import React from 'react';

const SkeletonOrder: React.FC = () => {
  return (
    <div className={'flex flex-wrap items-center space-y-2'}>
      {Array.from({ length: 15 }, (_, i) => i).map((item) => (
        <div
          key={`skeletonOrder${item}`}
          className="bg-gray-200 animate-pulse w-[100%] h-[150px]"
        ></div>
      ))}
    </div>
  );
};

export default SkeletonOrder;
