import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import noAvatar from '../../assets/images/noAvatar.png';
import { TrackingContext } from '../../contexts/TrackingContext';
import { useSlug } from '../../hooks/useSlug';
import { LoyaltyCTA } from '../../pages/LoyaltyClub';
import { useMerchantCache } from '../../queries/home';
import { useAuthStore } from '../../stores/login';
import { useMerchantStore } from '../../stores/merchant';
import { typeLinks } from '../../utils/constants';
import Skeleton from '../Skeleton/Skeleton';
import { ProfileHeader } from '../ProfileHeader';

const HeaderHome: React.FC = () => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const user = useAuthStore((state) => state.user);
  const {
    merchant,
    clubs,
    links,
    delivery,
    isMerchantOpen,
    assessments,
    isClosedReason,
  } = useMerchantStore((state) => ({
    merchant: state.merchant,
    clubs: state.clubs,
    links: state.links,
    delivery: state.delivery,
    isMerchantOpen: state.isMerchantOpen,
    assessments: state.assessments,
    isClosedReason: state.isClosedReason,
  }));
  const navigate = useNavigate();
  const { track } = React.useContext(TrackingContext);

  const handleOpenClub = () => {
    if (user && user.clubs && user.clubs.length > 0) {
      navigate(`/club/`);
    } else {
      navigate('/club/preview');
    }
  };

  const haveAccount = isAuthenticated && user?.taxpayer_id_number;
  const firstClubName = clubs?.[0]?.name;

  return (
    <React.Fragment>
      <div className="flex flex-col w-full p-2 mb-4 space-y-1 text-center">
        {/* Profile */}
        <ProfileHeader />
        {/* Logo */}
        <div className="self-center">
          <Skeleton
            className="w-20 h-20 md:h-20 md:w-20"
            borderRadius="9999px"
            isLoading={isLoading}
          >
            <img
              onClick={() => {
                track('OnCustomerViewMerchantDetails', {});
                navigate(`/details`);
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = noAvatar;
              }}
              className="w-20 h-20 rounded-full md:h-20 md:w-20 ring-2 ring-gray-100 cursor-pointer"
              src={merchant?.logo || noAvatar}
              alt="Logotipo"
              loading="lazy"
            />
          </Skeleton>
        </div>
        {/* Nome da loja */}
        <div className="flex flex-row self-center space-x-1 font-bold prose prose-lg cursor-pointer">
          <Skeleton
            width="250px"
            height="25px"
            borderRadius="10px"
            isLoading={isLoading}
          >
            <div
              onClick={() => {
                track('OnCustomerViewMerchantDetails', {});
                navigate(`/details`);
              }}
            >
              {merchant?.name}
            </div>
            <Link
              to={`/details`}
              onClick={() => {
                track('OnCustomerViewMerchantDetails', {});
              }}
              className="self-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                stroke="#adadad"
                strokeWidth="2"
                className="w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          </Skeleton>
        </div>

        {/* Loja fechada */}
        {isMerchantOpen === false && !isLoading && (
          <div className="flex flex-row self-center space-x-1 px-2 p-1 rounded-lg font-semibold text-xs text-white bg-[#ad0505]">
            Loja fechada
          </div>
        )}

        {/* Loja fora de horário */}
        {isMerchantOpen === true && !isLoading && isClosedReason != null && (
          <div className="flex flex-row self-center space-x-1 px-2 p-1 rounded-lg font-semibold text-xs text-white bg-[#ad0505]">
            {isClosedReason}
          </div>
        )}

        {/* Nota média da loja e valor mínimo do pedido para entrega */}
        <div className="flex flex-row self-center space-x-1">
          <Skeleton
            width="150px"
            height="15px"
            borderRadius="10px"
            isLoading={isLoading}
          >
            {assessments && assessments.avg != null && (
              <>
                <article className="prose text-xs lg:text-sm font-bold">
                  {parseFloat(assessments.avg).toFixed(1)} &bull;{' '}
                </article>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#F4B746"
                  className="w-4 h-4 lg:w-5 lg:h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                    clipRule="evenodd"
                  />
                </svg>
              </>
            )}
            <article className="prose text-xs text-gray-600 lg:text-sm">
              Pedido mínimo{' '}
              {delivery && delivery.amount_order_min !== null
                ? parseInt(delivery.amount_order_min).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : 'R$ 0,00'}
            </article>
          </Skeleton>
        </div>
        {/* Opções para os links */}
        <div className="flex flex-row self-center space-x-1">
          <Skeleton
            width="340px"
            height="30px"
            borderRadius="10px"
            isLoading={isLoading}
          >
            {links?.map((link, index) => (
              <a
                href={link.link}
                target="_blank"
                className="text-xs w-28 min-h-5 bg-primary text-contrastText p-1 rounded-md hover:bg-color-hover"
                key={`linkButton${index}`}
                onClick={() => {
                  switch (link.type) {
                    case 'WHATSAPP': {
                      track('OnCustomerClickWhatsAppPill', {});
                      break;
                    }
                    case 'INSTAGRAM': {
                      track('OnCustomerClickInstagramPill', {});
                      break;
                    }
                    case 'MAPS': {
                      track('OnCustomerClickGMapsPill', {});
                      break;
                    }
                  }
                }}
              >
                {typeLinks[link.type]}
              </a>
            ))}
          </Skeleton>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeaderHome;
