import { Content } from '../queries/home/types';

export function useCalculateFeesFinalValue(
  merchantCache: Content | undefined,
  totalCart: number | undefined,
  selectedPaymentMethod?: string,
): number {
  if (!merchantCache) return 0;
  if (!totalCart) return 0;
  const account_fee_credit_card = merchantCache?.accounts.find(
    (item) => item.type === 'BANK' && item?.fees?.status === true,
  );
  const account_fee_pix = merchantCache?.accounts.find(
    (item) => item.type === 'BANK' && item?.fees?.status === true,
  );

  const fee_credit_card = account_fee_credit_card
    ? account_fee_credit_card.fees?.fee_credit_card
    : 0;
  const fee_pix = account_fee_pix ? account_fee_pix.fees?.fee_pix : 0;

  const calculateFee = (value: number) => {
    if (
      selectedPaymentMethod &&
      selectedPaymentMethod === 'CHECKOUT_CREDIT_CARD'
    ) {
      const percentage = fee_credit_card / 100;
      const fee_result = value * percentage;
      const result = value + fee_result;
      return result;
    } else if (
      selectedPaymentMethod &&
      selectedPaymentMethod === 'CHECKOUT_PIX'
    ) {
      const percentage = fee_pix / 100;
      const fee_result = value * percentage;
      const result = value + fee_result;

      return result;
    } else {
      return value;
    }
  };

  const valorFinalSemPesagem = calculateFee(totalCart);
  return valorFinalSemPesagem;
}

export function useCalculateFees(
  merchantCache: Content | undefined,
  totalCart: number | undefined,
  selectedPaymentMethod?: string,
): number {
  if (!merchantCache) return 0;
  if (!totalCart) return 0;
  const account_fee_credit_card = merchantCache?.accounts.find(
    (item) => item.type === 'BANK' && item?.fees?.status === true,
  );
  const account_fee_pix = merchantCache?.accounts.find(
    (item) => item.type === 'BANK' && item?.fees?.status === true,
  );

  const fee_credit_card = account_fee_credit_card
    ? account_fee_credit_card.fees?.fee_credit_card
    : 0;
  const fee_pix = account_fee_pix ? account_fee_pix.fees?.fee_pix : 0;

  const calculateFee = (value: number) => {
    if (
      selectedPaymentMethod &&
      selectedPaymentMethod === 'CHECKOUT_CREDIT_CARD'
    ) {
      const percentage = fee_credit_card / 100;
      const fee_result = value * percentage;
      const result = fee_result;
      return result;
    } else if (
      selectedPaymentMethod &&
      selectedPaymentMethod === 'CHECKOUT_PIX'
    ) {
      const percentage = fee_pix / 100;
      const fee_result = value * percentage;
      const result = fee_result;

      return result;
    } else {
      return value;
    }
  };

  const valorFinalSemPesagem = calculateFee(totalCart);
  return valorFinalSemPesagem;
}
