import { Modal } from '@onbeef/components/modal';
import { CloseIcon, Medium } from '@onbeefapp/constants';
import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

interface RotativeImagesDetailProps {
  images: Medium[];
  index: number;
  productName: string;
  open: boolean;
  onClose: () => void;
}

export const RotativeImagesDetail: React.FC<RotativeImagesDetailProps> = ({
  images,
  index = 0,
  productName,
  open,
  onClose,
}) => {
  const [activeImage, setActiveImage] = React.useState(index);

  React.useEffect(() => {
    setActiveImage(index);
  }, [index]);

  return (
    <Modal
      className="w-full h-max-screen"
      isOpen={open}
      onClose={onClose}
      closeIcon={false}
    >
      <div className="w-full h-full h-max-screen h-max-screen flex flex-col justify-between">
        <div className="w-full flex justify-between p-6 items-center">
          <span className="text-lg font-medium">{productName}</span>
          <button onClick={onClose} className="">
            <XMarkIcon className="w-8 h-8 text-primary" />
          </button>
        </div>
        <div className="w-full flex justify-center p-6">
          <ZoomableImage
            src={images[activeImage]?.file_url || ''}
            alt="Imagem do produto"
          />
        </div>
        <div className="flex flex-row w-full items-center justify-center gap-2 p-6">
          {images.length > 1 &&
            images.map((image, i) => {
              return (
                <button
                  key={image.id}
                  type="button"
                  className="rounded-lg border-2 border-gray-400 overflow-hidden"
                  onClick={() => setActiveImage(i)}
                >
                  <img
                    src={image.file_url}
                    alt="Imagem do produto"
                    className="w-24 h-24"
                  />
                </button>
              );
            })}
        </div>
      </div>
    </Modal>
  );
};

const ZoomableImage = ({ src, alt }: { src: string; alt: string }) => {
  return (
    <TransformWrapper
      initialScale={1}
      minScale={0.5}
      maxScale={5}
      pinch={{ step: 0.5 }}
    >
      {() => (
        <div className="zoom-container">
          <TransformComponent>
            <img src={src} alt={alt} className="zoomable-image" />
          </TransformComponent>
        </div>
      )}
    </TransformWrapper>
  );
};
