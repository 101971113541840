import { useNavigate } from 'react-router-dom';
import { useMerchantStore } from '../../stores/merchant';
import PwaAndroid from './Android';
import PwaIphone from './iphone';

export default function Pwa() {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const merchant = useMerchantStore((state) => state.merchant);

  const goBack = () => {
    navigate(`/`);
  };

  const agent = queryParams.get('agent');

  if (!merchant) return <></>;

  return (
    <div>
      <div>
        <div>
          <div
            className="flex w-8 m-2 items-center justify-center rounded-full bg-primary"
            onClick={goBack}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              strokeWidth={1.5}
              stroke="currentColor"
              className="py-2 w-8 h-8 text-contrastText"
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div className="w-100 mt-8">
          <div className="flex flex-col gap-[15px] px-12">
            <div>
              <center>
                <img
                  src={merchant.logo}
                  className="h-[100px] rounded-[10px]"
                  alt="logo da loja"
                  loading="lazy"
                ></img>
              </center>
            </div>
            <div>
              <span className="font-semibold text-2xl">
                Adicione nossa loja ao seu celular.
              </span>
            </div>
            <div className="flex flex-col gap-1 text-base">
              <span className="font-semibold">
                São 2 cliques e não precisa baixar nada.
              </span>
              <span>
                <b>1 - </b>Clique no botão abaixo
              </span>
              <span>
                <b>2 - </b>“Adicionar na tela”
              </span>
              <span>
                <b>3 - </b>Prontinho, estamos na sua tela!
              </span>
            </div>
            <div className="text-lg">
              <span className="font-semibold">{merchant.name}</span>, pedir
              online nunca foi tão fácil.
            </div>
          </div>

          {agent === 'android' && <PwaAndroid />}
          {agent === 'iphone' && <PwaIphone />}
        </div>
      </div>
    </div>
  );
}
