import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput } from '@onbeef/components/input';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useLoginCustomer } from '../../queries/login';
import { useAuthStore } from '../../stores/login';
import posthog from 'posthog-js';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useMerchantStore } from '../../stores/merchant';
import { Skeleton } from '@onbeef/components/skeleton';
import HeaderBar from '../../components/Headers/HeaderBar';
import { Helmet } from 'react-helmet';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido')
    .required('Informe um email valido'),
  password: Yup.string().required('Informe uma senha'),
});

export const Login: React.FC = () => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);

  const { merchant } = useMerchantStore((state) => ({
    merchant: state.merchant,
  }));

  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [hasTypedEmailState, setHasTypedEmailState] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const login = useLoginCustomer();
  const { setAuthState, logout } = useAuthStore((state) => ({
    setAuthState: state.setAuthState,
    logout: state.logout,
  }));
  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const [inputValues, setInputValues] = useState(methods.getValues());

  const onSubmit = (formData: { email: string; password: string }) => {
    login
      .mutateAsync({
        email: formData.email,
        password: formData.password,
      })
      .then((data) => {
        setAuthState(true, data);
        posthog?.identify(formData.email, {
          email: formData.email,
        });
        if (location.state && location.state.goBack) {
          navigate(location.state.goBack, { replace: true });
        } else {
          navigate('/');
        }
      })
      .catch(() => {
        logout();
      });
  };

  const hasTypedEmail =
    !inputValues.email || inputValues?.email === '' ? false : true;
  const hasTypedPassword =
    !inputValues.password || inputValues?.password === '' ? false : true;

  const goToPasswordInput = (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const emailInput = document.getElementById('email') as HTMLInputElement;
    if (hasTypedEmail && emailInput.validity.valid) {
      e.preventDefault();
      setHasTypedEmailState(true);
      setTimeout(() => document.getElementById('password')?.focus(), 100);
    }
  };

  return (
    <div className="flex flex-col px-6 pt-6">
      <Helmet>
        <meta name="title" content={`Login ${merchant?.name}`} />
      </Helmet>
      <HeaderBar></HeaderBar>
      <div className="w-full h-screen flex flex-col items-center justify-center vertical-center p-6">
        <div className="w-full max-w-md">
          <Skeleton
            isLoading={isLoading}
            className="h-20 w-20 mb-6 rounded-full"
          >
            <img
              alt="Logo"
              className="h-20 w-20 mb-6 md:hidden flex rounded-full"
              height="32"
              src={merchant?.logo}
              style={{
                aspectRatio: '32/32',
                objectFit: 'cover',
              }}
              width="32"
            />
          </Skeleton>
          <Skeleton
            isLoading={isLoading}
            className="h-10 w-full rounded-lg mb-6"
          >
            <h2 className="text-3xl font-bold mb-6">Seja bem-vindo!</h2>
            <p className="mb-2">Acesse sua conta.</p>
          </Skeleton>
          <Skeleton
            isLoading={isLoading}
            repeatQuantity={3}
            className="h-10 w-full rounded-lg"
          >
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className="space-y-6"
              >
                <div className="flex flex-col">
                  <TextInput
                    name="email"
                    placeholder="E-mail"
                    type="email"
                    className={`pt-3 pb-3 rounded-t-lg ${
                      !hasTypedEmailState ? 'rounded-b-lg' : ''
                    } text-base f-16 transition-all duration-700 ease-in-out`}
                    endIcon={
                      <button onClick={(e) => goToPasswordInput(e)}>
                        <ArrowRightIcon
                          className={`h-6 w-6 mr-2 mb-1 text-gray-600 ${
                            hasTypedEmailState ? 'hidden' : ''
                          }`}
                        />
                      </button>
                    }
                    onChange={(e) => {
                      setInputValues({ ...inputValues, email: e.target.value });
                      if (hasTypedEmailState) {
                        setHasTypedEmailState(false);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        goToPasswordInput(e);
                      }
                    }}
                    isFromLogin
                    required
                  />
                  {hasTypedEmailState && (
                    <TextInput
                      name="password"
                      placeholder="Senha"
                      type={hiddenPassword ? 'password' : 'text'}
                      className={`mb-1 pt-3 pb-3 rounded-b-lg text-base f-16 ${
                        !hasTypedEmailState ? 'hidden' : ''
                      } transition-all duration-700 ease-in-out`}
                      endIcon={
                        <button
                          type="button"
                          onClick={() => setHiddenPassword(!hiddenPassword)}
                        >
                          {hiddenPassword ? (
                            <EyeIcon
                              className={`h-6 w-6 mr-2 mb-1 text-gray-600 ${
                                !hasTypedPassword || !hasTypedEmailState
                                  ? 'hidden'
                                  : ''
                              }`}
                            />
                          ) : (
                            <EyeSlashIcon
                              className={`h-6 w-6 mr-2 mb-1 text-gray-600 ${
                                !hasTypedPassword || !hasTypedEmailState
                                  ? 'hidden'
                                  : ''
                              }`}
                            />
                          )}
                        </button>
                      }
                      onChange={(e) => {
                        setInputValues({
                          ...inputValues,
                          password: e.target.value,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSubmit(inputValues);
                        }
                      }}
                      isFromLogin
                      required
                    />
                  )}
                  {login.isError && (
                    <span className="text-red-400 text-sm">
                      Não foi possível realizar o login, verifique se suas
                      credenciais estão corretas!
                    </span>
                  )}
                  <Link
                    className="text-sm mt-2 text-red-600 hover:underline"
                    to="/forgot-password"
                  >
                    Esqueceu sua senha?
                  </Link>
                </div>
                <div className="flex flex-col gap-2">
                  <button
                    className="w-full bg-primary text-contrastText px-6 py-2 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                    type="submit"
                    disabled={login.isLoading}
                    onClick={(e) => {
                      if (
                        (hasTypedEmail && !hasTypedPassword) ||
                        (!hasTypedEmailState && hasTypedPassword)
                      ) {
                        goToPasswordInput(e);
                        if (!hasTypedEmailState && hasTypedPassword) {
                          onSubmit(inputValues);
                        }
                      }
                    }}
                  >
                    {login.isLoading
                      ? 'Carregando...'
                      : hasTypedEmail && hasTypedPassword
                        ? 'Acessar'
                        : 'Avançar'}
                  </button>
                  <span>
                    Não tem conta?{' '}
                    <Link to="/signin" className="underline">
                      Criar uma
                    </Link>
                  </span>
                </div>
              </form>
            </FormProvider>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};
