import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput } from '@onbeef/components/input';
import { Skeleton } from '@onbeef/components/skeleton';
import { LoadingIcon, Toast } from '@onbeefapp/constants';
import { AxiosError } from 'axios';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import HeaderBar from '../../../components/Headers/HeaderBar';
import { useSlug } from '../../../hooks/useSlug';
import { useChangePassword } from '../../../queries/customer';
import { useMerchantCache } from '../../../queries/home';

interface ProfilePasswordForm {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
}

const profilePasswordValidationSchema = Yup.object().shape({
  old_password: Yup.string().required('Senha antiga é obrigatória'),
  new_password: Yup.string().required('Nova senha é obrigatória'),
  new_password_confirmation: Yup.string().required(
    'Confirmação da nova senha é obrigatória',
  ),
});

export const ProfilePassword: React.FC = () => {
  const params = useSlug();
  const { isLoading: isMerchantLoading } = useMerchantCache(params.slug);

  const methods = useForm<ProfilePasswordForm>({
    resolver: yupResolver(profilePasswordValidationSchema),
  });

  const { mutateAsync: changePassword, isLoading } = useChangePassword();

  const onSubmit = async (data: ProfilePasswordForm) => {
    try {
      if (data.new_password !== data.new_password_confirmation) {
        Toast.error('A nova senha não coincide com a de confirmação.');
        return;
      }
      const res = await changePassword({
        current_password: data.old_password,
        password: data.new_password,
        password_confirmation: data.new_password_confirmation,
      });
      res.message && Toast.success(res.message);
      methods.reset();
    } catch (error) {
      (error as AxiosError<{ error: string[] }>).response?.data?.error?.forEach(
        (msg) => {
          Toast.error(msg);
        },
      );
    }
  };

  return (
    <div className="w-full h-full p-4 space-y-2">
      <HeaderBar>
        <span className="text-sm font-normal text-center w-full">
          Alterar senha
        </span>
      </HeaderBar>

      <FormProvider {...methods}>
        <form
          className="flex flex-col p-2 gap-4"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Skeleton
            isLoading={isMerchantLoading}
            repeatQuantity={4}
            className="w-full h-10"
          >
            <span className="text-sm font-medium">Alteração de senha.</span>
            <TextInput
              name="old_password"
              customLabel="Senha atual"
              placeholder="Senha atual"
              type="password"
            />
            <TextInput
              name="new_password"
              customLabel="Nova senha"
              placeholder="Nova senha"
              type="password"
            />
            <TextInput
              name="new_password_confirmation"
              customLabel="Confirmação da nova senha"
              placeholder="Confirmação da nova senha"
              type="password"
            />
            <button
              type="submit"
              className="rounded-lg bg-primary text-contrastText px-5 py-2 w-full h-10 text-center self-center flex justify-center"
            >
              {isLoading ? <LoadingIcon /> : 'Salvar'}
            </button>
          </Skeleton>
        </form>
      </FormProvider>
    </div>
  );
};
