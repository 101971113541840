import React from 'react';

interface SkeletonProps {
  children: React.ReactNode;
  width?: string;
  height?: string;
  borderRadius?: string;
  repeatQuantity?: number;
  isLoading: boolean;
  className?: string;
}

const Skeleton: React.FC<SkeletonProps> = ({
  children,
  width,
  height,
  borderRadius,
  repeatQuantity,
  isLoading,
  className,
}) => {
  const skeletonStyle: React.CSSProperties = {
    width,
    height,
    borderRadius,
  };

  if (!isLoading) return <>{children}</>;

  if (repeatQuantity) {
    return (
      <div className="flex flex-col space-y-1">
        {Array.from({ length: repeatQuantity }, (_, i) => i).map((_, index) => (
          <div
            key={`skeleton-${index}`}
            className={`bg-gray-200 animate-pulse ${className}`}
            style={skeletonStyle}
          ></div>
        ))}
      </div>
    );
  }

  return (
    <div
      className={`bg-gray-200 animate-pulse ${className}`}
      style={skeletonStyle}
    ></div>
  );
};

export default Skeleton;
