import { Slide, toast, ToastOptions, ToastContent } from 'react-toastify'

export class Toast {
  private static config: ToastOptions = {
    position: 'top-center',
    autoClose: 1500,
    closeButton: false,
    icon: true,
    hideProgressBar: true,
    closeOnClick: true,
    transition: Slide,
    theme: 'colored',
  }

  static success(message: ToastContent, customConfig?: ToastOptions) {
    const config = { ...Toast.config, className: 'toastify-sucess' }
    toast.success(message, { ...config, ...customConfig } || config)
  }

  static warn(message: ToastContent, customConfig?: ToastOptions) {
    toast.warn(message, customConfig || Toast.config)
  }

  static warnPendingOrder(message: ToastContent, customConfig?: ToastOptions) {
    const config = { ...Toast.config, autoClose: 99999, id: 'pending-order' }
    toast.warn(message, customConfig || config)
  }

  static error(message: ToastContent, customConfig?: ToastOptions) {
    toast.error(message, customConfig || Toast.config)
  }
}
