import { useComplementsStore } from '../stores/complements';
import { ProductType } from '@onbeefapp/constants';

// funcao para trazer
export function getQuantity(
  product_id: string,
  complement_id: string,
  item_id: string,
): { formatted: string; number: number; quantity: number } {
  const getComplementsItem = useComplementsStore.getState().getComplementsItem;

  let complement = getComplementsItem(product_id, complement_id, item_id);

  if (!complement) {
    return { formatted: '0', number: 0, quantity: 0 };
  }

  if (complement?.totalQuantity > 0) {
    if (complement?.type == ProductType.KG) {
      return {
        formatted: `${complement.totalQuantity}${
          complement?.items_kg === 1 ? 'g' : ''
        }`,
        number: complement.totalQuantity,
        quantity: complement.quantity,
      };
    } else {
      return {
        formatted: `${complement.totalQuantity}`,
        number: complement.totalQuantity,
        quantity: complement.quantity,
      };
    }
  } else {
    return { formatted: '0', number: 0, quantity: 0 };
  }
}
