import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMerchantStore } from '../../stores/merchant';
import Modal from './Modal';

const OpenForOrdersModal: React.FC = () => {
  const { isClosedReason } = useMerchantStore((state) => ({
    merchant: state.merchant,
    isClosedReason: state.isClosedReason,
  }));

  const navigate = useNavigate();

  const back = () => {
    navigate(`/`);
  };

  if (!isClosedReason) return null;

  return (
    <div>
      <Modal
        title="Loja Fechada"
        showConfirmButton={true}
        textConfirmButton="Continuar"
        showCancelButton={true}
        onCancel={back}
      >
        <p>{isClosedReason}</p>
      </Modal>
    </div>
  );
};

export default OpenForOrdersModal;
