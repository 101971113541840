import React, {
  useState,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
} from 'react';

interface CodeInputProps {
  onComplete?: (code: string) => void;
}

export const CodeInput: React.FC<CodeInputProps> = ({ onComplete }) => {
  const [code, setCode] = useState<string[]>(['', '', '', '']);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = event.target.value;

    if (/^\d?$/.test(value)) {
      const newDigits = [...code];
      newDigits[index] = value;
      setCode(newDigits);

      if (value && index < 3) {
        inputRefs.current[index + 1]?.focus();
      }

      if (newDigits.every((digit) => digit)) {
        onComplete?.(newDigits.join(''));
      }
    }
  };

  const handleKeyDown = (
    event: KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.key === 'Backspace') {
      if (!code[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
      } else if (code[index]) {
        handleChange(
          { target: { value: '' } } as ChangeEvent<HTMLInputElement>,
          index,
        );
      }
    }
  };

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      {code.map((digit, index) => (
        <input
          key={index}
          id={`digit-${index}`}
          type="text"
          inputMode="numeric"
          maxLength={1}
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
          className="h-20 w-14 shadow-xl border border-gray-200 rounded-lg text-center"
        />
      ))}
    </div>
  );
};
