import { LoadingIcon } from '@onbeefapp/constants'
import React from 'react'
import { useCartStore } from '../../../../catalogo/src/stores/cart'
import { isWhatsappExtension } from '../../utils'
interface Props {
  isLoading: boolean
}

export const ExtensionCheckoutButton: React.FC<Props> = ({ isLoading }) => {
  const { isEditOrder } = useCartStore((state) => ({
    isEditOrder: state.isEditOrder,
  }))

  return (
    <>
      <button
        className="w-full flex justify-center p-2 text-contrastText bg-primary rounded-md text-sm"
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? (
          <LoadingIcon />
        ) : isEditOrder ? (
          'Alterar pedido'
        ) : isWhatsappExtension() ? (
          'Concluir pedido e copiar mensagem de confirmação'
        ) : (
          'Concluir pedido'
        )}
      </button>
    </>
  )
}
