import centavosOKImg from './assets/centavos_ok.jpg'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { Dialog } from '@material-tailwind/react'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UseMutationResult } from '@tanstack/react-query'
import 'config/global.css'
import { useCartStore } from '../../../apps/catalogo/src/stores/cart'

const Listitem: React.FC<{ num: string; children: React.ReactNode }> = ({
  num,
  children,
}) => {
  return (
    <li className="flex flex-row items-center justify-start">
      <div className="flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full">
        <h6 className="text-lg font-medium">{num}</h6>
      </div>
      <div className="flex-grow ml-4 text-md">{children}</div>
    </li>
  )
}

interface StepProps {
  nextStep: () => void
  validate?: (code: string) => void
  error?: any
  loading?: boolean
}

const Step1 = ({ nextStep }: StepProps) => {
  const handleVerificar = () => {
    nextStep()
  }

  return (
    <div className="flex flex-col items-center justify-center h-full gap-5">
      <h1 className="text-lg font-bold text-center">
        Por segurança, é importante verificar seu cartão. Entenda como funciona:
      </h1>

      <ul className="flex flex-col gap-3 p-0">
        <Listitem num="1">
          <p>
            Fizemos uma cobrança abaixo do valor da compra no seu cartão. Ex:{' '}
            <br /> Valor da compra R$ 100,00. <br /> <b>Valor cobrado</b> R$
            97,80.
          </p>
        </Listitem>
        <Listitem num="2">
          <p>
            Confira o <b>valor cobrado</b> na sua fatura pelo nome que inicia
            com ON*
          </p>
        </Listitem>
        <Listitem num="3">
          <p>Depois, é só voltar aqui pra informar qual o valor da cobrança.</p>
        </Listitem>
      </ul>

      <div className="w-80">
        <p className="text-sm text-center">
          Sem essa confirmação, sua compra nao será processada. Caso não
          confirme, não se preocupe, dentro dos próximos dias, o valor será
          extornado.
        </p>
      </div>

      <div className="absolute w-80 bottom-20">
        <button
          className="w-full px-4 py-2 text-white rounded-full bg-primary focus:outline-none focus:bg-secondary"
          onClick={handleVerificar}
        >
          <b>Verificar</b>
        </button>
      </div>
    </div>
  )
}

const Step2 = ({ validate, error, loading }: StepProps) => {
  const handleConfirmar = () => {
    if (!validate) return
    const code = value.split(',')[1]
    if (!code) {
      return
    }
    validate(code)
  }

  const [value, setValue] = React.useState('R$ ')

  function formatCurrency(value: string) {
    const onlyNumbers = value.replace(/[^0-9]/g, '')
    const formattedValue = onlyNumbers.replace(/(\d{1,})(\d{2})$/, '$1,$2')
    return `R$ ${formattedValue}`
  }

  function handleChange(event: any) {
    setValue(formatCurrency(event.target.value))
  }

  return (
    <div className="grid justify-center gap-5">
      <h1 className="font-bold text-md">
        Agora, digite abaixo o valor (incluindo centavos) que apareceu na
        fatura:
      </h1>

      <input
        className="w-full p-2 border-b-2 border-gray-300 outline-none"
        type="text"
        value={value}
        onChange={handleChange}
      />

      <div className="w-full p-4 bg-gray-300 rounded-lg">
        <p className="font-bold">
          <b>Importante:</b> você tem apenas 2 chances para digitar o valor
          exato da cobrança e finalizar a compra. Caso não confirme, a compra
          será cancelada e dentro dos próximos dias, o valor será extornado.
        </p>
      </div>

      {error ? (
        <div className="w-full">
          <div className="p-4 py-2 bg-red-300 rounded-lg">
            <b>Erro: </b>
            {error.response?.data?.error[0]}
          </div>
        </div>
      ) : null}

      {loading ? (
        <div className="flex items-center justify-center w-full rounded-full bg-primary">
          <ArrowPathIcon className="h-10 text-white animate-spin" />
        </div>
      ) : (
        <button
          className="w-full px-4 py-2 mt-3 text-white rounded-full bg-primary"
          onClick={handleConfirmar}
        >
          <b>Confirmar valor</b>
        </button>
      )}
    </div>
  )
}

const steps = [, , Step1, Step2]

export interface ValidateCentsPayload {
  cents: string
  order_id: string
  code: string
}

type ValidateCentsProps = {
  validateCents: UseMutationResult<any, any, ValidateCentsPayload, any>
}

export function ValidateCents({ validateCents }: ValidateCentsProps) {
  const params = useParams()
  const navigate = useNavigate()

  const isCheckoutLink = useCartStore((state) => state.isCheckoutLink)

  const [openSuccess, setOpenSuccess] = React.useState(false)

  const [activeStep, setActiveStep] = React.useState(2)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const validate = async (cents: string) => {
    if (!params.orderid || !params.code) return
    validateCents.mutate({
      cents: cents,
      order_id: params.orderid,
      code: params.code,
    })
  }

  React.useEffect(() => {
    if (validateCents.data) {
      const res = validateCents.data
      if (
        res.data?.message ===
          'Pagamento verificado com sucesso, agora só espere o lojista confirmar o pedido!' ||
        res.data?.message === 'Pagamento capturado com sucesso'
      ) {
        setOpenSuccess(true)
      }
    } else {
      const res = (validateCents.error as any)?.response
      if (
        res?.data?.error[0] ===
        'Número de tentativas excedida, o valor reservado no seu cartão vai ser liberado em instantes!'
      ) {
        // redirect to error
      }
    }
  }, [validateCents])

  const CurrStep = steps[activeStep]

  return (
    <div className="flex items-center justify-center w-full">
      <div className="gap-4 p-4" style={{ padding: '20px' }}>
        <h1 className="mt-4 mb-4 text-3xl font-bold">Verificar cartão</h1>

        <div className="flex justify-between">
          <span>Etapa</span>
          <span>{activeStep}/4</span>
        </div>

        <div className="flex items-center w-full">
          <div className="w-full h-2 bg-gray-300 rounded-full">
            <div
              className="h-2 transition-all rounded-full bg-primary"
              style={{ zIndex: 999, width: `${(activeStep / 4) * 100}%` }}
            ></div>
          </div>
        </div>

        <div className="w-full mt-4">
          {CurrStep ? (
            <CurrStep
              nextStep={handleNext}
              validate={validate}
              error={validateCents.error}
              loading={validateCents.isLoading}
            />
          ) : null}
        </div>
      </div>

      <Dialog placeholder={''} size="xxl" open={openSuccess} handler={() => {}}>
        <div className="items-center justify-center max-w-full gap-4 p-4 mt-4">
          <div className="flex items-center justify-center">
            <img src={centavosOKImg} width={'200px'} loading="lazy" />
          </div>
          <div className="mt-4 mb-4">
            <h6 className="text-xl font-bold">
              <b>Pronto! Tudo certo para usar seu cartão</b>
            </h6>
          </div>

          <div className="mt-4 mb-4">
            <p className="text-base">
              Agora com seu cartão de crédito verificado, seus pagamentos são
              aprovados com mais rapidez e segurança.
            </p>
          </div>

          <div className="mt-4 mb-4">
            <div
              className="bg-[#fff0d9] p-4 rounded-lg"
              style={{ width: '100%' }}
            >
              <p>
                <b>Importante:</b> cadastrar e utilizar cartão de crédito
                desconhecido é ilegal e você poderá ser responsabilizado pelo
                seu uso.
              </p>
            </div>
          </div>

          <div className="mt-4 mb-4">
            <button
              className="w-full px-4 py-2 font-bold text-white rounded-full bg-primary hover:bg-secondary"
              onClick={() => {
                // redirect to success
                if (isCheckoutLink) {
                  navigate('/checkout/success')
                } else {
                  navigate(`/status/${params.orderid}`)
                }
              }}
              style={{ textTransform: 'none' }}
            >
              <b>Continuar</b>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
