import ProductCard from '@onbeef/components/productCard';
import { ProductType, Toast, promotionStarted } from '@onbeefapp/constants';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TrackingContext } from '../../contexts/TrackingContext';
import { Product } from '@onbeefapp/constants';
import { ProductCartType, useCartStore } from '../../stores/cart';
import { useDrawerStore } from '../../stores/drawer';
import { useMerchantStore } from '../../stores/merchant';
import { getQuantity } from '../../utils/ProductUtils';

interface CategoryProps {
  product: Product;
  scroll?: boolean;
}

const CardProduct: React.FC<CategoryProps> = ({ product, scroll = true }) => {
  const { addCart, increaseQuantity, removeProduct, products } = useCartStore(
    (state) => ({
      addCart: state.addCart,
      removeProduct: state.removeProduct,
      increaseQuantity: state.increaseQuantity,
      products: state.products,
    }),
  );

  const { isMerchantOpen, schedules, merchant } = useMerchantStore((state) => ({
    isMerchantOpen: state.isMerchantOpen,
    schedules: state.schedules,
    merchant: state.merchant,
  }));

  const { formatted: formattedQuantity, number: quantityNumber } = getQuantity(
    products,
    product,
  );

  const { toggleDrawer, setProductDrawer } = useDrawerStore((state) => ({
    toggleDrawer: state.toggleDrawer,
    setProductDrawer: state.setProductDrawer,
  }));

  const navigate = useNavigate();

  const { track } = React.useContext(TrackingContext);

  const navigateProduct = () => {
    if (isSoldOut) {
      return Toast.error('Produto esgotado');
    }

    track('OnCustomerViewProduct', {
      ...product,
    });
    navigate(`/product/${product.slug}`);
  };

  // Função para abrir o drawer de gramas
  const handleClickGrams = () => {
    if (product.openModal) {
      navigateProduct();
      return;
    }

    if (product.product_type === ProductType.KG) {
      setProductDrawer(product as ProductCartType);
      toggleDrawer('drawerGrams');
    }
  };

  // Função para adicionar a quantidade do produto
  const addProduct = () => {
    if (product.openModal) {
      navigateProduct();
      return;
    }

    if (product.product_type === ProductType.KG) {
      handleClickGrams();
      return;
    }

    if (!isMerchantOpen && schedules?.receive_orders_when_closed === false) {
      return Toast.error(
        'Loja fechada, não é possivel adicionar produtos na sacola',
      );
    }

    if (
      (product.product_type === ProductType.UNIT ||
        product.product_type === ProductType.KIT) &&
      !product.openModal
    ) {
      // setQuantity(quantity + 1);

      if (
        product?.promotion &&
        product?.promotion?.max_buyer &&
        product?.promotion?.max_buyer != 0 &&
        quantityNumber >= product?.promotion?.max_buyer
      ) {
        return Toast.error(
          `Este produto possui o limite de ${product?.promotion?.max_buyer} por pessoa`,
        );
      }

      if (quantityNumber < 1) {
        addCart(product, 1, '');
        track('OnCustomerCartAddProduct', {
          ...product,
        });
      } else {
        increaseQuantity(product.id);
        track('OnCustomerCartIncreaseQtProduct', {
          ...product,
        });
      }
    }
  };

  // Função para diminuir a quantidade do produto
  const removeProductView = () => {
    if (product.openModal) {
      navigateProduct();
      return;
    }

    if (product.product_type === ProductType.KG) {
      handleClickGrams();
    } else {
      if (quantityNumber > 0) {
        removeProduct(product.id);
      }
    }

    track('OnCustomerCartRemoveProduct', {
      ...product,
    });
  };

  // Atributo scroll = true serve para quando o componente for usado em um scroll horizontal. Caso seja false, ele diminui o componente para melhor visualização

  /* Verificando se o estoque do produto esta ESGOTADO */

  const isSoldOut =
    product?.promotion?.available != null &&
    product?.promotion?.available === 0;

  if (
    product.promotion &&
    product.promotion.start_at != null &&
    !promotionStarted(product.promotion) &&
    product.is_highlight === 1
  ) {
    return null;
  }

  // Funções para verificar se a loja está aberta e se o produto pode ser adicionado na sacola
  const handleCheckAddProduct = () => {
    if (!isMerchantOpen && schedules?.receive_orders_when_closed === false) {
      navigateProduct();
    } else {
      addProduct();
    }
  };
  const handleCheckRemoveProduct = () => {
    if (!isMerchantOpen && schedules?.receive_orders_when_closed === false) {
      navigateProduct();
    } else {
      removeProductView();
    }
  };

  return (
    <ProductCard
      product={product}
      scroll={scroll}
      handleCheckAddProduct={handleCheckAddProduct}
      handleCheckRemoveProduct={handleCheckRemoveProduct}
      quantityNumber={quantityNumber}
      handleClickProduct={navigateProduct}
      merchant={merchant}
      formattedQuantity={formattedQuantity}
    ></ProductCard>
  );
};

export default CardProduct;
