import { CutTypes } from '@onbeefapp/constants';
import { CutPayload } from '../queries/order/types';
import { useCartStore } from '../stores/cart';

interface SelectedCutDetail {
  name: string;
  quantity: number;
  comments: string;
  isPackage: boolean;
  package_quantity: number | null;
}

export const getCutsQuantity = (selectedCuts?: CutPayload[]) => {
  if (!selectedCuts) return 0;
  return selectedCuts?.reduce((acc, cur) => acc + cur.quantity, 0);
};

export const getSelectedCutDetail = (
  productID: string,
  cutID: string,
): SelectedCutDetail | undefined => {
  const product = useCartStore.getState().products?.[productID];
  if (!product) return undefined;
  const selectedCut = product.selectedCuts?.find((c) => c.id === cutID);
  const cut = product.cuts?.find((c) => c.id === cutID);
  if (!selectedCut || !cut) return undefined;

  return {
    name: cut.label || cut.name,
    quantity: selectedCut.quantity,
    comments: selectedCut.comments || '',
    isPackage: cut.type === CutTypes.PACKAGE,
    package_quantity: selectedCut.package_quantity,
  };
};

export const calculatedCutsTotalPrice = (
  productID: string,
  price: number,
): number => {
  const cartProduct = useCartStore.getState().products[productID];
  let total = 0;
  if (!cartProduct) return total;
  total = cartProduct.selectedCuts
    .map((selectedCut) => {
      const cut = cartProduct.cuts.find((c) => c.id === selectedCut.id);
      const p = cut?.price ? cut.price : price;
      return (selectedCut.quantity * p) / 1000;
    })
    .reduce((acc, cur) => acc + cur, 0);
  return total;
};
