import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput } from '@onbeef/components/input';
import { Toast } from '@onbeefapp/constants';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useNewPassword } from '../../queries/login';
import { Typography } from '@material-tailwind/react';

interface ICustomerResetPassword {
  password: string;
  password_confirmation: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Informe uma senha'),
  password_confirmation: Yup.string().required('Informe uma senha'),
});

export default function CustomerResetPassword() {
  const newPassword = useNewPassword();
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm<ICustomerResetPassword>({
    resolver: yupResolver(validationSchema),
  });

  const [password, password_confirmation] = methods.watch([
    'password',
    'password_confirmation',
  ]);
  const passwordsMatch = password === password_confirmation;

  const onSubmit = (formData: ICustomerResetPassword) => {
    if (!passwordsMatch) {
      Toast.error('Senhas não conferem!');
    }
    if (params.email && params.token) {
      newPassword
        .mutateAsync({
          email: params.email,
          token: params.token,
          password: formData.password,
          password_confirmation: formData.password_confirmation,
        })
        .then((response) => {
          if ('message' in response.data) {
            Toast.success(response.data.message);
            navigate('/login');
          } else if (response.data.error) {
            Toast.error(response.data.error.join(' '));
          }
        })
        .catch((ex) => {
          Toast.error(ex);
        });
    }
  };

  return (
    <div className="flex w-full h-[100vh] items-center justify-center">
      <div className="flex flex-col w-[90%] items-center">
        <div className="mt-3 mb-6 text-center space-y-2">
          <h2 className="text-3xl font-bold mb-6"></h2>
          <p className="mb-2">
            <Typography
              variant="h5"
              className="font-bold"
              placeholder={'nome da loja'}
            >
              Defina sua nova senha
            </Typography>
          </p>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex min-w-[250px] flex-col space-y-4"
          >
            <div className="space-y-1">
              <TextInput
                name="password"
                placeholder="Nova senha"
                required
                type="password"
                className="mt-1 p-3 border border-gray-500 rounded"
              />
            </div>
            <div className="space-y-1">
              <TextInput
                name="password_confirmation"
                placeholder="Confirme sua nova senha"
                required
                type="password"
                className="mt-1 p-3 border border-gray-500 rounded"
              />
            </div>
            <div className="flex justify-between items-center">
              <button
                className="bg-red-600 text-white px-6 py-2 rounded"
                type="submit"
              >
                {newPassword.isLoading ? 'Carregando...' : 'Salvar alteração'}
              </button>
            </div>
            {newPassword.isSuccess && 'Senha atualizada!'}
            {newPassword.isError && 'Erro!'}
            {!passwordsMatch && 'Senhas não conferem!'}
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
