import * as Yup from 'yup'
import { cpf, cnpj } from 'cpf-cnpj-validator'

export const customerValidationSchema = Yup.object().shape({
  name: Yup.string().when(['have_account', 'with_name_phone'], {
    is: (have_acc: boolean, with_name: boolean) =>
      have_acc ? false : with_name,
    then: (schema) => schema.required('Nome é obrigatório'),
  }),
  phone: Yup.string().when(['have_account', 'with_name_phone'], {
    is: (have_acc: boolean, with_phone: boolean) =>
      have_acc ? false : with_phone,
    then: (schema) => schema.required('Celular é obrigatório'),
  }),
  email: Yup.string().when(['have_account'], {
    is: false,
    then: (schema) =>
      schema.email('Email inválido').required('Email é obrigatório'),
  }),
  taxpayer_id_number: Yup.string().when(['have_account', 'without_cpf'], {
    is: (haveAcc: boolean, withoutCpf: boolean) => {
      return haveAcc ? !withoutCpf : !haveAcc
    },
    then: (schema) =>
      schema
        .required('CPF é obrigatório')
        .min(14, 'Informe um CPF válido')
        .test('taxpayer_id_number', 'CPF/CNPJ inválido', (value) =>
          validateCPForCNPJ(value)
        ),
  }),
  birthday: Yup.string().when(['have_account'], {
    is: false,
    then: (schema) => schema.required('Data de nascimento obrigatória'),
  }),
  gender: Yup.string().when(['have_account'], {
    is: false,
    then: (schema) => schema.required('Sexo é obrigatório'),
  }),
  password: Yup.string().when(['have_account', 'with_password'], {
    is: (have_acc: boolean, with_pass: boolean) =>
      have_acc ? false : with_pass,
    then: (schema) => schema.required('Senha é obrigatória'),
  }),
  password_confirmation: Yup.string().when(
    ['have_account', 'with_password', 'no_password_confirmation'],
    {
      is: (
        have_acc: boolean,
        with_pass: boolean,
        no_pass_confirmation: boolean
      ) => (have_acc ? false : with_pass && !no_pass_confirmation),
      then: (schema) => schema.required('Confirmação de senha é obrigatória'),
    }
  ),
})

function validateCPForCNPJ(value?: string | null | undefined): boolean {
  if (!value) return true
  return cpf.isValid(value) || cnpj.isValid(value)
}
