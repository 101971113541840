import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useMerchantStore } from '../../stores/merchant';

const OrderEntrypoint: React.FC = () => {
  const params = useSlug();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: merchantData } = useMerchantCache(params.slug);

  // Do not use Stores before this line!! (useMerchantStore, useProductsStore, etc)
  // in case you switch merchants, clear the cache

  // Merchant Data
  const setMerchant = useMerchantStore((state) => state.setMerchant);

  if (merchantData?.content) {
    setMerchant(merchantData?.content);
  }

  React.useEffect(() => {
    // Garantir que o customer está logado
    const token = localStorage.getItem('access_token');
    if (!token) {
      navigate(`/login`, { state: { goBack: location.pathname } });
    }
  }, [location]);

  // Scroll to top on route change
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default OrderEntrypoint;
