import { Skeleton } from '@onbeef/components/skeleton';
import FormAddress from '../../../../components/Forms/FormAddress';
import { useSlug } from '../../../../hooks/useSlug';
import { useMerchantCache } from '../../../../queries/home';
import { useMerchantStore } from '../../../../stores/merchant';
import { useUserStore } from '../../../../stores/user';
import { Address, Toast } from '@onbeefapp/constants';
import {
  useCreateAddress,
  useUpdateAddress,
} from '../../../../queries/customer';
import { AxiosError } from 'axios';
import { Modal } from '@onbeef/components/modal';
import { CRUDAddressResponse } from '../../../../queries/customer/types';

interface ProfileAddressFormProps {
  isOpen: boolean;
  onClose: () => void;
  address?: Address;
}

export const ProfileAddressForm: React.FC<ProfileAddressFormProps> = ({
  isOpen,
  onClose,
  address,
}) => {
  const params = useSlug();
  const { isLoading: isMerchantLoading } = useMerchantCache(params.slug);

  const addresses = useUserStore((state) => state.addresses);
  const setAddresses = useUserStore((state) => state.setAddresses);

  const { mutateAsync: updateAddress, isLoading: updateLoading } =
    useUpdateAddress();
  const { mutateAsync: createAddress, isLoading: createLoading } =
    useCreateAddress();
  const isLoading = updateLoading || createLoading;

  const onSubmit = async (formData: Address) => {
    try {
      let res: CRUDAddressResponse;
      let adrs = addresses;

      const addressPayload = {
        ...formData,
        latitude: formData.latitude || null,
        longitude: formData.longitude || null,
      };

      if (address && address.id) {
        res = await updateAddress({ id: address.id, address: addressPayload });
        adrs = addresses?.map((ad) => {
          if (ad.id === address.id) {
            return res.data;
          }
          return ad;
        });
      } else {
        res = await createAddress(addressPayload);
        if (addresses) {
          adrs = [...addresses, res.data];
        }
      }
      if (res.data) {
        setAddresses(adrs);
      }
      res.message && Toast.success(res.message);
      onClose();
    } catch (error) {
      (error as AxiosError<{ error: string[] }>).response?.data?.error?.forEach(
        (msg) => {
          Toast.error(msg);
        },
      );
    }
  };

  return (
    <Modal className="w-full h-full" isOpen={isOpen} onClose={onClose}>
      <div className="w-full h-full p-4 space-y-2">
        <Skeleton
          isLoading={isMerchantLoading}
          repeatQuantity={10}
          className="w-full h-10"
        >
          <span className="text-sm font-medium">
            {address ? 'Edite seu endereço' : 'Crie um endereço'}
          </span>
          <FormAddress
            onAddressSelected={onSubmit}
            predefinedAddress={address}
            isLoading={isLoading}
          />
        </Skeleton>
      </div>
    </Modal>
  );
};
