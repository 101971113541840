import React, { useEffect, useRef, useState } from 'react'
import { useFormContext, FieldError } from 'react-hook-form'
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'
import cx from 'classnames'

export type OptionSearch = {
  value: string
  label: React.ReactNode | string
}

export interface SelectSearchProps
  extends React.SelectHTMLAttributes<HTMLDivElement> {
  placeholder: string
  options: OptionSearch[] | undefined
  name: string
  create?: boolean
  createLabel?: string
  onCreate?: (value: string) => Promise<string | undefined>
  disabled?: boolean
  onSelectOption?: (value: string) => void
}

export const SelectSearch: React.FC<SelectSearchProps> = ({
  options,
  placeholder,
  className,
  name,
  create = false,
  createLabel = 'Pressione enter para criar',
  onCreate,
  disabled,
  onSelectOption,
}) => {
  const { setValue, watch } = useFormContext()
  const [optionsList, setOptionsList] = useState<OptionSearch[] | undefined>(
    options
  )
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<OptionSearch | null>(
    null
  )
  const selectRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => {
    setOptionsList(options)
    setIsOpen(!isOpen)
  }

  const selectOption = (option: OptionSearch) => {
    setSelectedOption(option)
    setValue(name, option.value)
    setIsOpen(false)
  }

  const createOption = async (value: string) => {
    const option: OptionSearch = {
      value: value,
      label: value,
    }
    setSelectedOption(option)
    setIsOpen(false)
    if (onCreate) {
      onCreate(value)
        .then((id) => {
          if (id) {
            setValue(name, id)
          } else {
            console.log('Failed to create brand')
          }
        })
        .catch((error) => {
          console.error('Error occurred:', error)
        })
    }
  }

  const watchedValue = watch(name)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    const option = options?.find((option) => option.value === watchedValue)
    setSelectedOption(option || null)
  }, [options, watchedValue])

  useEffect(() => {
    setOptionsList(options)
  }, [options])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const removeAccents = (str: string) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  const searchOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = removeAccents(event.target.value) // Remove os acentos da entrada

    if (value) {
      const filteredOptions = options?.filter((option) => {
        if (option?.label && typeof option?.label === 'string') {
          const optionWithoutAccents = removeAccents(option?.label) // Remove os acentos do texto da opção
          return optionWithoutAccents
            .toLowerCase()
            .includes(value.toLowerCase())
        }
      })
      setOptionsList(filteredOptions)
    } else {
      setOptionsList(options)
    }
  }

  const {
    formState: { errors },
  } = useFormContext()

  const fieldError = errors[name] as FieldError | undefined
  const isFieldError = fieldError && fieldError.message

  return (
    <div ref={selectRef} className="relative inline-block w-full text-left">
      <div
        onClick={() => {
          if (disabled) {
            return
          }
          if (!isOpen) {
            toggleDropdown()
          }
        }}
        className={cx(
          className,
          `inline-flex w-full ${
            !isOpen && 'px-4 py-2'
          } border border-gray-400 h-10 rounded-md bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-gray-200`,
          disabled &&
            'cursor-not-allowed opacity-50 bg-gray-100 text-gray-500 ',
          isFieldError && 'border-red-400'
        )}
      >
        {!isOpen && (
          <button
            type="button"
            onClick={() => {
              if (disabled) {
                return
              }
              toggleDropdown()
            }}
            className={cx(
              'flex flex-row w-full justify-between text-left',
              disabled && 'cursor-not-allowed'
            )}
          >
            {selectedOption ? <>{selectedOption.label}</> : placeholder}

            <ChevronDownIcon
              className={cx(
                'h-5 transition-all ml-3 place-content-end self-center text-gray-700',
                isOpen && 'rotate-180'
              )}
              strokeWidth={2}
              id="arrow-icon-select"
            />
          </button>
        )}

        {isOpen && (
          //On press "Enter" create a new option
          <input
            type="text"
            placeholder={'Digite para pesquisar'}
            onChange={searchOptions}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && create && optionsList?.length === 0) {
                createOption(e.currentTarget.value)
              }
            }}
            className="w-full h-full text-left px-4 rounded-md bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-gray-200"
          />
        )}
      </div>
      <div
        className={cx(
          'absolute transition-all max-h-64 overflow-y-scroll ease-in-out z-10 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5',
          !isOpen ? 'hidden' : 'grid'
        )}
      >
        {optionsList?.map((option, index) => (
          <div
            key={index}
            onClick={() => {
              selectOption(option)
              if (onSelectOption) {
                onSelectOption(option.value)
              }
            }}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center cursor-pointer"
          >
            {option.label}
          </div>
        ))}
        {!create && optionsList?.length === 0 && (
          <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center cursor-pointer">
            Nenhum resultado encontrado
          </div>
        )}

        {create && optionsList?.length === 0 && (
          <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center cursor-pointer">
            {createLabel}
          </div>
        )}
      </div>
    </div>
  )
}
