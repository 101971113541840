import * as Yup from 'yup'

export function mergeSchema(...schemas: Yup.ObjectSchema<any>[]) {
  const [first, ...rest] = schemas

  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas?.concat(schema),
    first
  )

  return merged || Yup.object().shape({})
}

export function isWhatsappExtension() {
  return window.location.hostname.includes('extensao')
}
