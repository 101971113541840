import * as React from 'react';
import ListAppMethods from '../../../components/Lists/ListAppMethods';

export type OptionValue = string | undefined;
export type OptionsSetter = (paymentMethod: OptionValue) => void;

interface OptionsAppProps {
  active: boolean;
  show?: boolean;
  showPix?: boolean;
  setter: OptionsSetter;
  value: OptionValue;
}

const OptionsApp: React.FC<OptionsAppProps> = ({
  setter,
  value,
  active,
  show = true,
  showPix,
}) => {
  if (!active || !show) return null;

  return (
    <React.Fragment>
      <ListAppMethods setter={setter} selectedValue={value} showPix={showPix} />
    </React.Fragment>
  );
};

export default OptionsApp;
