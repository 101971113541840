import { diff } from '@onbeefapp/constants'
import React from 'react'

interface CountdownProps {
  targetDate?: Date
  onStop: () => void
}

export const Countdown: React.FC<CountdownProps> = ({ targetDate, onStop }) => {
  if (!targetDate) return

  const [timeRemaining, setTimeRemaining] = React.useState(
    diff(targetDate, new Date())
  )

  React.useEffect(() => {
    const interval = setInterval(() => {
      const newTimeRemaining = diff(targetDate, new Date())

      if (newTimeRemaining.seconds < 0) {
        onStop()
        clearInterval(interval)
      }

      setTimeRemaining(newTimeRemaining)
    }, 1000)

    return () => clearInterval(interval)
  }, [targetDate])

  const minutes = timeRemaining.minutes.toString().padStart(2, '0')
  const seconds = timeRemaining.seconds.toString().padStart(2, '0')

  return <>{`${minutes}:${seconds}`}</>
}
