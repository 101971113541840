// GET https://api.lojista.2tdelivery.com.br/api/customers/cards

import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import api from '../../services/api';
import { authPhone } from '../login';
import {
  CardsResponse,
  CreateCardResponse,
  PayWithCreditCard,
  PayWithCreditCardResponse,
} from './types';

export const payWithCreditCard = async (payload: PayWithCreditCard) => {
  if (!payload) return;
  const { data } = await api.post<PayWithCreditCardResponse>(
    '/customers/orders/link/pay',
    payload,
  );
  return data;
};

export const createCard = async (payload: any) => {
  if (!payload) return;
  const authPhoneData = await authPhone({
    phone: payload.phone,
    name: payload.name,
    taxpayer_id_number: payload.taxpayer_id_number,
  });
  localStorage.setItem('access_token', authPhoneData.access_token);
  const { data } = await api.post<CreateCardResponse>(
    '/customers/cards/extension',
    payload,
  );
  return data;
};

export const getHaveCards = async (visitorId: string | undefined) => {
  if (!visitorId) return;
  const { data } = await api.get<
    { haveCards?: boolean } | (CardsResponse & { haveCards?: never })
  >('/customers/cards', {
    headers: <any>{
      token: visitorId,
    },
  });
  return data;
};

// devices
async function getDevices(ctx: QueryFunctionContext) {
  const [, fingerPrint] = ctx.queryKey;
  if (!fingerPrint) return;
  const { data } = await api.post<{
    device_id: string;
    status?: string;
    message?: string;
  }>(`/customers/devices`, {
    token: fingerPrint,
  });
  return data;
}

async function resendCode(device_id: string) {
  if (!device_id) return;
  const { data } = await api.post<{
    device_id: string;
    status?: string;
    message?: string;
  }>(`/customers/cards/resend`, {
    device_id: device_id,
  });
  return data;
}
// {
//     "token": "lKtFDAGD",
//     "device_id": "4288-0fe5df1e6ce4",
//     "validation_code": "111111"
//   }
async function validateCode(ctx: QueryFunctionContext) {
  const [, fingerPrint, device_id, validation_code] = ctx.queryKey;
  if (!validation_code || validation_code.toString().length < 6) return;
  if (!fingerPrint || !device_id) return;
  const { data } = await api.post<{ message: string }>(
    `/customers/devices/validation`,
    {
      token: fingerPrint,
      device_id,
      validation_code,
    },
  );
  return data;
}

interface ValidateCentsPayload {
  cents: string;
  order_id: string;
  code: string;
}

async function validateCents({ cents, order_id, code }: ValidateCentsPayload) {
  if (!cents || !order_id) return;
  let res = await api.post(
    `/all/customers/orders/${order_id}/verification/${code}`,
    {
      cents,
    },
  );
  return res;
}

export function useCreateCard() {
  return useMutation(['useCreatecard'], createCard);
}

export function useGetHaveCards() {
  return useMutation(['getHaveCards'], getHaveCards);
}

export function usePayWithCreditCard() {
  return useMutation(['payWithCreditCard'], payWithCreditCard);
}

export function useGetDevices(fingerPrint: string | undefined) {
  return useQuery(['getDevices', fingerPrint], getDevices, {
    refetchOnWindowFocus: false,
    staleTime: 2000000,
  });
}

export function useResendCode() {
  return useMutation(['resendCode'], resendCode);
}

export function useValidateCode(
  fingerPrint: string | undefined,
  device_id: string | undefined,
  validation_code: string | undefined,
) {
  return useQuery(
    ['validateCode', fingerPrint, device_id, validation_code],
    validateCode,
    {
      refetchOnWindowFocus: false,
      staleTime: 2000000,
    },
  );
}

export function useValidateCents() {
  return useMutation(['validateCents'], validateCents);
}
