import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

const options = {
  api_host: 'https://app.posthog.com',
};
if (typeof window !== 'undefined') {
  posthog.init(
    import.meta.env.VITE_REACT_PUBLIC_POSTHOG_KEY,
    options,
    'catalogo_ph',
  );
}

export default function PHProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
