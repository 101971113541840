import { KitProduct, Product } from '@onbeefapp/constants';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import {
  calculateKitProductPrice,
  formatKitProductQuantity,
  getKitProductLabel,
  isSameKitItem,
} from '../utils/KitsUtils';
import { KitItemPayload } from '../queries/order/types';

export interface StoreKitItem extends KitItemPayload {
  price: number;
  label: string;
  formatted_quantity: string;
  item_id: string;
  additional_value: number;
}

export interface StoreKit {
  [category_id: string]: StoreKitItem[];
}

type KitsStore = {
  kits: { [product_id: string]: StoreKit };
  getKitItems: (productID: string) => StoreKit | undefined;
  deleteKitProduct: (productID: string) => void;
  _totalSelectedItems: (productID: string, categoryID: string) => number;
  _findCurrentItem: (
    productID: string,
    categoryID: string,
    item: KitProduct,
  ) => StoreKitItem | undefined;
  _updateItem: (
    productID: string,
    categoryID: string,
    item: KitProduct,
    data?: StoreKitItem,
  ) => void;
  addItem: (product: Product, categoryID: string, item: KitProduct) => void;
  removeItem: (productID: string, categoryID: string, item: KitProduct) => void;
  getItemQuantity: (
    productID: string,
    categoryID: string,
    item: KitProduct,
  ) => { quantity: number; formatted: string };
  checkRequiredKitItems: (product: Product) => string | undefined;
  checkCategoryAddDisabled: (
    product: Product,
    categoryID: string,
  ) => { disabled: boolean; count?: number; total?: number };
  clearAllKits: () => void;
};

export const useKitsStore = create(
  persist<KitsStore>(
    (set, get) => ({
      _totalSelectedItems(productID, categoryID) {
        const selectedItems = get().kits[productID]?.[categoryID];
        return selectedItems
          ? selectedItems.reduce((acc, cur) => acc + cur.quantity, 0)
          : 0;
      },
      _findCurrentItem(productID, categoryID, item) {
        return get().kits[productID]?.[categoryID]?.find((i) =>
          isSameKitItem(i, item),
        );
      },
      _updateItem(productID, categoryID, item, data) {
        const kits = get().kits;
        const newItems = [
          ...(kits[productID]?.[categoryID] || [])?.filter(
            (i) => !isSameKitItem(i, item),
          ),
        ];
        if (data) {
          newItems.push(data);
        }
        kits[productID] = {
          ...(kits[productID] || {}),
          [categoryID]: newItems,
        };
        set({ kits });
      },
      kits: {},
      getKitItems(productID) {
        return get().kits[productID];
      },
      deleteKitProduct(productID) {
        const kits = get().kits;
        delete kits[productID];
      },
      addItem(product, categoryID, item) {
        const { disabled } = get().checkCategoryAddDisabled(
          product,
          categoryID,
        );
        if (disabled) return;
        const curItem = get()._findCurrentItem(product.id, categoryID, item);
        if (!item.repeat && curItem?.quantity === 1) return;
        const newQuantity = (curItem?.quantity || 0) + 1;
        get()._updateItem(product.id, categoryID, item, {
          id: item.product.id,
          item_id: item.kit_product_id || '',
          cut_id: item.cut_product_id,
          price: calculateKitProductPrice(item),
          label: getKitProductLabel(item),
          quantity: newQuantity,
          formatted_quantity: formatKitProductQuantity(newQuantity, item),
          additional_value: item.price || 0,
        });
      },
      removeItem(productID, categoryID, item) {
        const curItem = get()._findCurrentItem(productID, categoryID, item);
        if (!curItem) return;
        const newQuantity = curItem.quantity - 1;
        if (newQuantity === 0) {
          get()._updateItem(productID, categoryID, item); // delete
        } else {
          get()._updateItem(productID, categoryID, item, {
            ...curItem,
            quantity: newQuantity,
            formatted_quantity: formatKitProductQuantity(newQuantity, item),
          });
        }
      },
      getItemQuantity(productID, categoryID, item) {
        const curItem = get()._findCurrentItem(productID, categoryID, item);
        return {
          quantity: curItem?.quantity || 0,
          formatted: curItem?.formatted_quantity || '',
        };
      },
      checkCategoryAddDisabled(product, categoryID) {
        const cats = product.items?.categories;
        if (!cats) return { disabled: false };
        const cat = cats.find((c) => c.kit_category_id === categoryID);
        if (!cat) return { disabled: false };
        const totalItems = get()._totalSelectedItems(product.id, categoryID);
        if (cat.max != null && totalItems >= cat.max) {
          return {
            disabled: true,
            count: totalItems,
            total: cat.max,
          };
        }
        return {
          disabled: false,
          count: totalItems,
          total: cat.max || undefined,
        };
      },
      checkRequiredKitItems(product) {
        const cats = product.items?.categories;
        if (!cats) return undefined;
        let total = 0;
        for (let cat of cats) {
          const totalItems = get()._totalSelectedItems(
            product.id,
            cat.kit_category_id,
          );
          total += totalItems;
          // with min = required
          if (cat.min != null && totalItems === 0) {
            return cat.kit_category_id;
          }
        }
        return total === 0 ? cats[0]?.kit_category_id : undefined;
      },
      clearAllKits() {
        set({ kits: {} });
      },
    }),
    {
      name: 'kits',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
