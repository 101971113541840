interface SearchInputProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: any;
  placeholder: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  value,
  placeholder,
}) => {
  return (
    <>
      <input
        type="text"
        onChange={onChange}
        value={value}
        className={`py-2 px-2 pl-10 rounded-md w-full text-xs`}
        placeholder={placeholder}
      />
      <div
        className={`absolute inset-y-0 flex items-center pointer-events-none left-0 pl-2`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            fillRule="evenodd"
            d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </>
  );
};

export default SearchInput;
