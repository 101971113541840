export type AddressComponent = {
  long_name: string
  short_name: string
  types: string[]
}

export default function getAddressInfo(addressComponents: AddressComponent[]): {
  streetNumber: string
  streetName: string
  neighborhood: string
  city: string
  state: string
  country: string
  zipCode: string
} {
  const result = {
    streetNumber: '',
    streetName: '',
    neighborhood: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
  }

  for (const component of addressComponents) {
    if (component.types.includes('street_number')) {
      result.streetNumber = component.long_name
    } else if (component.types.includes('route')) {
      result.streetName = component.long_name
    } else if (
      component.types.includes('sublocality_level_1') ||
      component.types.includes('sublocality')
    ) {
      result.neighborhood = component.long_name
    } else if (
      component.types.includes('locality') ||
      component.types.includes('administrative_area_level_2')
    ) {
      result.city = component.long_name
    } else if (component.types.includes('administrative_area_level_1')) {
      result.state = component.short_name
    } else if (component.types.includes('country')) {
      result.country = component.short_name
    } else if (component.types.includes('postal_code')) {
      result.zipCode = component.long_name
    }
  }

  return result
}
