import { LoyaltyRewardStatus } from '@onbeefapp/constants';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderBar from '../../components/Headers/HeaderBar';
import { useAuthStore } from '../../stores/login';
import { useMerchantStore } from '../../stores/merchant';
import { useMerchantCache } from '../../queries/home';
import { useSlug } from '../../hooks/useSlug';
import { Skeleton } from '@onbeef/components/skeleton';
import Star from '../../assets/images/star.png';
import StarList from '../../assets/images/favorite.png';
import Confetti from '../../assets/confetti.gif';
import Stars from '../../../../../packages/assets/images/stars.png';
import WallPaper from '../../../../../packages/assets/images/wallpaper.png';
import { useRefreshLoyaltyData } from '../../queries/loyalty';

export const LoyaltyDetail: React.FC = () => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);
  const merchantLoyaltyProgramme = useMerchantStore(
    (state) => state.loyalty_programme,
  );
  const user = useAuthStore((state) => state.user);
  const { mutateAsync: refreshLoyaltyData } = useRefreshLoyaltyData();
  const navigate = useNavigate();
  const updateLoyalty = useAuthStore((state) => state.updateLoyalty);

  const userLoyalty = user?.loyalty_programmes && user.loyalty_programmes[0];
  const userProgress = userLoyalty?.reward?.redemption?.percentage ?? 0;

  const handleRefreshLoyalty = async () => {
    const data = await refreshLoyaltyData();
    if (data) {
      updateLoyalty(data);
    }
  };

  useEffect(() => {
    handleRefreshLoyalty();
  }, []);

  const giftAvailable =
    userLoyalty?.gift &&
    userLoyalty.gift?.redemption?.status !== LoyaltyRewardStatus.RESCUED;

  const alreadySubed = userLoyalty != null;

  const giftSubtitle = userLoyalty?.gift?.message || '';

  React.useEffect(() => {
    if (!merchantLoyaltyProgramme || !alreadySubed) {
      navigate('/loyalty', { replace: true });
    }
  }, [merchantLoyaltyProgramme, alreadySubed]);

  return (
    alreadySubed && (
      <div className="w-full h-full">
        <div className="bg-primary z-[-9] absolute w-full h-60 opacity-50" />

        <img
          src={Stars}
          alt="Imagem do clube"
          className="w-full h-60 z-[-8] object-cover absolute top-0 grayscale"
        />
        <img
          src={WallPaper}
          alt="Imagem do clube"
          className="w-full h-60 z-[-10] object-cover absolute top-0 grayscale"
        />

        <div className="w-full h-full space-y-2">
          <HeaderBar className="p-4 !mb-16" colorButton="bg-white"></HeaderBar>
          <div className="flex flex-col space-y-4 items-center">
            <div className="absolute top-5 flex flex-col space-y-2">
              <img
                src={Star}
                alt="Imagem do clube"
                className="h-14 w-14 z-[-8] self-center"
              />
              <div className="text-lg font-medium text-contrastText">
                Clube de fidelidade
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col p-5 space-y-4 bg-white rounded-t-2xl">
          {(userLoyalty.gift?.redemption || userLoyalty.reward?.redemption) &&
            userProgress <= 100 &&
            userProgress > 0 && (
              <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-2 items-center">
                  <span className="text-lg font-medium mt-1">
                    Seja bem vindo(a) {user?.name}!
                  </span>
                </div>
                <div className="flex flex-col space-y-5">
                  {userLoyalty.gift?.redemption?.percentage <= 100 &&
                    userLoyalty.gift?.redemption?.percentage > 0 &&
                    (!userLoyalty.gift?.redemption ||
                      userLoyalty.gift?.redemption?.status !=
                        LoyaltyRewardStatus.RESCUED) && (
                      <>
                        <div className="flex flex-row space-x-2 font-semibold text-black">
                          <img
                            src={StarList}
                            className="h-4 w-4 mt-1 "
                            alt="Estrela"
                          />
                          <div>
                            {userLoyalty.gift?.redemption?.message ?? ''}
                          </div>
                        </div>
                        {userLoyalty.gift?.redemption?.status ===
                        LoyaltyRewardStatus.TO_RESCUE ? (
                          <Link
                            className="text-contrastText text-center bg-primary p-2 rounded-lg text-lg font-semibold"
                            to="/"
                          >
                            Resgatar meu prêmio
                          </Link>
                        ) : (
                          <Link
                            className="text-contrastText text-center bg-primary p-2 rounded-lg text-lg font-semibold"
                            to="/"
                          >
                            Fazer pedido
                          </Link>
                        )}
                      </>
                    )}

                  {userLoyalty.reward?.redemption?.percentage <= 100 &&
                    userLoyalty.reward?.redemption?.percentage > 0 &&
                    (!userLoyalty.reward?.redemption ||
                      userLoyalty.reward?.redemption?.status !=
                        LoyaltyRewardStatus.RESCUED) && (
                      <>
                        <div className="flex flex-row space-x-2 font-semibold text-black">
                          <img
                            src={StarList}
                            className="h-4 w-4 mt-1 "
                            alt="Estrela"
                          />
                          <div>
                            {userLoyalty.reward?.redemption?.message ?? ''}
                          </div>
                        </div>
                        {userLoyalty.reward?.redemption?.status ===
                        LoyaltyRewardStatus.TO_RESCUE ? (
                          <Link
                            className="text-contrastText text-center bg-primary p-2 rounded-lg text-lg font-semibold"
                            to="/"
                          >
                            Resgatar meu prêmio
                          </Link>
                        ) : (
                          <Link
                            className="text-contrastText text-center bg-primary p-2 rounded-lg text-lg font-semibold"
                            to="/"
                          >
                            Fazer pedido
                          </Link>
                        )}
                      </>
                    )}
                </div>
              </div>
            )}
          {(userLoyalty?.reward?.redemption?.percentage === 0 ||
            userLoyalty?.gift?.redemption?.percentage === 0) &&
            (userLoyalty.gift?.redemption || userLoyalty.reward?.redemption) &&
            (userLoyalty.gift?.redemption?.status !=
              LoyaltyRewardStatus.RESCUED ||
              userLoyalty.reward?.redemption?.status !=
                LoyaltyRewardStatus.RESCUED) && (
              <Skeleton
                isLoading={isLoading}
                repeatQuantity={3}
                className="w-full h-10 rounded-lg"
              >
                <div className="flex flex-col gap-4">
                  <div className="flex flex-row gap-2 items-center">
                    <span className="text-lg font-medium mt-1">
                      Seja bem vindo(a) {user?.name}!
                    </span>
                  </div>
                  <div className="flex flex-col space-y-5">
                    <span className="font-ligth">
                      Agora você esta fazendo parte do nosso clube de
                      fidelidade, voce terá direito ao seguintes benefícios:
                    </span>
                    <ul className="flex flex-col font-semibold space-y-2 text-black px-6">
                      {merchantLoyaltyProgramme?.messages.map((message) => (
                        <div className="flex flex-row space-x-2">
                          <img
                            src={StarList}
                            className="h-4 w-4 mt-1"
                            alt="Estrela"
                          />
                          <div>{message}</div>
                        </div>
                      ))}
                    </ul>
                  </div>
                  <span className="font-ligth">
                    Para começar a ganhar pontos faça seu primeiro pedido!
                  </span>
                  <Link
                    className="text-contrastText text-center bg-primary p-2 rounded-lg text-lg font-semibold"
                    to="/"
                  >
                    Começe a fazer pedidos
                  </Link>
                </div>
              </Skeleton>
            )}
          {userProgress === 100 && (
            <div className="flex flex-col gap-2 items-center">
              {userLoyalty.gift?.redemption?.status ===
              LoyaltyRewardStatus.RESCUED ? (
                <div className="flex flex-row gap-1 p-4 rounded-md shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                  {userLoyalty.gift?.redemption?.message ?? ''}
                </div>
              ) : (
                <>
                  <img src={Confetti} className="w-24 h-24" alt="Confetti" />
                  {/* <LoyaltyCTA withoutLink /> */}
                  {giftAvailable && (
                    <div className="flex flex-row gap-1 p-4 rounded-md shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                      {giftSubtitle}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    )
  );
};
