import logo from '../../assets/images/onbeef_logo.png';
import { IncompleteList } from '../../queries/home/types';
import { useMerchantStore } from '../../stores/merchant';
import * as React from 'react';

const Incomplete: React.FC = () => {
  const incompleteList = useMerchantStore((state) => state.incompleteList);

  return (
    <div className="p-4">
      <div className="flex space-y-1 flex-col">
        <img
          src={logo}
          alt="Logotipo"
          className="self-center"
          width={'60px'}
          loading="lazy"
        />
        <div className="text-base font-semibold text-center">
          Dados necessários incompletos
        </div>
        <div className="text-sm font-normal text-center">
          Complete os itens necessários para acessar sua loja
        </div>
        <div className="flex flex-col p-6 space-y-2 self-center">
          {incompleteList.map((item: IncompleteList) => (
            <div key={item.slug} className="flex flex-row space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className={`w-6 h-6 ${
                  item.status === true ? 'text-[#00a323]' : 'text-[#d10404]'
                }`}
              >
                {item.status === true ? (
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clipRule="evenodd"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                    clipRule="evenodd"
                  />
                )}
              </svg>

              <div className="text-sm self-center">{item.message}</div>
            </div>
          ))}
        </div>
        <div className="text-sm font-normal text-center">
          Ao cadastrar os dados, atualize esta página
        </div>
      </div>
    </div>
  );
};

export default Incomplete;
