import { Address, Gender } from '@onbeefapp/constants';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { getStorage } from '../utils/function';

type UserStore = {
  customer_id: string;
  name: string;
  phone: string;
  email: string;
  taxpayer_id_number: string;
  birthday?: string;
  gender?: Gender;
  ageGroup?: string;
  money_back: string;
  setCustomerId: (customer_id: string) => void;
  setName: (name?: string) => void;
  setEmail: (email?: string) => void;
  setPhone: (phone?: string) => void;
  setTaxPayerIdNumber: (taxpayer_id_number?: string) => void;
  setBirthday: (birthday?: string) => void;
  setGender: (gender?: Gender) => void;
  setAgeGroup: (ageGroup?: string) => void;
  setMoneyBack: (money_back: string) => void;
  address?: Address;
  addresses?: Address[];
  setAddress: (address?: Address) => void;
  setAddresses: (addresses?: Address[]) => void;
  clear: () => void;
};

export const useUserStore = create(
  persist<UserStore>(
    (set) => ({
      customer_id: '',
      name: '',
      phone: '',
      email: '',
      taxpayer_id_number: '',
      birthday: '',
      gender: '' as Gender,
      merchant_id: '',
      money_back: '',
      ageGroup: '',
      address: undefined,
      addresses: undefined,
      setCustomerId(customer_id) {
        set(() => ({ customer_id: customer_id }));
      },
      setAddress(address) {
        set(() => ({ address: address }));
      },
      setAddresses(addresses) {
        set(() => ({ addresses: addresses }));
      },
      setName(name) {
        set(() => ({ name: name }));
      },
      setEmail(email) {
        set(() => ({ email: email }));
      },
      setPhone(phone) {
        set(() => ({ phone: phone }));
      },
      setTaxPayerIdNumber(taxpayer_id_number) {
        set(() => ({ taxpayer_id_number: taxpayer_id_number }));
      },
      setBirthday(birthday) {
        set(() => ({ birthday }));
      },
      setGender(gender) {
        set(() => ({ gender }));
      },
      setMoneyBack(money_back) {
        set(() => ({ money_back: money_back }));
      },
      setAgeGroup(ageGroup) {
        set(() => ({ ageGroup }));
      },
      clear() {
        set({
          name: '',
          phone: '',
          email: '',
          taxpayer_id_number: '',
          address: undefined,
          money_back: '',
          customer_id: '',
          birthday: '',
          gender: '' as Gender,
          ageGroup: '',
        });
      },
    }),
    {
      name: 'user',
      storage: createJSONStorage(() => getStorage()),
      version: 1,
    },
  ),
);
