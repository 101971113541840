import * as React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import TrackingProvider from '../../contexts/TrackingContext';
import { useSlug } from '../../hooks/useSlug';
import {
  useMerchantCache,
  useMerchantNextSchedules,
  useMerchantSchedules,
} from '../../queries/home';
import { useBannerStore } from '../../stores/banner';
import { useCartStore } from '../../stores/cart';
import { useComplementsStore } from '../../stores/complements';
import { useMerchantStore } from '../../stores/merchant';
import { useProductsStore } from '../../stores/products';
import { colors } from '../../utils/constants';
import { useAuthStore } from '../../stores/login';
import { useRefreshLoyaltyData } from '../../queries/loyalty';
import { notifyLoyalty } from '../LoyaltyClub';
import { useGetCustomerAddresses } from '../../queries/customer';
import { useUserStore } from '../../stores/user';
import { Modal } from '@onbeef/components/modal';
import { isDateExpired } from '@onbeefapp/constants';
import { Helmet } from 'react-helmet';

const Entrypoint: React.FC = () => {
  const params = useSlug();
  const location = useLocation();
  const navigate = useNavigate();

  const [expiredSubsModal, setExpiredSubsModal] = React.useState(false);

  const user = useAuthStore((state) => state.user);
  const updateLoyalty = useAuthStore((state) => state.updateLoyalty);
  const setAddresses = useUserStore((state) => state.setAddresses);
  const setAddress = useUserStore((state) => state.setAddress);

  const { clearCart, merchantId } = useCartStore((state) => ({
    clearCart: state.clearCart,
    merchantId: state.merchantId,
  }));
  const clearMerchantIdComplements = useComplementsStore(
    (state) => state.clearMerchantIdComplements,
  );

  function clearCache() {
    const keys = ['products', 'merchant', 'banner', 'complements'];
    clearCart();
    clearMerchantIdComplements();
    keys.forEach((key) => {
      sessionStorage.removeItem(key);
    });
  }

  const handleRefreshLoyalty = async () => {
    const data = await refreshLoyaltyData();
    if (
      data &&
      merchantData?.content.loyalty_programme &&
      merchantData?.content.loyalty_programme.status === 'ACTIVE'
    ) {
      updateLoyalty(data);
      notifyLoyalty(data?.[0]);
    }
  };

  const { data: merchantData, error: merchantError } = useMerchantCache(
    params.slug,
  );
  const { data: merchantSchedules } = useMerchantSchedules(
    merchantData?.content.merchant.id,
  );
  const { data: merchantNextSchedules } = useMerchantNextSchedules(
    merchantData?.content.merchant.id,
  );
  const { mutateAsync: refreshLoyaltyData } = useRefreshLoyaltyData();
  const { mutateAsync: getCustomerAddresses } = useGetCustomerAddresses();

  React.useEffect(() => {
    const body = document.documentElement;
    const titleMerchant = document.getElementById('title-merchant');
    const iconMerchant = document.getElementById('icon-merchant');

    if (merchantData && merchantData.content.merchant.id !== merchantId) {
      clearCache();
    }

    if (body != null && merchantData) {
      const color = merchantData
        ? colors[merchantData?.content?.merchant?.color]
        : 'theme-red';

      body.classList.add(color);

      if (titleMerchant) {
        titleMerchant.innerHTML = merchantData?.content?.merchant?.name;
      }

      if (iconMerchant) {
        iconMerchant.setAttribute(
          'href',
          merchantData?.content?.merchant?.logo,
        );
      }

      const merchantAdsOptions = merchantData?.content.merchant?.options_global;
      if (merchantAdsOptions) {
        if (merchantAdsOptions.length > 0) {
          for (const i in merchantAdsOptions) {
            const adsOption = merchantAdsOptions[i];
            try {
              switch (adsOption.key) {
                case 'GTM_TAG': {
                  TagManager.initialize({
                    gtmId: `${adsOption.value}`,
                  });
                  break;
                }
                case 'ANALYTICS_TAG': {
                  ReactGA.initialize(adsOption.value);
                  break;
                }
                case 'FB_PIXEL_TAG': {
                  ReactPixel.init(`${adsOption.value}`);
                  ReactPixel.pageView();
                  break;
                }
              }
            } catch (error) {
              console.error('Error initializing AdsOptions:', error);
            }
          }
        }
      }

      if (
        isDateExpired(merchantData.content.merchant.subscription.expired_at)
      ) {
        setExpiredSubsModal(true);
      }
    }
  }, [merchantData]);

  React.useEffect(() => {
    if (user && user.loyalty_programmes && user.loyalty_programmes.length > 0) {
      handleRefreshLoyalty();
    }
  }, []);

  React.useEffect(() => {
    if (user) {
      getCustomerAddresses().then((res) => {
        setAddresses(res);
        res.forEach((addrs) => {
          if (addrs.default) setAddress(addrs);
        });
      });
    }
  }, [user]);

  // Scroll to top on route change
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Do not use Stores before this line!! (useMerchantStore, useProductsStore, etc)
  // in case you switch merchants, clear the cache

  // Merchant Data
  const setMerchant = useMerchantStore((state) => state.setMerchant);
  const setSchedules = useMerchantStore((state) => state.setSchedules);
  const setNextSchedules = useMerchantStore((state) => state.setNextSchedules);
  const setCategoriesAndProducts = useProductsStore(
    (state) => state.setCategoriesAndProducts,
  );
  const setHighlights = useProductsStore((state) => state.setHighlights);
  const setStoreLogo = useProductsStore((state) => state.setStoreLogo);
  const setImages = useBannerStore((state) => state.setImages);

  // in case the merchant is not found
  if (merchantError) {
    clearCache();
    navigate('/not-found');
  }

  React.useEffect(() => {
    if (merchantData?.content) {
      setMerchant(merchantData?.content);
      setStoreLogo(merchantData?.content?.merchant?.logo);
      setCategoriesAndProducts(merchantData?.content?.categories);
      setHighlights(merchantData?.content?.highlights);
      setImages(merchantData?.content?.advertisements);
    }
  }, [merchantData]);

  if (merchantSchedules) {
    setSchedules(merchantSchedules);
  }
  if (merchantNextSchedules) {
    setNextSchedules(merchantNextSchedules);
  }

  return (
    <TrackingProvider>
      <Helmet>
        <meta
          name="description"
          content={`${merchantData?.content.merchant.name}, ${merchantData?.content.merchant.description}`}
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content={`${merchantData?.content.merchant.name}`}
        />
        <meta
          property="og:description"
          content={merchantData?.content.merchant.description}
        />
        <meta property="og:url" content={window.location.origin} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={merchantData?.content.merchant.logo}
        />
      </Helmet>
      <Outlet />
      <Modal
        isOpen={expiredSubsModal}
        closeIcon={false}
        onClose={() => {}}
        className="w-4/5 lg:w-1/2"
      >
        <div className="p-8 flex flex-col items-center text-center gap-4">
          <span className="text-4xl">😭</span>
          <span className="text-xl font-semibold">
            Praticidade interrompida
          </span>
          <span>
            Infelizmente não estamos mais oferecendo a facilidade e a
            praticidade de comprar online por nosso site.
          </span>
          <span>
            Agradecemos sua preferência e pedimos desculpas pela inconveniência.
          </span>
          <div className="flex flex-col items-center gap-2">
            <span className="font-medium text-primary">Precisa de ajuda?</span>
            <button
              type="button"
              className="p-2 rounded-lg bg-primary text-contrastText"
              onClick={() => {
                if (!merchantData?.content.merchant.phone) return;
                const url =
                  'https://api.whatsapp.com/send?phone=+55' +
                  encodeURIComponent(
                    parseInt(
                      merchantData?.content.merchant.phone.replace(
                        /[^0-9]/g,
                        '',
                      ),
                    ),
                  ) +
                  '&text=Olá, fui comprar pelo site da Onbeef, mas vi que não está mais disponível. Podem me ajudar?';
                window.open(url, '_blank');
              }}
            >
              Entre em contato no whatsapp
            </button>
          </div>
        </div>
      </Modal>
    </TrackingProvider>
  );
};

export default Entrypoint;
