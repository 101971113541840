import { useMutation } from '@tanstack/react-query';
import api from '../../services/api';
import {
  GetLinkOrderDetailsResponse,
  PayOrderPayload,
  PayOrderResponse,
} from './types';

export const getLinkOrderDetails = async ({
  orderID,
  code,
}: {
  orderID: string;
  code: string;
}) => {
  const { data } = await api.get<GetLinkOrderDetailsResponse>(
    `/all/customers/orders/${orderID}/link/${code}`,
  );
  return data;
};

export function useGetLinkOrderDetails() {
  return useMutation(['getLinkOrderDetails'], getLinkOrderDetails);
}

export const payOrder = async ({
  orderID,
  code,
  payload,
}: {
  orderID: string;
  code: string;
  payload: PayOrderPayload;
}) => {
  const { data } = await api.post<PayOrderResponse>(
    `/all/customers/orders/${orderID}/link/${code}/pay`,
    payload,
  );
  return data;
};

export function usePayOrder() {
  return useMutation(['payOrder'], payOrder);
}
