import * as React from 'react'
import { Skeleton, SkeletonProduct } from '@onbeef/components/skeleton'
import { Complement, Item } from '../../types/types'
import ListComplement from './ListComplement'
import { Product } from '@onbeefapp/constants'

interface ComplementProps {
  complement: Complement
  product: Product
  isLoading: boolean
  handleClickGrams: (item: Item) => void
  handleAddComplement: (item: Item) => void
  handleRemoveComplement: (item: Item) => void
  getQuantityItemComplement: (item: Item) => {
    formattedQuantity: string
    quantityNumber: number
    quantityComplement: number
  }
  quantityComplements: number
  setRef: (element: HTMLDivElement | null) => void
}

const ComplementProduct: React.FC<ComplementProps> = ({
  complement,
  product,
  isLoading,
  handleClickGrams,
  handleAddComplement,
  handleRemoveComplement,
  getQuantityItemComplement,
  quantityComplements,
  setRef,
}) => {
  const [cols, setCols] = React.useState(4)

  const updateCols = () => {
    const windowWidth = window.innerWidth - 650
    const calculatedCols = Math.floor(windowWidth / 84) - 1
    setCols(calculatedCols > 3 ? calculatedCols : 3)
  }

  React.useEffect(() => {
    updateCols()
    window.addEventListener('resize', updateCols)
    return () => {
      window.removeEventListener('resize', updateCols)
    }
  }, [])

  return (
    <div>
      <div className="p-2 pl-4 pr-4" ref={setRef}>
        <Skeleton
          className="w-24 mb-1 h-7"
          isLoading={isLoading}
          borderRadius="10px"
        >
          <div className="justify-between flex-row flex">
            <article className="leading-normal text-sm font-semibold">
              {complement?.name}
            </article>
            {complement.required === 1 && (
              <article className="leading-normal text-xs font-normal self-center text-gray-500">
                Obrigatório
              </article>
            )}
          </div>
          {product.complementsImages(complement) && (
            <div className="text-sm">Selecione um dos cortes abaixo</div>
          )}
          {complement.required === 1 ? (
            <div className="text-xs">
              {(complement.quantity_min === 0 && complement.quantity_max > 0) ||
              complement.quantity_min == complement.quantity_max
                ? `Escolha ${complement.quantity_max} ${
                    complement.quantity_max > 1 ? 'opções' : 'opção'
                  }`
                : `Escolha de ${complement.quantity_min} até ${complement.quantity_max} opções`}
            </div>
          ) : (
            <div className="text-xs">
              {(complement.quantity_min === 0 && complement.quantity_max > 0) ||
              complement.quantity_min == complement.quantity_max
                ? `Escolha até ${complement.quantity_max} ${
                    complement.quantity_max > 1 ? 'opções' : 'opção'
                  }`
                : `Escolha de ${complement.quantity_min} até ${complement.quantity_max} opções`}
            </div>
          )}
        </Skeleton>
      </div>
      {product.complementsImages(complement) ? (
        <div
          style={{ gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))` }}
          className="transition-all lg:ml-2 grid duration-300 transform gap-2 p-2 justify-items-center"
        >
          <SkeletonProduct scroll={false} isLoading={isLoading}>
            {complement?.items.map((item, index) => (
              <ListComplement
                item={item}
                complement={complement}
                key={`complementItem${item.id}-${index}`}
                product={product}
                handleClickGrams={handleClickGrams}
                isLoading={isLoading}
                handleAddComplement={handleAddComplement}
                handleRemoveComplement={handleRemoveComplement}
                getQuantityItemComplement={getQuantityItemComplement}
                quantityComplements={quantityComplements}
              />
            ))}
          </SkeletonProduct>
        </div>
      ) : (
        <div>
          {complement?.items.map((item, index) => (
            <ListComplement
              item={item}
              complement={complement}
              key={`complementItem2${item.id}-${index}`}
              product={product}
              handleClickGrams={handleClickGrams}
              isLoading={isLoading}
              handleAddComplement={handleAddComplement}
              handleRemoveComplement={handleRemoveComplement}
              getQuantityItemComplement={getQuantityItemComplement}
              quantityComplements={quantityComplements}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default ComplementProduct
