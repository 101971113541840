import React from 'react';

interface SkeletonRateOrderProps {
  readonly: boolean;
}

const SkeletonRateOrder: React.FC<SkeletonRateOrderProps> = ({ readonly }) => {
  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col p-4 mt-4 w-[100%] md:w-[60%] rounded-lg align-center space-y-6">
        <div>
          <div className="bg-gray-200 animate-pulse text-gray-200 rounded-lg">
            {readonly ? 'Sua avaliação' : 'Avalie seu pedido'}
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="bg-gray-200 animate-pulse text-gray-200 rounded-lg">
            O que você achou?
          </div>
          <div className="bg-gray-200 animate-pulse text-gray-200 rounded-lg">
            Avalie de 1 a 5 estrelas para classificar.
          </div>
          <div className="bg-gray-200 animate-pulse w-full h-12 rounded-lg"></div>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="bg-gray-200 animate-pulse text-gray-200 rounded-lg">
            O que pode melhorar?
          </div>
          <div className="w-full flex flex-wrap gap-1">
            <div className="bg-gray-200 animate-pulse w-full h-16 rounded-lg"></div>
          </div>
        </div>
        <div>
          <div className="bg-gray-200 animate-pulse text-gray-200 rounded-lg">
            Deixar comentário
          </div>
          <textarea className="mt-2 w-full h-[100px] bg-gray-200 border-gray-200 animate-pulse text-base resize-none outline-none rounded-md px-2 py-1 border rounded-lg"></textarea>
        </div>
        {!readonly && (
          <div className="flex w-full items-center justify-center">
            <button className="bg-gray-200 animate-pulse text-gray-200 rounded-lg px-6 py-2 text-contrastText font-medium rounded-lg">
              Avaliar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SkeletonRateOrder;
