import { useMutation } from '@tanstack/react-query';
import api from '../../services/api';
import { RegisterCustomerPayload, RegisterCustomerResponse } from './types';

export async function registerCustomer(payload: RegisterCustomerPayload) {
  const { data } = await api.post<RegisterCustomerResponse>(
    '/customers/register',
    payload,
  );
  return data;
}

export function useRegisterCustomer() {
  return useMutation(['registerCustomer'], registerCustomer);
}
