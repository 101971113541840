import { useMerchantStore } from '../../stores/merchant';
import NoPhoto from '../../assets/images/no-photos.png';
import SkeletonProductCart from '../Skeleton/SkeletonProductCart';
import { Product } from '@onbeefapp/constants';

interface Props {
  product: Product;
}

export const CardRewardProduct: React.FC<Props> = ({ product }) => {
  const merchant = useMerchantStore((state) => state.merchant);

  return (
    <SkeletonProductCart>
      <div className="flex flex-row space-x-2 w-full py-2">
        <img
          className="object-cover w-[70px] h-[70px] rounded-lg"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = merchant?.logo || NoPhoto;
          }}
          src={product.media[0]?.file_url || merchant?.logo || NoPhoto}
          alt="Imagem do produto"
          loading="lazy"
        />
        <div className="flex flex-col space-y-1 w-full items-start">
          <div className="font-semibold text-sm text-green-600">Brinde</div>
          <div className="font-semibold text-sm">{product.name}</div>
        </div>
      </div>
    </SkeletonProductCart>
  );
};
