import {
  AddressUtils,
  formatHour2,
  getTodayWeekDay,
} from '@onbeefapp/constants';
import * as React from 'react';
import noAvatar from '../../assets/images/noAvatar.png';
import HeaderBar from '../../components/Headers/HeaderBar';
import Skeleton from '../../components/Skeleton/Skeleton';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { useMerchantStore } from '../../stores/merchant';
import { daysOfWeek } from '@onbeefapp/constants';
import { Tab } from '@onbeef/components/tab';
import cx from 'classnames';
import { Stars } from '@onbeef/components/stars';
import PixImage from '../../assets/images/pix.png';

interface Schedule {
  id: string;
  start: string | null;
  end: string | null;
  day: string;
  closed: number;
}
type DayOfWeek = keyof typeof daysOfWeek;

const reorderDaysStartingFromMonday = (schedules: Schedule[]): Schedule[] => {
  const daysOfWeekOrder: string[] = [
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
    'SUN',
  ];

  const dayToIndex: Record<string, number> = {};
  daysOfWeekOrder.forEach((day, index) => {
    dayToIndex[day] = index;
  });

  schedules.sort((a, b) => {
    const dayA = a.day;
    const dayB = b.day;
    return (dayToIndex[dayA] as number) - (dayToIndex[dayB] as number);
  });

  return schedules;
};

const Details: React.FC = () => {
  const params = useSlug();

  const [activeTab, setActiveTab] = React.useState<number>(1);

  const {
    merchant,
    delivery,
    schedulesOfWeek,
    payment_methods,
    address,
    assessments,
    activePix,
    activeCreditCard,
    activeCheckoutLink,
  } = useMerchantStore((state) => ({
    merchant: state.merchant,
    delivery: state.delivery,
    schedulesOfWeek: state.schedulesOfWeek,
    payment_methods: state.payment_methods,
    address: state.address,
    assessments: state.assessments,
    activePix: state.activePix,
    activeCreditCard: state.activeCreditCard,
    activeCheckoutLink: state.activeCheckoutLink,
  }));

  const whatsappPix = payment_methods?.find(
    (item) =>
      item.name.includes('Whatsapp') && item.pivot && item.pivot.status === 1,
  );
  const activeWhatsappPix = Boolean(whatsappPix);

  const withOnline =
    activePix || activeCreditCard || activeCheckoutLink || activeWhatsappPix;

  const { isLoading } = useMerchantCache(params.slug);
  const orderedSchedules: Schedule[] = reorderDaysStartingFromMonday(
    (schedulesOfWeek as Schedule[]) || [],
  );
  const today = getTodayWeekDay();

  return (
    <div className="p-2 flex flex-col">
      {/* HeaderBar */}
      <HeaderBar isMerchantDetailsPage backToMainPage />

      {/* Header */}
      <div className="flex flex-col pl-5 text-center">
        <div>
          <center>
            <Skeleton
              className="h-20 w-20"
              borderRadius="9999px"
              isLoading={isLoading}
            >
              <img
                className="rounded-full h-20 w-20 mb-2"
                src={merchant?.logo || noAvatar}
                alt="Logotipo"
                loading="lazy"
              />
            </Skeleton>
          </center>
        </div>
        <div className="self-center text-center">
          <Skeleton
            width="250px"
            height="15px"
            className="mb-2"
            borderRadius="10px"
            isLoading={isLoading}
          >
            <article className="text-lg font-bold">{merchant?.name}</article>
          </Skeleton>
          <Skeleton
            width="250px"
            height="15px"
            borderRadius="10px"
            isLoading={isLoading}
          >
            {assessments && assessments.avg != null && (
              <div className="flex flex-row items-center">
                <span className="text-xs font-semibold mr-2">
                  {parseFloat(assessments.avg).toFixed(1)} &bull;
                </span>
                <Stars
                  readonly
                  value={Number(parseFloat(assessments.avg).toFixed(0))}
                  size="5"
                />
                <span className="text-xs font-semibold ml-2">
                  &bull; {assessments.quantity} avaliações
                </span>
              </div>
            )}
          </Skeleton>
        </div>
      </div>

      {/* Informações da loja */}
      <Skeleton
        width="400px"
        height="300px"
        borderRadius="10px"
        isLoading={isLoading}
        className="mx-auto mt-4"
      >
        <div className="bg-white flex flex-col mx-auto w-full max-w-xl mt-4 rounded-lg px-2">
          <div className="flex mx-auto mb-4">
            <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
              <span className="text-sm lg:text-base ml-1">Sobre a loja</span>
            </Tab>
            <Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>
              <span className="text-sm lg:text-base ml-1">Horário</span>
            </Tab>
            <Tab active={activeTab === 3} onClick={() => setActiveTab(3)}>
              <span className="text-sm lg:text-base ml-1">Pagamento</span>
            </Tab>
          </div>
          <div className="px-4 pb-6">
            {activeTab === 2 ? (
              <div>
                {orderedSchedules?.map((schedule) => {
                  const dayFromSchedule: string = schedule.day;
                  const day: DayOfWeek = dayFromSchedule as DayOfWeek;
                  const dayName: string = daysOfWeek[day];
                  const active = day === today;
                  if (
                    schedule?.start === null ||
                    schedule.end === null ||
                    schedule?.closed == 1
                  ) {
                    return (
                      <div
                        key={schedule.id}
                        className={cx(
                          'flex flex-row items-center justify-between w-full mb-1',
                          active
                            ? 'text-gray-900 font-medium'
                            : 'text-gray-500',
                        )}
                      >
                        <span className="w-20">{dayName}</span>
                        <span className="w-full">
                          &bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fechado
                        </span>
                      </div>
                    );
                  }

                  return (
                    <div
                      key={schedule.id}
                      className={cx(
                        'flex flex-row items-center justify-between w-full mb-1',
                        active ? 'text-gray-900 font-medium' : 'text-gray-500',
                      )}
                    >
                      <span className={cx('w-20')}>{dayName}</span>
                      <span className="w-full">
                        &bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {formatHour2(schedule.start)} às{' '}
                        {formatHour2(schedule.end)}
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : activeTab === 3 ? (
              <div className="flex flex-col gap-2">
                {withOnline && (
                  <div className="flex flex-col gap-1 items-start">
                    <span className="font-semibold">Pagamentos Online</span>
                    {activePix && (
                      <div className="flex flex-row items-center justify-between w-full mb-1">
                        <div className="mr-2 font-semibold">
                          <img
                            src={PixImage}
                            alt={'PIX'}
                            width={'15px'}
                            loading="lazy"
                          ></img>
                        </div>
                        <div className="w-full font-normal">PIX</div>
                      </div>
                    )}
                    {activeCreditCard && (
                      <div className="flex flex-row items-center justify-between w-full mb-1">
                        <div className="mr-2 font-semibold">
                          <img
                            src={
                              'https://2tspace.sfo3.cdn.digitaloceanspaces.com/metodos_pagamento%2Fcredit-card.png'
                            }
                            alt={'Cartão de crédito'}
                            width={'15px'}
                            loading="lazy"
                          ></img>
                        </div>
                        <div className="w-full font-normal">
                          Cartão de crédito
                        </div>
                      </div>
                    )}
                    {activeCheckoutLink && (
                      <div className="flex flex-row items-center justify-between w-full mb-1">
                        <div className="mr-2 font-semibold">
                          <img
                            src={
                              'https://2tspace.sfo3.cdn.digitaloceanspaces.com/metodos_pagamento%2Fcredit-card.png'
                            }
                            alt={'Link de pagamento'}
                            width={'15px'}
                            loading="lazy"
                          ></img>
                        </div>
                        <div className="w-full font-normal">
                          Link de pagamento
                        </div>
                      </div>
                    )}
                    {activeWhatsappPix && whatsappPix && (
                      <div className="flex flex-col mb-2">
                        <div
                          key={whatsappPix.id}
                          className="flex flex-row items-center justify-between w-full mb-1"
                        >
                          <div className="mr-2 font-semibold">
                            <img
                              src={whatsappPix.image}
                              alt={whatsappPix.name}
                              width={'15px'}
                              loading="lazy"
                            ></img>
                          </div>
                          <div className="w-full font-normal">
                            {whatsappPix.name}
                          </div>
                        </div>
                        <span className="text-sm font-light text-gray-600">
                          {whatsappPix.pivot?.comments}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                <div className="flex flex-col gap-2 items-start">
                  <span className="font-semibold">Pagamentos na entrega</span>
                  {payment_methods?.map((payment) => {
                    if (
                      payment.pivot?.status !== 1 ||
                      payment.name.includes('Whatsapp')
                    ) {
                      return null;
                    }
                    return (
                      <div className="flex flex-col mb-2">
                        <div
                          key={payment.id}
                          className="flex flex-row items-center justify-between w-full mb-1"
                        >
                          <div className="mr-2 font-semibold">
                            <img
                              src={payment.image}
                              alt={payment.name}
                              width={'15px'}
                              loading="lazy"
                            ></img>
                          </div>
                          <div className="w-full font-normal">
                            {payment.name}
                          </div>
                        </div>
                        <span className="text-sm font-light text-gray-600">
                          {payment.pivot?.comments}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div>
                <p className="text-sm text-left mb-6 text-gray-600">
                  {merchant?.description}
                </p>

                <h6 className="mb-2 font-medium">Endereço</h6>
                <p className="text-sm mb-6 text-left text-gray-600">
                  {AddressUtils.getFormattedAddress(address)}
                </p>

                {delivery && delivery.time && (
                  <>
                    <h6 className="mb-2 font-medium">
                      Tempo estimado de entrega
                    </h6>
                    <p className="text-sm text-left text-gray-600">
                      {delivery.time}
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </Skeleton>
    </div>
  );
};

export default Details;
