import * as Yup from 'yup'
import { cpf, cnpj } from 'cpf-cnpj-validator'

export const billingAddressValidationSchema = Yup.object().shape({
  without: Yup.boolean(),
  street_name: Yup.string().when(['without'], {
    is: false,
    then: (schema) => schema.required('Rua é obrigatorio'),
  }),
  street_number: Yup.string().when(['without'], {
    is: false,
    then: (schema) => schema.required('Numero é obrigatorio'),
  }),
  neighborhood: Yup.string().when(['without'], {
    is: false,
    then: (schema) => schema.required('Bairro é obrigatorio'),
  }),
  city: Yup.string().when(['without'], {
    is: false,
    then: (schema) => schema.required('Cidade é obrigatorio'),
  }),
  state: Yup.string().when(['without'], {
    is: false,
    then: (schema) => schema.required('Estado é obrigatorio'),
  }),
  zip: Yup.string().when(['without'], {
    is: false,
    then: (schema) => schema.required('CEP é obrigatorio'),
  }),
  street_complement: Yup.string().nullable(),
})

function validateCPForCNPJ(value: string): boolean {
  return cpf.isValid(value) || cnpj.isValid(value)
}

export const cardValidationSchema = Yup.object().shape({
  credit_card_active: Yup.boolean(),
  without_info: Yup.boolean(),
  name: Yup.string().when(['credit_card_active'], {
    is: true,
    then: (schema) => schema.required('Nome do cartão obrigatório'),
  }),
  number: Yup.string().when(['credit_card_active'], {
    is: true,
    then: (schema) => schema.required('Número do cartão obrigatório'),
  }),
  expiration: Yup.string().when(['credit_card_active'], {
    is: true,
    then: (schema) =>
      schema
        .required('Data de expiração obrigatório')
        .matches(
          /^(0[1-9]|1[0-2])\/\d{2}$/,
          'Deve ser uma data de validação (MM/AA)'
        ),
  }),
  cvv: Yup.string().when(['credit_card_active'], {
    is: true,
    then: (schema) =>
      schema.required('CVV obrigatório').min(3, '3 dígitos no mínimo'),
  }),
  email: Yup.string()
    .email('E-mail inválido')
    .when(['without_info'], {
      is: false,
      then: (schema) => schema.required('E-mail é obrigatorio'),
    }),
  taxpayer_id_number: Yup.string().when(['without_info'], {
    is: false,
    then: (schema) =>
      schema
        .required('CPF/CNPJ é obrigatorio')
        .test('taxpayer_id_number', 'CPF/CNPJ inválido', (value) =>
          validateCPForCNPJ(value)
        ),
  }),
})

export const creditCardValidation = Yup.object().shape({
  card: cardValidationSchema.required(),
  billing_address: billingAddressValidationSchema.required(),
})
