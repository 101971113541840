import {
  LoyaltyRewardStatus,
  Toast,
  loyaltyDiscountTypeToHuman,
} from '@onbeefapp/constants';
import { LoyaltyProgramme } from '../../queries/loyalty/types';

export const notifyLoyalty = (loyalty?: LoyaltyProgramme) => {
  if (!loyalty) return;

  const check = (
    reward: LoyaltyProgramme['reward'] | LoyaltyProgramme['gift'],
  ) => {
    if (!reward) return;
    const toRescue = reward.redemption.status === LoyaltyRewardStatus.TO_RESCUE;
    if (toRescue) {
      let msg = 'Prêmio pronto para resgate: ';
      if (reward.discount) {
        msg += loyaltyDiscountTypeToHuman(reward.discount);
      } else if (reward.product) {
        msg += reward.product.name;
      }
      Toast.success(msg, { delay: 2000, autoClose: 10000 });
    }
  };

  check(loyalty.reward);
  check(loyalty.gift);
};
