import { Product } from '@onbeefapp/constants';
import { useCartStore } from '../../stores/cart';
import { useDrawerStore } from '../../stores/drawer';
import { getComments } from '../../utils/ProductUtils';
import React from 'react';

type DrawerCommentsProps = {
  product?: Product;
};

const DrawerComments: React.FC<DrawerCommentsProps> = ({ product }) => {
  const { updateCommentsCart } = useCartStore((state) => ({
    updateCommentsCart: state.updateCommentsCart,
  }));
  const { isOpen, toggleDrawer, key } = useDrawerStore((state) => ({
    isOpen: state.isOpen,
    toggleDrawer: state.toggleDrawer,
    key: state.key,
  }));

  const { comments } = getComments(product?.id!);

  let open = (isOpen && key !== 'drawerComments') || !isOpen ? false : true;

  const toggle = () => {
    toggleDrawer('drawerComments');
  };

  return (
    <React.Fragment>
      <div
        className={`z-[99999] fixed inset-0  bottom-0 right-0 p-4 shadow-lg transition-all duration-300 transform
        ${
          open
            ? 'translate-y-0 bg-black-transparent-medium'
            : 'hidden bg-transparent'
        }`}
        onClick={toggle}
      />
      <div
        className={`translate-y-0 fixed z-[99999] bottom-0 right-0 p-4 bg-white shadow-lg w-full rounded-t-2xl transition-all duration-300 transform
        ${open ? 'translate-y-0' : 'translate-y-full'}`}
      >
        <div className="p-4 text-left text-black">
          <article className="text-sm font-semibold">
            Adicione uma instrução
          </article>
          <article className="text-sm font-normal">
            Diga para nós como você quer o seu produto e se há algo que podemos
            fazer para melhor te atender.
          </article>
          <div>
            <textarea
              onChange={(e) => {
                updateCommentsCart(product?.id!, e.target.value);
              }}
              value={comments}
              className="w-full h-24 mt-4 p-2 border border-gray-300 rounded-md"
              placeholder="Ex: Tirar a gordura, cortar em bifes finos, etc."
            />
          </div>
          <button
            onClick={toggle}
            className="rounded-full w-full p-2 bg-primary text-contrastText"
          >
            Salvar
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DrawerComments;
