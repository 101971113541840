// Mask Expiry Date credit card (MM/YY)
export const maskExpiryDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{2})\d+?$/, '$1')
}

// Mask Credit Card (0000 0000 0000 0000)
export const maskCreditCard = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
}
// 000.000.000-00
export const maskCPF = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const maskGramas = (value: string) => {
  return value.replace(/\./g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

// 00.000.000/0000-00
export const maskCNPJ = (value: string) => {
  return value
    .replace(/\D/g, '') // Remove non-numeric characters
    .replace(/^(\d{2})(\d)/, '$1.$2') // Add dot after the first two digits
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Add dot after the following three digits
    .replace(/\.(\d{3})(\d)/, '.$1/$2') // Add slash after the next three digits
    .replace(/(\d{4})(\d)/, '$1-$2') // Add hyphen after the next four digits
    .replace(/(-\d{2})\d+?$/, '$1') // Keep only the last two digits with the hyphen
}

export const maskPhone = (phoneNumber: string): string => {
  const cleaned = phoneNumber.replace(/\D/g, '')
  let formatted = ''

  if (cleaned.length <= 2) {
    formatted = cleaned
  } else if (cleaned.length <= 6) {
    formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2)}`
  } else if (cleaned.length <= 10) {
    formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(
      2,
      6
    )}-${cleaned.slice(6)}`
  } else {
    formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(
      2,
      7
    )}-${cleaned.slice(7, 11)}`
  }

  return formatted
}

// 00000-000
export const maskCEP = (value: string) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
export const maskDate = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

// Aceita apenas que letras sejam digitadas
export const maskOnlyLetters = (value: string) => {
  return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '')
}

// Aceita apenas números
export const maskOnlyNumbers = (value: string) => {
  return value.replace(/\D/g, '')
}

export const percentageMask = (setter: (value: string) => void) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value
    const numericValue = rawValue.replace(/\D/g, '')
    let valueWithPercentage = ''

    if (numericValue === '' || numericValue === '0') {
      valueWithPercentage = '0%'
    } else {
      valueWithPercentage = `${parseInt(numericValue)}%`
    }
    setter(valueWithPercentage)
  }

  return handleChange
}
