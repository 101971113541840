// import Bifegrosso from '../assets/images/cortes/Bife grosso.png';
import bifefino from '../assets/images/cortes/Bife Fino - Site.png';
import bifegrosso from '../assets/images/cortes/Bife Grosso - Site.png';
import bifemedio from '../assets/images/cortes/Bife Médio - Site.png';
import Carpaccio from '../assets/images/cortes/Carpaccio.png';
import Hamburguer from '../assets/images/cortes/Hamburguer.png';
import inteira from '../assets/images/cortes/Inteira.png';
import Strogonoff2 from '../assets/images/cortes/Strogonoff2.png';
import tiras from '../assets/images/cortes/TirasOnbeef.png';
import apassarinho2 from '../assets/images/cortes/a passarinho2.png';
// import apassarinho from '../assets/images/cortes/a passarinho.png';
import bifemedio2 from '../assets/images/cortes/bife medio-2.png';
import cubos from '../assets/images/cortes/cubos.png';
import desfiado from '../assets/images/cortes/desfiado.png';
import empanado from '../assets/images/cortes/empanado.png';
import escalope from '../assets/images/cortes/escalope.png';
import espeto from '../assets/images/cortes/espeto.png';
import fatiado from '../assets/images/cortes/fatiado.png';
import feijoada from '../assets/images/cortes/feijoada.png';
import file from '../assets/images/cortes/file.png';
import grelha from '../assets/images/cortes/grelha.png';
import iscas from '../assets/images/cortes/iscas.png';
import linguica from '../assets/images/cortes/linguica.png';
import medalhao from '../assets/images/cortes/medalhao.png';
import moida1 from '../assets/images/cortes/moida1.png';
import moida2 from '../assets/images/cortes/moida2.png';
import ostrinha from '../assets/images/cortes/ostrinha.png';
import peca1 from '../assets/images/cortes/peca1.png';
import picado from '../assets/images/cortes/picado.png';
import postas from '../assets/images/cortes/postas.png';
// import strogonoff1 from '../assets/images/cortes/strogonoff1.png';
import temperado from '../assets/images/cortes/temperado.png';
import tirinhasg from '../assets/images/cortes/tirinhas.png';
import airfryer from '../assets/images/tags/air-fryer_2796533.png';
import calendario from '../assets/images/tags/calendario.png';
import churrasco from '../assets/images/tags/churrasco.png';
import cold from '../assets/images/tags/cold_106057.png';
import ensopado from '../assets/images/tags/ensopado.png';
import espetoTag from '../assets/images/tags/espeto.png';
import forno from '../assets/images/tags/forno.png';
import frigideira from '../assets/images/tags/frigideira.png';
import grellhar from '../assets/images/tags/grelhar-alimentos 1.png';
import sousvide from '../assets/images/tags/maquina-sous-vide.png';
import smoker from '../assets/images/tags/noun-smoker-4423358 1.png';
import panela from '../assets/images/tags/panela.png';
import refogado from '../assets/images/tags/refogado.png';
import wind from '../assets/images/tags/wind_4183437.png';

export const loadingSvg = (
  <svg
    className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

export const typeToHuman: Record<string, string> = {
  DELIVERY: 'Entrega',
  PENDING: 'Pendente',
  REJECTED: 'Cancelado X',
  PREPARATION: 'Em Preparo',
  OUT_FOR_DELIVERY: 'Saiu Para Entrega',
  DELIVERED: 'Entregue',
  WITHDRAWAL: 'Retirada',
};

export const typeToHumanColor = {
  DELIVERY: { color: '#0088ff', label: 'Entrega' },
  PENDING: { color: '#ff8400', label: 'Pendente' },
  REJECTED: { color: '#c7000a', label: 'Cancelado' },
  CANCELED: { color: '#c7000a', label: 'Cancelado' },
  FAILED: { color: '#c7000a', label: 'Falha' },
  WHATSAPP: { color: '#9c9c9c', label: 'Via whatsapp' },
  PREPARATION: { color: '#ff8400', label: 'Em preparo' },
  OUT_FOR_DELIVERY: { color: '#ff8400', label: 'Saiu para entrega' },
  DELIVERED: { color: '#3da628', label: 'Entregue' },
  WITHDRAWAL: { color: '#3da628', label: 'Retirada' },
};

export const pagarmeToHumanColor = {
  processing: { color: '#ff8400', label: 'Processando' },
  captured: { color: '#3da628', label: 'Pago' },
  authorized: { color: '#3da628', label: 'Autorizado' },
  paid: { color: '#3da628', label: 'Pago' },
  canceled: { color: '#c7000a', label: 'Cancelado' },
  failed: { color: '#c7000a', label: 'Falha no pagamento' },
  not_authorized: { color: '#c7000a', label: 'Não autorizado' },
  refunded: { color: '#c7000a', label: 'Estornado' },
  pending: { color: '#ff8400', label: 'Aguardando pagamento' },
  waiting_payment: { color: '#ff8400', label: 'Aguardando pagamento' },
  pending_refund: { color: '#ff8400', label: 'Aguardando estorno' },
  refused: { color: '#c7000a', label: 'Recusado' },
  chargedback: { color: '#c7000a', label: 'Chargeback' },
  analyzing: { color: '#ff8400', label: 'Em análise' },
  pending_review: { color: '#ff8400', label: 'Revisão' },
};

export const typeLinks = {
  INSTAGRAM: 'Instagram',
  WHATSAPP: 'Whatsapp',
  MAPS: 'Localização',
};

export const colors = {
  '#1b9051': 'theme-green',
  '#000000': 'theme-black',
  '#F61F1B': 'theme-red',
  '#a62707': 'theme-red-dark',
  '#173189': 'theme-blue',
  '#5E1593': 'theme-purple',
  '#F87622': 'theme-orange',
  '#FFE135': 'theme-yellow',
};

export const daysOfWeek = {
  MON: 'Segunda',
  TUE: 'Terça',
  WED: 'Quarta',
  THU: 'Quinta',
  FRI: 'Sexta',
  SAT: 'Sábado',
  SUN: 'Domingo',
};

export enum ProductType {
  KG = 'KG',
  UNIT = 'UNIT',
}

export enum PaymentMethodType {
  PIX = 'PIX',
  MONEY = 'Dinheiro',
  CHECKOUT_CREDIT_CARD = 'CHECKOUT_CREDIT_CARD',
  CHECKOUT_PIX = 'CHECKOUT_PIX',
}

export enum DeliveryType {
  DELIVERY = 'DELIVERY',
  WITHDRAWAL = 'WITHDRAWAL',
  UBER = 'UBER',
}

export enum DeliveryMerchantType {
  DELIVERY = 'DELIVERY',
  WITHDRAWAL_AND_DELIVERY = 'WITHDRAWAL_AND_DELIVERY',
  WITHDRAWAL = 'WITHDRAWAL',
}

export enum WherePaymentType {
  APP = 'APP',
  WITHDRAWALORDELIVERY = 'WITHDRAWALORDELIVERY',
}

export enum StepType {
  ADDRESS = 'ADDRESS',
  SCHEDULING = 'SCHEDULING',
  DELIVERY_INSTRUCTIONS = 'DELIVERY_INSTRUCTIONS',
  FINISHED = 'FINISHED',
  NONE = 'NONE',
}

export enum StatusType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
}

const dias: Record<string, string> = {
  MON: 'Segunda-Feira',
  TUE: 'Terça-Feira',
  WED: 'Quarta-Feira',
  THU: 'Quinta-Feira',
  FRI: 'Sexta-Feira',
  SAT: 'Sabado',
  SUN: 'Domingo',
};

export enum mobileSize {
  mobile = 768,
}

export const tagsImages: Record<string, string> = {
  Defumar: smoker,
  Airfryer: airfryer,
  Refogado: refogado,
  Frigideira: frigideira,
  Churrasco: churrasco,
  Defumado: smoker,
  'Sous vide': sousvide,
  Panela: panela,
  Grelha: grellhar,
  Espeto: espetoTag,
  Ensopado: ensopado,
  Assado: forno,
  'Dia a dia': calendario,
  Resfriado: wind,
  Congelado: cold,
};
export const cortesImages: Record<string, string> = {
  Carpaccio: Carpaccio,
  Hamburguer: Hamburguer,
  Strogonoff: Strogonoff2,
  'À passarinho': apassarinho2,
  Cubos: cubos,
  Desfiado: desfiado,
  Empanado: empanado,
  Escalope: escalope,
  Espeto: espeto,
  Espetinho: espeto,
  Feijoada: feijoada,
  Filé: file,
  Grelha: grelha,
  Isca: iscas,
  Linguiça: linguica,
  Medalhão: medalhao,
  Moído: moida2,
  'Moído 1x': moida2,
  'Moído 2x': moida2,
  Ostrinha: ostrinha,
  Peça: peca1,
  Picado: picado,
  Postas: postas,
  Temperado: temperado,
  Tirinha: tirinhasg,
  Tiras: tiras,
  Bife: bifemedio2,
  'Bife Fino': bifefino,
  'Manta Fina': bifefino,
  'Manta fina': bifefino,
  'Bife Grosso': bifegrosso,
  'Manta Grossa': bifegrosso,
  'Bife fino': bifefino,
  'Bife grosso': bifegrosso,
  'Manta grossa': bifegrosso,
  'Bife médio': bifemedio,
  'Manta média': bifemedio,
  Inteiro: inteira,
  Fatiado: fatiado,
  Moida: moida1,
  Moída: moida1,
};

export default function weekDayToHuman(day?: string) {
  if (!day) return '';
  return dias[day];
}
