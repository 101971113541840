import * as React from 'react'
import { Complement, Item } from '../../types/types'
import ListComplementImages from './ListComplementImages'
import ListComplementItem from './ListComplementItem'
import { useComplementsStore } from '../../../apps/catalogo/src/stores/complements'
import { Product } from '@onbeefapp/constants'

interface ListComplementProps {
  item: Item
  complement: Complement
  product: Product
  isLoading: boolean
  handleClickGrams: (item: Item) => void
  handleAddComplement: (item: Item) => void
  handleRemoveComplement: (item: Item) => void
  getQuantityItemComplement: (item: Item) => {
    formattedQuantity: string
    quantityNumber: number
    quantityComplement: number
  }
  quantityComplements: number
}

const ListComplement: React.FC<ListComplementProps> = ({
  item,
  complement,
  product,
  handleClickGrams,
  isLoading,
  handleAddComplement,
  handleRemoveComplement,
  getQuantityItemComplement,
  quantityComplements,
}) => {
  const statusButton = (): boolean => {
    if (quantityComplements === complement.quantity_max) {
      return false
    }

    if (
      getQuantityItemComplement(item).quantityNumber === 1 &&
      complement.no_repeat === 0
    ) {
      return false
    }

    return true
  }
  const { getSumQuantityComplementsByComplementId } = useComplementsStore(
    (state) => ({
      getSumQuantityComplementsByComplementId:
        state.getSumQuantityComplementsByComplementId,
    })
  )

  const formattedQuantity = getQuantityItemComplement(item).formattedQuantity
  const quantityNumber = getQuantityItemComplement(item).quantityNumber
  const sumQuantityComplements = getSumQuantityComplementsByComplementId(
    product.id,
    complement.id
  )
  const props = {
    handleAddComplement,
    formattedQuantity,
    handleClickGrams,
    isLoading,
    item,
    quantityNumber,
    statusButton,
    handleRemoveComplement,
    disabledButton:
      sumQuantityComplements == complement.quantity_max ? true : false,
  }

  return (
    <React.Fragment>
      {product.complementsImages(complement) && (
        <ListComplementImages {...props} />
      )}
      {!product.complementsImages(complement) && (
        <ListComplementItem {...props} />
      )}
    </React.Fragment>
  )
}

export default ListComplement
