import { useSlug } from '../../hooks/useSlug';
import { OptionValue } from '../../pages/PaymentMethods/Options/OptionsApp';
import { useMerchantCache } from '../../queries/home';
import Skeleton from '../Skeleton/Skeleton';

type CardMethodPaymentProps = {
  name: string;
  slug: string;
  image?: string;
  selectedValue: OptionValue;
  onClick?: () => void;
};

const CardMethodPayment: React.FC<CardMethodPaymentProps> = ({
  image,
  slug,
  name,
  selectedValue,
  onClick,
}) => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);

  return (
    <div
      className="flex flex-row w-full h-auto items-center border-gray-200 cursor-pointer"
      onClick={onClick}
    >
      <Skeleton isLoading={isLoading} className="w-full h-16 rounded-lg ">
        <div
          className={`flex relative flex-row space-x-2 w-full ${
            selectedValue === slug
              ? 'border-blue-400 border-2'
              : 'border-gray-300 border-[1px]'
          } rounded-lg p-2 py-2`}
        >
          <div className="flex flex-col w-full">
            <div className="flex flex-row gap-2 items-center">
              <img src={image} alt={name} className="w-6 h-6" loading="lazy" />
              <div className="font-semibold text-xs self-center">{name}</div>
            </div>
          </div>
          {selectedValue === slug && (
            <div className="absolute right-[-4px] top-[-8px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4 h-4 text-[#009c17] bg-white rounded-full"
              >
                <path
                  fillRule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
        </div>
      </Skeleton>
    </div>
  );
};

export default CardMethodPayment;
