import React from 'react';
import { useNavigate } from 'react-router-dom';
import Coupons from '../../assets/images/coupons.png';
import Diamond from '../../assets/images/diamond.png';
import { TrackingContext } from '../../contexts/TrackingContext';
import { useSlug } from '../../hooks/useSlug';
import { LoyaltyCTA } from '../../pages/LoyaltyClub';
import { useMerchantCache } from '../../queries/home';
import { useAuthStore } from '../../stores/login';
import { useMerchantStore } from '../../stores/merchant';
import { useProductsStore } from '../../stores/products';
import Skeleton from '../Skeleton/Skeleton';

const Benefits: React.FC = () => {
  const user = useAuthStore((state) => state.user);
  const navigate = useNavigate();
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);

  const handleOpenClub = () => {
    if (user && user.clubs && user.clubs.length > 0) {
      navigate(`/club/`);
    } else {
      navigate('/club/preview');
    }
  };

  const search = useProductsStore((state) => state.search);

  const { clubs, hasCoupon, loyalty_programme } = useMerchantStore((state) => ({
    clubs: state.clubs,
    hasCoupon: state.hasCoupon,
    loyalty_programme: state.loyalty_programme,
  }));

  const { track } = React.useContext(TrackingContext);

  const addCoupon = () => {
    track(`OnCustomerLeaveCart`, {});
    track(`OnCustomerAtCartViewCoupon`, {});
    navigate(`/coupon`);
  };
  const isLoyaltyEnabled = loyalty_programme != null;

  if (!hasCoupon && (!clubs || clubs.length <= 0) && !isLoyaltyEnabled)
    return <></>;

  if (search) return null;

  return (
    <div className="px-2 pb-5 relative">
      <div className="space-x-1 flex w-full font-semibold mb-2">
        <Skeleton
          width={'150px'}
          height={'20px'}
          borderRadius="10px"
          isLoading={isLoading}
        >
          <div className="prose">Meus benefícios</div>
        </Skeleton>
      </div>
      <div className="flex flex-row w-full space-x-2">
        {clubs && clubs.length > 0 && (
          <Skeleton isLoading={isLoading} className="w-full h-32 rounded-lg ">
            <div
              onClick={handleOpenClub}
              className="border-2 flex w-full flex-col  border-gray-300 rounded-md cursor-pointer hover:bg-gray-200"
            >
              <div className="place-content-center p-3">
                <center>
                  <img
                    src={Diamond}
                    className="w-[50px] self-center"
                    alt="Estrelas"
                  />
                </center>
              </div>
              <div className="text-center w-full text-[10px] p-1 font-semibold text-gray-500 border-t-2 border-gray-300">
                Participe do nosso clube
              </div>
            </div>
          </Skeleton>
        )}
        <LoyaltyCTA />
        <Skeleton isLoading={isLoading} className="w-full h-32 rounded-lg ">
          {hasCoupon && (
            <div
              onClick={addCoupon}
              className="border-2 flex w-full flex-col  border-gray-300 rounded-md cursor-pointer hover:bg-gray-200"
            >
              <div className="place-content-center p-3">
                <center>
                  <img
                    src={Coupons}
                    className="w-[50px] self-center"
                    alt="Estrelas"
                  />
                </center>
              </div>
              <div className="text-center w-full text-[10px] p-1 font-semibold text-gray-500 border-t-2 border-gray-300">
                Cupons para usar na compra
              </div>
            </div>
          )}
        </Skeleton>
      </div>
    </div>
  );
};

export default Benefits;
