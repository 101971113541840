import { estados } from '@onbeefapp/constants'

export interface Address {
  id?: string
  default?: boolean
  addressable_type?: string
  addressable_id?: string
  street_name: string
  street_number: string | null
  street_complement?: string
  street_reference?: string
  neighborhood: string
  city: string
  state: string
  country: string
  zip: string
  latitude: string | null
  longitude: string | null
  created_at?: string | Date
  updated_at?: string | Date
}

export const formatState = (abbr: string) => {
  const foundState = estados.find((state) => state.id === abbr)
  return foundState ? foundState.estado : abbr
}

export const formatAddress = (address: string) => {
  const regexPattern = new RegExp(
    `\\b(${estados.map((state) => state.id).join('|')})\\b`,
    'g'
  )
  return address.replace(regexPattern, (matched) => formatState(matched))
}

export class AddressUtils {
  static getFormattedAddress(address?: Address) {
    if (!address) {
      return ''
    }

    let formattedAddress = ''

    if (address.street_name) {
      formattedAddress += address.street_name + ', '
    }

    if (address.street_number) {
      formattedAddress += address.street_number + ', '
    }

    if (address.neighborhood) {
      formattedAddress += address.neighborhood + ', '
    }

    if (address.city) {
      formattedAddress += address.city + ', '
    }

    if (address.state) {
      formattedAddress += formatAddress(address.state.toUpperCase() + ', ')
    }

    formattedAddress += 'Brasil' + ', '

    if (address.zip) {
      formattedAddress += address.zip + ', '
    }

    if (address.street_complement) {
      formattedAddress += address.street_complement
    }

    if (address.street_reference) {
      formattedAddress += ', Ponto de referência: ' + address.street_reference
    }

    return formattedAddress
  }

  static getStreetAndNumber(address?: Address) {
    if (!address) return ''

    let formattedAddress = ''

    if (address.street_name) {
      formattedAddress += address.street_name + ', '
    }

    if (address.street_number) {
      formattedAddress += address.street_number
    }

    return formattedAddress
  }

  static getNeighborhoodAndComplement(address?: Address) {
    if (!address) return ''

    let formattedAddress = ''

    if (address.neighborhood) {
      formattedAddress += address.neighborhood
    }

    if (address.street_complement) {
      formattedAddress += ' - ' + address.street_complement
    }

    return formattedAddress
  }
}

export default AddressUtils
