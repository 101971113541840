import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useGeneratedOrderStore } from '../stores/pix';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDate, formatOrderTime, formatTime } from '@onbeefapp/constants';

export const CheckoutLinkSuccess: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const idOrder = useGeneratedOrderStore((state) => state.idOrder);
  const generatedOrder = useGeneratedOrderStore(
    (state) => state.generatedOrder,
  );

  if (!idOrder || !generatedOrder[idOrder] || !generatedOrder[idOrder]?.code) {
    navigate('/');
  }

  const paidAt = location.state?.paid_at;

  return (
    <div
      className={`flex items-center bg-white p-5 text-center w-full h-screen`}
    >
      <div className="flex flex-col w-full items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <CheckCircleIcon className="w-20 h-20 text-green-500" />
          <h1 className="my-3 text-2xl font-bold text-center text-gray-700">
            Pedido pago
          </h1>
        </div>
        <div className="flex items-center gap-2">
          <span className="font-medium">#{generatedOrder[idOrder]?.code}</span>
          {paidAt && (
            <span className="text-sm">{formatOrderTime(paidAt, true)}</span>
          )}
        </div>
        <button
          type="button"
          className="w-full rounded-lg bg-primary p-2 text-contrastText mt-4 flex justify-center"
          onClick={() => navigate(`/orders/${idOrder}`)}
        >
          Acompanhar pedido
        </button>
      </div>
    </div>
  );
};
