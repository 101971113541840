import React from 'react';
import { useCartStore } from '../stores/cart';
import { useValidateCoupon } from '../queries/coupon';
import { useOrderDetails } from '../stores/order-details';

const useCalculateCouponDiscount = (subtotal: number) => {
  const coupon = useCartStore((state) => state.coupon);
  const setCoupon = useCartStore((state) => state.setCoupon);
  const merchantId = useCartStore((state) => state.merchantId);

  const { deliveryType } = useOrderDetails();

  const [result, setResult] = React.useState<number | undefined>(
    coupon?.amount,
  );

  const {
    mutate: validateCoupon,
    data,
    error,
    isLoading,
  } = useValidateCoupon();

  React.useEffect(() => {
    setResult(coupon?.amount);
  }, [coupon]);

  React.useEffect(() => {
    if (!coupon || !merchantId) return;

    validateCoupon({
      code: coupon.code,
      merchant_id: merchantId,
      order_amount: subtotal,
      type_delivery: deliveryType,
    });
  }, [subtotal]);

  React.useEffect(() => {
    if (data) {
      setCoupon(data);
      setResult(data.amount);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      setCoupon(undefined);
      setResult(0);
    }
  }, [error]);

  return { result, isLoading };
};

export default useCalculateCouponDiscount;
