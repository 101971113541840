import Countdown from '@onbeef/components/countdown'
import { Tooltip } from '@onbeef/components/tooltip'
import {
  AddIcon,
  CrownIcon,
  DefaultCategoryType,
  KitType,
  MinusIcon,
  ProductType,
  currencyFormat,
  promotionExpired,
  promotionStarted,
} from '@onbeefapp/constants'
import React from 'react'
import { Product } from '@onbeefapp/constants'
import { calculateDisplayClubPrice } from '../../../apps/catalogo/src/utils/ProductUtils'
import cx from 'classnames'
import { FireIcon } from '@heroicons/react/24/solid'

export type ProductCartType = Product & {
  quantity: number
  type: ProductType.UNIT | ProductType.KG | ProductType.KG
  hasComplements?: boolean
  totalAmount?: number // totalAmount é para visualização no carrinho
  calculatedValue?: number
  comments?: string
  hasClubDiscount: boolean
}

interface Props {
  product: Product
  scroll?: boolean
  handleCheckAddProduct: () => void
  handleCheckRemoveProduct: () => void
  handleClickProduct: () => void
  quantityNumber: number
  merchant: any
  formattedQuantity: string
  imageCardClassName?: string
}

const ProductCard: React.FC<Props> = ({
  product,
  scroll = true,
  handleCheckAddProduct,
  handleCheckRemoveProduct,
  handleClickProduct,
  merchant,
  quantityNumber,
  formattedQuantity,
  imageCardClassName,
}) => {
  // Atributo scroll = true serve para quando o componente for usado em um scroll horizontal. Caso seja false, ele diminui o componente para melhor visualização

  /* Verificando se a promoção já começou */

  const isPromotionStarted =
    product.promotion && promotionStarted(product.promotion)

  /* Verificando se a promoção do produto está expirada */

  const [isExpired, setIsExpired] = React.useState<boolean>(
    isPromotionStarted && promotionExpired(product.promotion)
  )

  /* Verificando se o estoque do produto esta ESGOTADO */

  const isSoldOut =
    product?.promotion?.available != null && product?.promotion?.available === 0

  /* Verificando se o produto possui estoque */

  const notSoldOut =
    product?.promotion?.available != null && product?.promotion?.available > 0

  const limitText = 30
  const truncatedText = product?.name.slice(0, limitText)

  const isOpenKit = product.kit_type === KitType.OPEN

  const showValuePerKilo = product.show_value_per_kilo

  if (
    product.promotion &&
    product.promotion.start_at != null &&
    !promotionStarted(product.promotion) &&
    product.is_highlight === 1
  ) {
    return null
  }

  const textButton = () => {
    if (quantityNumber > 0) {
      return <AddIcon />
    } else {
      if (product?.promotion && product?.promotion?.expired_at && !isExpired) {
        return (
          <Countdown
            productID={product.id}
            targetDate={new Date(product.promotion.expired_at)}
            setIsExpired={setIsExpired}
          />
        )
      }
      return 'Adicionar'
    }
  }

  const isAssado = product?.tags?.find(
    (tag) => tag.type === 'PRODUCT_STORAGE' && tag.slug === 'assado'
  )

  const isKitDaily =
    product?.default_category_tag === DefaultCategoryType.EVERYDAY_KIT

  const isKitClosed = product?.kit_type === KitType.CLOSED

  const withPromotionDiscount =
    product.promotion && product.promotion.fixed_amount
      ? product.original_value !== product.promotion.fixed_amount
      : false

  const isBestSeller = product.promotion
    ? !isExpired && !isSoldOut && product.is_best_seller
    : product.is_best_seller

  const bestSellerTarget =
    product.type === ProductType.KIT ? 'Kit' : isAssado ? 'Assado' : 'O'

  let stockTopOffset = 0
  if (isBestSeller) stockTopOffset += 1.25
  if (withPromotionDiscount) stockTopOffset += 2.25

  return (
    <div
      id={`product-${product.id}`}
      className="flex shrink-0 flex-col gap-y-2 mb-3 relative"
    >
      {isBestSeller && (
        <div className="flex items-center gap-1 absolute -top-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap bg-orange-200 text-red-700 text-xs font-semibold px-2 py-1 rounded-lg shadow-md z-30">
          <FireIcon className="h-4 w-4 stroke-2" />
          {bestSellerTarget} mais pedido
        </div>
      )}
      <div
        className={cx(
          `${
            !scroll
              ? 'flex flex-col w-[6.5rem] relative cursor-pointer my-1 min-h-[12rem]'
              : 'snap-start shrink-0 flex flex-col h-60 max-w-36 relative cursor-pointer min-h-[13.5rem]'
          }`,
          imageCardClassName
        )}
      >
        {/* Imagem do produto */}
        <img
          onClick={() => {
            handleClickProduct()
          }}
          src={product.media[0]?.file_url}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = merchant?.logo
          }}
          className={cx(
            `object-cover ${
              !scroll
                ? `h-[6.5rem] w-[6.5rem] rounded-md border-none ${
                    isSoldOut ? 'saturate-[0]' : ''
                  }`
                : `h-36 rounded-md border-none ${
                    isSoldOut ? 'saturate-[0]' : ''
                  }`
            }`,
            imageCardClassName
          )}
          loading="lazy"
        />
        {product.promotion && !isExpired && withPromotionDiscount && (
          <div
            style={isBestSeller ? { top: '1.25rem' } : {}}
            className={`bg-[#fad2d2] text-sm font-bold text-[#700000] items-center text-center absolute top-0 right-0 m-1 p-1 rounded-xl py-[7px] transition-all z-30 w-9`}
          >
            -{product.discount_percent}%
          </div>
        )}

        <div
          onClick={() => {
            handleClickProduct()
          }}
        >
          {/* Quantidade de unidades no estoque quando for promoção */}
          {notSoldOut && !isExpired && (
            <div
              style={{ top: `${stockTopOffset}rem` }}
              className="absolute right-0 p-1 m-1 bg-primary rounded-xl w-9 text-contrastText"
            >
              <article className="leading-normal text-[8px] font-bold uppercase text-center place-content-center place-items-center">
                {product?.promotion?.available} unid.
              </article>
            </div>
          )}

          {/* Quando for esgotado, mostrar faixa */}
          {isSoldOut && (
            <div
              className={cx(
                `absolute ${
                  !scroll ? `h-[6.5rem] w-[6.5rem]` : `max-w-36 h-36`
                } right-0 bg-black-transparent-light rounded-xl top-0 w-0 text-contrastText`,
                imageCardClassName
              )}
            >
              <article className="leading-normal rounded-md m-1 bg-[#ad0505] text-xs mt-[42%] font-bold uppercase text-center place-content-center place-items-center">
                Esgotado
              </article>
            </div>
          )}

          {/* Indicação para quando o produto for patrocinado */}
          {/* <div className="absolute top-0 left-0 p-1 rounded-br-sm bg-black-transparent rounded-tl-md w-15">
            <article className="leading-normal text-[8px] text-white font-bold text-center place-content-center place-items-center">
              Patrocinado
            </article>
          </div> */}

          {/* Preços */}
          <div className={`flex flex-col mt-1 mb-1 `}>
            {/* Preço clube */}
            {product.clubs && product.clubs.length > 0 && product.clubPrice && (
              <Tooltip
                text={product.clubName || ''}
                containerClassName="flex flex-row space-x-1 items-center"
              >
                <article className="prose prose-sm text-sm font-semibold">
                  {currencyFormat(
                    calculateDisplayClubPrice(product, !showValuePerKilo)
                  )}
                </article>
                <CrownIcon className="h-4" />
              </Tooltip>
            )}

            <div
              className={`flex ${
                !scroll ? 'flex-row space-x-1' : 'flex-row space-x-2'
              }`}
            >
              {/* Preço atual do produto */}
              {!isOpenKit && (
                <article className="prose prose-sm text-sm font-medium">
                  {isKitClosed && 'A partir de '}
                  {currencyFormat(product.displayPrice)}
                </article>
              )}

              {/* Opção para quando houver promoção */}
              {product.promotion && !isExpired && withPromotionDiscount && (
                <article className="line-through prose prose-sm text-xs md:text-xs text-gray-500">
                  {currencyFormat(
                    product.price_per_kilo &&
                      product.estimated_weight &&
                      !showValuePerKilo
                      ? (product.original_value! * product.estimated_weight) /
                          1000
                      : product.original_value
                  )}
                </article>
              )}
            </div>

            {/* Preço por pessoa dos kits */}
            {product.type === ProductType.KIT &&
              product.show_price_per_person &&
              product.number_of_people && (
                <article className="prose prose-sm text-xs font-bold flex flex-row items-center gap-1">
                  {currencyFormat(
                    Number(
                      (
                        product.displayPrice! / product.number_of_people
                      ).toFixed(2)
                    )
                  )}{' '}
                  <i className="font-bold">
                    {' '}
                    {isKitDaily ? 'por refeição' : 'por pessoa'}
                  </i>
                </article>
              )}
          </div>

          {/* Produto */}
          <div className="flex flex-col space-y-2">
            {/* Nome do produto */}
            <article
              className={`${!scroll ? 'text-xs' : 'text-sm'} font-normal`}
            >
              {truncatedText} {product.name.length > limitText && '...'}
              {!scroll && <br />}
              {/* Se for por kg, mostrar a quantidade de gramas do produto */}
              {product.product_type === ProductType.KG && showValuePerKilo
                ? `(KG)`
                : product.product_type === ProductType.KG
                  ? `${product.estimated_weight}g`
                  : ''}
            </article>
          </div>
        </div>
      </div>
      {/* Botão de adicionar */}
      <div
        className={`flex flex-row ${
          isSoldOut ? 'bg-[#EBEBE4]' : 'bg-primary'
        } px-2 font-semibold text-sm w-full rounded-full p-1 place-content-center text-contrastText`}
      >
        {/* Diminuir a quantidade do produto */}
        {(product.product_type === ProductType.UNIT ||
          product.product_type === ProductType.KIT) &&
          quantityNumber > 0 && (
            <button
              disabled={isSoldOut}
              className="rounded-full bg-primary"
              onClick={handleCheckRemoveProduct}
            >
              <MinusIcon />
            </button>
          )}

        {quantityNumber > 0 && (
          <div
            className="self-center w-full font-bold cursor-pointer leading-normal text-center"
            onClick={() => {
              handleCheckAddProduct()
            }}
          >
            {formattedQuantity}
          </div>
        )}

        {/* Botão para adicionar mais produto */}
        {(product.product_type === ProductType.UNIT ||
          product.product_type === ProductType.KIT ||
          quantityNumber <= 0) && (
          <button
            id={`add-product-${product.id}`}
            disabled={isSoldOut}
            className={`rounded-full bg-primary ${
              quantityNumber <= 0 && 'w-full h-full'
            }`}
            onClick={handleCheckAddProduct}
          >
            <center className={`${isSoldOut && 'bg-[#EBEBE4]'}`}>
              {textButton()}
            </center>
          </button>
        )}
      </div>
    </div>
  )
}

ProductCard.displayName = 'ProductCard'
export type ProductCardProps = Props
export default ProductCard
