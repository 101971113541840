import React from 'react';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';

interface SkeletonProps {
  children: React.ReactNode;
  scroll?: boolean;
}

const SkeletonProductCart: React.FC<SkeletonProps> = ({ children }) => {
  const params = useSlug();

  const { isLoading } = useMerchantCache(params.slug);

  // Atributo scroll = true serve para quando o componente for usado em um scroll horizontal. Caso seja false, ele diminui o componente para melhor visualização

  if (!isLoading) return <>{children}</>;

  return (
    <div className="flex flex-row w-full h-auto items-center last:border-0 mt-1 border-b-[1px] border-gray-200 pb-1">
      <div className="flex flex-row w-full space-x-2">
        <div
          className={`bg-gray-200 w-[70px] h-[70px] rounded-lg animate-pulse`}
        />
        <div className="flex flex-col w-full space-y-1 ">
          <div className="w-48 h-5 bg-gray-200 rounded-lg animate-pulse" />
          <div className="flex flex-row justify-between">
            <div className="w-24 h-5 bg-gray-200 rounded-lg animate-pulse" />
            <div className="flex flex-row items-center justify-between space-x-2">
              <div className="bg-gray-200 rounded-full animate-pulse w-7 h-7" />
              <div className="w-16 h-5 bg-gray-200 rounded-lg animate-pulse" />
              <div className="bg-gray-200 rounded-full animate-pulse w-7 h-7" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonProductCart;
