import React from 'react'
import Lottie from 'react-lottie'
import animationData from '../assets/404.json'
import { LeftArrow } from '@onbeefapp/constants/src/icons'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export const NotFound: React.FC = () => {
  return (
    <div className="relative">
      <div className="z-0 cursor-normal relative sm:hidden">
        <Lottie options={defaultOptions} height={300} width={300} />
      </div>
      <div className="z-0 cursor-normal relative hidden sm:flex">
        <Lottie options={defaultOptions} height={500} width={500} />
      </div>
      <div className="flex flex-col items-center justify-center -mt-12 sm:-mt-20 z-50 relative">
        <h1 className="text-2xl mb-4">Você se perdeu?</h1>
        <a
          href="/"
          className="flex text-white font-bold items-center py-1 rounded-md bg-red-500 px-6"
        >
          <LeftArrow className="mr-1 h-3 w-3" /> Voltar
        </a>
      </div>
    </div>
  )
}

NotFound.displayName = 'NotFound'
export default NotFound
