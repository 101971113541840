import { CreateOrderResponse } from '../queries/order/types';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { PayOrderResponse } from '../queries/checkout-link/types';
import { getStorage } from '../utils/function';

type GeneratedOrderStore = {
  timeGenerated: number;
  generatedOrder: Record<string, CreateOrderResponse | PayOrderResponse | null>;
  idOrder: string;
  usedLoyalty: boolean;
  setGeneratedOrder: (
    order_id: string,
    order: CreateOrderResponse | PayOrderResponse,
    used_loyalty: boolean,
  ) => void;
};

export const useGeneratedOrderStore = create(
  persist<GeneratedOrderStore>(
    (set) => ({
      generatedOrder: {},
      timeGenerated: 0,
      idOrder: '',
      usedLoyalty: false,
      setGeneratedOrder: (order_id, order, used_loyalty) => {
        set((state) => ({
          idOrder: order_id,
          usedLoyalty: used_loyalty,
          generatedOrder: {
            ...state.generatedOrder,
            [order_id]: order,
          },
          timeGenerated: Date.now(),
        }));
      },
    }),
    {
      name: 'generatedOrder',
      storage: createJSONStorage(() => getStorage()),
    },
  ),
);
