import * as React from 'react';

type HideMobileProps = {
  children: React.ReactNode;
  className?: string;
};

export default function HideMobile({ children, className }: HideMobileProps) {
  return <div className={`lg:hidden ${className}`}>{children}</div>;
}
