import { FormProvider, useForm } from 'react-hook-form';
import HeaderBar from '../../components/Headers/HeaderBar';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthStore } from '../../stores/login';
import {
  CreditCardForm,
  ICreditCardForm,
  creditCardValidation,
} from '@onbeef/components/creditCardForm';
import {
  LoadingIcon,
  Toast,
  maskPhone,
  mergeSchema,
} from '@onbeefapp/constants';
import { useUserStore } from '../../stores/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { useChangeClubPaymentMethod } from '../../queries/club';
import { AxiosError } from 'axios';
import { TextInput } from '@onbeef/components/input';
import React from 'react';
import * as Yup from 'yup';

type ChangeClubPaymentMethodForm = ICreditCardForm & {
  taxpayer_id_number: string;
  phone: string;
  email: string;
};

const validationSchema = mergeSchema(
  creditCardValidation,
  Yup.object().shape({
    email: Yup.string()
      .email('Deve ser um email válido')
      .required('Email obrigatório'),
    phone: Yup.string().required('Telefone é obrigatório'),
    taxpayer_id_number: Yup.string().required('CPF é obrigatório'),
  }),
);

export const ChangeClubPaymentMethod: React.FC = () => {
  const { club_id } = useParams();
  const navigate = useNavigate();

  const user = useAuthStore((state) => state.user);
  const updateUserClubSubscriptions = useAuthStore(
    (state) => state.updateUserClubSubscriptions,
  );

  const address = useUserStore((state) => state.address);

  const { mutateAsync: changeClubPaymentMethod, isLoading } =
    useChangeClubPaymentMethod();

  const methods = useForm<ChangeClubPaymentMethodForm>({
    resolver: yupResolver(validationSchema),
  });

  const subscription = user?.clubs.find((sub) => sub.club_id === club_id);

  const onSubmit = async (formData: ChangeClubPaymentMethodForm) => {
    if (!subscription) return;

    try {
      const res = await changeClubPaymentMethod({
        subs_id: subscription.id,
        card: {
          cvv: formData.card.cvv,
          expiry_month: formData.card.expiration.split('/')[0] || '',
          expiry_year: formData.card.expiration.split('/')[1] || '',
          holder_name: formData.card.name,
          number: formData.card.number,
          holder: {
            billing_address: {
              address: formData.billing_address.street_name,
              zip: formData.billing_address.zip,
              number: formData.billing_address.street_number,
              city: formData.billing_address.city,
              state: formData.billing_address.state,
              country: 'BR',
              complement: formData.billing_address.street_complement || '',
            },
            name: formData.card.name,
            email: formData.email,
            phone: formData.phone,
            taxpayer_id_number: formData.taxpayer_id_number,
          },
        },
      });
      res.message && Toast.success(res.message);
      updateUserClubSubscriptions(res.data);
      navigate('/club');
    } catch (error) {
      (error as AxiosError<{ error: string[] }>).response?.data?.error?.forEach(
        (msg) => {
          Toast.error(msg);
        },
      );
    }
  };

  React.useEffect(() => {
    if (user) {
      user.email && methods.setValue('email', user.email);
      user.taxpayer_id_number &&
        methods.setValue('taxpayer_id_number', user.taxpayer_id_number);
      user.phone && methods.setValue('phone', maskPhone(user.phone));
    }
  }, [user]);

  return (
    <FormProvider {...methods}>
      <div className="w-full h-full p-4 space-y-2">
        <HeaderBar>
          <span className="text-sm font-normal text-center w-full">
            Trocar forma de pagamento
          </span>
        </HeaderBar>

        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="w-full flex flex-col space-y-4"
        >
          <span>
            Trocar cartão de assinatura do{' '}
            <span className="font-medium">{subscription?.club_name}</span>
          </span>

          <TextInput
            name="email"
            customLabel="Email"
            disabled={user?.email != null}
          />
          <TextInput
            name="taxpayer_id_number"
            customLabel="CPF"
            disabled={user?.taxpayer_id_number != null}
          />
          <TextInput
            name="phone"
            customLabel="Celular"
            inputMode="numeric"
            autoComplete="phone"
            maxLength={15}
            placeholder="(00) 00000-0000"
            disabled={user?.phone != null}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              methods.setValue('phone', maskPhone(e.target.value));
              methods.trigger('phone');
            }}
          />

          <CreditCardForm active address={address} />

          <button
            type="submit"
            disabled={isLoading}
            className="p-2 bg-primary text-contrastText rounded-lg flex items-center justify-center"
          >
            {isLoading ? <LoadingIcon /> : 'Trocar'}
          </button>
        </form>
      </div>
    </FormProvider>
  );
};
