import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import { TrackingContext } from '../../contexts/TrackingContext';
import { useProductsStore } from '../../stores/products';
import { isMobileDevice } from '../../utils/function';
import Category from '@onbeef/components/category';

interface FilterCategoryProps {
  nameSelected?: string;
  isProduct?: boolean;
}

const FilterCategory: React.FC<FilterCategoryProps> = ({
  nameSelected,
  isProduct,
}) => {
  const { categories } = useProductsStore((state) => ({
    categories: state.categories,
  }));
  const categorySelect = categories.find(
    (category) => category.slug === nameSelected,
  );
  const navigate = useNavigate();
  const { track } = React.useContext(TrackingContext);
  const [isMobile, setIsMobile] = React.useState(isMobileDevice());

  const activeCategory = (category_id: string) => {
    const targetElement = document.getElementById(`category-${category_id}`);
    const container = document.getElementById(`scrollFilterCategory`);
    if (!targetElement || !container) return;

    if (isMobile) {
      const elementoRetangulo = targetElement.getBoundingClientRect();
      const containerRetangulo = container.getBoundingClientRect();

      const deslocamentoScroll =
        elementoRetangulo.left +
        container.scrollLeft -
        containerRetangulo.left -
        10;

      container.scrollTo({
        left: deslocamentoScroll,
        behavior: 'smooth',
      });
    } else {
      const elementoRetangulo = targetElement.getBoundingClientRect();
      const containerRetangulo = container.getBoundingClientRect();
      const deslocamentoScroll =
        elementoRetangulo.top +
        container.scrollTop -
        containerRetangulo.top -
        50;

      container.scrollTo({
        top: deslocamentoScroll,
        behavior: 'smooth',
      });
    }
  };

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (categorySelect) {
      activeCategory(categorySelect.id);
    }
  }, [categorySelect]);

  return (
    <React.Fragment>
      {/* Categorias para selecionar e levar o usuário a aba da categoria */}
      <div className="flex transition-all overflow-y-scroll h-full duration-300 ease-in-out">
        {/* Map das categorias */}
        <div
          className="space-x-2 lg:space-x-0 transition-all duration-300 ease-in-out flex lg:p-2 lg:gap-2  lg:flex-col snap-x snap-mandatory mt-2 mb-2 w-full mx:auto overflow-x-scroll overflow-y-scroll"
          id="scrollFilterCategory"
        >
          {categories.map((category, index) => {
            if (nameSelected || isProduct) {
              return (
                <Category
                  key={category.id}
                  id={category.id}
                  name={category.name}
                  active={nameSelected === category.slug}
                  index={index}
                  onClick={() => {
                    if (nameSelected) {
                      track('OnCustomerClickQuickCategoryPill', {
                        ...category,
                      });
                      activeCategory(category.id);
                      navigate(`/category/${category.slug}`);
                    } else if (isProduct) {
                      navigate(`/`);
                    }
                  }}
                />
              );
            }

            return (
              <Link
                to={category.id}
                spy={true}
                onSetActive={() => {
                  activeCategory(category.id);
                }}
                onClick={() => {
                  track('OnCustomerClickQuickCategoryPill', {
                    ...category,
                  });
                }}
                smooth={true}
                key={`category-${category.id}-${index}`}
                id={`category-${category.id}`}
                offset={-105}
                duration={500}
                activeClass="bg-primary !text-contrastText"
                className={`snap-start cursor-pointer shrink-0 mb-1 lg:mb-0 flex flex-col min-w-[20%] lg:h-10 lg:justify-center md:min-w-[10%] pl-2 pr-2 lg:border-none transition-all border border-current relative font-semibold
                text-primary lg:rounded-md rounded-2xl lg:w-full lg:text-sm text-sm lg:text-left text-center `}
              >
                {category.name}
              </Link>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilterCategory;
