import { useCartStore } from '../stores/cart';
import { useMerchantStore } from '../stores/merchant';
import React, { useState, useEffect } from 'react';

type LoadingScreenProps = {
  children?: React.ReactNode;
  active: boolean;
};

const LoadingScreen: React.FC<LoadingScreenProps> = ({ active = true }) => {
  const isCheckoutLink = useCartStore((state) => state.isCheckoutLink);
  const { merchant } = useMerchantStore((state) => ({
    merchant: state.merchant,
  }));

  const [index, setIndex] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);

  const texts = isCheckoutLink
    ? [
        'Processando pagamento',
        'Verificando dados',
        'Aguardando confirmação',
        'Finalizando a transação',
      ]
    : [
        'Enviando seu pedido',
        'Confirmando dados do pedido',
        'Organizando os itens do seu pedido',
        'Confirmando detalhes do pedido',
      ];

  useEffect(() => {
    if (!active) return;
    const timeoutID = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        setIndex((i) => (i + 1) % texts.length);
        setFadeOut(false);
      }, 500);
    }, 2000);
    return () => clearTimeout(timeoutID);
  }, [active, index]);

  const loadingText = texts[index];

  return (
    <React.Fragment>
      <div
        className={`bg-white  fixed inset-0  bottom-0 right-0 transition-all duration-300 transform ${
          active ? 'translate-y-0 z-[99999]' : 'translate-y-full z-[-10]'
        }`}
      >
        <div className="flex flex-col items-center justify-center w-full h-full">
          <img
            className="w-40 h-40 rounded-lg"
            src={merchant?.logo}
            alt="Logotipo"
            loading="lazy"
          />
          <svg
            className="w-20 h-20 text-primary animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v2a6 6 0 00-6 6z"
            ></path>
          </svg>
          <div className="text-lg font-medium relative">
            <div
              className={`transition-opacity duration-500 ${
                fadeOut ? 'opacity-10' : 'opacity-100'
              }`}
            >
              {loadingText}...
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoadingScreen;
