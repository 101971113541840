import { Tag } from '@onbeefapp/constants'
import React from 'react'

type TagOptionsProps = {
  text?: string
  selectedTags?: Record<string, Tag[] | undefined>
  setSelectedTag?: (tag: Tag, remove?: boolean) => void
  tags?: Tag[]
  black?: boolean
}

export const TagOptions = ({
  text,
  selectedTags,
  tags,
  black = false,
}: TagOptionsProps) => {
  if (!tags || tags.length === 0) {
    return null
  }
  return (
    <div className="flex flex-col space-y-2">
      {text && <article className="text-sm font-normal">{text}</article>}
      <div className="flex flex-wrap gap-2">
        {tags.map((t, index) => {
          return (
            <div
              key={`tagsProductEventType${index}`}
              className={`shrink-0 p-1 min-w-[95px]md:min-w-[10%] pl-2 pr-2 transition-all border border-${
                black ? 'black' : 'current'
              } font-semibold ${
                selectedTags &&
                selectedTags[t.type]?.find((tag) => tag.name === t.name)
                  ? `bg-${black ? 'white' : 'primary'} text-${
                      black ? 'black' : 'contrastText'
                    }`
                  : `bg-transparent text-${black ? 'black' : 'primary'}`
              } rounded-2xl text-xs text-center font-medium  flex flex-row gap-2 justify-center items-center`}
            >
              {t.image && (
                <img
                  className={`${black ? 'text-black' : 'text-contrastText'}`}
                  src={t.image}
                  width={'20px'}
                />
              )}
              {t.name}
            </div>
          )
        })}
      </div>
    </div>
  )
}
