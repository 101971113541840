import ListDeliveryMethods from '../../../components/Lists/ListDeliveryMethods';
import ListDeliveryPriceAndCoupon from '../../../components/Lists/ListDeliveryPriceAndCoupon';
import * as React from 'react';
import { useCartStore } from '../../../stores/cart';

interface OptionsDeliveryProps {
  active: boolean;
  show?: boolean;
  setExtensionCustomCouponCode: (code: string | undefined) => void;
  setExtensionCustomDeliveryFee: (fee: number | string | undefined) => void;
}

const OptionsDelivery: React.FC<OptionsDeliveryProps> = ({
  active,
  show = true,
  setExtensionCustomCouponCode,
  setExtensionCustomDeliveryFee,
}) => {
  const isExtension = useCartStore((state) => state.isExtension);

  if ((!active || !show) && !isExtension) return null;

  return (
    <React.Fragment>
      <ListDeliveryMethods />
      <ListDeliveryPriceAndCoupon
        setExtensionCustomCouponCode={setExtensionCustomCouponCode}
        setExtensionCustomDeliveryFee={setExtensionCustomDeliveryFee}
      />
    </React.Fragment>
  );
};

export default OptionsDelivery;
