import { Product } from '@onbeefapp/constants';
import { useMutation } from '@tanstack/react-query';
import api from '../../services/api';
import { useMerchantStore } from '../../stores/merchant';
import { SearchProductsParams, SearchProductsResponse } from './types';

async function getCategoryProducts({
  category_id,
  merchant_id,
}: {
  merchant_id?: string;
  category_id?: string;
}) {
  if (!merchant_id || !category_id) return;
  const { data } = await api.get<Product[]>(
    `/all/merchants/${merchant_id}/categories/${category_id}`,
  );
  return data;
}

export async function searchProducts(params: SearchProductsParams) {
  const merchant_id = useMerchantStore.getState().merchant?.id;
  if (!merchant_id) return;
  const { data } = await api.post<SearchProductsResponse>(
    `/all/merchants/${merchant_id}/products`,
    params,
  );
  return data;
}

export async function getProductBySlug(slug: string) {
  const merchant_id = useMerchantStore.getState().merchant?.id;
  if (!merchant_id) return;
  const { data } = await api.get<Product>(
    `/all/merchants/${merchant_id}/products/${slug}`,
  );
  return data;
}

export function useGetCategoryProducts() {
  return useMutation(['merchantCache'], getCategoryProducts);
}
