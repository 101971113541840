import React from 'react';

const SkeletonOrder: React.FC = () => {
  return (
    <div className="flex flex-col p-4 w-[100%] md:w-[60%] align-center space-y-4">
      <div className="flex space-x-6 items-center">
        <div className="bg-gray-200 animate-pulse w-20 h-20 rounded-full" />
        <div className="flex flex-col h-full flex-grow space-y-4">
          <div className="bg-gray-200 animate-pulse w-full h-6 rounded-lg"></div>
          <div className="bg-gray-200 animate-pulse w-full h-6 rounded-lg"></div>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="bg-gray-200 animate-pulse w-full h-6 rounded-lg"></div>
        <div className="flex items-center justify-center">
          <div className="bg-gray-200 animate-pulse w-full h-12 rounded-lg"></div>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <button className="w-full p-2 border border-gray-200 bg-gray-200 text-gray-200 animate-pulse rounded-lg font-medium">
          Ver cardápio
        </button>
        <button className="w-full p-2 border border-gray-200 bg-gray-200 text-gray-200 rounded-lg text-contrastText font-medium">
          Chamar loja no Whatsapp
        </button>
      </div>
      <div className="flex flex-col space-y-2 divide-y divide-gray-300">
        <div className="bg-gray-200 animate-pulse w-full h-20 flex flex-col rounded-lg"></div>
        <div className="bg-gray-200 animate-pulse w-full h-20 pt-2 rounded-lg"></div>
        <div className="flex flex-col pt-2">
          <div className="bg-gray-200 animate-pulse h-6 w-full flex items-center rounded-lg"></div>
        </div>
        <div className="flex flex-col pt-2">
          <div className="bg-gray-200 animate-pulse h-6 w-full flex items-center rounded-lg"></div>
        </div>
        <div className="pt-2">
          <div className="bg-gray-200 animate-pulse h-6 w-full flex items-center rounded-lg"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonOrder;
