import {
  Category,
  KitType,
  Product,
  ProductClub,
  ProductType,
  currencyFormat,
  isNowBetweenDates,
  isNowBetweenTimespan,
  promotionExpired,
  promotionStarted,
} from '@onbeefapp/constants';
import { ProductCartType, useCartStore } from '../stores/cart';
import { useComplementsStore } from '../stores/complements';
import { useAuthStore } from '../stores/login';
import { isUserClubActive } from './ClubUtils';
import { calculatedCutsTotalPrice } from './CutsUtils';
import { useMerchantStore } from '../stores/merchant';
import {
  calculateSelectedKitItemsAditionalValue,
  calculateSelectedKitItemsTotalValue,
} from './KitsUtils';
import { useKitsStore } from '../stores/kits';

// funcao para trazer
export function getQuantity(
  productsCarrinho: Record<string, ProductCartType>,
  product: Product,
): {
  formatted: string;
  number: number;
  quantity: number;
  totalAmountReturn: number;
} {
  if (!product) {
    return { formatted: '0', number: 0, quantity: 0, totalAmountReturn: 0 };
  }

  const totalAmount = productsCarrinho[product.id]?.totalAmount;
  const quantidade = productsCarrinho[product.id]?.quantity ?? 0;
  const getSumTotalQuantityComplements =
    useComplementsStore.getState().getSumTotalQuantityComplements;

  const getSumTotalQuantityComplementsValue = getSumTotalQuantityComplements(
    product.id,
  );

  if (quantidade > 0 || getSumTotalQuantityComplementsValue > 0) {
    if (
      productsCarrinho[product.id]?.type == ProductType.KG &&
      product.complements.length > 0 &&
      getSumTotalQuantityComplementsValue > 0 &&
      product.hasCuts === false
    ) {
      if (totalAmount) {
        return {
          formatted: `${totalAmount}g`,
          number: totalAmount,
          quantity: quantidade,
          totalAmountReturn: totalAmount,
        };
      }
    } else if (productsCarrinho[product.id]?.type == ProductType.KG) {
      if (totalAmount) {
        return {
          formatted: `${totalAmount}g`,
          number: totalAmount,
          quantity: quantidade,
          totalAmountReturn: totalAmount,
        };
      } else {
        return { formatted: '0', number: 0, quantity: 0, totalAmountReturn: 0 };
      }
    } else if (
      productsCarrinho[product.id]?.type == ProductType.UNIT &&
      getSumTotalQuantityComplementsValue > 0
    ) {
      return {
        formatted: `${quantidade}`,
        number: quantidade,
        quantity: quantidade,
        totalAmountReturn: quantidade,
      };
    } else if (
      productsCarrinho[product.id]?.type == ProductType.UNIT ||
      productsCarrinho[product.id]?.type == ProductType.KIT
    ) {
      return {
        formatted: `${quantidade}`,
        number: quantidade,
        quantity: quantidade,
        totalAmountReturn: quantidade,
      };
    }
  } else {
    if (product.product_type == ProductType.KG) {
      return { formatted: '0g', number: 0, quantity: 0, totalAmountReturn: 0 };
    } else {
      return { formatted: '0', number: 0, quantity: 0, totalAmountReturn: 0 };
    }
  }

  return { formatted: '1', number: 1, quantity: 1, totalAmountReturn: 1 };
}

export function getTotalPriceProduct(product_id: string) {
  const products = useCartStore.getState().products;

  const product = products[product_id];

  if (!product) return currencyFormat(0);

  const getSumTotalQuantityComplements =
    useComplementsStore.getState().getSumTotalQuantityComplements;

  let total = 0;

  if (product.product_type === ProductType.KG) {
    total = (product.totalAmount! * product.price_per_kilo!) / 1000;
  } else {
    total = product.price_per_unit! * product.quantity;
  }

  total += getSumTotalQuantityComplements(product.id);

  return currencyFormat(total);
}

export function getComments(product_id: string) {
  const products = useCartStore.getState().products;

  const product = products[product_id];

  return { comments: product?.comments ?? '' };
}

export function calculatedValue(
  product: Product,
  quantity: number,
  type: string,
  withoutComplements = false,
) {
  let total = 0;
  const getCountComplements =
    useComplementsStore.getState().getCountComplementsCuts;

  const getSumTotalQuantityComplements =
    useComplementsStore.getState().getSumTotalQuantityComplements;

  const complementsCut =
    typeof product.complementsCut == 'string'
      ? JSON.parse(product.complementsCut)
      : product.complementsCut;

  const oldCut = complementsCut?.length > 0;
  const hasCut = oldCut || product?.cuts?.length > 0;

  let price = 0;

  const isPromotion =
    product.promotion !== null &&
    promotionStarted(product.promotion) &&
    !promotionExpired(product.promotion);

  if (isPromotion && product?.promotion?.fixed_amount) {
    price = product?.promotion?.fixed_amount;
  } else if (
    (type === ProductType.UNIT || type === ProductType.KIT) &&
    product?.price_per_unit
  ) {
    price = product?.price_per_unit;
  } else if (type === ProductType.KG && product?.price_per_kilo) {
    price = product?.price_per_kilo;
  }

  if (type === ProductType.KIT && product.kit_type === KitType.OPEN) {
    const items = useKitsStore.getState().getKitItems(product.id);
    total = quantity * calculateSelectedKitItemsTotalValue(items);
  } else if (type === ProductType.UNIT || type === ProductType.KIT) {
    total = quantity === 0 ? price ?? 0 : quantity * (price ?? 0);
    if (type === ProductType.KIT && product.kit_type === KitType.CLOSED) {
      const items = useKitsStore.getState().getKitItems(product.id);
      total += calculateSelectedKitItemsAditionalValue(items);
    }
  } else if (hasCut && type === ProductType.KG && product.add_grams) {
    if (oldCut) {
      total =
        ((product.add_grams * getCountComplements(product.id)) / 1000) * price;
    } else {
      total = calculatedCutsTotalPrice(product.id, price);
    }
  } else if (!hasCut && type === ProductType.KG && product.estimated_weight) {
    total = ((product.estimated_weight * quantity) / 1000) * price;
  }

  if (withoutComplements) {
    return total;
  }

  return total + getSumTotalQuantityComplements(product.id);
}

export function getClub(
  product?: Product,
): { price: number; name: string; subs_id?: string } | undefined {
  if (!product?.clubs || product?.clubs.length === 0) return undefined;
  const userClubs = useAuthStore.getState().user?.clubs;

  const getPrice = (p?: ProductClub) => {
    const club = p?.pivot;
    return club
      ? club.price_per_kilo && product.estimated_weight
        ? club.price_per_kilo / 1000
        : club.price_per_unit
      : undefined;
  };
  const clubs = product.clubs.sort(
    (a, b) => (getPrice(a) ?? 0) - (getPrice(b) ?? 0),
  );
  const club =
    clubs.find((c) => userClubs?.some((uc) => uc.club_id === c.id)) || clubs[0];
  const userClub = userClubs?.find((uc) => uc.club_id === club?.id);
  const clubPrice = getPrice(club);

  return club && clubPrice && club.status !== 'INACTIVE'
    ? { price: clubPrice, name: club.name, subs_id: userClub?.id }
    : undefined;
}

export function hasClubDiscount(product?: Product): boolean {
  const userClubs = useAuthStore.getState().user?.clubs;
  if (!product || !userClubs) return false;

  return product.clubs?.some(
    (club) =>
      userClubs?.some((uc) => {
        if (club.status === 'INACTIVE') return false;
        return uc.club_id === club.id && isUserClubActive(uc);
      }),
  );
}

export function calculateDisplayClubPrice(
  product?: Product,
  use_estimated_weight = false,
): number {
  if (!product?.clubPrice) return 0;
  if (product.price_per_kilo) {
    return use_estimated_weight && product.estimated_weight
      ? product.clubPrice * product.estimated_weight
      : product.clubPrice * 1000;
  } else {
    return product.clubPrice;
  }
}

function isAvailable(
  dateStart: string | null,
  dateEnd: string | null,
  timeStart: string | null,
  timeEnd: string | null,
  days: string[] | null,
): boolean {
  const currentDate = new Date();
  const currentDay = currentDate
    .toLocaleDateString('en-US', { weekday: 'short' })
    .toUpperCase();

  if (dateStart && dateEnd && !isNowBetweenDates(dateStart, dateEnd)) {
    return false;
  }

  if (timeStart && timeEnd && !isNowBetweenTimespan(timeStart, timeEnd)) {
    return false;
  }

  if (days && !days.includes(currentDay)) {
    return false;
  }

  return true;
}

export function isProductAvailable(product?: Product): boolean {
  if (!product) return false;

  const pAvailable =
    product.specific_days === 1
      ? isAvailable(
          product.date_start,
          product.date_end,
          product.time_start,
          product.time_end,
          product.days,
        )
      : true;

  const cAvailable = product.categories.some((cat) => {
    const category = useMerchantStore
      .getState()
      .categories?.find((c) => c.id === cat.id);
    if (!category) return true;
    return category.specific_days === 1
      ? isAvailable(
          category.date_start,
          category.date_end,
          category.time_start,
          category.time_end,
          category.days,
        )
      : true;
  });

  return pAvailable && cAvailable;
}

export function isCategoryAvailable(category: Category): boolean {
  if (!category) return false;

  if (category.specific_days === 1) {
    return isAvailable(
      category.date_start,
      category.date_end,
      category.time_start,
      category.time_end,
      category.days,
    );
  }

  return true;
}
