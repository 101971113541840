import { useMerchantStore } from '../../stores/merchant';
import React from 'react';

interface ModalProps {
  title: string;
  children: React.ReactNode;
  showConfirmButton?: boolean;
  showCancelButton?: boolean;
  textConfirmButton?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  canClose?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  title,
  children,
  showConfirmButton = true,
  showCancelButton = false,
  textConfirmButton = 'Confirmar',
  onConfirm,
  onCancel,
  canClose = true,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const handleOverlayClick = () => {
    if (canClose) {
      setIsOpen(!isOpen);
    }
  };

  const { nextHour, schedules } = useMerchantStore((state) => ({
    nextHour: state.nextHour,
    schedules: state.schedules,
  }));

  const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[99999] flex items-center justify-center">
      <div
        className="fixed inset-0 bg-gray-900 opacity-50"
        onClick={handleOverlayClick}
      />
      <div
        className={`bg-white w-10/12 rounded-lg shadow-lg relative p-3`}
        onClick={handleModalClick}
      >
        <div className={`flex rounded-lg`}>
          <p className="text-xl font-semibold">{title}</p>
        </div>
        <div className={`flex my-2 text-sm`}>{children}</div>
        {schedules?.open === false &&
          schedules?.receive_orders_when_closed === true && (
            <>
              <div className={`flex my-2 text-sm`}>Horário que irá abrir:</div>
              <div className={`flex my-2 text-lg font-semibold`}>
                {nextHour?.day} - {nextHour?.start}
              </div>
            </>
          )}
        <div className={`flex my-2 text-sm`}>Deseja continuar o pedido?</div>

        <div className="flex flex-col w-full space-y-1 pt-2">
          {showConfirmButton && (
            <button
              className="px-4 py-2 w-full bg-primary text-contrastText text-sm font-semibold rounded-lg"
              onClick={() => {
                if (onConfirm) {
                  onConfirm();
                } else {
                  handleOverlayClick();
                }
              }}
            >
              {textConfirmButton}
            </button>
          )}
          {showCancelButton && (
            <button
              className="px-4 py-2 w-full text-sm font-semibold text-white bg-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-300"
              onClick={() => {
                if (onCancel) {
                  onCancel();
                } else {
                  handleOverlayClick();
                }
              }}
            >
              Cancelar
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
