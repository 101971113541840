import { FormCustomerCheckoutInfo } from '../../components/Forms/FormCustomerCheckoutInfo';
import ListCartProduct from '../../components/Lists/ListCartProduct';
import * as React from 'react';
import { useCartStore } from '../../stores/cart';

interface WithdrawProps {
  active: boolean;
  setExtensionCustomCouponCode: (code: string | undefined) => void;
  setExtensionCustomDeliveryFee: (fee: number | string | undefined) => void;
}

const Withdraw: React.FC<WithdrawProps> = ({
  active,
  setExtensionCustomCouponCode,
  setExtensionCustomDeliveryFee,
}) => {
  const { products, isExtension } = useCartStore((state) => ({
    products: state.products,
    isExtension: state.isExtension,
  }));
  if (!active) return null;

  return (
    <div className="mt-[110px]">
      {isExtension && (
        <FormCustomerCheckoutInfo
          setExtensionCustomCouponCode={setExtensionCustomCouponCode}
          setExtensionCustomDeliveryFee={setExtensionCustomDeliveryFee}
        />
      )}

      <div
        style={
          isExtension
            ? { marginBottom: '20rem' }
            : {
                marginBottom:
                  Object.values(products).length >= 5 ? '4rem' : '8.5rem',
              }
        }
        className="flex flex-col items-start justify-start p-2 mt-2 "
      >
        {/* Lista de produtos */}
        <ListCartProduct />
      </div>
    </div>
  );
};

export default Withdraw;
