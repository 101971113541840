import React from 'react';

interface SkeletonProps {
  children: React.ReactNode;
  isLoading: boolean;
}

const SkeletonAllSchedulings: React.FC<SkeletonProps> = ({
  children,
  isLoading,
}) => {
  return isLoading ? (
    <>
      <div className="flex flex-row overflow-x-auto w-full space-x-2">
        {Array.from({ length: 4 }, (_, i) => i).map((item) => (
          <div
            key={`skeletonScheduling${item}`}
            className="bg-gray-200 flex-shrink-0 animate-pulse w-[28%] h-[80px] rounded-lg"
          ></div>
        ))}
      </div>
      <div className="flex flex-col space-y-2">
        {Array.from({ length: 5 }, (_, i) => i).map((item) => (
          <div
            key={`skeletonScheduling2${item}`}
            className="bg-gray-200 animate-pulse w-[100%] h-[100px]"
          ></div>
        ))}
      </div>
    </>
  ) : (
    children
  );
};

export default SkeletonAllSchedulings;
