import { ClubSubscriptionStatus, Toast } from '@onbeefapp/constants';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../stores/login';

interface CreateClubSubscriptionProps {
  clubID: string;
  isFree: boolean;
}

export const CreateClubSubscription: React.FC<CreateClubSubscriptionProps> = ({
  clubID,
  isFree,
}) => {
  const navigate = useNavigate();

  const user = useAuthStore((state) => state.user);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const handleSubscription = () => {
    const haveAccount = isAuthenticated && user?.taxpayer_id_number != null;
    const alreadyHaveSubscription =
      haveAccount &&
      !!user.clubs.find(
        (club) => club.status === ClubSubscriptionStatus.ACTIVE,
      );

    if (alreadyHaveSubscription) {
      Toast.error(
        'Você já possui uma assinatura ativa, para assinar esse clube primeiro cancele a atual.',
      );
    } else {
      navigate(`/club/${clubID}`, { state: { is_free: isFree } });
    }
  };

  return (
    <button
      type="button"
      onClick={handleSubscription}
      className="bg-primary font-semibold text-sm p-2 text-contrastText w-full rounded-md"
    >
      Clique para assinar
    </button>
  );
};
