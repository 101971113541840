import { currencyFormat } from '@onbeefapp/constants';
import { useMerchantStore } from '../../../stores/merchant';
import { useMyOrdersStore } from '../../../stores/my-orders';
import { getFreightCostType } from '../../../utils/function';

const OrderDetailPaymentValues = () => {
  const selectedOrder = useMyOrdersStore((state) => state.selectedOrder);
  const merchant = useMerchantStore((state) => state.merchant);

  if (!selectedOrder) {
    return <></>;
  }

  const fee_amount_calc = selectedOrder.fee_amount
    ? parseFloat(String(selectedOrder.fee_amount))
    : 0;
  const freigth_amount_calc = selectedOrder.freight_amount
    ? parseFloat(String(selectedOrder.freight_amount))
    : 0;

  const clubDiscount = selectedOrder?.club_discount_amount
    ? selectedOrder.club_discount_amount
    : 0;

  const getTaxaEntrega = () => {
    return getFreightCostType(
      selectedOrder.type_cost_freight,
      selectedOrder.freight_amount,
      merchant?.delivery_details,
    );
  };

  const loyaltyIsDiscount =
    selectedOrder.loyalty_programme?.[0]?.reward?.product === null;

  return (
    <div className="flex flex-col space-y text-gray-600">
      {selectedOrder?.margin_amount != null && (
        <div className="flex justify-between">
          <div>
            <p className="font-semibold">Taxa de variação</p>
          </div>
          <div>
            <p className="font-semibold">
              {currencyFormat(selectedOrder?.margin_amount)}
            </p>
          </div>
        </div>
      )}
      <div className="flex justify-between">
        <div>
          <p className="font-semibold">Subtotal</p>
        </div>
        <div>
          <p className="font-semibold">
            {currencyFormat(selectedOrder?.subtotal_amount)}
          </p>
        </div>
      </div>
      {selectedOrder?.code_coupon && (
        <div className="flex justify-between">
          <div>
            <p className="font-semibold">Cupom {selectedOrder?.code_coupon}</p>
          </div>
          <div>
            <p className="font-semibold text-green-500">
              -
              {currencyFormat(
                parseFloat(String(selectedOrder.coupon_discount_amount)),
              )}
            </p>
          </div>
        </div>
      )}
      {selectedOrder?.loyalty_programme_discount_amount != null && (
        <div className="flex justify-between">
          <div>
            <p className="font-semibold">
              {selectedOrder.loyalty_programme?.[0]?.name}
            </p>
          </div>
          <div>
            {loyaltyIsDiscount ? (
              <p className="font-semibold text-green-500">
                -
                {currencyFormat(
                  parseFloat(
                    String(selectedOrder.loyalty_programme_discount_amount),
                  ),
                )}
              </p>
            ) : (
              <p className="font-medium text-green-600">
                Brinde{' '}
                {selectedOrder.loyalty_programme?.[0]?.reward?.product?.name}
              </p>
            )}
          </div>
        </div>
      )}
      {clubDiscount > 0 && (
        <div className="flex justify-between">
          <div>
            <p className="font-semibold">Desconto do Clube</p>
          </div>
          <div>
            <p className="font-semibold text-green-500">
              -{currencyFormat(clubDiscount)}
            </p>
          </div>
        </div>
      )}
      {selectedOrder.crm_bonus_discount_amount && (
        <div className="flex justify-between">
          <div>
            <p className="font-semibold">Cashback</p>
          </div>
          <div>
            <p className="font-semibold text-green-500">
              -{currencyFormat(selectedOrder.crm_bonus_discount_amount)}
            </p>
          </div>
        </div>
      )}
      <div className="flex justify-between">
        <div>
          <p className="font-semibold">Taxa de entrega</p>
        </div>
        <div>
          <p className="font-semibold">{getTaxaEntrega()}</p>
        </div>
      </div>

      {selectedOrder?.fee_amount && (
        <div className="flex justify-between">
          <div>
            <p className="font-semibold">Taxa de serviço</p>
          </div>
          <div>
            <p className="font-semibold">
              {currencyFormat(parseFloat(String(selectedOrder.fee_amount)))}
            </p>
          </div>
        </div>
      )}

      <div className="flex justify-between">
        <div>
          <p className="font-semibold">Total</p>
        </div>
        <div>
          <p className="font-semibold">
            {currencyFormat(selectedOrder?.balance_amount)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailPaymentValues;
