import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Order } from '../../queries/my-orders/types';
import CardOrder from '../Cards/CardOrder';
import SkeletonOrder from '../Skeleton/SkeletonOrder';

interface ListOrdersProps {
  orders: Order[];
  nextPage: () => void;
  hasMore: boolean;
  dataLength: number;
}

const ListOrders: React.FC<ListOrdersProps> = ({
  orders,
  nextPage,
  hasMore,
  dataLength,
}) => {
  return (
    <div className="w-[100%] md:w-[90%]">
      <InfiniteScroll
        className="flex flex-col space-y-4"
        dataLength={dataLength}
        next={nextPage}
        hasMore={hasMore}
        loader={<SkeletonOrder />}
      >
        {orders.length === 0 && (
          <span className="font-normal text-sm">Ainda sem nenhum pedido.</span>
        )}
        {orders.map((order, index) => (
          <CardOrder key={`cardOrder${order.code}${index}`} order={order} />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default ListOrders;
