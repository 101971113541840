import React from 'react';
import { useMerchantStore } from '../stores/merchant';
import { useCRMGetBonusDetails } from '../queries/crm';
import { useUserStore } from '../stores/user';
import { AxiosError } from 'axios';
import { debounce, Toast } from '@onbeefapp/constants';
import { OrderDetailsContext } from '../contexts/OrderDetailsContext';
import { Skeleton } from '@onbeef/components/skeleton';
import { CRMBonusDetailResponse } from '../queries/crm/types';

export const CRMProgressBar: React.FC = () => {
  const { merchant, crm_bonus } = useMerchantStore((state) => ({
    merchant: state.merchant,
    crm_bonus: state.crm_bonus,
  }));
  const name = useUserStore((state) => state.name);
  const phone = useUserStore((state) => state.phone);
  const { subtotalWithDiscount } = React.useContext(OrderDetailsContext);

  const [crmBonusDetail, setCrmBonusDetail] =
    React.useState<CRMBonusDetailResponse>();

  const withCRM = crm_bonus?.status === true;

  const { mutateAsync: getCRMBonus } = useCRMGetBonusDetails();

  const fetchCRMBonus = debounce(
    async (name: string, phone: string, balance_amount: number) => {
      try {
        if (!merchant || !phone || !name) return;
        const res = await getCRMBonus({
          id: merchant.id,
          name,
          phone,
          balance_amount,
        });
        setCrmBonusDetail(res);
      } catch (error) {
        const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
          ?.error;
        msgs?.forEach((msg) => Toast.error(msg));
      }
    },
    500,
  );

  React.useEffect(() => {
    if (withCRM) {
      fetchCRMBonus(name, phone, subtotalWithDiscount);
    }
  }, [withCRM, name, phone, subtotalWithDiscount]);

  const percentageProgress = `${
    crmBonusDetail?.content?.progress_bar?.percentage || 0
  }%`;

  return (
    withCRM &&
    crmBonusDetail &&
    crmBonusDetail.has_bonus && (
      <div className="px-2 py-4 gap-2 flex flex-col items-center">
        <span
          className="text-center text-gray-600 font-medium inline"
          dangerouslySetInnerHTML={{
            __html: crmBonusDetail?.content?.progress_bar?.body || '',
          }}
        ></span>
        <div className="flex flex-row items-start justify-start w-full h-2 bg-gray-200 rounded-full">
          <div
            className={`h-full bg-primary rounded-full transition-all duration-500`}
            style={{ width: percentageProgress }}
          ></div>
        </div>
      </div>
    )
  );
};
