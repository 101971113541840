import { Address } from '@onbeefapp/constants';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../../services/api';
import {
  CRUDAddressResponse,
  ChangePasswordPayload,
  Customers,
  CustomersDetails,
  UpdateCustomerPayload,
  UpdateCustomerResponse,
} from './types';

async function getCustomers() {
  const { data } = await api.get<Customers[]>(`/merchants/customers`);
  return data;
}

export async function getCustomer(id: string) {
  const { data } = await api.get<CustomersDetails>(`/merchants/customer/${id}`);
  return data;
}

export async function updateCustomer(payload: UpdateCustomerPayload) {
  const { data } = await api.put<UpdateCustomerResponse>('/customers', payload);
  return data;
}

export async function changePassword(payload: ChangePasswordPayload) {
  const { data } = await api.post<{ message: string }>(
    '/customers/change-password',
    payload,
  );
  return data;
}

export async function getCustomerAddresses() {
  const { data } = await api.get<Address[]>('/customers/address');
  return data;
}

export async function createAddress(address: Address) {
  const { data } = await api.post<CRUDAddressResponse>(
    '/customers/address',
    address,
  );
  return data;
}

export async function updateDefaultAddress(address_id: string) {
  const { data } = await api.put<CRUDAddressResponse>(
    `/customers/address/${address_id}/default`,
  );
  return data;
}

export async function updateAddress({
  id,
  address,
}: {
  id: string;
  address: Address;
}) {
  const { data } = await api.put<CRUDAddressResponse>(
    '/customers/address/' + id,
    address,
  );
  return data;
}

export async function deleteAddress(id: string) {
  const { data } = await api.delete<{ message: string }>(
    '/customers/address/' + id,
  );
  return data;
}

export function useCustomer() {
  return useQuery(['customers'], getCustomers);
}

export function useUpdateCustomer() {
  return useMutation(['updateCustomer'], updateCustomer);
}

export function useChangePassword() {
  return useMutation(['changePassword'], changePassword);
}

export function useGetCustomerAddresses() {
  return useMutation(['getCustomerAddresses'], getCustomerAddresses);
}

export function useCreateAddress() {
  return useMutation(['createAddress'], createAddress);
}

export function useUpdateDefaultAddress() {
  return useMutation(['updateDefaultAddress'], updateDefaultAddress);
}

export function useUpdateAddress() {
  return useMutation(['updateAddress'], updateAddress);
}

export function useDeleteAddress() {
  return useMutation(['deleteAddress'], deleteAddress);
}
