import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import api from '../../services/api';
import { Assessment, ListOrdersResponse, Motive } from './types';
import { OrderDetails } from '@onbeefapp/constants';

interface ListOrdersParams {
  page: number;
  merchant_id: string;
}

async function listOrders({ page, merchant_id }: ListOrdersParams) {
  const { data } = await api.get<ListOrdersResponse>(
    '/customers/orders/resume?page=' + page,
    {
      headers: { merchantId: merchant_id },
    },
  );
  return data;
}

async function getOrderDetail(ctx: QueryFunctionContext) {
  const [, id] = ctx.queryKey;
  if (!id) {
    return;
  }
  const { data } = await api.get<OrderDetails>(
    '/customers/orders/resume/' + id,
  );
  return data;
}

async function getOrderDetailMerchant(ctx: QueryFunctionContext) {
  const [, id] = ctx.queryKey;
  if (!id) {
    return;
  }
  const { data } = await api.get<OrderDetails>('/merchants/orders/' + id);
  return data;
}

async function getMotives(_ctx: QueryFunctionContext) {
  const { data } = await api.get<Motive[]>('/motives');
  return data;
}

type RegisterAssessmentParams = Omit<Assessment, 'motives'> & {
  motives: string[];
};

async function registerAssessment(assessment: RegisterAssessmentParams) {
  const { data } = await api.post('/customers/assessment', { ...assessment });
  return data;
}

export function useListOrders() {
  return useMutation(['listOrders'], listOrders);
}

export function useGetOrderDetail(id?: string) {
  return useQuery(['getOrderDetail', id], getOrderDetail, {
    retry: 1,
    refetchOnWindowFocus: false,
  });
}

export function useGetOrderDetailMerchant(id?: string) {
  return useQuery(['getOrderDetail', id], getOrderDetailMerchant, {
    retry: 1,
  });
}

export function useGetMotives() {
  return useQuery(['getMotives'], getMotives);
}

export function useRegisterAssessment() {
  return useMutation(['registerAssessment'], registerAssessment);
}
