import React from 'react';
import { useGetSubscriptionStatus } from '../../../queries/club';
import { UserClub } from '../../../queries/login/types';
import { useAuthStore } from '../../../stores/login';
import { ClubSubscriptionStatus } from '@onbeefapp/constants';

export interface PendingClubSubscriptionProps {
  subscription: UserClub;
}

export const PendingClubSubscription: React.FC<
  PendingClubSubscriptionProps
> = ({ subscription }) => {
  const userClubs = useAuthStore((state) => state.user?.clubs);
  const updateUserClubSubscriptions = useAuthStore(
    (state) => state.updateUserClubSubscriptions,
  );
  const { refetch, data } = useGetSubscriptionStatus(subscription.id);

  React.useEffect(() => {
    if (data && data.status !== ClubSubscriptionStatus.PENDING) {
      updateUserClubSubscriptions(
        userClubs?.map((sub) => {
          if (sub.id === data.id) return { ...sub, ...data };
          return sub;
        }) || [],
      );
    } else {
      refetch();
    }
  }, [data]);

  return (
    <div className="w-full flex flex-col space-y-4">
      <div className="flex flex-row items-center justify-center gap-1">
        <button
          type="button"
          className={`bg-orange-500 flex font-semibold justify-center text-sm p-2 text-contrastText w-full rounded-md`}
        >
          Confirmando pagamento
        </button>
      </div>
    </div>
  );
};
