import React from 'react';
import { useNavigate } from 'react-router-dom';
import Star from '../../assets/images/star_new.png';
import { useAuthStore } from '../../stores/login';
import { useMerchantStore } from '../../stores/merchant';
import { useMerchantCache } from '../../queries/home';
import { useSlug } from '../../hooks/useSlug';
import { Skeleton } from '@onbeef/components/skeleton';

export interface LoyaltyCTAProps {
  withoutLink?: boolean;
}

export const LoyaltyCTA: React.FC<LoyaltyCTAProps> = ({
  withoutLink = false,
}) => {
  const loyalty_programme = useMerchantStore(
    (state) => state.loyalty_programme,
  );

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const user = useAuthStore((state) => state.user);
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);
  const navigate = useNavigate();

  const handleOpenLoyaltyClub = () => {
    if (withoutLink) return;
    if (haveAccount && userLoyalty) {
      navigate(`/loyalty/detail`);
    } else {
      navigate(`/loyalty/`);
    }
  };

  const userLoyalty = user?.loyalty_programmes?.[0];
  const isLoyaltyEnabled =
    loyalty_programme != null && loyalty_programme.status === 'ACTIVE';

  const haveAccount = isAuthenticated && user;

  return (
    isLoyaltyEnabled && (
      <Skeleton isLoading={isLoading} className="w-full h-32 rounded-lg ">
        <div
          onClick={handleOpenLoyaltyClub}
          className="border-2 flex h-full w-full flex-col place-content-center border-gray-300 rounded-md cursor-pointer hover:bg-gray-200"
        >
          <div className="place-content-center p-3">
            <center>
              <img src={Star} className="w-[50px] self-center" alt="Estrelas" />
            </center>
          </div>
          <div className="text-center w-full text-[10px] p-1 font-semibold text-gray-500 border-t-2 border-gray-300">
            Compre e ganhe benefícios
          </div>
        </div>
      </Skeleton>
    )
  );
};
