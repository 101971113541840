import ComplementProduct from '@onbeef/components/complementProduct';
import { ProductType } from '@onbeefapp/constants';
import * as React from 'react';
import { ComplementItem } from '../../../../../packages/types/types';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import * as Types from '@onbeefapp/constants/src/utils/interfaces';
import { ProductCartType, useCartStore } from '../../stores/cart';
import { useComplementsStore } from '../../stores/complements';
import { useDrawerStore } from '../../stores/drawer';
import { getQuantity } from '../../utils/ComplementUtils';
import {
  getQuantity as ProductQuantity,
  getComments,
} from '../../utils/ProductUtils';

interface ComplementProps {
  complement: Types.Complement;
  product: Types.Product;
  setRef: (element: HTMLDivElement | null) => void;
}

const Complement: React.FC<ComplementProps> = ({
  complement,
  product,
  setRef,
}) => {
  const params = useSlug();
  const { isLoading } = useMerchantCache(params.slug);

  const { toggleDrawer, setProductDrawer, setComplement, setComplementItem } =
    useDrawerStore((state) => ({
      toggleDrawer: state.toggleDrawer,
      setProductDrawer: state.setProductDrawer,
      setComplement: state.setComplement,
      setComplementItem: state.setComplementItem,
    }));

  const {
    addComplement,
    deleteComplements,
    getSumQuantityComplements,
    getSumQuantityComplementsByComplementId,
    getCountComplementsTotal,
  } = useComplementsStore((state) => ({
    addComplement: state.addComplement,
    deleteComplements: state.deleteComplements,
    getSumQuantityComplementsByComplementId:
      state.getSumQuantityComplementsByComplementId,
    getSumQuantityComplements: state.getSumQuantityComplements,
    getCountComplementsTotal: state.getCountComplementsTotal,
  }));
  const { addCart, deleteProduct, removeProduct, products } = useCartStore(
    (state) => ({
      addCart: state.addCart,
      deleteProduct: state.deleteProduct,
      removeProduct: state.removeProduct,
      products: state.products,
    }),
  );

  const { quantity: quantityProduct } = ProductQuantity(products, product);
  const { comments } = getComments(product.id);

  const getQuantityItemComplement = (item: Types.Item) => {
    const {
      formatted: formattedQuantity,
      number: quantityNumber,
      quantity: quantityComplement,
    } = getQuantity(product.id, complement.id, item.id);
    return { formattedQuantity, quantityNumber, quantityComplement };
  };

  // Função para abrir o drawer de gramas
  const handleClickGrams = (item: Types.Item) => {
    if (product.product_type === ProductType.KG) {
      if (complement.items_kg === 0) {
        return handleAddComplement(item);
      }
      setProductDrawer(product as ProductCartType);
      setComplementItem(item);
      setComplement(complement);
      toggleDrawer('drawerGrams');
    }
  };

  // Função para adicionar a quantidade do produto
  const handleAddComplement = (item: Types.Item) => {
    if (
      (product.product_type === ProductType.KG && !complement) ||
      (product.product_type === ProductType.KG && complement.items_kg === 1)
    ) {
      handleClickGrams(item);
    } else {
      const quantityValue = getQuantityItemComplement(item).quantityNumber + 1;
      const sumQuantityComplements = getSumQuantityComplementsByComplementId(
        product.id,
        complement.id,
      );

      if (sumQuantityComplements < complement.quantity_max) {
        if (
          getQuantityItemComplement(item).quantityNumber === 1 &&
          complement.no_repeat === 0
        ) {
          return;
        }
        const newComplementItem: ComplementItem = {
          ...item,
          quantity: quantityValue,
          type: ProductType.UNIT,
          totalQuantity: quantityValue,
          items_kg: complement.items_kg,
        };
        addComplement(product.id, complement.id, newComplementItem);

        // Caso seja um complemento sendo adicionado e tipo de produto
        // é por KG, porém o complemento não deve afetar o peso (setinho angus)
        if (
          (complement &&
            product.product_type === ProductType.KG &&
            complement.items_kg === 0) ||
          product.product_type === ProductType.UNIT ||
          product.product_type === ProductType.KIT
        )
          if (product.cuts?.length > 0) {
            addCart(product, 0, comments);
          } else if (quantityProduct > 0) {
            addCart(product, quantityProduct, comments);
          } else {
            addCart(product, 1, '');
          }
      }
    }
  };

  // Função para diminuir a quantidade do produto
  const handleRemoveComplement = (item: Types.Item) => {
    if (product.product_type === ProductType.KG) {
      if (
        product.estimated_weight &&
        getQuantityItemComplement(item).quantityNumber <=
          product.estimated_weight
      ) {
        const newComplementItem: ComplementItem = {
          ...item,
          quantity: 0,
          type: product.product_type,
          totalQuantity: 0,
          items_kg: complement.items_kg,
        };
        addComplement(product.id, complement.id, newComplementItem);
        let quantity =
          quantityProduct - getQuantityItemComplement(item).quantityComplement;
        if (quantity < 0) {
          quantity = 0;
        }

        // Caso seja um complemento sendo removido e tipo de produto
        // é por KG, porém o complemento não deve afetar o peso (setinho angus)
        if (
          complement &&
          product.product_type === ProductType.KG &&
          complement.items_kg === 0 &&
          product?.cuts?.length < 0
        )
          return;

        if (product.cuts?.length > 0) quantity = 0;

        addCart(product, quantity, '');
        return;
      }
      handleClickGrams(item);
    } else {
      let quantityValue = getQuantityItemComplement(item).quantityNumber - 1;
      if (quantityValue < 0) {
        quantityValue = 0;
      }
      const complementItem: ComplementItem = {
        ...item,
        quantity: quantityValue,
        type: product.product_type,
        totalQuantity: quantityValue,
        items_kg: complement.items_kg,
      };
      const quantityComplements = getSumQuantityComplements(product.id);
      if (quantityComplements == 1) {
        deleteComplements(product.id);
        deleteProduct(product.id);
        removeProduct(product.id);
      } else {
        addComplement(product.id, complement.id, complementItem);
        addCart(product, quantityProduct, comments);
      }
    }
  };

  const props = {
    complement,
    product,
    handleClickGrams,
    isLoading,
    handleAddComplement,
    handleRemoveComplement,
    getQuantityItemComplement,
    quantityComplements: getCountComplementsTotal(product.id, complement.id),
    setRef,
  };

  return <ComplementProduct {...props} />;
};

export default Complement;
