import React, { useEffect } from 'react';
import { useSlug } from '../../hooks/useSlug';
import { useMerchantCache } from '../../queries/home';
import { DrawerFilters } from '@onbeefapp/drawers';
import { TrackingContext } from '../../contexts/TrackingContext';
import { useDrawerStore } from '../../stores/drawer';
import { useProductsStore } from '../../stores/products';
import FilterCategory from '../Filters/FilterCategory';
import HideMobile from '../HideMobile/HideMobile';
import Skeleton from '../Skeleton/Skeleton';
import ProductSearchInput from './ProductSearchInput';
import { Filter } from '@onbeefapp/constants';
import { useMerchantStore } from '../../stores/merchant';
import { CRMProgressBar } from '../CRMProgressBar';

interface SearchBarProps {
  cart?: boolean;
  nameSelected?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  cart = false,
  nameSelected,
}) => {
  const params = useSlug();

  const merchantFilters = useMerchantStore((state) => state.filters);

  const { isLoading } = useMerchantCache(params.slug);

  const [isCart, setIsCart] = React.useState(false);

  useEffect(() => {
    const stickyContainer = document.getElementById('stickyContainer');

    if (stickyContainer) {
      const initialOffsetTop = stickyContainer.offsetTop;

      window.addEventListener('scroll', () => {
        if (window.scrollY >= initialOffsetTop) {
          setIsCart(true);
          stickyContainer.classList.add('fixed');
        } else {
          setIsCart(false);
          stickyContainer.classList.remove('fixed');
        }
      });
    }
  }, []);

  const productStore = useProductsStore();
  const {
    isOpen,
    toggleDrawer,
    key: id_drawer,
  } = useDrawerStore((state) => ({
    isOpen: state.isOpen,
    toggleDrawer: state.toggleDrawer,
    key: state.key,
  }));

  return (
    <div
      className="sticky p-2 top-0 z-40 pt-1 bg-[#f5f5f5] pb-px transition-all duration-300 ease-in-out"
      id="stickyContainer"
    >
      <Skeleton
        width="100%"
        height="60px"
        borderRadius="5px"
        isLoading={isLoading}
      >
        <CRMProgressBar />
        <ProductSearchInput cart={cart ? cart : isCart} />
        <DrawerFilters
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
          key={'drawerFilters'}
          id={id_drawer ?? ''}
          filters={merchantFilters}
          filterProducts={productStore.filterProducts}
          selectedFilters={productStore.filters}
          clearSelectedFilters={productStore.clearFilters}
          setSelectedFilter={productStore.setSelectedFilter}
        />
        <HideMobile>
          <FilterCategory nameSelected={nameSelected} />
        </HideMobile>
      </Skeleton>
    </div>
  );
};

export default SearchBar;
