import React from 'react';

interface SkeletonTabProps {
  isLoading: boolean;
  children: React.ReactNode;
}

const SkeletonAward: React.FC<SkeletonTabProps> = ({ children, isLoading }) => {
  if (!isLoading) return <>{children}</>;

  return (
    <div
      className={`flex items-center justify-center cursor-pointer p-2 relative w-full`}
    >
      <div
        className={`bg-gray-200 animate-pulse w-[110px] h-8 rounded-[10px]`}
      ></div>
    </div>
  );
};

export default SkeletonAward;
