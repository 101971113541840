import { OrderDetails } from '@onbeefapp/constants';
import { Order } from '../queries/my-orders/types';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type MyOrdersStore = {
  orders: Record<number, Order[]>;
  selectedOrder?: OrderDetails;
  isLoading: boolean;
  setOrders: (page: number, orders: Order[]) => void;
  setSelectedOrder: (selectedOrder: OrderDetails | undefined) => void;
  setIsLoading: (isLoading: boolean) => void;
};

export const useMyOrdersStore = create(
  persist<MyOrdersStore>(
    (set) => ({
      orders: {},
      selectedOrder: undefined,
      isLoading: false,
      setOrders: (page: number, orders: Order[]) => {
        set((state) => ({
          orders: { ...state.orders, [page]: orders },
        }));
      },
      setSelectedOrder: (selectedOrder) => {
        set(() => ({
          selectedOrder: selectedOrder,
        }));
      },
      setIsLoading: (isLoading) => {
        set(() => ({
          isLoading: isLoading,
        }));
      },
    }),
    {
      name: 'my-orders',
      version: 1,
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
