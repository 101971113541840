import React from 'react';

interface SkeletonProps {
  children: React.ReactNode;
  isLoading: boolean;
}

const SkeletonCoupons: React.FC<SkeletonProps> = ({ children, isLoading }) => {
  return isLoading ? (
    <div className={'flex flex-wrap items-center space-y-2'}>
      {Array.from({ length: 15 }, (_, i) => i).map((item) => (
        <div
          key={`skeletonOrder${item}`}
          className="bg-gray-200 animate-pulse w-[100%] h-[150px]"
        ></div>
      ))}
    </div>
  ) : (
    children
  );
};

export default SkeletonCoupons;
