import { Toast } from '@onbeefapp/constants';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import DrawerGrams from '../../components/Drawers/DrawerGrams';
import HeaderBar from '../../components/Headers/HeaderBar';
import { Tab } from '../../components/Tab/Tab';
import { TrackingContext } from '../../contexts/TrackingContext';
import { useCartStore } from '../../stores/cart';
import { useComplementsStore } from '../../stores/complements';
import { useMerchantStore } from '../../stores/merchant';
import { useOrderDetails } from '../../stores/order-details';
import { useUserStore } from '../../stores/user';
import {
  DeliveryMerchantType,
  DeliveryType,
  StepType,
} from '../../utils/constants';
import CartSummary from './CartSummary';
import Delivery from './Delivery';
import Withdraw from './Withdraw';

interface Props {
  onClickBack?: () => void;
  onClickContinue?: () => void;
  showSummary?: boolean;
  orderId?: string;
  handleExtensionMoreSchedulings?: () => void;
}

const Cart: React.FC<Props> = ({
  onClickBack,
  onClickContinue,
  handleExtensionMoreSchedulings,
  showSummary,
  orderId,
}) => {
  const { clearCart, products, isExtension } = useCartStore((state) => ({
    clearCart: state.clearCart,
    products: state.products,
    isExtension: state.isExtension,
  }));
  const { clearMerchantIdComplements } = useComplementsStore((state) => ({
    clearMerchantIdComplements: state.clearMerchantIdComplements,
  }));
  const { delivery } = useMerchantStore((state) => ({
    delivery: state.delivery,
  }));
  const { track } = React.useContext(TrackingContext);

  const deliveryMerchant = delivery && delivery.type[0];

  const {
    setCurrentStep,
    setDeliveryType,
    deliveryType,
    setDeliveryInstructions,
  } = useOrderDetails((state) => {
    return {
      currentStep: state.currentStep,
      setCurrentStep: state.setCurrentStep,
      setDeliveryType: state.setDeliveryType,
      setDeliveryInstructions: state.setDeliveryInstructions,
      deliveryType: state.deliveryType,
    };
  });

  React.useEffect(() => {
    if (deliveryMerchant === DeliveryMerchantType.WITHDRAWAL_AND_DELIVERY) {
      setDeliveryType(deliveryType || DeliveryType.DELIVERY);
      setActiveTab(
        deliveryType ? (deliveryType === DeliveryType.WITHDRAWAL ? 2 : 1) : 1,
      );
    } else if (deliveryMerchant === DeliveryMerchantType.WITHDRAWAL) {
      setDeliveryType(DeliveryType.WITHDRAWAL);
      setActiveTab(2);
    } else {
      setDeliveryType(DeliveryType.DELIVERY);
      setActiveTab(1);
    }
  }, []);

  const [activeTab, setActiveTab] = React.useState<number>(
    deliveryType === DeliveryType.WITHDRAWAL ? 2 : 1,
  );
  const { address } = useUserStore((state) => ({
    address: state.address,
  }));

  React.useEffect(() => {
    if (!address && deliveryType !== DeliveryType.WITHDRAWAL) {
      setCurrentStep(StepType.ADDRESS);
    }
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const handleClearCartClick = () => {
    setDeliveryInstructions('');
    track(`OnCustomerAtCartClear`, {});
    track(`OnCustomerLeaveCart`, {});
    clearCart();
    clearMerchantIdComplements();
    if (!isExtension) {
      navigate(`/`);
    } else if (onClickBack) {
      onClickBack();
    }
    Toast.success('Sua sacola está vazia');
  };

  const [extensionCustomCouponCode, setExtensionCustomCouponCode] =
    React.useState<string | undefined>('');
  const [extensionCustomDeliveryFee, setExtensionCustomDeliveryFee] =
    React.useState<number | string | undefined>(0);

  const handleSetCouponCode = (code: string | undefined) => {
    setExtensionCustomCouponCode(code);
  };

  const handleSetDeliveryFee = (fee: number | string | undefined) => {
    setExtensionCustomDeliveryFee(fee);
  };

  return (
    <React.Fragment>
      <div className="pb-24" id="cart-home">
        <div className="p-2">
          <div className="fixed top-0 z-40 pt-3 pr-[17px] bg-[#f5f5f5] pb-px transition-all duration-300 ease-in-out w-full">
            {/* HeaderBar */}
            {isExtension ? (
              <HeaderBar isCart={true} onClickBack={onClickBack}>
                <div
                  className={`text-sm font-normal text-center ${
                    Object.values(products).length <= 0 ? 'w-full' : 'ml-8'
                  }`}
                >
                  Sacola
                </div>
                {Object.values(products).length > 0 && (
                  <button
                    className="text-xs font-semibold text-[#d90016] text-center"
                    onClick={handleClearCartClick}
                  >
                    Limpar
                  </button>
                )}
              </HeaderBar>
            ) : (
              <HeaderBar isCart={true}>
                <div
                  className={`text-sm font-normal text-center ${
                    Object.values(products).length <= 0 ? 'w-full' : 'ml-8'
                  }`}
                >
                  Sacola
                </div>
                {Object.values(products).length > 0 && (
                  <button
                    className="text-xs font-semibold text-[#d90016] text-center"
                    onClick={handleClearCartClick}
                  >
                    Limpar
                  </button>
                )}
              </HeaderBar>
            )}

            {/* Two tabs with icon on the left and text on the right */}
            <div className="flex h-[55px]">
              <Tab
                show={
                  deliveryMerchant === DeliveryMerchantType.DELIVERY ||
                  deliveryMerchant ===
                    DeliveryMerchantType.WITHDRAWAL_AND_DELIVERY
                    ? true
                    : false
                }
                active={activeTab === 1}
                onClick={() => {
                  setDeliveryType(DeliveryType.DELIVERY);
                  if (!address) setCurrentStep(StepType.ADDRESS);
                  handleTabClick(1);
                  const div = document.getElementById('cart');
                  if (div) {
                    div.scrollTop = 0;
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`w-9 h-9 mr-2 rounded-full p-2 ${
                    activeTab === 1
                      ? 'bg-primary text-contrastText'
                      : 'text-primary'
                  }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                  />
                </svg>

                <span className="ml-1 text-primary text-sm">Entrega</span>
              </Tab>
              <Tab
                show={
                  deliveryMerchant === DeliveryMerchantType.WITHDRAWAL ||
                  deliveryMerchant ===
                    DeliveryMerchantType.WITHDRAWAL_AND_DELIVERY
                    ? true
                    : false
                }
                active={activeTab === 2}
                onClick={() => {
                  setDeliveryType(DeliveryType.WITHDRAWAL);
                  setCurrentStep(StepType.FINISHED);
                  handleTabClick(2);
                  const div = document.getElementById('cart');
                  if (div) {
                    div.scrollTop = 0;
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`w-9 h-9 mr-2 rounded-full p-2 ${
                    activeTab === 2
                      ? 'bg-primary text-contrastText'
                      : 'text-primary'
                  }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                  />
                </svg>

                <span className="ml-1 text-primary text-sm">Retirada</span>
              </Tab>
            </div>
          </div>

          {/* Content */}

          {/* Content delivery */}

          <Delivery
            active={activeTab === 1}
            setExtensionCustomCouponCode={handleSetCouponCode}
            setExtensionCustomDeliveryFee={handleSetDeliveryFee}
            handleExtensionMoreSchedulings={handleExtensionMoreSchedulings}
          />

          {/* Content withdraw */}

          <Withdraw
            active={activeTab === 2}
            setExtensionCustomCouponCode={handleSetCouponCode}
            setExtensionCustomDeliveryFee={handleSetDeliveryFee}
          />
        </div>
        <CartSummary
          orderId={orderId}
          onClickContinue={onClickContinue}
          showSummary={showSummary}
          extensionCustomCouponCode={extensionCustomCouponCode}
          extensionCustomDeliveryFee={extensionCustomDeliveryFee}
          setExtensionCustomCouponCode={handleSetCouponCode}
          setExtensionCustomDeliveryFee={handleSetDeliveryFee}
        />
      </div>

      {/* Drawer com as especifições da gramatura do produto */}
      {!isExtension && <DrawerGrams />}
    </React.Fragment>
  );
};

export default Cart;
