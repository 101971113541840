import { Toast, WherePaymentType } from '@onbeefapp/constants'
import cx from 'classnames'
import * as React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import noAvatar from '../../../apps/catalogo/src/assets/images/noAvatar.png'
import FormCheckout from '../../../apps/catalogo/src/components/Forms/FormCheckout'
import HeaderBar from '../../../apps/catalogo/src/components/Headers/HeaderBar'
import OpenForOrdersModal from '../../../apps/catalogo/src/components/Modals/OpenForOrdersModal'
import Skeleton from '../../../apps/catalogo/src/components/Skeleton/Skeleton'
import { OrderDetailsContext } from '../../../apps/catalogo/src/contexts/OrderDetailsContext'
import { TrackingContext } from '../../../apps/catalogo/src/contexts/TrackingContext'
import { useSlug } from '../../../apps/catalogo/src/hooks/useSlug'
import { useGetLinkOrderDetails } from '../../../apps/catalogo/src/queries/checkout-link'
import { useMerchantCache } from '../../../apps/catalogo/src/queries/home'
import { useGetSchedulings } from '../../../apps/catalogo/src/queries/order'
import { useCartStore } from '../../../apps/catalogo/src/stores/cart'
import { useComplementsStore } from '../../../apps/catalogo/src/stores/complements'
import { useAuthStore } from '../../../apps/catalogo/src/stores/login'
import { useMerchantStore } from '../../../apps/catalogo/src/stores/merchant'
import { useOrderDetails } from '../../../apps/catalogo/src/stores/order-details'
import { useUserStore } from '../../../apps/catalogo/src/stores/user'
import { getUserClub } from '../../../apps/catalogo/src/utils/ClubUtils'

const Checkout: React.FC = () => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)
  const user = useAuthStore((state) => state.user)
  const clearUser = useUserStore((state) => state.clear)
  const {
    clearCart,
    products,
    isCheckoutLink,
    setIsCheckoutLink,
    setLinkOrderDetails,
  } = useCartStore((state) => ({
    clearCart: state.clearCart,
    products: state.products,
    isCheckoutLink: state.isCheckoutLink,
    setIsCheckoutLink: state.setIsCheckoutLink,
    setLinkOrderDetails: state.setLinkOrderDetails,
  }))
  const { clearMerchantIdComplements } = useComplementsStore((state) => ({
    clearMerchantIdComplements: state.clearMerchantIdComplements,
  }))

  const {
    setDeliveryInstructions,
    setWherePaymentType,
    setSelectedPaymentMethod,
  } = useOrderDetails((state) => {
    return {
      setDeliveryInstructions: state.setDeliveryInstructions,
      setWherePaymentType: state.setWherePaymentType,
      setSelectedPaymentMethod: state.setSelectedPaymentMethod,
    }
  })

  const params = useParams()
  const { slug } = useSlug()

  const { isLoading: isLoadingMerchant } = useMerchantCache(slug)
  const navigate = useNavigate()
  const { clubs, merchant } = useMerchantStore((state) => ({
    clubs: state.clubs,
    merchant: state.merchant,
  }))

  // getting scheduling data
  const { data } = useGetSchedulings(merchant?.id)

  const { clubDiscountAvailable, totalClubDiscountFormatted } =
    React.useContext(OrderDetailsContext)

  const { mutateAsync: getLinkOrderDetails } = useGetLinkOrderDetails()

  const { track } = React.useContext(TrackingContext)

  const handleClearCartClick = () => {
    setDeliveryInstructions('')
    track(`OnCustomerAtCheckoutCartClear`, {})
    track(`OnCustomerLeaveCheckout`, {})
    clearCart()
    clearMerchantIdComplements()
    navigate(`/`)
    Toast.success('Sua sacola está vazia')
  }

  React.useEffect(() => {
    const run = async () => {
      const { id, code } = params
      if (id && code) {
        try {
          const order = await getLinkOrderDetails({ orderID: id, code: code })
          if (order) {
            setLinkOrderDetails(order)
            setIsCheckoutLink(true)
            setWherePaymentType(WherePaymentType.APP)
            setSelectedPaymentMethod(undefined)
            clearUser()
          }
        } catch (error) {
          setLinkOrderDetails(undefined)
          setIsCheckoutLink(false)
          Toast.error(
            'Link de pagamento inválido, entre em contato com a loja!'
          )
          navigate('/', { replace: true })
        }
      } else {
        setLinkOrderDetails(undefined)
        setIsCheckoutLink(false)
      }
    }
    run()
  }, [])

  const activeClub = isAuthenticated ? getUserClub() : null

  return (
    <React.Fragment>
      <div className="p-2">
        <div className="fixed top-0 z-30 pt-3 pr-[17px] bg-[#f5f5f5] pb-px transition-all duration-300 ease-in-out w-full">
          {/* HeaderBar */}
          <HeaderBar isCheckout={true}>
            <div
              className={`text-sm font-normal text-center ${
                Object.values(products).length <= 0 || isCheckoutLink
                  ? 'w-full'
                  : 'ml-8'
              }`}
            >
              {isCheckoutLink ? 'Link de pagamento' : 'Sacola'}
            </div>
            {!isCheckoutLink && Object.values(products).length > 0 && (
              <button
                className="text-xs font-semibold text-[#d90016] text-center"
                onClick={handleClearCartClick}
              >
                Limpar
              </button>
            )}
          </HeaderBar>
        </div>

        <div className="flex flex-col space-y-1 items-start justify-start mt-[55px] px-1">
          <div
            className={cx(
              'flex flex-row justify-start  border-b-[1px] pb-2 space-x-2',
              isCheckoutLink ? 'items-center' : 'items-start'
            )}
          >
            <Skeleton
              className="w-10 h-10 md:h-14 md:w-14"
              borderRadius="9999px"
              isLoading={isLoadingMerchant}
            >
              <img
                className="w-10 h-10 rounded-full md:h-14 md:w-14"
                src={merchant?.logo || noAvatar}
                alt="Logotipo"
                loading="lazy"
              />
            </Skeleton>
            <div className="flex flex-col items-start justify-start space-y-1">
              <Skeleton
                isLoading={isLoadingMerchant}
                className="w-48 h-4 rounded-lg"
              >
                <div
                  className="text-sm font-normal text-left"
                  onClick={() => {
                    track(`OnCustomerLeaveCheckout`, {})
                    track(`OnCustomerViewMerchantDetails`, {})
                    navigate('details')
                  }}
                >
                  {merchant?.name}
                </div>
              </Skeleton>

              {!isCheckoutLink && (
                <Skeleton
                  isLoading={isLoadingMerchant}
                  className="w-32 h-4 rounded-lg"
                >
                  <button
                    className="text-xs font-bold text-[#d90016] text-left"
                    onClick={() => {
                      track(`OnCustomerLeaveCheckout`, {})
                      navigate(`/`)
                    }}
                  >
                    Adicionar mais itens
                  </button>
                </Skeleton>
              )}
            </div>
          </div>
        </div>
        {clubs && clubs.length > 0 && clubDiscountAvailable && (
          <div className="flex flex-col shadow-md p-4 items-center space-y-3 mb-2">
            <Skeleton
              className="w-full h-9 rounded-lg"
              isLoading={isLoadingMerchant}
            >
              <div className="w-full flex flex-col items-center justify-center space-y-1 text-green-600">
                {user && activeClub ? (
                  <Link
                    to="/club"
                    state={{ clubName: activeClub.club_name }}
                    className="underline"
                  >
                    {user.name} - Membro VIP {activeClub.club_name}
                  </Link>
                ) : (
                  <div className="w-full flex flex-col items-center space-y-2 text-sm">
                    <button
                      type="button"
                      className="w-full px-2 py-1 border border-green-600 rounded-lg bg-transparent"
                    >
                      <Link to="/club" state={{ goBack: '/checkout' }}>
                        Economize {totalClubDiscountFormatted} nessa compra!
                      </Link>
                    </button>
                    {!isAuthenticated && (
                      <Link
                        to="/login"
                        state={{ goBack: '/checkout' }}
                        className="underline font-bold"
                      >
                        Já sou membro
                      </Link>
                    )}
                  </div>
                )}
              </div>
            </Skeleton>
          </div>
        )}
        <FormCheckout
          setExtensionCustomCouponCode={() => {}}
          setExtensionCustomDeliveryFee={() => {}}
        />
      </div>
      {data && data?.status === 'ACTIVE' && <OpenForOrdersModal />}
    </React.Fragment>
  )
}

export default Checkout
