import React from 'react'
import cx from 'classnames'

interface Props {
  id: string
  name: string
  active: boolean
  index: number
  responsive?: boolean
  onClick: () => void
  className?: string
}

const Category: React.FC<Props> = ({
  id,
  name,
  active,
  index,
  responsive = true,
  onClick,
  className,
}) => {
  return (
    <div
      key={`category-${id}-${index}`}
      id={`category-${id}`}
      onClick={onClick}
      className={cx(
        `snap-start cursor-pointer shrink-0  flex flex-col min-w-[20%]  md:min-w-[10%] pl-2 pr-2 transition-all ${
          responsive && 'lg:border-none lg:justify-center lg:h-10'
        } border border-current relative font-semibold ${
          active
            ? 'bg-primary text-contrastText'
            : 'bg-transparent text-primary'
        } lg:rounded-md rounded-2xl text-sm lg:text-sm text-center font-medium`,
        className
      )}
    >
      {name}
    </div>
  )
}

Category.displayName = 'Category'
export type CategoryProps = Props
export default Category
