import { useMutation } from '@tanstack/react-query';
import api from '../../services/api';
import { CRMBonusDetailResponse } from './types';

const CRMSendPhoneCode = async (token: string) => {
  const { data } = await api.post(`/all/customers/verify/phone`, { token });
  return data;
};

const CMRValidatePhone = async ({
  code,
  token,
}: {
  code: string;
  token: string;
}) => {
  const { data } = await api.post(`/all/customers/crm/validation`, {
    code,
    token,
  });
  return data;
};

const CRMGetBonusDetails = async ({
  id,
  name,
  phone,
  balance_amount,
}: {
  id: string;
  name: string;
  phone: string;
  balance_amount?: number;
}) => {
  const { data } = await api.get<CRMBonusDetailResponse>(
    `/all/customers/${phone}/merchants/${id}/crm/bonus?balance_amount=${
      balance_amount || 0
    }&name=${name}`,
  );
  return data;
};

export function useCRMSendPhoneCode() {
  return useMutation(['CRMSendPhoneCode'], CRMSendPhoneCode);
}

export function useCRMValidatePhone() {
  return useMutation(['CMRValidatePhone'], CMRValidatePhone);
}

export function useCRMGetBonusDetails() {
  return useMutation(['CRMGetBonusDetails'], CRMGetBonusDetails);
}
